import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import {
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';

import {
  Icon,
} from '../../common';

interface IProps {
  contactId: string;
  contactType: string;
  isTrackingContact?: boolean;
  onRemove: (contactId: string, contactType: string) => void;
  onSetTrackingContact: (contactId: string, contactType: string, removeAsTrackingContact: boolean) => void;
}

@autoBindMethods
@observer
class ContactRowDropdown extends Component<IProps> {

  @action
  private onRemove () {
    const { contactId, contactType, onRemove } = this.props;
    onRemove(contactId, contactType);
  }

  @action
  private onSetTrackingContact () {
    const { contactId, contactType, isTrackingContact, onSetTrackingContact } = this.props
      , removeAsTrackingContact = !!isTrackingContact;
    onSetTrackingContact(contactId, contactType, removeAsTrackingContact);
  }

  public render () {
    const {
      contactType,
      isTrackingContact,
    } = this.props;

    return (
      <div className='dropdown-arrow right text-center'>
        <DropdownButton
          bsStyle='link'
          id={`contact-row-dropdown-${contactType}`}
          noCaret
          pullRight
          title={<Icon type='ellipsis-v' />}
        >
          <MenuItem eventKey='remove' onSelect={this.onRemove}>
            <Icon type='trash' /> Remove
          </MenuItem>
          <MenuItem eventKey='set-tracking-contact' onSelect={this.onSetTrackingContact}>
            {isTrackingContact ? 'Remove' : 'Set'} as Tracking Contact
          </MenuItem>
        </DropdownButton>
      </div>
    );
  }
}

export default ContactRowDropdown;
