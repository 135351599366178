import { computed, observable } from 'mobx';

import { ICase } from '../interfaces';

class CaseUpdateStoreClass {
  @observable public _case: ICase | null = null;
  @observable public caseSearchResults: ICase[] = [];

  public clear () {
    this._case = null;
    this.caseSearchResults = [];
  }

  @computed
  public get sidebarData () {
    if (!this._case) { return null; }

    const { attorney, date_of_loss, id, tracking_summary, lawfirm, plaintiff } = this._case
      , trackingStatus = tracking_summary && tracking_summary.tracking_status
      ;

    return {
      attorney: attorney ? `${attorney.first_name} ${attorney.last_name}` : '',
      case: id,
      date_of_loss,
      last_tracking_status: trackingStatus,
      lawfirm: lawfirm ? lawfirm.name : '',
      plaintiff_name: plaintiff ? `${plaintiff.first_name} ${plaintiff.last_name}` : '',
    };
  }
}

export default CaseUpdateStoreClass;
