import React, { Component } from 'react';

import {
  IPlaintiff,
} from '../../interfaces';

import {
  FormModal,
} from '../../lib/mighty-fields';

import FIELD_SETS_PLAINTIFF from './main-contacts/FieldSets';

interface IProps {
  onClose: () => void;
  onSave: () => void;
  plaintiff: IPlaintiff;
}

class PlaintiffInfoModal extends Component<IProps> {
  public render () {
    const {
      onClose,
      onSave,
      plaintiff,
    } = this.props;

    return (
      <FormModal
        fieldSets={FIELD_SETS_PLAINTIFF}
        model={plaintiff}
        onClose={onClose}
        onSave={onSave}
        title='Plaintiff Info'
      />
    );
  }
}

export default PlaintiffInfoModal;
