import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import UpdateRequestForm from './UpdateRequestForm';
import WrongAttorneyForm from './WrongAttorneyForm';
import NoUpdateForm from './NoUpdateForm';
import UpdateRequestButton from './UpdateRequestButton';
import { AppConstants } from '../../constants';
import { SessionStoreClass } from '../../stores';

const { UPDATE_REQUEST_TYPES } = AppConstants;

interface IProps {
  fromTask?: boolean;
  onSubmit: (data: any) => void;
  valueKey?: string;
}

interface IInjected extends IProps {
  SessionStore: SessionStoreClass;
}

@inject('SessionStore')
@autoBindMethods
@observer
class UpdateRequestOptions extends Component <IProps> {
  get injected () {
    return this.props as IInjected;
  }

  get followUpText () {
    const { SessionStore: { isGuest } } = this.injected;

    if (isGuest) { return 'When should we contact you again for an update?'; }

    return 'Schedule a follow-up date';
  }

  public render () {
    const { onSubmit, valueKey, fromTask } = this.props
      , commonProps = { onSubmit, fromTask, followUpText: this.followUpText }
      ;

    switch (valueKey) {
      case UPDATE_REQUEST_TYPES.HAS_UPDATE.key:
        return <UpdateRequestForm {...commonProps} />;
      case UPDATE_REQUEST_TYPES.ATTORNEY_NO_LONGER_REPRESENTS_PLAINTIFF.key:
        return <WrongAttorneyForm {...commonProps} attribute={valueKey} />;
      case UPDATE_REQUEST_TYPES.ATTORNEY_NEVER_REPRESENTED_PLAINTIFF.key:
        return <WrongAttorneyForm {...commonProps} attribute={valueKey} />;
      case UPDATE_REQUEST_TYPES.NO_UPDATE.key:
        return <NoUpdateForm {...commonProps} attribute={valueKey} />;
      default:
        return <UpdateRequestButton fromTask={fromTask} />;
    }
  }
}

export default UpdateRequestOptions;
