import { isArray } from 'lodash';

export default {
  toForm (data: { [key: string]: any }) {
    const form = new FormData();

    Object.keys(data).forEach(key => {
      // FormData converts all values to strings, including null and undefined.
      // DRF will coerce empty strings to None on the backend.
      const val = data[key] === null ? '' : data[key];

      if (val === undefined) { return; }

      if (isArray(val)) {
        val.forEach(arrayVal => form.append(key, arrayVal));
      }
      else {
        form.append(key, val);
      }
    });

    return form;
  },
};
