import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import cx from 'classnames';
import { noop } from 'lodash';

import {
  ControlLabel,
  FormGroup,
  HelpBlock,
  InputGroup,
} from 'react-bootstrap';

import { createInputContainer } from '../../containers';
import { IFormInput, IInputContainer, Value } from '../../interfaces';

interface IProps extends IFormInput {
  accept?: string;
  addonAfter?: JSX.Element;
  addonBefore?: JSX.Element;
  id?: string;
  label?: string;
  multiple?: boolean;
  name: string;
  onChange?: (value: Value) => void;
  required?: boolean;
}

interface IPropDefaults extends IProps {
  accept: string;
  disabled: boolean;
  multiple: boolean;
  onChange: (value: Value) => void;
  required: boolean;
  type: string;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@autoBindMethods
@observer
class File extends Component<IProps, {}> {
  @observable public value = '';

  public static defaultProps: Partial<IPropsFormsy> = {
    accept: '*',
    disabled: false,
    multiple: false,
    onChange: noop,
    required: false,
    type: 'text',
  };

  get formsy () {
    return this.props as IPropsFormsy;
  }

  public onChange (e: any) {
    const { multiple, onChange, setValue } = this.formsy
      , files = e.target.files;

    let value = null;

    /* istanbul ignore next */
    if (files.length) {
      if (multiple) {
        value = files;
      }
      else {
        value = files[0];
      }
    }

    this.value = value;

    setValue(value);
    onChange(value);
  }

  public render () {
    const {
        accept,
        addonAfter,
        addonBefore,
        controlId,
        errorMessage,
        formGroupId,
        isDisabled,
        label,
        multiple,
        required,
        showErrorMessage,
        showRequiredMessage,
        validationState,
      } = this.formsy
      , className = cx(
        { disabled: isDisabled },
        'form-group-file',
        { 'has-value': !!this.value },
      )
      , formElement = (
        <input
          accept={accept}
          className='form-control'
          disabled={isDisabled}
          multiple={multiple}
          onChange={this.onChange}
          type='file'
        />
      );

    return (
      <FormGroup id={formGroupId} controlId={controlId} className={className} validationState={validationState}>
        {label &&
          <ControlLabel>
            {label}
            {required &&
              <span className='required'>*</span>
            }
          </ControlLabel>
        }

        {addonBefore || addonAfter
          ? (
            <InputGroup>
              {addonBefore}
              {formElement}
              {addonAfter}
            </InputGroup>
          )
          : formElement
        }

        {showRequiredMessage &&
          <HelpBlock>
            {`${label || 'This field'} is required.`}
          </HelpBlock>
        }

        {showErrorMessage &&
          <HelpBlock>{errorMessage}</HelpBlock>
        }
      </FormGroup>
    );
  }
}

export default File;
