import React from 'react';

import { Icon } from '../common';
import { NavDropdown, NavMenuItem } from './';

import {
  GuardedDataAdminPageLink,
  MyAccountPageLink,
} from './PageLinks';

interface IProps {
  onLogoutClick: () => void;
}

const SettingsNavDropdown: React.SFC<IProps> = props => {
  return (
    <NavDropdown
      id='settings-dropdown'
      noCaret
      title={<Icon type='gear' />}
    >
      <MyAccountPageLink />
      <GuardedDataAdminPageLink />
      <NavMenuItem id='btn-settings-logout' onClick={props.onLogoutClick}>Logout</NavMenuItem>
    </NavDropdown>
  );
};

export default SettingsNavDropdown;
