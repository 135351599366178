import React, { Component } from 'react';

import { default as ReactRating } from 'react-rating';

interface IProps {
  emptySymbol?: string;
  fullSymbol?: string;
  initialRating?: number;
  onChange?: (value: number) => void;
  quiet?: boolean;
  readonly?: boolean;
}

class MightyReactRating extends Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    emptySymbol: 'fa fa-star-o',
    fullSymbol: 'fa fa-star',
    quiet: true,
  };

  public render () {
    const { emptySymbol, fullSymbol, quiet } = this.props;

    return (
      <div className='rating'>
        <ReactRating
          emptySymbol={emptySymbol}
          fullSymbol={fullSymbol}
          quiet={quiet}
          {...this.props}
        />
      </div>
    );
  }
}

export default MightyReactRating;
