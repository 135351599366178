import React, { Component } from 'react';
import { FormattingUtils } from '../../utils';
import { inject } from 'mobx-react';

import {
  Button,
  Row,
} from 'react-bootstrap';

import { Icon } from '../common';

import CaseActivity from './CaseActivity';

const { getNameOrDefault, toKey, truncate } = FormattingUtils;
import { AppConstants } from '../../../src/constants';

import {
  IActivity,
} from '../../interfaces';
import { SessionStoreClass } from '../../stores';

const { ACTIVITY_FILE_NAME_TRUNCATE_LENGTH } = AppConstants;

interface IDocumentsSharedActivity extends IActivity {
  by_person: object;
  documents: object[];
  person_shared_with: object;
  requested_by_user_principal_party: boolean;
}

interface IProps {
  activity: IDocumentsSharedActivity;
}

interface IInjected extends IProps {
  SessionStore: SessionStoreClass;
}

@inject('SessionStore')
class DocumentsShared extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public render () {
    const { by_person, documents, person_shared_with, requested_by_user_principal_party } = this.props.activity
      , { SessionStore } = this.injected
      , isReceived = requested_by_user_principal_party
      , headerPerson = isReceived ? by_person : person_shared_with
      , activityBody = (
        <div>
          <h3>Documents {isReceived ? 'Received' : 'Shared'}</h3>
          <p><span className='status'>{isReceived ? 'From' : 'To'}: </span>{getNameOrDefault(headerPerson)}</p>
          <Row className='status'>Documents:</Row>
          <Row className='files'>
            {documents
              .map((document: any, idx) =>
                <div key={idx}>
                  <Button
                    bsStyle='link'
                    href={SessionStore.addAuthenticationToUrl(`${document.file_download_url}${toKey({ dl: 0 })}`)}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <Icon type='download' />
                    {truncate(document.name, ACTIVITY_FILE_NAME_TRUNCATE_LENGTH)}
                  </Button>
                </div>,
            )}
          </Row>
        </div>
      );

    return (
      <CaseActivity
        activity_body={activityBody}
        dot='icon'
        icon='file'
        {...this.props}
      />
    );
  }
}

export default DocumentsShared;
