import React, { Component, SyntheticEvent } from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { noop, debounce, get } from 'lodash';
import cx from 'classnames';

import {
  Button,
  ControlLabel,
  Dropdown,
  FormGroup,
  HelpBlock,
  InputGroup,
  MenuItem,
} from 'react-bootstrap';

import FormattingUtils from '../../utils/FormattingUtils';
import { AppConstants } from '../../constants';
import { createInputContainer } from '../../containers';

import Icon from '../common/Icon';

const { truncate } = FormattingUtils;

const {
  DEBOUNCE_DELAY,
  TRUNCATE_OBJECT_SEARCH,
} = AppConstants;

import {
  IFormInput,
  IInputContainer,
  Value,
} from '../../interfaces';

const UnTypedDropdown: any = Dropdown;

const MenuHeader = (props: {[key: string]: any}) => <li {...props} className='dropdown-header' />;

interface IProps extends IFormInput {
  async?: boolean;
  defaultValue?: any;
  objectRender: (value: any) => string;
  objectKey: string;
  objects: Array<{ [key: string]: any }>;
  onSearchInputChange?: (value: string) => void;
}

interface IPropDefaults extends IProps {
  async: boolean;
  onChange: (value: any) => void;
  onSearchInputChange: (value: string) => void;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@inject()
@autoBindMethods
@observer
class ObjectSelect extends Component<IProps> {
  private debouncedGetOptions: () => void;
  private refsSearch: any;
  private valueObject: any;

  public static defaultProps: Partial<IProps> = {
    async: false,
    disabled: false,
    onSearchInputChange: noop,
    required: false,
  };

  @observable private open = false;
  @observable private search = '';
  @observable private value: Value = null;

  constructor (props: IProps) {
    super(props);

    const { defaultValue, objects, objectKey } = props;
    this.value = (defaultValue && defaultValue[objectKey]) || props.value || null;
    this.valueObject = defaultValue || objects.find((o) => o[objectKey] === this.value) || null;
    this.debouncedGetOptions = debounce(() => this.propsWithDefaults.onSearchInputChange(this.search), DEBOUNCE_DELAY);
  }

  get formsy () {
    return this.props as IPropsFormsy;
  }

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  public componentDidUpdate () {
    if (this.refsSearch) {
      this.refsSearch.focus();
    }
  }

  private async onSearchChange (event: SyntheticEvent<any>) {
    const target = event.target as HTMLInputElement;
    this.search = target.value;

    if (this.propsWithDefaults.async && !!this.search) {
      this.debouncedGetOptions();
    }
  }

  private handleToggle (open: boolean, _target: any, data: { source: string }) {
    if (data.source !== 'select') {
      this.open = open;
    }
  }

  private onSelect (value: any) {
    const { objectKey, objects, onChange } = this.propsWithDefaults;
    this.value = get(value, objectKey, '');
    this.valueObject = objects.find((o) => o[objectKey] === this.value);
    this.formsy.setValue(this.value);
    this.search = '';
    this.open = false;
    onChange(this.value);
  }

  // istanbul ignore next
  private dontClose (event: SyntheticEvent<any>) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }

  private onClear () {
    this.onSelect('');
  }

  private setSearchRef (component: HTMLInputElement | null) {
    this.refsSearch = component;
  }

  get objects () {
    const {
      async,
      objectRender,
      objects,
    } = this.propsWithDefaults;

    if (async) { return objects; }

    return objects.filter((object: object) => {
      return objectRender(object).toLowerCase().includes(this.search.toLowerCase());
    });
  }

  /* tslint:disable cyclomatic-complexity */
  private renderDropdown () {
    const {
        field,
        label,
        objectRender,
        objectKey,
      } = this.props
      , noResultsFound = !!this.search.length && !this.objects.length
      , {
        controlId,
        errorMessage,
        isDisabled,
        showErrorMessage,
        showRequiredMessage,
        validationState,
      } = this.formsy
      ;

    return (
      <FormGroup validationState={validationState}>
        <UnTypedDropdown
          disabled={isDisabled}
          id={`dropdown-${controlId}`}
          onBlur={noop}
          onToggle={this.handleToggle}
          open={this.open}
        >
          <Dropdown.Toggle>
            {this.value && truncate(objectRender(this.valueObject), TRUNCATE_OBJECT_SEARCH)}
          </Dropdown.Toggle>

          <div className='search'>
            <input
              id={`dropdown-search-${controlId}`}
              name={`search_${field}`}
              onChange={this.onSearchChange}
              onClick={this.dontClose}
              ref={this.setSearchRef}
              value={this.search}
            />
          </div>

          <Dropdown.Menu>
            {noResultsFound && (
              <MenuHeader>
                No results found
              </MenuHeader>
            )}

            {this.objects.map((option: { [key: string]: any }) => (
              <MenuItem
                eventKey={option}
                key={option[objectKey]}
                onSelect={this.onSelect}
              >
                {objectRender(option)}
              </MenuItem>
            ))}
          </Dropdown.Menu>
        </UnTypedDropdown>

        {showRequiredMessage && <HelpBlock>{label || 'This field'} is required.</HelpBlock>}
        {showErrorMessage && <HelpBlock>{errorMessage}</HelpBlock>}
      </FormGroup>
    );
  }
  /* tslint:enable cyclomatic-complexity */

  public render () {
    const {
        label,
        required,
      } = this.props
      , {
        controlId,
        formGroupId,
        isDisabled,
      } = this.formsy
      , className = cx(
        { disabled: isDisabled },
        'dropdown-search-create',
        'form-group-dropdown',
        { focused: this.open },
        { 'has-value': !!this.value },
      );

    return (
      <FormGroup id={formGroupId} controlId={`dropdown-${controlId}`} className={className}>
        {!!this.value &&
        <Button bsStyle='link' className='btn-clear' disabled={isDisabled} onClick={this.onClear}>
          <Icon type='times-circle' />
        </Button>
        }
        {label &&
          <ControlLabel>
            {label}

            {required &&
              <span className='required'>*</span>}
          </ControlLabel>}

        <InputGroup>
          {this.renderDropdown()}
        </InputGroup>
      </FormGroup>
    );
  }
}

export default ObjectSelect;
