import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import {
  IFieldConfigObjectSelect,
  Value,
} from '../../interfaces';

interface IProps extends IFieldConfigObjectSelect {
  value: Value;
}

@inject()
@autoBindMethods
@observer
class ObjectSelectDisplay extends Component<IProps> {
  public render () {
    const {
        objectRender,
        objectKey,
        objects,
        value,
      } = this.props
      , valueObject = objects.find((o) => o[objectKey] === value)
      ;

    return <p>{objectRender(valueObject)}</p>;
  }
}

// Not yet used anywhere
// Funny export required to get around istanbul bug
// istanbul ignore next
function formatObjectSelect (value: Value, fieldConfig: IFieldConfigObjectSelect) {
  return <ObjectSelectDisplay value={value} {...fieldConfig} />;
}

export { formatObjectSelect };
export default ObjectSelectDisplay;
