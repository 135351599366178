import { action, observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import AppState from '../lib/AppState';
import { IAuthenticator, ITransport, IUser, IWindow } from '../interfaces';

@autoBindMethods
class SessionAuthenticatorClass implements IAuthenticator {
  @observable public user?: IUser;
  @observable public hasValidSession = false;

  private transport: ITransport;

  private get window () {
    return window as unknown as IWindow;
  }

  constructor (transport: ITransport) {
    this.transport = transport;
  }

  get isAuthenticated () {
    return this.hasValidSession;
  }

  public async prepareInvoke () {
    return true;
  }

  public handleUnauthorized () {
    AppState.SessionStore.logout();
  }

  @action
  public async getAuthentication (options: any) {
    options.protected = false;

    try {
      const user = await this.transport.get('/auth/user/', options);

      this.user = user;
      this.hasValidSession = true;

      await this.setUserFromRepresentatives();
    }
    catch (e) {
      if (AppState.SessionStore.isSessionHijacked) {
        AppState.SessionStore.endImpersonation();
      } else {
        window.location.href = `${this.window.Mighty.AUTH_URL}/?redirectUri=${this.window.Mighty.FUNSOFT_URL}`;
      }
    }
  }

  public getAuthorizationHeader () {
    return 'session';
  }

  public async setUserFromRepresentatives () {
    if (this.user === undefined) { return; }

    const currentUser = this.user;

    const staffAccounts = await this.transport.get('/funder-staff/')
    , user = staffAccounts.find((i: { id: any; }) => i.id === currentUser.id) || null;

    currentUser.permissions = user ? user.permissions : [];
    currentUser.groups = user ? user.groups : [];

    AppState.FunderStore.staffAccounts = staffAccounts;
    AppState.SessionStore.setUser(currentUser);
  }

  @action
  public clearAuthentication () {
    this.hasValidSession = false;
    this.transport.post('/auth/logout/', { protected: false });
  }

  public addAuthenticationToUrl (url: string) {
    return url;
  }
}

export default SessionAuthenticatorClass;
