import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { MenuItem } from 'react-bootstrap';

import { FunderStoreClass } from '../../stores';
import { ITrancheSchedule } from '../../interfaces';

import TrancheScheduleMenuItem from './TrancheScheduleMenuItem';

interface IInjected {
  FunderStore: FunderStoreClass;
}

@inject('FunderStore')
@observer
@autoBindMethods
class TrancheSchedulesNav extends Component {
  get injected () {
    return this.props as IInjected;
  }

  public async componentDidMount () {
    const { FunderStore } = this.injected;

    await FunderStore.fetchTranches();
  }

  public render () {
    const { FunderStore } = this.injected
      , tranches = FunderStore.tranches
      ;

    if (!tranches) {
      return (
        <MenuItem disabled>
          Loading...<span className='spinner' />
        </MenuItem>
      );
    }

    if (!tranches.length) {
      return <MenuItem disabled>None</MenuItem>;
    }

    return (
      tranches.map((schedule: ITrancheSchedule) => (
        <TrancheScheduleMenuItem key={schedule.id} schedule={schedule} />
      ))
    );
  }
}

export default TrancheSchedulesNav;
