import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { action, observe } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { get } from 'lodash';

import { Button, Grid, Well } from 'react-bootstrap';

import { Icon } from '../../common';
import { FormFieldSets } from '../../../lib/mighty-fields';
import { Form } from '../../common-formsy';

import FormattingUtils from '../../../utils/FormattingUtils';
import AppConstants from '../../../constants/AppConstants';

import {
  ICase,
} from '../../../interfaces';

import { FormManager } from '../../../utils';
import ActivityList from '../../common-activities/ActivityList';

import SaveSMSReplyModal from './SaveSMSReplyModal';
import SMSSavedReplyDropdown from './SMSSavedReplyDropdown';
import UiStoreClass from '../../../stores/UiStoreClass';
import { CaseStoreClass, FunderStoreClass, SessionStoreClass } from '../../../stores';

const { getNameOrDefault } = FormattingUtils;
const { NO_PERMISSION_TEXT, PERMISSIONS } = AppConstants;

const UnTypedSaveSMSReplyModal: any = SaveSMSReplyModal;
const UnTypedSMSSavedReplyDropdown: any = SMSSavedReplyDropdown;

interface IProps {
  _case: ICase;
}

interface IInjected extends IProps {
  CaseStore: CaseStoreClass;
  FunderStore: FunderStoreClass;
  SessionStore: SessionStoreClass;
  UiStore: UiStoreClass;
}

const FIELD_SETS = [
  [{
    field: 'recipient',
    label: null,
    type: 'hidden',
  }],
  [{
    field: 'body',
    label: null,
    placeholder: 'Add sms body...',
    required: true,
    type: 'textDynamic',
  }],
];

@inject('CaseStore', 'FunderStore', 'SessionStore', 'UiStore')
@autoBindMethods
@observer
class TabSms extends Component<IProps> {
  private formManager: FormManager;

  public constructor (props: IProps) {
    super(props);
    this.formManager = new FormManager({
      fieldSets: FIELD_SETS,
      model: { recipient: this.plaintiffPhoneDisplay() },
      onSave: this.onSave,
      onSuccess: this.onSuccess,
    });

    if (props._case) {
     observe(props._case, this.updateRecipient);
    }
  }

  private get injected () {
    return this.props as IInjected;
  }

  public componentDidMount () {
    this.injected.CaseStore.caseSms.loadInitial({ id: this.props._case.id });
  }

  private plaintiffPhone () {
    return get(this.props._case, 'plaintiff.phone_number', '');
  }

  private plaintiffPhoneDisplay () {
    const plaintiff = get(this.props._case, 'plaintiff');
    return `${getNameOrDefault(plaintiff)} <${this.plaintiffPhone()}>`;
  }

  // istanbul ignore next
  private updateRecipient () {
    const recipient = this.plaintiffPhoneDisplay();
    if (recipient !== get(this.formManager.submitData, 'recipient')) {
      this.formManager.onChange.recipient(recipient);
    }
  }

  public setSMSBodyFromSavedReply (reply: {message: string}) {
    this.formManager.onChange.body(reply.message);
  }

  public async deleteSMSSavedReply (reply: {id: string}) {
    const { FunderStore } = this.injected;

    await FunderStore.deleteSMSSavedReply(reply);
    await FunderStore.fetchSMSSavedReplies();
  }

  @action
  private async onSave (formData: object) {
    const data = formData as { recipient: string, body: string }
      , {
        CaseStore,
      } = this.injected
      , submitData = {
        case: this.props._case.id,
        sms: {
          body: data.body,
          to_number: this.plaintiffPhone(),
        },
      };

    await CaseStore.sendSms(submitData);
  }

  private onSuccess () {
    this.formManager.reset();
  }

  private renderForm () {
    const {
        CaseStore,
        FunderStore,
        UiStore,
      } = this.injected
      , { _case } = this.props
      ;

    return (
      <div className='tab-sms'>
        <Form {...this.formManager.formProps}>
          <Grid fluid>
            <div className='plaintiff-phone'>To : {this.plaintiffPhoneDisplay()}</div>
            <FormFieldSets
              fieldSets={FIELD_SETS}
              {...this.formManager.formGroupsProps}
            />
          </Grid>

          <div className='form-footer dropdown-arrow'>
            <UnTypedSMSSavedReplyDropdown
              dropdownButtonId='sms-saved-reply-dropdown'
              onDeleteSMSSavedReply={this.deleteSMSSavedReply}
              onSaveSMSReplyClick={UiStore.modals.SaveSMSReply.open}
              onSMSSavedReplyClick={this.setSMSBodyFromSavedReply}
              smsSavedReplies={FunderStore.smsSavedReplies}
            />
            <Button disabled={this.formManager.submitDisabled()} bsSize='small' bsStyle='primary' type='submit'>
              {this.formManager.isSubmitting ? 'Sending...' : 'Send'}
            </Button>
          </div>
        </Form>

        <ActivityList
          _case={_case}
          activities={CaseStore.caseSms.activities}
          loading={CaseStore.caseSms.loading}
          loadingNew={CaseStore.caseSms.loadingNew}
        />

        {UiStore.modals.SaveSMSReply.showing() &&
          <UnTypedSaveSMSReplyModal />}
      </div>
    );
  }

  public render () {
    const {
        SessionStore,
        UiStore,
      } = this.injected
      , permission = SessionStore.userHasPermission(PERMISSIONS.CREATE_CASE_SMS)
      ;

    if (!permission) {
      return (
        <Well className='message'>
          <p>{NO_PERMISSION_TEXT}</p>
        </Well>
      );
    }

    // no phone
    if (!this.plaintiffPhone()) {
      return (
        <Well className='message'>
          <p>The plaintiff is missing a phone number.</p>
          <Button bsStyle='link' onClick={UiStore.modals.PlaintiffInfo.open}>
          <Icon type='pencil' /> Edit Plaintiff Info</Button>
        </Well>
      );
    }

    return this.renderForm();
  }
}

export default TabSms;
