import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { NavItem } from 'react-bootstrap';
import { Icon } from '../../common';

const ASIDE_TAB_URL_POSITION = 4;

interface IProps {
  fromTab: string;
  icon?: string;
  label: string;
  pathname: string;
  toTab: string;
}

class SidebarTabNav extends Component<IProps> {
  public render () {
    const {
      fromTab,
      icon,
      label,
      pathname,
      toTab,
    } = this.props
      , pathSplit = pathname.split('/');

    pathSplit[ASIDE_TAB_URL_POSITION] = toTab;
    const newPath = `${pathSplit.join('/')}`;

    return (
      <LinkContainer to={{ pathname: newPath, state: `FROM_${fromTab.toUpperCase()}_TAB` }}>
        <NavItem className={`nav-case-aside-${toTab}`}>
          {icon && <Icon type={icon} />}
          {label}
        </NavItem>
      </LinkContainer>
    );
  }
}

export default SidebarTabNav;
