import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { Button } from 'react-bootstrap';
import Icon from './Icon';

interface IProps {
  classNames?: string;
  emptyText: string;
  iconType?: string;
  onClick: (...args: any[]) => any;
}

interface IPropDefaults extends IProps {
  iconType: string;
}

@autoBindMethods
@observer
class EmptyStateButton extends Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    iconType: 'plus-square-o',
  };

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  public render () {
    const {
        classNames,
        emptyText,
        iconType,
        onClick,
      } = this.propsWithDefaults
      , isDefault = iconType === 'plus-square-o'
      , emptyClassNames = cx({ 'btn-add': isDefault }, classNames);

    return (
      <Button bsStyle='link' onClick={onClick} className={emptyClassNames}>
        <Icon type={iconType} />
        {emptyText}
      </Button>
    );
  }
}

export default EmptyStateButton;
