import { action, observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import { AppConstants } from '../constants';
import { ITransport } from '../interfaces';

interface ITermsOfService {
  link: string;
  copy: string;
}

@autoBindMethods
class LinkCopyStoreClass {
  @observable public termsOfService: ITermsOfService = {} as ITermsOfService;
  @observable public isStoreReady: boolean = false;

  public transport: ITransport;

  constructor (transport: ITransport) {
    this.transport = transport;
  }

  @action
  public async fetch () {
    try {
      const linkCopyItems = await this.transport.get('/link-copy-items/', { protected: false })
        , { TERMS_OF_SERVICE } = linkCopyItems;
      this.termsOfService = TERMS_OF_SERVICE || AppConstants.TERMS_OF_SERVICE_DEFAULT;
    }
    catch (error) {
      this.termsOfService = AppConstants.TERMS_OF_SERVICE_DEFAULT;
    }

    this.isStoreReady = true;
  }
}

export default LinkCopyStoreClass;
