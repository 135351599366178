import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { NavItem, NavItemProps } from 'react-bootstrap';

interface IProps extends NavItemProps {
  to?: string | object;
}

const NavLink: React.SFC<IProps> = ({ to, ...rest }) => {
  const NavItemComponent = <NavItem {...rest} />;
  return to ? <LinkContainer to={to}>{NavItemComponent}</LinkContainer> : NavItemComponent;
};

export default NavLink;
