import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { isBoolean, kebabCase } from 'lodash';
import cx from 'classnames';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import SmartBool from '../../utils/SmartBool';
import AppConstants from '../../constants/AppConstants';
import FacetHeader from './FacetHeader';

const { TRANSITION_SLIDE } = AppConstants;

interface IProps {
  className?: any;
  collapsible?: boolean;
  fieldName: string;
  hasValue: boolean;
  isCollapsed?: boolean;
  label: string;
  onToggleCollapse?: (isCollapsed: boolean) => void;
  reset?: (fieldName: string) => void;
}

interface IPropDefaults extends IProps {
  collapsible: boolean;
}

@autoBindMethods
@observer
class Facet extends Component<IProps, {}> {
  private isCollapsed: SmartBool;

  public static defaultProps: Partial<IProps> = {
    collapsible: true,
  };

  constructor (props: IProps) {
    super(props);
    const isCollapsed = isBoolean(props.isCollapsed) ? props.isCollapsed : true;
    this.isCollapsed = new SmartBool(isCollapsed && !props.hasValue);
  }

  public componentWillReceiveProps (nextProps: IPropDefaults) {
    const { isCollapsed } = this.props;

    // Facet cannot have value and be collapsed
    if (nextProps.hasValue) {
      this.isCollapsed.setFalse();
      return;
    }

    // Respect changed isCollapsed prop
    // istanbul ignore next
    if (isCollapsed !== nextProps.isCollapsed) {
      this.isCollapsed.set(!!nextProps.isCollapsed);
    }
  }

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  private toggleCollapsed () {
    const { onToggleCollapse } = this.propsWithDefaults;
    this.isCollapsed.toggle();
    if (onToggleCollapse) {
      onToggleCollapse(this.isCollapsed.isTrue);
    }
  }

  private renderBody () {
    const { collapsible } = this.propsWithDefaults;
    if (!!collapsible) {
      return (
        <ReactCSSTransitionGroup {...TRANSITION_SLIDE}>
          {!this.isCollapsed.isTrue && (
            <div className='slider'>
              <div className='facet-body'>{this.props.children}</div>
            </div>
          )}
        </ReactCSSTransitionGroup>
      );
    }
    return <div className='facet-body'>{this.props.children}</div>;
  }

  public render () {
    const { className, collapsible, fieldName, hasValue, label, reset } = this.propsWithDefaults
      , classNames = cx('facet', { collapsible }, className)
      , toggleProps = {} as { toggleCollapsed?: () => any };

    if (collapsible) {
      toggleProps.toggleCollapsed = this.toggleCollapsed;
    }

    return (
      <div id={`facet-${kebabCase(fieldName)}`} className={classNames}>
        <FacetHeader
          fieldName={fieldName}
          hasValue={hasValue}
          isCollapsed={this.isCollapsed.isTrue}
          label={label}
          reset={reset}
          {...toggleProps}
        />
        {this.renderBody()}
      </div>
    );
  }
}
export default Facet;
