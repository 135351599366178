import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { MenuItem } from 'react-bootstrap';
import autoBindMethods from 'class-autobind-decorator';

import { CaseStoreClass, LienStore as LienStoreClass, UiStoreClass } from '../../stores';
import { FormModal } from '../../lib/mighty-fields';
import { ICase, ILien } from '../../interfaces';
import { getCurrentDate } from '../../utils/util';
import { createGuardedContainer, createDisabledContainer } from '../../containers';
import AppConstants from '../../constants/AppConstants';

const { PERMISSIONS } = AppConstants;

interface IProps {
  _case: ICase;
  isDisabled: boolean;
}

interface IInjected extends IProps {
  CaseStore: CaseStoreClass;
  LienStore: LienStoreClass;
  UiStore: UiStoreClass;
}

@inject('CaseStore', 'LienStore', 'UiStore')
@observer
@autoBindMethods
class GuardedCloseAllLiensMenuItem extends Component <IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get liensToClose (): ILien[] {
    return this.props._case.getLiens({ is_confirmed: true, is_open: true }) || [];
  }

  private async onCloseAllLiens (form: any) {
    const { CaseStore, LienStore } = this.injected
      , results = await LienStore.bulkUpdate(this.liensToClose, { is_open: false, ...form })
      ;
    results.map((result: any) => LienStore.flatStore.lien.store(result));
    await CaseStore.refresh(this.props._case.id);
  }

  private get shouldPluralizeDialog () {
    return this.liensToClose.length > 1;
  }

  private get dialogInstructions () {
    const { lien, close } = this.shouldPluralizeDialog
        ? { lien: 'liens', close: 'all of these liens' }
        : { lien: 'lien', close: 'this lien' }
      ;
    return (
      <p>The case has {this.liensToClose.length} open {lien}. You are about to close {close} and set the closing date to the value below.</p>
    );
  }

  private get saveText () {
    return this.shouldPluralizeDialog ? 'Close Liens' : 'Close Lien';
  }

  public render () {
    const { isDisabled } = this.props
      , { UiStore } = this.injected
    ;

    return (
      <Fragment>
        <MenuItem disabled={isDisabled} onSelect={UiStore.modals.CloseAllLiens.open}>Close All Liens</MenuItem>
        {UiStore.modals.CloseAllLiens.showing() &&
        <FormModal
          childrenBefore={this.dialogInstructions}
          fieldSets={[[{ field: 'date_closed', type: 'date' }]]}
          model={{ date_closed: getCurrentDate() }}
          onClose={UiStore.modals.CloseAllLiens.close}
          onSave={this.onCloseAllLiens}
          saveText={this.saveText}
          title='Close All Liens'
        />
        }
      </Fragment>
    );
  }

}

export default createGuardedContainer({
  disabledComponent: createDisabledContainer(GuardedCloseAllLiensMenuItem),
  enabledComponent: GuardedCloseAllLiensMenuItem,
  permissionName: PERMISSIONS.CLOSE_LIEN,
});
