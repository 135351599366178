import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { FormattingUtils } from '../../utils';
import { ICase } from '../../interfaces';
import CaseLabel from './CaseLabel';

const { getNameOrDefault } = FormattingUtils;

interface IProps {
  _case: ICase;
}

@observer
class CaseHeaderTitle extends Component <IProps> {
  public render () {
    const { _case } = this.props;

    return (
      <div className='col-title'>
        <h1 className='fs-mask'>
          {getNameOrDefault(_case.plaintiff)}
          {_case.hasLiens({is_imported: true}) && <span className='tag-imported'>Imported</span>}
        </h1>
        <div className='id'>Case ID <span>{_case.vanity_id}</span></div>
        <div className='list-tracking-status'>
          {_case.return_status && <CaseLabel status={_case.return_status} />}
        </div>
      </div>
    );
  }
}

export default CaseHeaderTitle;
