import autoBindMethods from 'class-autobind-decorator';

import { cloneDeep } from 'lodash';
import { ITransport } from '../interfaces';

@autoBindMethods
class ClientView {
  protected endpoint: string;
  protected transport: any;

  constructor (endpoint: string, transport: ITransport) {
    this.endpoint = endpoint;
    this.transport = transport;
  }

  public async retrieve (options: any = {}) {
    return (await this.transport.get(this.endpoint, cloneDeep(options)));
  }
}

export default ClientView;
