import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { noop, omit, uniqBy, find } from 'lodash';
import autoBindMethods from 'class-autobind-decorator';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import FormattingUtils from '../../utils/FormattingUtils';
const { getNameOrDefault } = FormattingUtils;

interface IProps {
  bsStyle?: string;
  defaultLabel?: string;
  filterName: string;
  nullName?: string;
  onFilterChange?: (...args: any[]) => any;
  optionId?: string;
  optionName?: string;
  options?: any[];
  pullRight?: boolean;
  selectedId?: string | null;
  showNullOption?: boolean;
}

interface IPropDefaults extends IProps {
  nullName: string;
  onFilterChange: (...args: any[]) => any;
  optionId: string;
  pullRight: boolean;
  showNullOption: boolean;
}

@autoBindMethods
@observer
class DropdownFilter extends Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    nullName: 'All',
    onFilterChange: noop,
    optionId: 'id',
    pullRight: true,
    showNullOption: true,
  };

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  private onSelect (val: any) {
    const { onFilterChange, filterName } = this.propsWithDefaults,
      changes: { [key: string]: any } = {};
    changes[filterName] = val;
    onFilterChange(changes);
  }

  public render () {
    const {
        defaultLabel,
        filterName,
        nullName,
        optionId,
        optionName,
        options,
        selectedId,
        showNullOption,
        ...rest
      } = this.propsWithDefaults,
      remainingProps = omit(rest, 'onFilterChange');

    if (!options) {
      // istanbul ignore next
      return null;
    }

    const optionsMap = uniqBy(options, optionId)
      , selectedOption = find(optionsMap, i => i[optionId] === selectedId)
      , defaultField = (optionName ? { field: optionName } : {}) as { [key: string]: string }
      , defaultValue = (defaultLabel ? { defaultValue: defaultLabel } : {}) as { [key: string]: string }
      , label = getNameOrDefault(selectedOption, { ...defaultValue, ...defaultField });

    return (
      <DropdownButton
        id={`dropdown-filter-${filterName}`}
        title={label}
        {...remainingProps}
      >
        {showNullOption && (
          <MenuItem
            active={!selectedId}
            onSelect={this.onSelect}
            eventKey={null}
          >
            {nullName}
          </MenuItem>
        )}

        {optionsMap.map(option => (
          <MenuItem
            key={option[optionId]}
            active={option[optionId] === selectedId}
            eventKey={option[optionId]}
            onSelect={this.onSelect}
          >
            {getNameOrDefault(option, defaultField)}
          </MenuItem>
        ))}
      </DropdownButton>
    );
  }
}

export default DropdownFilter;
