import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { browserHistory } from 'react-router';
import autoBindMethods from 'class-autobind-decorator';
import { get } from 'lodash';

import CaseModel from '../../models/CaseModel';
import CaseStoreClass from '../../stores/CaseStoreClass';
import { Loader, Page } from '../common';

import Case from './Case';

interface IProps {
  params: {
    aside: string,
    id: string,
    main: string,
  };
}

interface IInjected extends IProps {
  CaseStore: CaseStoreClass;
}

@inject('CaseStore')
@autoBindMethods
@observer
class CasePage extends Component<IProps> {
  @observable private errorMessage: null | string = null;
  @observable private isLoading = false;
  @observable private _case: null | CaseModel = null;

  public componentWillMount () {
    this.setStateFromProps(this.props as IProps);
  }

  // istanbul ignore next
  public componentWillReceiveProps (nextProps: IProps) {
    if (get(this._case, 'id') !== get(nextProps.params, 'id')) {
      this.setStateFromProps(nextProps);
    }
  }

  private get injected () {
    return this.props as IInjected;
  }

  @action
  public async setStateFromProps (props: IProps) {
    const { params: { id } } = props
      , { CaseStore } = this.injected;

    this.errorMessage = null;
    this.isLoading = true;

    try {
      this._case = await CaseStore.retrieve(id);
    }
    catch (err) {
      this.errorMessage = err.statusText || 'An error occurred loading the case.';
    }
    finally {
      this.isLoading = false;
    }
  }

  public renderCase () {
    const {
        params: { main, aside },
      } = this.props
      , pathname = browserHistory.getCurrentLocation().pathname
      ;

    if (this.isLoading) {
      return <Loader className='page-loader' logo />;
    }

    if (this.errorMessage) {
      return <div className='page-error'>{this.errorMessage}</div>;
    }

    if (!this._case) {
      // istanbul ignore next
      return <div className='page-error'>An error occurred loading the case.</div>;
    }

    return (
      <Case _case={this._case} main={main} aside={aside} pathname={pathname} key={ this._case.id } />
    );
  }

  public render () {
    return (
      <Page name='case' type='detail'>
        <Page.Content>
          {this.renderCase()}
        </Page.Content>
      </Page>
    );
  }
}

export default CasePage;
