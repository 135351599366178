import { action, computed, observable, toJS } from 'mobx';
import fileSaver from 'browser-filesaver';
import autoBindMethods from 'class-autobind-decorator';
import { find, sortBy, get, pick } from 'lodash';
import { format } from 'date-fns';

import AppConstants from '../constants/AppConstants';
import FormattingUtils from '../utils/FormattingUtils';
import {
  IFunder,
  IFunderStaff,
  IFunderTemplate,
  ILawFirm,
  ILienConfiguration,
  ITrancheSchedule,
  ITransport,
  IWindow,
} from '../interfaces';
import CaseStoreClass from './CaseStoreClass';
import SessionStoreClass from './SessionStoreClass';
import { toast } from '../utils';

interface ILienType extends ILienConfiguration {
  application_key?: string;
  key: string;
}

interface IApplicationWorkflow extends ILienConfiguration {
  application_key: string;
  key: string;
}

const {
    APPLICATION_WORKFLOW_CONFIG,
    APPLICATION_MODELS,
    LIEN_MODELS,
    LIEN_MODELS_BY_FUNDING_TYPE,
    FUNDING_TYPES,
    PRINCIPAL_PARTY_TYPES,
    SERVICING_TYPES,
    SUPPORT_EMAIL,
  } = AppConstants
  , {
    getNameOrDefault,
    stripNonAlpha,
  } = FormattingUtils;

@autoBindMethods
class FunderStoreClass {
  @observable public funder?: IFunder | Partial<IFunder> = {};
  @observable public funderStaff: IFunderStaff | null = null;
  @observable public isStoreReady: boolean = true;
  @observable public staffAccounts: IFunderStaff[] = [];
  @observable public smsSavedReplies: object[] = [];
  @observable public templates: IFunderTemplate[] = [];
  @observable public tranches?: ITrancheSchedule[] | null = null;

  public CaseStore: CaseStoreClass;
  public SessionStore?: SessionStoreClass;
  public transport: ITransport;
  public funderIsReady: Promise<any> | null = null;
  public funderIsReadyResolve: any;

  constructor (CaseStore: CaseStoreClass, transport: ITransport) {
    this.CaseStore = CaseStore;
    this.transport = transport;
    this.funderIsReady = new Promise((resolve, _reject) => {
      this.funderIsReadyResolve = resolve;
    });
  }

  public async setSessionStore (SessionStore: SessionStoreClass) {
    this.SessionStore = SessionStore;

    // only fetch rep and funder info when user is logged in (i.e. not logged out and not using the widget)
    if (SessionStore.user) {
      this.isStoreReady = false;
      await this.fetchRepresentativesAndFunderInfo();
      this.isStoreReady = true;
    }
  }

  @computed
  public get applicationWorkflows (): IApplicationWorkflow[] {
    const { DISABLED } = APPLICATION_WORKFLOW_CONFIG;
    return this.lienTypes.filter(lienType => lienType.application_workflow !== DISABLED) as IApplicationWorkflow[];
  }

  @computed
  public get showApplicationWorkflow () {
    return !!this.applicationWorkflows.length;
  }

  @computed
  public get defaultApplicationType () {
    if (!this.applicationWorkflows.length) { return null; }
    const fundingType = find(this.applicationWorkflows, {application_key: APPLICATION_MODELS.LEGAL_FUNDING.key}) || this.applicationWorkflows[0];
    return APPLICATION_MODELS[fundingType.application_key].model;
  }

  @computed
  public get representatives () {
    return this.staffAccounts.filter(rep => !rep.is_mighty_support);
  }

  @action
  public async fetchFunderTemplates () {
    const templates = await this.transport.get('/funder-document-templates/');
    this.templates = sortBy(templates, 'order');
  }

  @action
  public async fetchSMSSavedReplies () {
    this.smsSavedReplies = await this.transport.get('/sms-saved-replies/');
  }

  @action
  public async fetchTranches () {
    const params = { originator: this.funder?.id }
      , tranches = await this.transport.get('/coop-tranche-reports/', { params })
      ;

    this.tranches = tranches.results;
  }

  public async saveSMSReply (data: object) {
    return await this.transport.post('/sms-saved-replies/', {data});
  }

  public async deleteSMSSavedReply (smsSavedReply: { id: string }) {
    const id = smsSavedReply.id;
    return await this.transport.delete('/sms-saved-replies/:id/', {urlParams: { id }});
  }

  public async fetchEmailTemplate (caseId: string, templateId: string) {
    const baseUrl = `/backend/documents/document-templates/${templateId}/case/${caseId}/email`
      , url = this.transport.addAuthenticationToUrl(baseUrl);

    // istanbul ignore next
    if (!url) { return ''; }

    try {
      const email = await this.transport.get(url);
      return email;
    }

    // istanbul ignore next
    catch (e) {
      // istanbul ignore next
      return '';
    }
  }

  @action
  public async fetchRepresentativesAndFunderInfo () {
    if (!this.staffAccounts.length) {
      const staffAccounts = await this.transport.get('/funder-staff/');
      this.staffAccounts = staffAccounts || [];
    }

    this.setFunderStaff();

    if (this.funderStaff && this.funderStaff.funder) {
      const funder = await this.transport.get('/funders/:id/', {urlParams: {id: this.funderStaff.funder}});
      this.funder = funder;
      this.funderIsReadyResolve(this.funder);
    }
  }

  public async downloadFunderTemplate (templateId: string, lookupId: string, filename: string) {
    const url = `${(window as unknown as IWindow).Mighty.API_HOST}/api/v1/funder-document-templates/${templateId}/?format=docx&lookup_id=${lookupId}`
      , headers = { Accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/json; charset=utf-8;' };

    try {
      await this.transport.getDocument(url, filename, {options: {headers}});
    }
    catch (e) {
      toast.error(`Unable to generate lien statement, please chat us or email ${SUPPORT_EMAIL} to resolve this issue.`);
    }
  }

  public async downloadTemplate (by: string, byId: string, templateId: string, filename: string) {
    const url = `${(window as unknown as IWindow).Mighty.API_HOST}/backend/documents/document-templates/${templateId}/${by}/${byId}/docx`;

    try {
      await this.transport.getDocument(url, filename);
    }
    catch (e) {
      toast.error(`Unable to generate lien statement, please chat us or email ${SUPPORT_EMAIL} to resolve this issue.`);
    }
  }

  public async downloadContactTemplate (contact: { id: string }, contactType: string, contactTemplateId: string, lawfirm: ILawFirm) {
    const prefix = `Lien_statement_${stripNonAlpha(lawfirm.name)}`
      , postfix = `_${format(new Date(), 'YYYY_MM_DD')}.docx`
      , name = stripNonAlpha(getNameOrDefault(contact));

    await this.downloadTemplate(contactType, contact.id, contactTemplateId, `${prefix}_${name}${postfix}`);
  }

  public async downloadTemplates (by: string, lawfirm: ILawFirm) {
    const prefix = `Lien_statement_${stripNonAlpha(lawfirm.name)}`
      , postfix = `_${format(new Date(), 'YYYY_MM_DD')}.docx`
      , templates: { [key: string]: IFunderTemplate | null } = {
        attorney: this.templateAttorney,
        lawfirm: this.templateLawFirm,
      }
      , fetch: { [key: string]: (id: string) => Promise<Array<{ id: string }>> } = {
        attorney: this.CaseStore.fetchAttorneys,
      }
      , template = templates[by];

    if (!template) { return; }

    const objects = fetch[by] && await fetch[by](lawfirm.id);

    // Lawfirm
    if (!objects) {
      await this.downloadTemplate(
        by, lawfirm.id, template.id, `${prefix}${postfix}`);
      return;
    }

    // Attorney or LawFirmContact
    for (const obj of objects) {
      const name = stripNonAlpha(getNameOrDefault(obj));
      await this.downloadTemplate(
        by, obj.id, template.id, `${prefix}_${name}${postfix}`);
    }
  }

  public saveCSV (csv: string, titlePrefix: string) {
    const csvBlob = new window.Blob([csv], { type: 'text/csv' })
      , labelDate = format(new Date(), 'YYYY_MM_DD')
      ;

    fileSaver.saveAs(csvBlob, `${titlePrefix}_${labelDate}.csv`);
  }

  public async downloadTrancheSchedule (id: string) {
    const url = '/coop-tranche-reports/:id/schedule/'
      , csv = await this.transport.get(url, { headers: { Accept: 'text/csv' }, urlParams: { id } })
      ;

    this.saveCSV(csv, 'mighty_tranche');
  }

  @action
  public setFunderStaff () {
    this.funderStaff = this.staffAccounts.find(i => i.id === get(this.SessionStore, 'user.id')) || null;
  }

  @action
  public async updateFunderStaff (data: object) {
    if (!this.funderStaff) { return; }
    this.funderStaff = await this.transport.patch('/funder-staff/:id/', { data, urlParams: { id: this.funderStaff.id } });
  }

  @action
  public async updateAccountInfo (data: object) {
    if (!this.funder) { return; }
    this.funder = await this.transport.patch('/funders/:id/', { data, urlParams: { id: this.funder.id } });
  }

  @action
  public async flagContact (data: object) {
    return (await this.transport.post('/party-warnings/', {data}));
  }

  @action
  public async removeContactFlag (id: string) {
    return (await this.transport.delete('/party-warnings/:id/', {urlParams: { id }}));
  }

  @computed
  public get canSeeCapitalProviders () {
    return !!this.funder && !!this.funder.can_see_capital_providers;
  }

  @computed
  public get canUseCommunicationTools () {
    return !!this.funder && !!this.funder.can_use_communication_tools;
  }

  @computed
  public get canSeeCoopReplacementCaseSection () {
    return!!this.funder && !!this.funder.can_see_coop_replacement_case_section;
  }

  @computed
  public get canSeeHCFAForm () {
    return !!this.funder && !!this.funder.can_see_hcfa_form;
  }

  @computed
  public get isFinanceCustomer () {
    return !!this.funder && !!this.funder.is_finance_customer;
  }

  @computed
  public get hasCoopAgreement () {
    return !!this.funder && !!this.funder.has_coop_agreement;
  }

  @computed
  public get useExperimentalContracts () {
    return !!this.funder && !!this.funder.can_use_experimental_contract_generator;
  }

  @computed
  public get isMedicalProvider () {
    return !!this.funder && !!this.funder.is_medical_provider;
  }

  public doesFundingType (fundingType: string) {
    return this.lienConfigurations.some(lienConfig => {
      return LIEN_MODELS_BY_FUNDING_TYPE[fundingType].includes(lienConfig.type);
    });
  }

  @computed
  public get fundingTypes () {
    return Object.keys(FUNDING_TYPES)
      .filter(key => this.doesFundingType(FUNDING_TYPES[key]))
      .map(key => FUNDING_TYPES[key]);
  }

  @computed
  public get principalPartyTypes () {
    const medicalPrincipalPartyType = this.isMedicalProvider
      ? PRINCIPAL_PARTY_TYPES.MEDICAL_PROVIDER
      : PRINCIPAL_PARTY_TYPES.MEDICAL_FUNDER
      , fundingToPrincipalPartyType = {
      [FUNDING_TYPES.PLAINTIFF]: PRINCIPAL_PARTY_TYPES.PLAINTIFF_FUNDER,
      [FUNDING_TYPES.MEDICAL]: medicalPrincipalPartyType,
      [FUNDING_TYPES.GENERIC]: PRINCIPAL_PARTY_TYPES.GENERIC_LIENHOLDER,
    };
    return this.fundingTypes.map(fundingType => fundingToPrincipalPartyType[fundingType]);
  }

  public getLienConfig (lienModel: string) {
    return this.lienConfigurations.find(lienConfig => {
      return lienConfig.type === lienModel;
    });
  }

  @computed
  public get doesPlaintiffFunding () {
    return this.doesFundingType(FUNDING_TYPES.PLAINTIFF);
  }

  @computed
  public get doesMedicalFunding () {
    return this.doesFundingType(FUNDING_TYPES.MEDICAL);
  }

  @computed
  public get doesGenericFunding () {
    return this.doesFundingType(FUNDING_TYPES.GENERIC);
  }

  @computed
  public get isServiced () {
    return !!this.funder && this.funder.servicing_type !== SERVICING_TYPES.NONE;
  }

  get customAnalyticsConfigurations () {
    return toJS(get(this.funder, 'custom_analytics_configurations', [])) || [];
  }

  get analyticsTypes () {
    const types = []
      , customNames = this.customAnalyticsConfigurations.map(config => config.name);

    if (this.isMedicalProvider) {
      return ['provider', ...customNames];
    }

    if (this.doesPlaintiffFunding) {
      types.push('plaintiff');
    }
    if (this.doesMedicalFunding) {
      types.push('medical');
    }

    return [...types, ...customNames];
  }

  @computed
  public get funderLogo () {
    const logo = get(this.funder, 'logo', null);
    return logo && `${logo}&dl=0`;
  }

  @computed
  public get lienConfigurations (): ILienConfiguration[] {
    if (!this.funder || !this.funder.lien_configurations) { return []; }
    return this.funder.lien_configurations;
  }

  @computed
  public get lienTypes (): ILienType[] {
    return this.lienConfigurations.map(lienConfig => {
      const lienModel = (find(LIEN_MODELS, {model: lienConfig.type}) || {})
        , modelProps = pick(lienModel, ['key', 'application_key']);
      return {...lienConfig, ...modelProps} as ILienType;
    });
  }

  @computed
  public get caseTemplates () {
    return this.templates.filter(template => template.context_type === 'case');
  }

  @computed
  public get caseEmailTemplates () {
    return this.caseTemplates.filter(template => !!template.email_template);
  }

  @computed
  public get caseDocxTemplates () {
    return this.caseTemplates.filter(template => !!template.docx_template);
  }

  @computed
  public get payoffTemplates () {
    return this.templates.filter(template => template.context_type === 'payoff');
  }

  @computed
  public get medicalLienTemplates () {
    return this.templates.filter(template => template.context_type === 'medical_lien');
  }

  @computed
  public get funderStaffDisplayMentionOptions () {
    const displayName = ((staff: IFunderStaff) => `${staff.first_name} ${staff.last_name}`)
      , funderStaffId = this.funderStaff && this.funderStaff.id;

    return this.representatives
      .map(rep => ({ display: displayName(rep), id: rep.id }))
      .filter(rep => rep.id !== funderStaffId);
  }

  public getTemplate (contextType: string) {
    const filteredTemplates = this.templates.filter(template => template.context_type === contextType);
    if (filteredTemplates.length < 1) {
      return null;
    }

    return filteredTemplates[0];
  }

  @computed public get templateLawFirm () { return this.getTemplate('lawfirm'); }
  @computed public get templateAttorney () { return this.getTemplate('attorney'); }

  @computed
  public get isRegistryEnabled () {
    return this.funder && this.funder.enable_pi_portal_integration;
  }
}

export default FunderStoreClass;
