import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { kebabCase, isString } from 'lodash';

import { Button } from 'react-bootstrap';

import Icon from '../../components/common/Icon';
import SmartBool from '../../utils/SmartBool';
import { IFieldSetPartial } from './interfaces';

import Card from './Card';
import FormModal from './FormModal';

interface IProps {
  className?: string;
  fieldSets: IFieldSetPartial[];
  isLoading?: boolean;
  modalComponent?: React.Component;
  model: object;
  onSave: (model: object) => Promise<void>;
  title: string | React.ReactNode;
  wrapperName?: string;
}

@autoBindMethods
@observer
class EditableCard extends Component<IProps, {}> {
  @observable private editing = new SmartBool();

  public static defaultProps: Partial<IProps> = {
    fieldSets: [],
    model: {},
  };

  private buttons () {
    return (
      <Button
        bsStyle='link'
        className='btn-edit pull-right'
        onClick={this.editing.setTrue}
      >
        <Icon type='pencil' />
      </Button>
    );
  }

  public render () {
    const { modalComponent, title } = this.props
      , modalClassName = isString(title) && `modal-${kebabCase(title)}`
      , ModalComponent: any = modalComponent || FormModal;

    return (
      <Card {...this.props} renderTopRight={this.buttons}>
        {this.editing.isTrue && (
          <ModalComponent {...this.props} className={modalClassName} onClose={this.editing.setFalse} />
        )}
      </Card>
    );
  }
}

export default EditableCard;
