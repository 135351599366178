import React from 'react';
import cx from 'classnames';

interface IProps {
  large?: boolean;
}

const Spacer: React.SFC<IProps> = props => <div className={cx('spacer', { large: props.large })} />;

Spacer.defaultProps = {
  large: false,
};

export default Spacer;
