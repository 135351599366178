import { action, computed, observable, toJS } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { pick, get } from 'lodash';
import axios from 'axios';
import '@datadog/browser-rum/bundle/datadog-rum';

// IE fix (window.location.origin)
import 'location-origin';

import { FormattingUtils, Storage } from '../utils';
import { AppConstants } from '../constants';
import {
  IDocument,
  ITransport,
  IUser,
  IWindow,
} from '../interfaces';
import FunderStoreClass from './FunderStoreClass';

const { toKey } = FormattingUtils;

const SNAPSHOT_KEY = 'store-SessionStore';

const SNAPSHOT_ATTRIBUTES = [
  'impersonatedUserId',
  'isSessionHijacked',
  'isStoreReady',
  'passwordResetLogin',
  'user',
];

const { MIGHTY_SERVICE_TIERS } = AppConstants;

@autoBindMethods
class SessionStore {
  public transport: ITransport;
  public FunderStore: FunderStoreClass;

  @observable public isStoreReady = true;

  @observable public error: { login?: Error } = {};
  @observable public hasRegistryAccount: boolean | null = null;
  @observable public impersonatedUserId: string | null = null;
  @observable public isNewVersionAvailable = false;
  @observable public isSessionHijacked = false;
  @observable public passwordResetLogin = false;
  @observable public user: IUser | null = null;

  constructor (FunderStore: FunderStoreClass, transport: ITransport) {
    this.FunderStore = FunderStore;
    this.transport = transport;
    this.load();

    setInterval(this.checkForNewVersion, AppConstants.APP_UPDATE_CHECK_INTERVAL);

    this.isStoreReady = true;
  }

  private get window () {
    return window as unknown as IWindow;
  }

  @action
  public load () {
    const snapshot = pick(Storage.get(SNAPSHOT_KEY), SNAPSHOT_ATTRIBUTES);
    if (!snapshot) { return; }

    Object.entries(snapshot as any).forEach(([key, val]) => {
      (this as { [key: string]: any })[key] = val;
    });
  }

  public save () {
    const snapshot = pick(toJS(this), SNAPSHOT_ATTRIBUTES);
    Storage.set(SNAPSHOT_KEY, snapshot);
  }

  @computed
  get isLoggedIn () {
    return this.transport.isAuthenticated;
  }

  @computed
  get isGuest () {
    return !this.user;
  }

  @computed
  get isMightyFreeUser () {
    return MIGHTY_SERVICE_TIERS.free === get(this.FunderStore, 'funder.mighty_service_tier');
  }

  @action
  public async hijackSession (impersonatedUserId: string) {
    try {
      this.isSessionHijacked = true;
      this.impersonatedUserId = impersonatedUserId;

      await this.transport.getAuthentication({});
      await this.FunderStore.fetchRepresentativesAndFunderInfo();
    }
    catch (err) {
      // tslint:disable-next-line no-console
      console.error('SessionStore :: Error hijacking session', err);
    }

    this.save();
  }

  @action
  public endImpersonation () {
    this.user = null;
    this.isSessionHijacked = false;
    this.impersonatedUserId = null;
    this.hasRegistryAccount = null;

    this.save();
  }

  @action
  public async checkForNewVersion () {
    if (this.isNewVersionAvailable) {
      // no need to keep checking
      return;
    }

    try {
      const stats = await axios({method: 'get', url: `${window.location.origin}/stats.json`});
      if (stats.data.jsHash !== this.window.Mighty.APP_HASH) {
        this.isNewVersionAvailable = true;
      }
    }
    catch (err) {
      // swallow, not a big deal
    }
  }

  @action
  public setUser (user: IUser) {
    // tslint:disable-next-line no-console
    console.log('SessionStore :: Setting user');

    this.user = user;

    if (this.user?.id && !this.isSessionHijacked) {
      if (this.window.DD_RUM) {
        this.window.DD_RUM.setUser({
          email: this.user.email,
          id: this.user.id,
          name: `${this.user.first_name} ${this.user.last_name}`,
        });

        this.window.DD_RUM.startSessionReplayRecording();
      }
    }

    this.save();
  }

  @action
  public async login (data: { username: string, password: string }) {
    if (typeof data !== 'object') {
      throw new Error('Invalid argument \'data\', expected type object.');
    }
    else if (!data.username) {
      throw new Error('Invalid argument \'data.username\', expect non-empty string.');
    }
    else if (!data.password) {
      throw new Error('Invalid argument \'data.password\', expect non-empty string.');
    }

    try {
      await this.transport.getAuthentication({ data });
      delete this.error.login;
    }
    catch (err) {
      this.error.login = err;
    }
  }

  @action
  public logout () {
    this.transport.clearAuthentication();

    this.user = null;
    this.isSessionHijacked = false;
    this.impersonatedUserId = null;
    this.hasRegistryAccount = null;

    this.save();
  }

  @action
  public addAuthenticationToUrl (url: string) {
    return this.transport.addAuthenticationToUrl(url);
  }

  public async requestPasswordReset (email: string) {
    return (await this.transport.post('/auth/password/reset/', {
      data: { email },
      protected: false,
    }));
  }

  public getDocumentViewUrl (document: IDocument) {
    const { download_url } = document
      , url = `${this.window.Mighty.API_HOST}${download_url}${toKey({dl: 0})}`;

    return this.addAuthenticationToUrl(url);
  }

  // On a 400 response, axios throws an error
  // resetPassword makes a POST request to the MightyApi
  // If the new_password passes validation, user is routed to login
  // MightyApi responds with a 400 if the new_password fails validation
  // We catch the 400 response as an error, and return the error messages
  // The error messages are rendered by PasswordResetPage
  @action
  public async resetPassword (data: object) {
    let errors: string[] = [];
    try {
      await this.transport.post('/auth/password/reset/confirm/', { data, protected: false });
      this.passwordResetLogin = true;
      return errors;
    }
    catch (err) {
      errors = err.response.data.new_password || err.response.data.non_field_errors;
      return errors;
    }
  }

  @action
  public async requestPasswordSet (data: object) {
    await this.transport.post('/auth/password/set/', { data });
  }

  // istanbul ignore next
  public trackEvent (eventType: string, additional = {}) {
    if (this.isSessionHijacked) {
      return;
    }

    this.window.DD_RUM.addAction(eventType, additional);
  }

  public userHasPermission (permission: string) {
    return !!this.user && this.user.permissions.includes(permission);
  }

  public async fetchRegistryAccounts () {
    if (this.hasRegistryAccount === null) {
      const registryAccounts = await this.transport.get(`${this.window.Mighty.API_HOST}/api/registry/v1/accounts-auth/`);
      this.hasRegistryAccount = !!registryAccounts.results.length;
    }
  }
}

export default SessionStore;
