import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { Button } from 'react-bootstrap';
import FormattingUtils from '../../utils/FormattingUtils';
import Icon from '../../components/common/Icon';
const { getOrDefault } = FormattingUtils;

interface IProps {
  object: { [key: string]: any, id: string };
  onSelect: (object: object) => void;
  quickAddFormat?: any;
}

@autoBindMethods
class QuickAddButton extends Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    quickAddFormat: getOrDefault,
  };

  private onClick () {
    const { object } = this.props;
    this.props.onSelect(object);
  }

  public render () {
    const { object, quickAddFormat } = this.props;
    return (
      <li key={object.id}>
        <Button bsStyle='link' bsSize='small' onClick={this.onClick}>
          <div className='ellipsis'>
            <Icon type='plus-square-o' /> {quickAddFormat(object)}
          </div>
        </Button>
      </li>
    );
  }
}

export default QuickAddButton;
