import React, { Component, Fragment } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';

import { Nav, Navbar, NavItem } from 'react-bootstrap';

import SettingsNavDropdown from './SettingsNavDropdown';
import { SearchBar, SearchNavItem } from './search';
import { Icon } from '../common';

import AnalyticsNav from './AnalyticsNav';
import ApplicationsNavDropdown from './ApplicationsNavDropdown';
import CaseTrackingNavDropdown from './CaseTrackingNavDropdown';
import ContactsNavDropdown from './ContactsNavDropdown';
import TrancheSchedulesNav from './TrancheSchedulesNav';

import {
  GuardedReportsPageLink,
  GuardedTrancheScheduleDropdown,
  HelpPageLink,
} from './PageLinks';

import { UiStoreClass, FunderStoreClass } from '../../stores';

interface IProps {
  onLogoutClick: () => void;
}

interface IInjected extends IProps {
  FunderStore: FunderStoreClass;
  UiStore: UiStoreClass;
}

@inject('UiStore', 'FunderStore')
@observer
@autoBindMethods
class FullUserNavbar extends Component <IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public render () {
    const { onLogoutClick } = this.props
      , { FunderStore, UiStore } = this.injected
      , canUseAnalytics = get(FunderStore, 'funder.can_use_analytics', false)
      ;

    return (
      <Fragment>
        <Nav className='navbar-menu'>
          {FunderStore.showApplicationWorkflow && <ApplicationsNavDropdown />}
          <CaseTrackingNavDropdown />
          <GuardedReportsPageLink query={null} />
          {canUseAnalytics && <AnalyticsNav /> }
          <ContactsNavDropdown />
          {FunderStore.hasCoopAgreement && <GuardedTrancheScheduleDropdown>
            <TrancheSchedulesNav />
          </GuardedTrancheScheduleDropdown>}
        </Nav>

        <Nav className='navbar-menu' pullRight>
          <SearchNavItem className='visible-sm' />
          <HelpPageLink />
          <SettingsNavDropdown onLogoutClick={onLogoutClick} />
        </Nav>

        <Navbar.Form className='hidden-xs hidden-sm' pullRight>
          <SearchBar />
        </Navbar.Form>

        <Nav pullRight>
          <NavItem id='btn-navbar-add' className='hidden-xs' onClick={UiStore.modals.NewCase.open}>
            <Icon type='plus' />
          </NavItem>
        </Nav>
      </Fragment>
    );
  }
}

export default FullUserNavbar;
