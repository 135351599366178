import React from 'react';
import { inject } from 'mobx-react';

import NavDropdown from './NavDropdown';
import { OpenApplicationsPageLink, RejectedApplicationsPageLink } from './PageLinks';
import { UiStoreClass } from '../../stores';

interface IInjected {
  UiStore: UiStoreClass;
}

const ApplicationsNavDropdown: React.SFC = props => {
  const { UiStore } = props as IInjected;

  return (
    <NavDropdown id='applications-dropdown' title='Applications'>
      <OpenApplicationsPageLink query={UiStore.applicationsPage.query} />
      <RejectedApplicationsPageLink query={UiStore.rejectedApplicationsPage.query} />
    </NavDropdown>
  );
};

export default inject('UiStore')(ApplicationsNavDropdown);
