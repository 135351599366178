import { action } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { get } from 'lodash';

import { AppConstants } from '../constants';
import CaseStoreClass from './CaseStoreClass';
import { ICase, ITransport } from '../interfaces';

const { CONTACT_TYPES } = AppConstants;

interface IUpsertData {
  _case?: ICase | null;
  contactData: any;
  contactType: string;
}

@autoBindMethods
class ContactStoreClass {
  private caseStore: CaseStoreClass;
  private transport: ITransport;

  constructor (caseStore: CaseStoreClass, transport: ITransport) {
    this.caseStore = caseStore;
    this.transport = transport;
  }

  private getBaseURLFromContactType (contactType: string) {
    const baseURL = get(CONTACT_TYPES[contactType], 'baseUrl');

    if (!baseURL) { throw new Error('No configuration exists for this contact type'); }

    return baseURL;
  }

  @action
  public async list (params: any, contactType: string) {
    const baseUrl = this.getBaseURLFromContactType(contactType);
    return (await this.transport.get(baseUrl, { params }));
  }

  @action
  public async createWithoutCase ({ contactData, contactType }: IUpsertData) {
    const baseUrl = this.getBaseURLFromContactType(contactType)
      , newContact = await this.transport.post(baseUrl, { data: contactData });

    return newContact;
  }

  @action
  public async create ({ _case, contactData, contactType }: IUpsertData) {
    const baseUrl = this.getBaseURLFromContactType(contactType)
      , newContact = await this.transport.post(baseUrl, { data: contactData });

    await this.assignToCase({ _case, contactId: newContact.id, contactType });

    return newContact;
  }

  @action
  public async update ({ _case, contactData, contactType }: IUpsertData) {
    const baseUrl = this.getBaseURLFromContactType(contactType)
      , contact = await this.transport.patch(`${baseUrl}:contactId/`, {
        data: contactData,
        urlParams: { contactId: contactData.id },
      });

    await this.assignToCase({ _case, contactId: contact.id, contactType });

    return contact;
  }

  @action
  public async assignToCase ({ _case, contactId, contactType, removeTrackingContact = false, setTrackingContact = false }: any) {
    if (!_case){ return; }

    const data = this.getCaseAssignmentData(_case, contactId, contactType, removeTrackingContact, setTrackingContact);
    return await this.caseStore.update({ caseId: _case.id, data });
  }

  private getContactData (_case: ICase, contactId: string, contactType: string) {
    const caseContactIds = _case.contacts.map((contact: any) => contact.id);
    if (contactType === CONTACT_TYPES.ATTORNEY.type) {
      return { attorney: contactId };
    }

    if (!caseContactIds.includes(contactId)) {
      return { contacts: [...caseContactIds, contactId] };
    }

    return {};
  }

  private getTrackingContactData (contactId: string, removeTrackingContact: boolean, setTrackingContact: boolean) {
    if (removeTrackingContact) {
      return { tracking_contact: null };
    }

    if (setTrackingContact) {
      return { tracking_contact: contactId };
    }

    return {};
  }

  private getCaseAssignmentData (_case: ICase, contactId: string, contactType: string, removeTrackingContact: boolean, setTrackingContact: boolean) {
    const contactData = this.getContactData(_case, contactId, contactType)
      , trackingContactData = this.getTrackingContactData(contactId, removeTrackingContact, setTrackingContact)
      ;

    return { ...contactData, ...trackingContactData };
  }
}

export default ContactStoreClass;
