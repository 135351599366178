/* tslint:disable variable-name */
import { computed, observable, toJS } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { get, isBoolean, isArray, isString } from 'lodash';

import FlatStore from '../stores/FlatStore';
import { AppConstants } from '../constants';
import { IApplication, ICase, ILien } from '../interfaces';

import BaseModel from './BaseModel';

const {
  APPLICATION_STATUS,
  LIEN_MODELS_BY_FUNDING_TYPE,
} = AppConstants;

const ACCEPTING_RETURN = { is_confirmed: true, is_open: true };

@autoBindMethods
class CaseModel extends BaseModel implements ICase {
  @observable public applications: IApplication[] = [];
  @observable public case_settlement_offer = {};
  @observable public custom_field_data = {};
  @observable public defendants = [];
  @observable public documents = [];
  @observable public download_all_lien_docs_url: string = '';
  @observable public id: string;
  @observable public lead_source = null;
  @observable public liens_acknowledged = false;
  @observable public migrate_to_registry = true;
  @observable public payoff_download_url?: string;
  @observable public prior_fundings = [];
  @observable public registry_url: string = '';
  @observable public return_status = null;
  @observable public state_of_incident = null;
  @observable public tracking_summary = {
    case: null,
    last_update_body: null,
    last_update_recorded_at: null,
    tracking_attributes: [],
    tracking_status: null,
  };
  @observable public type = null;

  // Not part of case endpoint response
  @observable public loadingLiens = false;
  @observable public liens: string[] = [];

  // istanbul ignore next
  constructor (data: ICase, flatStore: FlatStore) {
    super(flatStore);
    this.extend(data);
    this.id = data.id;
  }

  public getLiens (filter: { [key: string]: any } = {}): ILien[] {
    const liensUnsorted = this.liens.map(id => this.flatStore.lien.get(id))
      , byAgreementDate = (a: ILien, b: ILien) => get(b, 'agreement_date') < get(a, 'agreement_date') ? -1 : 1
      , liens = liensUnsorted.sort(byAgreementDate)
      , filterKeys = Object.keys(filter)
      ;

    if (filterKeys.length) {
      return liens.filter(lien => {
        return filterKeys.every(filterKey => {
          const filterValue = filter[filterKey]
            , lienValue = toJS(get(lien, filterKey));

          if (filterKey === 'funding_type') {
            return LIEN_MODELS_BY_FUNDING_TYPE[filterValue].includes(get(lien, 'type'));
          }

          if (isBoolean(filterValue)) {
            if (isArray(lienValue)) {
              return !!lienValue.length === filterValue;
            }
            return !!lienValue === filterValue;
          }

          // Not currently used, but could be used to filter by exact value
          // of an attribute, like { type: LIEN_MODELS.LEGACY.model }
          // istanbul ignore next
          return lienValue === filterValue;
        });
      });
    }

    // Not currently used, but if there are no filters, just return all liens
    // istanbul ignore next
    return liens;
  }

  public hasLiens (filter = {}): boolean {
    return !!this.getLiens(filter).length;
  }

  public getLeadSource () {
    if (isString(this.lead_source)) {
      return this.flatStore.leadSource.get(this.lead_source);
    }
    return this.lead_source;
  }

  @computed
  get openApplications () {
    return this.applications.filter(app => app.is_open);
  }

  @computed
  get rejectedApplications () {
    const rejected = APPLICATION_STATUS.rejected.toLowerCase();
    return this.applications.filter(app => app.status === rejected);
  }

  @computed get liensAcceptingReturns () { return this.getLiens(ACCEPTING_RETURN); }
  @computed get hasLiensAcceptingReturns () { return !!this.liensAcceptingReturns.length; }
}

export default CaseModel;
