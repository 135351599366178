import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { withinDecimalPlaces } from '../../../utils/util';
import { ITableField } from './LiensTable';
import { ILienState } from './CaseLogPaymentModal';

interface IProps {
  lien: ILienState;
  onCheckboxChange: (lienId: string, checkAll: boolean) => void;
  onInputChange: (lienId: string, value: unknown, key?: string) => void;
  tableFields: ITableField[];
}

@autoBindMethods
@observer
class LienTableRow extends Component<IProps> {
  private onInputChange (event: any) {
    const { lien } = this.props
      , { id: key, value } = event.target;

    if (!withinDecimalPlaces(value)) { return; }
    this.props.onInputChange(lien.id, value, key);
  }

  private onCheckboxChange (event: any) {
    const { lien } = this.props
      , { checked } = event.target;

    this.props.onCheckboxChange(lien.id, checked);
  }

  public render () {
    const { lien, tableFields } = this.props
      , tableFieldsSliced = tableFields.filter(tableField => !!tableField.formatter) as Array<ITableField & { formatter: (value: any, ...args: any[]) => React.ReactNode }>
      , tableFieldInputs = tableFields.filter(tableField => tableField.type === 'input')
      ;

    return (
      <tr key={lien.id}>
        <td className='col-checkbox'>
          <div>
            <input
              checked={lien.checked}
              id={`lien-return-checkbox-${lien.id}`}
              name={lien.id}
              onChange={this.onCheckboxChange}
              type='checkbox'
            />
            <label htmlFor={`lien-return-checkbox-${lien.id}`} />
          </div>
        </td>
        {tableFieldsSliced.map((field, idx) => (
          <td key={field.key} className={cx({'text-right': idx > 0})}>{field.formatter(lien[field.key])}</td>
        ))}
        {tableFieldInputs.map(tableFieldInput => (
          <td className='col-return-amount text-right' key={tableFieldInput.key}>
            {lien.checked && (
              <input
                className='condensed text-right'
                disabled={tableFieldInput.disabled}
                id={tableFieldInput.key}
                name={lien.id}
                onChange={this.onInputChange}
                type='number'
                value={lien[tableFieldInput.key]}
              />
            )}
          </td>
        ))}
      </tr>
    );
  }
}

export default LienTableRow;
