import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { MenuItem } from 'react-bootstrap';

import { formatDate } from '@mighty-justice/utils';

import { Icon } from '../common';
import { SmartBool } from '../../utils';
import { FunderStoreClass } from '../../stores';
import { ITrancheSchedule } from '../../interfaces';

interface IProps {
  schedule: ITrancheSchedule;
}

interface IInjected extends IProps {
  FunderStore: FunderStoreClass;
}

@inject('FunderStore')
@observer
@autoBindMethods
class TrancheScheduleMenuItem extends Component<IProps> {
  @observable private isLoading = new SmartBool();

  get injected () {
    return this.props as IInjected;
  }

  public async onSelect (eventKey: any) {
    const { FunderStore } = this.injected;

    await this.isLoading.until(FunderStore.downloadTrancheSchedule(eventKey));
  }

  public render () {
    const { id, start_date } = this.props.schedule;

    return (
      <MenuItem
        className='tranche-schedule'
        disabled={this.isLoading.isTrue}
        eventKey={id}
        onSelect={this.onSelect}
      >
        {this.isLoading.isTrue
          ? <span><span className='spinner' /> <span /></span>
          : <Icon className='download-tranche' type='download' />
        }
        {formatDate(start_date)}
      </MenuItem>
    );
  }
}

export default TrancheScheduleMenuItem;
