import React, { Component, Fragment } from 'react';
import { computed, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { omit, omitBy, has, get, zipObject } from 'lodash';

import { insertIf } from '@mighty-justice/utils';

import { RepresentativeDropdownFilter } from '../common';
import { AppConstants } from '../../constants';
import { isEmptyOrNil, mapObjectKeys } from '../../utils/util';
import FormattingUtils from '../../utils/FormattingUtils';

import {
  IFacetOption,
} from '../../interfaces';

import { FacetResetAll, FacetComplete, FacetDate } from '../common-facet';

import ApplicationListTypeDropdown from '../page-case/main-applications/ApplicationListTypeDropdown';
import FunderStoreClass from '../../stores/FunderStoreClass';
import UiStoreClass from '../../stores/UiStoreClass';
import { default as ApplicationStoreClass } from '../../stores/ApplicationStore';

const {
  getNameOrDefault,
} = FormattingUtils;

const UnTypedRepresentativeDropdownFilter: any = RepresentativeDropdownFilter;

const {
  APPLICATION_FACETS_API,
  APPLICATION_FACETS_DISPLAY,
  APPLICATION_MODELS,
  BOOLEAN_FILTER_OPTIONS,
  OPEN_APPLICATION_STATES,
  REGISTRY_SHARING_ELIGIBILITY_OPTIONS,
  REPRESENTATIVES_UNASSIGNED,
} = AppConstants;

const keysToDisplay = (object: object) => mapObjectKeys(object, APPLICATION_FACETS_DISPLAY);

interface IFacetFilters {
  [key: string]: {
    [key: string]: boolean;
  };
}

export interface ICounts {
  [key: string]: any;

  count: number;
  id: string;
  name: string;
}

export interface ISidebarCounts {
  [key: string]: ICounts[];
}

const DEFAULT_FILTERS: IFacetFilters = {
    case__registry_sharing_eligibility_summary__sharing_eligibility: {},
    is_claim_received: {},
    is_treatment_scheduled: {},
    medical_facility: {},
    medical_provider: {},
    status: {},
  }
  , FILTER_NAMES = Object.keys(DEFAULT_FILTERS)
  , CONST_FILTERS = [
    'case__registry_sharing_eligibility_summary__sharing_eligibility',
    'is_claim_received',
    'is_treatment_scheduled',
    'status',
  ]
  , DYNAMIC_FILTERS = ['medical_facility', 'medical_provider']
  , DATE_FILTERS = ['case__processing_follow_up_date']
  ;

interface IProps {
  changeFilters: (resetAll: boolean) => void;
  changeTableType: (change: object) => void;
  sidebarCounts: ISidebarCounts;
  tableType: string;
}

interface IInjected extends IProps {
  ApplicationStore: ApplicationStoreClass;
  FunderStore: FunderStoreClass;
  UiStore: UiStoreClass;
}

@inject('ApplicationStore', 'FunderStore', 'UiStore')
@autoBindMethods
@observer
class ApplicationsSidebar extends Component<IProps> {
  private isResetting = false;

  @observable private facetFilters: IFacetFilters = DEFAULT_FILTERS;
  @observable private dateFilters: { [key: string]: string[] | null } = {};
  @observable private activeFilters: { [key: string]: string | null } = {};

  private get injected () {
    return this.props as IInjected;
  }

  private get sidebarCounts () {
    const { sidebarCounts } = this.props;
    return sidebarCounts && keysToDisplay(sidebarCounts);
  }

  constructor (props: IInjected) {
    super(props);
    this.componentWillReceiveProps(props);
  }

  public componentWillReceiveProps (nextProps: IInjected) {
    const {
      UiStore: { applicationsPage: { filters } },
    } = nextProps;

    this.activeFilters = omit(filters, 'status');

    CONST_FILTERS.forEach(filter => {
      const options = filter === 'status' ? OPEN_APPLICATION_STATES : BOOLEAN_FILTER_OPTIONS;
      if (filters[filter]) {
        options.forEach(option => {
          this.facetFilters[filter][option.value] = filters[filter].includes(option.value);
        });
      }
    });

    DYNAMIC_FILTERS.forEach(filter => {
      if (filters[filter]) {
        const values = filters[filter].split(',');
        values.forEach(value => {
          this.facetFilters[filter][value] = true;
        });
      }
    });
  }

  @computed
  private get selectedRepresentative () {
    const { UiStore: { applicationsPage } } = this.injected;
    if (applicationsPage.filters.is_unassigned) {
      return REPRESENTATIVES_UNASSIGNED;
    }
    return applicationsPage.filters.representative;
  }

  @computed
  private get filters () {
    const filters = {...this.activeFilters};
    FILTER_NAMES.forEach(name => {
      const facet = this.facetFilters[name]
        , chosen = Object.keys(facet).filter(key => facet[key] === true);

      filters[name] = chosen.join(',') || null;
    });

    DATE_FILTERS.forEach(name => {
      filters[`${name}_after`] = this.dateFilters[name]?.[0] || null;
      filters[`${name}_before`] = this.dateFilters[name]?.[1] || null;
    });

    return omitBy(filters, isEmptyOrNil);
  }

  private changeFilters () {
    const { changeFilters } = this.props
      , { UiStore: { applicationsPage } } = this.injected;

    applicationsPage.filters = this.filters as { [key: string]: string };
    changeFilters(this.isResetting);
  }

  private onRepresentativeChange (filterParams: { [key: string]: string }) {
    this.activeFilters = filterParams;
    this.changeFilters();
  }

  private onCheckboxChange (value: string, isChecked: boolean, fieldName: string) {
    this.facetFilters[fieldName][value] = isChecked;
    this.changeFilters();
  }

  private onDateFilterChange (field: string, value: Record<string, string>) {
    this.dateFilters = {...this.dateFilters, [field]: Object.values(value)};
    this.changeFilters();
  }

  private resetFilter (fieldName: string) {
    this.isResetting = true;
    if (DATE_FILTERS.includes(fieldName)) {
      this.dateFilters[fieldName] = [];
    }
    else {
      this.facetFilters[fieldName] = {};
    }
    this.changeFilters();
    this.isResetting = false;
  }

  private onResetAllClick () {
    Object.keys(this.sidebarCounts).forEach(this.resetFilter);
    DATE_FILTERS.forEach(this.resetFilter);
  }

  private getCounts (key: string) {
    if (!has(this.sidebarCounts, key)) {
      return undefined;
    }

    const optionArray: ICounts[] = this.sidebarCounts[key]
      , optionIds = optionArray.map((option: ICounts) => option.id)
      , optionCounts = optionArray.map((option: ICounts) => option.count)
      , countsObj = zipObject(optionIds, optionCounts)
      ;

    return countsObj;
  }

  private get registrySharingEligibilityOptions () {
    const { FunderStore } = this.injected;

    if (get(FunderStore, 'funder.has_private_cases')) { return REGISTRY_SHARING_ELIGIBILITY_OPTIONS; }
    return REGISTRY_SHARING_ELIGIBILITY_OPTIONS.filter((option: IFacetOption) => option.value !== 'eligible_private');
  }

  private getOptions (facetName: string) {
    const staticOptions: { [key: string]: IFacetOption[] } = {
      case__registry_sharing_eligibility_summary__sharing_eligibility: this.registrySharingEligibilityOptions as IFacetOption[],
      is_claim_received: BOOLEAN_FILTER_OPTIONS,
      is_treatment_scheduled: BOOLEAN_FILTER_OPTIONS,
      status: OPEN_APPLICATION_STATES as IFacetOption[],
    };

    if (has(staticOptions, facetName)) {
      return staticOptions[facetName];
    }

    const options: ICounts[] = toJS(get(this.sidebarCounts, facetName, []));

    // Join first and last name for medical providers
    if (facetName === 'medical_provider') {
      options.forEach((option: any) => {
        option.name = getNameOrDefault({
          first_name: option.medical_lien_summary__medical_provider__first_name,
          last_name: option.medical_lien_summary__medical_provider__last_name,
        });
      });
    }

    return options.map((option: ICounts) => ({
      name: option.name,
      value: option.id,
    }));
  }

  private get facets () {
    const { tableType } = this.props
      , { FunderStore } = this.injected
      , isMedicalApplicationTable = tableType === APPLICATION_MODELS.MEDICAL.model
      , baseFacets = [
        { fieldName: 'status', label: 'Application Status', sorted: true },
        {
          fieldName: 'case__processing_follow_up_date',
          limited: false,
          title: 'Processing Follow-Up Date',
        },
      ]
      , medicalFacets = [
        { fieldName: 'is_treatment_scheduled', label: 'Treatment Scheduled' },
        { fieldName: 'is_claim_received', label: 'Claim Received' },
        { fieldName: 'medical_facility', label: 'Medical Facility' },
        { fieldName: 'medical_provider', label: 'Medical Provider' },
      ]
      , registryFacet = {
        fieldName: 'case__registry_sharing_eligibility_summary__sharing_eligibility',
        label: 'Law Firm Portal Eligibility',
      }
    ;

    return [
      ...baseFacets,
      ...(isMedicalApplicationTable ? medicalFacets : []),
      ...insertIf(!!FunderStore.isRegistryEnabled, registryFacet),
    ];
  }

  private onSelect (selected: IFacetOption, fieldName: string) {
    const id = get(selected, 'id');

    this.onCheckboxChange(id, true, fieldName);
  }

  private async searchFacets (params: object, fieldName: string) {
    const { tableType } = this.props
      , { ApplicationStore } = this.injected
      , facetName = get(APPLICATION_FACETS_API, fieldName)
      , search = get(params, 'search')
      , searchParams = { facet: facetName, facet_search: search, ...omit(params, 'search')};

    return await ApplicationStore.searchFacets(tableType, searchParams);
  }

  private fetchOptions (_params: object, fieldName: string) {
    const options = this.getOptions(fieldName)
      , counts: { [key: string]: number } = this.getCounts(fieldName) || {}
      , checked: { [key: string]: boolean } = this.facetFilters[fieldName];

    return options.map(option => ({
      ...option,
      checked: checked[option.value],
      count: get(counts, option.value, 0),
    })) as IFacetOption[];
  }

  public render () {
    const { tableType, changeTableType } = this.props
      , { UiStore: { applicationsPage } } = this.injected;

    const facetFilters: { [key: string]: string[] } = { tableType: [tableType] };
    Object.keys(this.facetFilters).forEach(key => {
      facetFilters[key] = Object.keys(this.facetFilters[key]).filter(subKey => {
        return !!this.facetFilters[key][subKey];
      });
    });

    return (
      <div>
        <ApplicationListTypeDropdown
          changeTableType={changeTableType}
          tableType={tableType}
        />

        {this.facets.length > 1 &&
          <FacetResetAll onClick={this.onResetAllClick} />}

        <UnTypedRepresentativeDropdownFilter
          loggedInUserLabel='My Applications'
          onFilterChange={this.onRepresentativeChange}
          selectedRepresentative={this.selectedRepresentative}
          showAll
          showUnassigned
        />

        {this.facets.map(facet => {
          if (DATE_FILTERS.includes(facet.fieldName)) {
            return (
              <Fragment key={`${tableType}_${facet.fieldName}`}>
                <hr />
                <FacetDate
                  collapsible={false}
                  key={facet.fieldName}
                  onQueryChange={this.onDateFilterChange}
                  reset={this.resetFilter}
                  value={this.dateFilters[facet.fieldName]}
                  {...facet}
                />
              </Fragment>
            );
          }
          return (
            <Fragment key={`${tableType}_${facet.fieldName}`}>
              <hr />
              <FacetComplete
                collapsible={false}
                fetchOptions={this.fetchOptions}
                fetchSearch={this.searchFacets}
                filters={facetFilters}
                loading={applicationsPage.loading || this.getCounts(facet.fieldName) === undefined}
                onCheckboxChange={this.onCheckboxChange}
                onSelect={this.onSelect as any}
                reset={this.resetFilter}
                {...facet}
              />
            </Fragment>
          );
        })}

      </div>
    );
  }
}

export default ApplicationsSidebar;
