import autoBindMethods from 'class-autobind-decorator';
import URI from 'urijs';

import { IAuthenticator } from '../interfaces';

@autoBindMethods
class TokenAuthenticatorClass implements IAuthenticator {
  public apiToken?: string;

  constructor () {
    const search = URI.parseQuery(window.location.search) as { token?: string };
    this.apiToken = search.token;
  }

  get isAuthenticated () {
    return !!this.apiToken;
  }

  public async prepareInvoke () {
    return true;
  }

  public getAuthorizationHeader () {
    return `Token ${this.apiToken}`;
  }

  public handleUnauthorized () {
    return;
  }

  public addAuthenticationToUrl (url: string) {
    if (typeof url !== 'string') {
      throw new TypeError('Invalid argument \'url\', expected string.');
    }

    const urlClean = url.replace(/&?token=[^&$]+/i, ''); // clean up existing token
    return urlClean.indexOf('?') === -1
      ? `${urlClean}?token=${this.apiToken}`
      : `${urlClean}&token=${this.apiToken}`;
  }
}

export default TokenAuthenticatorClass;
