import React, { Component } from 'react';
import { filter } from 'lodash';

import { Row } from 'react-bootstrap';

import { AppConstants } from '../../../src/constants';

import { IActivity, ICase, IDocument } from '../../interfaces';
import { FormattingUtils } from '../../utils';

import CaseActivity from './CaseActivity';

const { truncate } = FormattingUtils;
const { ACTIVITY_FILE_NAME_TRUNCATE_LENGTH } = AppConstants;

interface IDocumentsSharedToRegistry extends IActivity {
  document_ids: string[];
}

interface IProps {
  _case: ICase;
  activity: IDocumentsSharedToRegistry;
  lienDocuments: IDocument[];
}

class DocumentsSharedToRegistry extends Component<IProps> {
  public render () {
    const { _case, activity: { document_ids }, lienDocuments } = this.props
    , caseDocuments = document_ids.map((id: string) => _case.documents.find((document: any) => document.id === id))
    , caseLienDocuments = document_ids.map((id: string) => lienDocuments.find((document: any) => document.id === id))
    , isCaseDocumentsEmpty = caseDocuments.every((document) => document === undefined);

    const activityBody = (
      <div>
        <h3>Documents Shared to Law Firm Portal</h3>
        <Row className='status'>Documents:</Row>
        <Row className='files'>
          {filter(!isCaseDocumentsEmpty ? caseDocuments : caseLienDocuments)
            .map((document: any, idx: any) =>
              <li key={idx}>
                {truncate(document.name, ACTIVITY_FILE_NAME_TRUNCATE_LENGTH)}
              </li>,
          )}
        </Row>
      </div>
    );

    return (
      <CaseActivity
        activity_body={activityBody}
        dot='icon'
        icon='file'
        {...this.props}
      />
    );
  }
}

export default DocumentsSharedToRegistry;
