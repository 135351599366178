import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { kebabCase, some } from 'lodash';
import { Row } from 'react-bootstrap';

import { RadioButtonGroup } from '../../../common';

interface IProps {
  attributes: Array<{ value: string, name: string }>;
  category: string;
  key: string;
  onSelectedAttributesChanged: (selectedAttribute: string[], checked: boolean) => {};
  selectedTrackingAttributes: string[];
}

@autoBindMethods
@observer
class CaseUpdateAttributes extends Component<IProps> {
  @observable private stillAfterCase: { [key: string]: boolean|null } = { Attorney: null, Plaintiff: null};

  private onCasePursuingClick (value: string, fieldName: string) {
    const isAfterCase = value !== 'true';
    this.stillAfterCase[fieldName] = isAfterCase;
    if (!isAfterCase) {
      this.props.onSelectedAttributesChanged(this.props.selectedTrackingAttributes, false);
    }
  }

  private renderCategoryRadioButtons (category: string, stillAfterCase: boolean) {
    const actionText = category === 'Attorney' ? 'trying' : 'pursuing';

    return (
      <RadioButtonGroup
        checker={stillAfterCase}
        descriptionLookupString='grouping.description'
        fieldName={category}
        handleRadioClick={this.onCasePursuingClick}
        items={[
          { grouping: { description: `Still ${actionText} the case`, item: true } },
          { grouping: { description: `No longer ${actionText} the case`, item: false } },
        ]}
        lookupString='grouping.item'
        title={`${category} Status`}
      />
    );
  }

  private onTrackingAttributeCheck (e: any) {
    const { checked } = e.target
      , { name } = e.currentTarget
      ;

    this.props.onSelectedAttributesChanged([name], checked);
  }

  public render () {
    const { category, attributes} = this.props
      , isSomeAttributeChecked = some(
        attributes,
        (status) => this.props.selectedTrackingAttributes.includes(status.value)
      )
      , shouldShowCategoryAttributes = this.stillAfterCase[category] !== null
        ? this.stillAfterCase[category]
        : isSomeAttributeChecked
      ;

    return (
      <Row key={this.props.key}>
        {this.renderCategoryRadioButtons(category, !shouldShowCategoryAttributes)}
        {shouldShowCategoryAttributes &&
          <ul className='list-checkbox two-columns'>
            {attributes.map(status => (
              <li key={status.value}>
                <input
                  id={kebabCase(`tracking_attribute_${status.value}`)}
                  name={status.value}
                  onChange={this.onTrackingAttributeCheck}
                  type='checkbox'
                  checked={this.props.selectedTrackingAttributes.includes(status.value)}
                />
                <label htmlFor={kebabCase(`tracking_attribute_${status.value}`)}>{status.name}</label>
              </li>
            ))}
          </ul>
        }
      </Row>
    );
  }
}

export default CaseUpdateAttributes;
