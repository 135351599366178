import autoBindMethods from 'class-autobind-decorator';
import { clone } from 'lodash';

const KEYS = ['page', 'id', 'main', 'aside']
  , DEFAULTS: { [key: string]: string } = {
    aside: 'feed',
    main: 'details',
    page: 'case',
  };

@autoBindMethods
class CaseUrlBuilder {
  private urlObj = clone(DEFAULTS);

  constructor (pathname: string) {
    return this.fromPath(pathname);
  }

  public fromPath (pathname: string) {
    const pathnameSplit = pathname.split('/').filter(s => !!s);

    pathnameSplit.forEach((val, i) => {
      this.urlObj[KEYS[i]] = val;
    });

    return this;
  }

  public change (key: string, value: string) {
    this.urlObj[key] = value;
    return this;
  }

  public toPathname () {
    return `/${KEYS.map(key => this.urlObj[key]).join('/')}`;
  }
}

export default CaseUrlBuilder;
