import React, { Component, Fragment } from 'react';
import { Row } from 'react-bootstrap';
import { get } from 'lodash';

import { InfoWell } from '../../components/common';
import { fillInFieldConfig } from './common';
import { IFieldConfigPartial } from '../../lib/mighty-fields';

interface IProps {
  fieldConfig: IFieldConfigPartial;
  model: { [key: string]: any };
}

class CardRow extends Component<IProps, {}> {
  public render () {
    const { model } = this.props
      , fieldConfig = fillInFieldConfig(this.props.fieldConfig)
      , { field, render, label, showLabel, writeOnly } = fieldConfig;

    if (!render) {
      // istanbul ignore next
      return;
    }

    const value = render(get(model, field), fieldConfig);

    if (writeOnly) {
      return null;
    }

    return (
      <Row key={field}>
        {!showLabel ? value
        : (
          <Fragment>
            <InfoWell.Label>{label}</InfoWell.Label>
            <InfoWell.Value>{value}</InfoWell.Value>
          </Fragment>
        )}
      </Row>
    );
  }
}

export default CardRow;
