import { action, observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { get, find, filter, reduce } from 'lodash';

import { AppConstants } from '../constants';

const { CONTACT_TYPES, REGISTRY_SHARING_ELIGIBILITY_OPTIONS } = AppConstants;

import {
  ITransport,
} from '../interfaces';

interface IDebouncer {
  flush: () => void;
}

interface IOption {
  name: string;
  value: any;
}

interface ICaseTypeOption {
  name: string;
  id: any;
}

interface IDocumentTypeOption {
  description: string;
  id: number;
  name: string;
}

interface IApplicationStatusTransitionReason {
  id: string;
  reason: {
    short_code: string,
  };
}

export interface IBulkEditConfiguration {
  id: string;
  name: string;
  fields: string[];
}

export interface IBulkAddField {
  field_name: string;
  options: string[];
  required: boolean;
}

const BLANK_OPTION: IOption = { value: null, name: '' }
  , BLANK_CASE_TYPE_OPTION: ICaseTypeOption = { id: null, name: '' };

@autoBindMethods
class OptionsStoreClass {
  private transport: ITransport;

  @observable public loading = true;

  @observable public applicationStatusOptions: IOption[] = [];
  @observable public applicationStatusTransitionReasons: IApplicationStatusTransitionReason[] = [];
  @observable public bulkEditConfigurations: IBulkEditConfiguration[] = [];
  @observable public caseMedicalStatusOptions: IOption[] = [];
  @observable public caseTrackingAttributeOptions: IOption[] = [];
  @observable public caseTrackingFollowUpDateStatusOptions: IOption[] = [];
  @observable public caseTrackingStatusOptions: IOption[] = [];
  @observable public caseTypeOptions: ICaseTypeOption[] = [];
  @observable public documentTypeOptions: IDocumentTypeOption[] = [];
  @observable public genericLienTypes: Array<{ id: string, name: string }> = [];
  @observable public importConfigurations: Array<{ id: string, name: string }> = [];
  @observable public bulkAddFields: IBulkAddField[] = [];
  @observable public leadSourceCategoryOptions: IOption[] = [];
  @observable public returnTypes: IOption[] = [];
  @observable public stateOptions: IOption[] = [];
  @observable public warningReasons: { [key: string]: string } = {};

  public debouncers: Set<IDebouncer> = new Set([]);

  public sexOptions: IOption[] = [
    {name: 'Male', value: 'M'},
    {name: 'Female', value: 'F'},
  ];

  public yesOrNoOptions: IOption[] = [
    {name: 'Yes', value: true},
    {name: 'No', value: false},
  ];

  public methodOfCommunicationOptions: IOption[] = [
    'Cell',
    'Email',
    'Fax',
    'Phone',
  ].map(v => ({ name: v, value: v }));

  public contactTypeOptions: IOption[] = [
    {name: CONTACT_TYPES.ATTORNEY.shortDisplay, value: CONTACT_TYPES.ATTORNEY.type},
    {name: CONTACT_TYPES.LAW_FIRM.shortDisplay, value: CONTACT_TYPES.LAW_FIRM.type},
    {name: CONTACT_TYPES.OTHER.shortDisplay, value: CONTACT_TYPES.OTHER.type},
  ];

  public relationshipToCaseOptions = [
    {name: 'Attorney', value: 'Attorney'},
    {name: 'Paralegal/Case Manager', value: 'Paralegal/Case Manager'},
    {name: 'Other', value: 'Other'},
  ];

  public registrySharingEligibilityOptions: IOption[] = REGISTRY_SHARING_ELIGIBILITY_OPTIONS;

  // istanbul ignore next
  constructor (transport: ITransport) {
    this.transport = transport;
  }

  public async search (endpoint: string, search: string) {
    const params = { search }
      , response = await this.transport.get(endpoint, { params });
    return response.results;
  }

  public registerDebouncer (func: IDebouncer) {
    this.debouncers.add(func);
  }

  public removeDebouncer (func: IDebouncer) {
    this.debouncers.delete(func);
  }

  public async flushDebouncers () {
    await Promise.all(Array.from(this.debouncers).map(debounced => debounced.flush()));
  }

  public async fetchLeadSourceNameOptions (category: string, search: string) {
    return (await this.transport.get('/lead-sources/', { params: { category, search } })).results;
  }

  @action
  public async fetchImportConfigurationOptions () {
    const configs = await this.transport.get('/funder-import-configurations/');
    this.importConfigurations = configs.map((config: {id: string, label: string}) => ({id: config.id, name: config.label}));
  }

  @action
  public async prepareOptionStore () {
    const response = await this.transport.get('/options/');

    this.applicationStatusOptions = response['application_statuses'];
    this.applicationStatusTransitionReasons = response['application_status_transition_reasons'];
    this.bulkAddFields = response['bulk_add_fields'];
    this.bulkEditConfigurations = response['bulk_edit_configurations'];
    this.caseMedicalStatusOptions = response['case_medical_statuses'];
    this.caseTrackingAttributeOptions = response['case_tracking_attributes'];
    this.caseTrackingFollowUpDateStatusOptions = response['case_tracking_follow_up_date_statuses'];
    this.caseTrackingStatusOptions = response['case_tracking_statuses'];
    this.caseTypeOptions = response['case_types'];
    this.documentTypeOptions = response['document_types'];
    this.genericLienTypes = response['generic_lien_subtypes'].map((subtype: IOption) => ({id: subtype.value, name: subtype.name}));
    this.leadSourceCategoryOptions = response['lead_source_types'];
    this.returnTypes = response['return_types'];
    this.stateOptions = response['states'];
    this.warningReasons = reduce(response['warning_reasons'], (reasons: any, r: IOption) => { reasons[r.value] = r.name; return reasons; }, {});

    this.loading = false;
  }

  @action
  public async prepareIntake () {
    const response = await this.transport.get('/intake-options/');

    this.caseTypeOptions = response['case_types'];
    this.stateOptions = response['states'];

    this.loading = false;
  }

  public getStateOptionByCode (code: string) {
    return this.getFirstOptionByKey(this.stateOptions, 'code', code) as IOption || BLANK_OPTION ;
  }

  public formatStateName (obj?: { address?: { state: string } }) {
    if (!obj || !get(obj, 'address.state')) { return null; }
    return this.getStateOptionByCode(obj!.address!.state).name;
  }

  public getCaseTypeOptionById (id: string) {
    return this.getFirstOptionByKey(this.caseTypeOptions, 'id', id) as ICaseTypeOption || BLANK_CASE_TYPE_OPTION;
  }

  public getApplicationTranisitionReasonsByStatus (status: string) {
    return this.getAllOptionsByKey(this.applicationStatusTransitionReasons, 'status', status) || BLANK_OPTION;
  }

  public getApplicationTranistionReasonByShortCode (shortCode: string) {
    return this.applicationStatusTransitionReasons.find((o) => o.reason.short_code === shortCode);
  }

  public getFirstOptionByKey (options: object[], key: string, value: any) {
    return find(options, { [key]: value });
  }

  public getAllOptionsByKey (options: object[], key: string, value: any) {
    return filter(options, { [key]: value });
  }
}

export default OptionsStoreClass;
