import React, { Component } from 'react';
import NamePhoneSearchResultItem from './NamePhoneSearchResultItem';
import { IDisplaySearchResultItemConfiguration } from './SearchResultUtils';

class MedicalFacilityContactResultItem extends Component<IDisplaySearchResultItemConfiguration> {
  public render () {
    const displayItemConfiguration: IDisplaySearchResultItemConfiguration = {
      ...this.props,
      entity: 'medicalfacilitycontact',
      path: '/contacts/people/',
    };

    return (
      <NamePhoneSearchResultItem { ...displayItemConfiguration } />
    );
  }
}

export default MedicalFacilityContactResultItem;
