import React, { Component, Fragment } from 'react';
import { browserHistory } from 'react-router';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';

import {
  Col,
  HelpBlock,
  Row,
} from 'react-bootstrap';

import TaskCompletedModal from './TaskCompletedModal';
import { ActionButton, DocumentTableBody, Icon, Link, Spacer } from '../common';
import ClientsClass from '../../clients/ClientsClass';
import { SmartBool, FormattingUtils, TableRenderingUtils } from '../../utils';
import { CaseStoreClass, FunderStoreClass, SessionStoreClass } from '../../stores';

import AppConstants from '../../constants/AppConstants';

const { formatDateTime, parseAndPreserveNewlines, toKey } = FormattingUtils;
const { USER_FLOW } = AppConstants;

const {
  renderFileType,
} = TableRenderingUtils;

interface IProps {
  task: any;
}

interface IInjected extends IProps {
  CaseStore: CaseStoreClass;
  Clients: ClientsClass;
  FunderStore: FunderStoreClass;
  SessionStore: SessionStoreClass;
}

@inject('CaseStore', 'Clients', 'SessionStore', 'FunderStore')
@observer
@autoBindMethods
class DocumentSharingTaskDetail extends Component<IProps> {
  @observable private showCompletedMessage = new SmartBool();

  public componentDidMount () {
    const location = browserHistory.getCurrentLocation();
    if (location.state === USER_FLOW.FROM_COMPLETED_TASK_RESPONSE) {
      this.showCompletedMessage.setTrue();
    }
  }

  private get injected () {
    return this.props as IInjected;
  }

  private getViewUrl (document: { download_url: string }) {

    const { download_url } = document
      , url = `${download_url}${toKey({ dl: 0 }, download_url)}`;

    return this.injected.SessionStore.addAuthenticationToUrl(url);
  }

  // istanbul ignore next
  private trackDownloadEvent () {
    const { SessionStore } = this.injected
      , { task } = this.props
      ;

    if (SessionStore.isGuest) {
      SessionStore.trackEvent('DOCUMENT_SHARED_DOWNLOADED', { taskId: task.id });
    }
  }

  private getDownloadFileLink (row: any) {
    return <a href={this.getViewUrl(row.original)} target='_blank' onClick={this.trackDownloadEvent}>{row.original.name}</a>;
  }

  get documents () {
    const { task } = this.props
      , documents = get(task, 'documents_shared.documents', []);

    return documents.map((document: {[key: string]: string}) => {
      return { ...document, download_url: document.file_download_url };
    });
  }

  // istanbul ignore next
  private onDownloadDocumentClick (url: string, filename?: string) {
    const { CaseStore } = this.injected;
    this.trackDownloadEvent();
    CaseStore.onDocumentDownloadClick(url, filename);
  }

  private get columns () {
    return [
      {
        accessor: 'file',
        Cell: renderFileType,
        className: 'col-file',
        Header: 'File Type',
        maxWidth: 25,
      },
      {
        accessor: 'name',
        Cell: this.getDownloadFileLink,
        Header: 'Name',
        minWidth: 150,
      },
      {
        accessor: 'download_url',
        Cell: (row: any) => (
          <div>
            <ActionButton data={row.value} onClick={this.onDownloadDocumentClick}>
              <Icon type='download' />
            </ActionButton>
          </div>
        ),
        className: 'col-buttons',
        Header: 'actions',
        width: 60,
      },
    ];
  }

  private renderDocumentsTabLink () {
    const { SessionStore: { isGuest } } = this.injected
      , { task } = this.props
      ;

    if (isGuest) { return null; }

    return (
      <Fragment>
        <p>These documents are also available in{' '}
          <Link to={`/case/${task.case}/documents/feed`}>Documents</Link>
        </p>
      </Fragment>
    );
  }

  private renderSharedDocuments () {
    if (!this.documents.length) {
      return <HelpBlock>No documents were shared.</HelpBlock>;
    }

    return (
      <Fragment>
        {this.renderDocumentsTabLink()}

        <Spacer large />

        <DocumentTableBody
          columns={this.columns}
          documents={this.documents}
          downloadable
        />
      </Fragment>
    );
  }

  private renderNote () {
    const { task } = this.props
      , sharerNote = get(task, 'documents_shared.sharer_note')
      ;
    if (!sharerNote) { return null; }

    return (
      <Fragment>
        <hr />
        <h3>Notes</h3>
        <p>{parseAndPreserveNewlines(sharerNote)}</p>
      </Fragment>
    );
  }

  public render () {
    const { task: { created_at, recipient_email } } = this.props;

    return (
      <Row>
        <Col xs={10} sm={8} lg={6}>
          <h3>Documents Sent By</h3>
          <p>{recipient_email}</p>
          <p>{formatDateTime(created_at)}</p>

          <hr/>

          <h3>Attached Documents</h3>

          {this.renderSharedDocuments()}

          {this.renderNote()}
        </Col>
        <TaskCompletedModal
          showCompletedMessage={this.showCompletedMessage}
        />
      </Row>
    );
  }
}

export default DocumentSharingTaskDetail;
