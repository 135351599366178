import React, { Component } from 'react';
import { noop, isEqual, isEmpty, map, get } from 'lodash';
import autoBindMethods from 'class-autobind-decorator';
import { action, computed, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import cx from 'classnames';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import ReactTooltip from 'react-tooltip';
import { Button } from 'react-bootstrap';

import { AppConstants } from '../../constants';

import { SelectInputComponent } from '../../utils/TableRenderingUtils';

import {
  Link,
  Loader,
} from '../common';
import TablePaginationFooter from './TablePaginationFooter';

import ListPageStore from '../../stores/ListPageStore';
import { toggleArrayIncludes } from '../../utils/util';
import { SessionStoreClass } from '../../stores';

const CheckboxTable = checkboxHOC(ReactTable);

const { TOOLTIP_ID_TABLE } = AppConstants;

interface IProps {
  columns: any[];
  currentPageNumber?: string | null;
  entityType: string;
  emptyText?: React.ReactNode;
  handlePagination?: (pageURL: string) => void;
  handleSort: (params?: Array<{ id: string, desc: boolean }>) => void;
  onLoadMoreClick?: () => void;
  onSelectRows?: (rows: string[]) => void;
  pageSize?: number;
  pageStore: ListPageStore;
  paginated?: boolean;
  reactTableProps?: object;
  renderHeader?: () => JSX.Element;
  rowLocation?: (state: object, rowInfo: object, column: object) => string | void;
  selectable?: boolean;
  selectedRows?: string[];
  tableClassName?: any;
  title?: string;
}

interface IPropDefaults extends IProps {
  currentPageNumber: string | null;
  handlePagination: (pageURL: string) => void;
  onLoadMoreClick: () => void;
  onSelectRows: (rows: string[]) => void;
  paginated: boolean;
  rowLocation: (state: object, rowInfo: object, column: object) => string | void;
  selectedRows: string[];
}

interface IInjected extends IPropDefaults {
  SessionStore: SessionStoreClass;
}

@inject('SessionStore')
@autoBindMethods
@observer
class BaseList extends Component<IProps, {}> {
  @observable private selectAllRows = false;
  private tableRef?: any;

  public static defaultProps: Partial<IProps> = {
    currentPageNumber: null,
    handlePagination: noop,
    onLoadMoreClick: noop,
    onSelectRows: noop,
    paginated: false,
    rowLocation: noop,
    selectedRows: [],
  };

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  private get injected () {
    return this.props as IInjected;
  }

  public componentDidUpdate () {
    ReactTooltip.rebuild();
  }

  private handleSort (params: Array<{ id: string, desc: boolean }>) {
    const { handleSort, pageStore } = this.props;

    if (isEqual(params, toJS(pageStore.ordering))) { return; }

    pageStore.ordering = params;
    handleSort();
  }

  @action
  private resetSelectedRows () {
    this.selectAllRows = false;
    this.onSelectRows(this.propsWithDefaults.selectedRows);
  }

  private handleOnChange (params: { sorted: Array<{ id: string, desc: boolean }>, data: any[] }) {
    this.resetSelectedRows();
    if (params.sorted.length && params.data.length) {
      this.handleSort(params.sorted);
    }
  }

  private onResizerClick () {
    const { entityType } = this.props;
    this.injected.SessionStore.trackEvent('RESIZE_TABLE_COLUMN', { table: entityType });
  }

  @computed
  private get isEmpty () {
    return isEmpty(this.props.pageStore.tableData);
  }

  private onSelectRows (selectedRows: string[]) {
    this.propsWithDefaults.onSelectRows(selectedRows);
  }

  @action
  private toggleRowSelection (key: string, _shift: any, _row: any) {
    const selectedRows = toggleArrayIncludes(toJS(this.propsWithDefaults.selectedRows), key);
    this.onSelectRows(selectedRows);
  }

  @action
  private toggleSelectAllRows () {
    let newSelectedRows = [];

    if (!this.selectAllRows) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.tableRef && this.tableRef.getWrappedInstance();

      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      newSelectedRows = map(currentRecords, item => get(item, '_original.case_id'));
    }

    this.selectAllRows = !this.selectAllRows;
    this.onSelectRows(newSelectedRows);
  }

  private isRowSelected (key: string) {
    return this.propsWithDefaults.selectedRows.includes(key);
  }

  private getTrProps (_state: any, rowInfo: object, _column: any) {
    const caseId = get(rowInfo, 'original.case_id')
      , className = caseId && this.isRowSelected(caseId) && 'hover';

    return { className };
  }

  private getTdProps (state: object, rowInfo: object, column: { clickable?: boolean, id: string }) {
    let to = this.propsWithDefaults.rowLocation(state, rowInfo, column) || null;

    if (column.clickable === false || column.id === '_selector') {
      to = null;
    }

    return { to };
  }

  private tdComponent ({ className, children, to, ...rest }: { className: any, children: any, to: string }) {
    const classNames = cx(className, 'rt-td')
      , TdComponent = to ? Link : 'div';

    return <TdComponent className={classNames} to={to} {...rest}>{children}</TdComponent>;
  }

  private getResizerProps () {
    return { onClick: this.onResizerClick };
  }

  private setTableRef (ref: any) {
    this.tableRef = ref;
  }

  private renderHeader () {
    const { title, renderHeader } = this.props;
    if (renderHeader) { return renderHeader(); }
    if (title) {
      return (
        <header>
          <h1>{title}</h1>
        </header>
      );
    }
    return null;
  }

  private onPreviousPageClick () {
    const { pageStore: { previousPageURL }, handlePagination } = this.propsWithDefaults;
    if (!previousPageURL) { return; }
    handlePagination(previousPageURL);
  }

  private onNextPageClick () {
    const { pageStore: { nextPageURL }, handlePagination } = this.propsWithDefaults;
    if (!nextPageURL) { return; }
    handlePagination(nextPageURL);
  }

  private renderLoadMore () {
    const { pageStore, onLoadMoreClick } = this.props
      , { nextPageURL, loading } = pageStore;

    return !!nextPageURL && (
      <div className='load-wrapper'>
        <Button disabled={loading} onClick={onLoadMoreClick}>
          {loading
            ? <Loader className='button-loader' />
            : 'Load More'
          }
        </Button>
      </div>
    );
  }

  private renderPagination () {
    const { pageStore, currentPageNumber, pageSize } = this.propsWithDefaults
      , { nextPageURL, previousPageURL } = pageStore;

    return !this.isEmpty && (
      <TablePaginationFooter
        currentPageNumber={currentPageNumber}
        hasNextPage={!!nextPageURL}
        hasPreviousPage={!!previousPageURL}
        onNextPageClick={this.onNextPageClick}
        onPreviousPageClick={this.onPreviousPageClick}
        pageSize={pageSize}
        total={pageStore.total || 0}
      />
    );
  }

  public render () {
    const { columns, emptyText, pageStore, selectable, entityType, paginated, reactTableProps, tableClassName } = this.propsWithDefaults
      , { loading, ordering, tableData } = pageStore
      , listClassNames = cx('main-list', `${entityType}-list`, { paginated })
      , tableClassNames = cx('-highlight', '-resizable', '-selectable', tableClassName)
      , noResults = !!(!loading && this.isEmpty)
      , TableComponent = selectable ? CheckboxTable : ReactTable
      , noEntityText = emptyText || (<div>No {entityType} were found matching these filters</div>)
      , checkboxProps = {
        isSelected: this.isRowSelected,
        selectAll: this.selectAllRows,
        selectedRows: this.propsWithDefaults.selectedRows, // triggers mobx render
        selectType: 'checkbox',
        selectWidth: 40,
        toggleAll: this.toggleSelectAllRows,
        toggleSelection: this.toggleRowSelection,
      };

    return (
      <div className={listClassNames}>
        {this.renderHeader()}
        <div className='table-wrapper'>
          {loading && <div className='table-overlay'><Loader className='table-loader' logo /></div>}
          {noResults && <div className='table-overlay empty'>{noEntityText}</div>}
          <TableComponent
            className={tableClassNames}
            columns={columns}
            data={toJS(tableData)}
            defaultSorted={toJS(ordering)}
            getResizerProps={this.getResizerProps}
            getTdProps={this.getTdProps}
            getTrProps={this.getTrProps}
            keyField='case_id'
            manual
            minRows={0}
            noDataText={null}
            onFetchData={this.handleOnChange}
            ref={this.setTableRef}
            SelectAllInputComponent={SelectInputComponent}
            SelectInputComponent={SelectInputComponent}
            showPagination={false}
            TdComponent={this.tdComponent}
            {...checkboxProps}
            {...reactTableProps}
          />
        </div>
        {paginated
          ? this.renderPagination()
          : this.renderLoadMore()
        }
        <ReactTooltip
          html={true}
          id={TOOLTIP_ID_TABLE}
          type='info'
          place='bottom'
        />
      </div>
    );
  }
}

export default BaseList;
