import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import { MenuItem } from 'react-bootstrap-typeahead';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';

import FormattingUtils from '../../../../utils/FormattingUtils';
import { OptionsStoreClass } from '../../../../stores';

import {
  getBaseSearchResultDisplayItem,
  IDisplaySearchResultItemConfiguration,
  ISearchResultItemProps,
} from './SearchResultUtils';

const { formatDate, getNameOrDefault, getOrDefault, pluralize } = FormattingUtils;

interface IProps extends ISearchResultItemProps {
  OptionsStore: OptionsStoreClass;
}

@inject('OptionsStore')
@autoBindMethods
@observer
class PlaintiffSearchResultItem extends Component<IProps> {

  public render () {
    const { item, OptionsStore } = this.props
      , displayItemConfiguration: IDisplaySearchResultItemConfiguration = {
        ...this.props,
        entity: 'plaintiff',
        path: '/case/',
      }
      , caseType = getOrDefault(OptionsStore.getCaseTypeOptionById(item.type).name)
      , dateOfLoss = formatDate(item.date_of_loss)
      , displayItem = getBaseSearchResultDisplayItem(displayItemConfiguration)
      , lawfirm = getNameOrDefault(item.lawfirm)
      , plaintiffName = getNameOrDefault(item.plaintiff)
      , stateName = OptionsStore.formatStateName(item)
      , caseTypeDisplay = caseType === 'Motor Vehicle Accident' ? 'MVA' : caseType;

    return (
      <MenuItem key={item.id} option={displayItem} position={item.index}>
        <div className='search-result-item' data-for={item.id} data-tip>
          <p>
            <span className='pull-right'>Case ID: {displayItem.vanityId}</span>
            <strong className='fs-mask'>{plaintiffName}</strong>
          </p>
          <p>
            <span className={cx('pull-right', 'fs-mask')}>Date of Loss: {dateOfLoss}</span>
            <span className='sub-info'>{caseTypeDisplay}, {lawfirm} {stateName && <span>({stateName})</span>}</span>
          </p>
        </div>
        {!!item.medical_facilities.length && (
          <ReactTooltip id={item.id} type='info' effect='solid' place='left' className='tooltip-dropdown-menu'>
            <div>
              <strong>Medical {pluralize('Facility', item.medical_facilities.length, false)}:</strong>
              <p>{item.medical_facilities.join(', ')}</p>
            </div>
          </ReactTooltip>
        )}
      </MenuItem>
    );
  }
}

export default PlaintiffSearchResultItem;
