import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Helmet from 'react-helmet';
import autoBindMethods from 'class-autobind-decorator';
import { browserHistory } from 'react-router';

import { AppConstants } from '../../constants';
import { Page, RadioButtonGroup } from '../common';
import { Form } from '../common-formsy';

import CaseSelectionModule from './CaseSelectionModule';
import UpdateRequestModule from './UpdateRequestModule';
import UpdateResponseModule from './UpdateResponseModule';
import UpdateSidebarCaseDetails from './UpdateSidebarCaseDetails';
import { CaseUpdateStoreClass, SessionStoreClass } from '../../stores';

const { CASE_UPDATE_RADIO_OPTIONS, CASE_UPDATE_TYPES } = AppConstants;

interface IProps {
  params: {
    updateAction: 'request' | 'add';
  };
}

interface IInjected extends IProps {
  CaseUpdateStore: CaseUpdateStoreClass;
  SessionStore: SessionStoreClass;
}

@inject('CaseUpdateStore', 'SessionStore')
@autoBindMethods
@observer
class CaseUpdatePage extends Component<IProps> {
  // istanbul ignore next
  public componentWillUnmount () {
    const { CaseUpdateStore } = this.injected;
    CaseUpdateStore.clear();
  }

  private get injected () {
    return this.props as IInjected;
  }

  private handleRadioClick (option: string) {
    browserHistory.push(`/case-update/${option}`);
  }

  private renderAction () {
    const { params: { updateAction } } = this.props;

    switch (updateAction) {
      case CASE_UPDATE_TYPES.request.key:
        return <UpdateRequestModule />;
      case CASE_UPDATE_TYPES.add.key:
        return <UpdateResponseModule />;
      default:
        return null;
    }
  }

  public renderRadioButtonGroup () {
    const { params: { updateAction } } = this.props
      , { SessionStore } = this.injected
      ;

    if (!SessionStore.isMightyFreeUser) {
      return null;
    }

    return (
      <Fragment>
        <RadioButtonGroup
          checker={updateAction}
          descriptionLookupString='label'
          fieldName='update'
          handleRadioClick={this.handleRadioClick}
          items={CASE_UPDATE_RADIO_OPTIONS}
          lookupString='key'
          title={'What would you like to do?'}
        />
        <hr />
      </Fragment>

    );
  }
  public render () {
    const { params: { updateAction } } = this.props
      , { CaseUpdateStore } = this.injected
      , updateType = CASE_UPDATE_TYPES[updateAction]
      , title = updateType.label
      ;

    return (
      <Page name='task-detail' type='detail' hasSidebar>
        <Helmet title={title} />
        <Page.Sidebar className='sidebar-type-task'>
          {CaseUpdateStore.sidebarData && <UpdateSidebarCaseDetails data={CaseUpdateStore.sidebarData}/>}
        </Page.Sidebar>
        <Page.Content>
          <header className='condensed'>
            <h1>{title}</h1>
          </header>
          <div className='main'>
            <div className='col-main'>
              <div className='form-page'>
                {this.renderRadioButtonGroup()}
                <Form className='modal-form-style'>
                  <CaseSelectionModule updateAction={updateAction} />
                </Form>

                {this.renderAction()}
              </div>
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default CaseUpdatePage;
