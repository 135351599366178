import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import LeadSourceModal from './LeadSourceModal';

import { CaseLogPaymentModal } from './modal-log-payment';
import { ICase } from '../../interfaces';
import { UiStoreClass, SessionStoreClass } from '../../stores';
import CaseDetailHeaderAssignments from './CaseDetailHeaderAssignments';
import CaseHeaderPayments from './CaseHeaderPayments';
import CaseHeaderTitle from './CaseHeaderTitle';

export interface IHeaderProps {
  _case: ICase;
}

interface IInjected extends IHeaderProps {
  SessionStore: SessionStoreClass;
  UiStore: UiStoreClass;
}

@inject('SessionStore', 'UiStore')
@autoBindMethods
@observer
class CaseDetailHeader extends Component <IHeaderProps> {
  public get injected () {
    return this.props as IInjected;
  }

  private renderHeaderActions () {
    const { _case } = this.props
      , { UiStore, SessionStore } = this.injected
      ;

    if (SessionStore.isMightyFreeUser) { return null; }

    return (
      <div className='col-right'>
        <CaseDetailHeaderAssignments {...this.props} />
        <CaseHeaderPayments _case={_case} />

        {UiStore.modals.LeadSource.showing() && <LeadSourceModal _case={_case} />}
        {UiStore.modals.CaseLogPayment.showing() && <CaseLogPaymentModal key='CaseLogPaymentModal' _case={_case} />}
      </div>
    );
  }

  public render () {
    const { _case } = this.props;

    return (
      <header>
        <CaseHeaderTitle _case={_case} />
        <div className='buttons'>
          {this.renderHeaderActions()}
        </div>
      </header>
    );
  }
}

export default CaseDetailHeader;
