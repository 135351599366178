import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { uniqBy, get } from 'lodash';

import { IActivity, ITransport } from '../interfaces';

interface IActivityApiParams {
  id?: string;
  page_size?: number;
  type?: string | null;
}

const ACTIVITIES_PAGE_SIZE = 100;

const ACTIVITIES_SORT = (a: IActivity, b: IActivity) => {
  // Actual sort order
  if (a.recorded_at < b.recorded_at) { return -1; }
  if (a.recorded_at > b.recorded_at) { return 1; }

  // Determinism
  if (a.id < b.id) { return -1; }
  if (a.id > b.id) { return 1; }

  // istanbul ignore next
  return 0;
};

interface IApiCaseActivities {
  count: number;
  next?: string;
  previous?: string;
  results: IActivity[];
}

@autoBindMethods
class ActivityStore {
  private params: IActivityApiParams;
  private rawActivities: IActivity[] = [];
  private response?: IApiCaseActivities;
  private transport: ITransport;

  @observable public loading = true;
  @observable public loadingMore = false;
  @observable public loadingNew = false;

  constructor (transport: ITransport, params: IActivityApiParams) {
    this.transport = transport;
    this.params = { page_size: ACTIVITIES_PAGE_SIZE, ...params };
  }

  get activitiesFilter () {
    return this.params.type || null;
  }

  get activities () {
    return uniqBy(this.rawActivities, 'id').sort(ACTIVITIES_SORT);
  }

  get hasMore () {
    return !!get(this.response, 'next');
  }

  get firstOrNull () {
    return this.activities.length ? this.activities[0] : null;
  }

  public updateParams (params = {}) {
    this.params = { ...this.params, ...params };
  }

  private async load () {
    this.response = undefined;
    const response = await this.transport.get('/case-activities/', { params: this.params });
    this.response = response as IApiCaseActivities;
    this.rawActivities = get(this.response, 'results', []);
  }

  public async loadInitial (paramsChanges: IActivityApiParams) {
    this.loading = true;
    this.updateParams(paramsChanges);
    await this.load();
    this.loading = false;
  }

  public async loadNew (type: string, request: Promise<any>) {
    this.loadingNew = true;
    if (![null, type].includes(this.activitiesFilter)) {
      this.loading = true;
      this.updateParams({ type: null });
    }
    // Await post request
    await request;
    // Request activities
    await this.load();
    this.loadingNew = false;
    this.loading = false;
  }

  public async loadMore () {
    // istanbul ignore next
    if (!this.response || !this.response.next) {
      return;
    }

    this.loadingMore = true;
    const response = await this.transport.get(this.response.next);
    this.response = response;
    this.rawActivities = this.rawActivities.concat(response.results);
    this.loadingMore = false;
  }
}

export default ActivityStore;
