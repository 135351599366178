import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Button, Col, Grid, Row } from 'react-bootstrap';

import { RadioButtonGroup } from '../common';
import MultipleCaseForm from './MultipleCaseForm';
import SingleCaseForm from './SingleCaseForm';

const ADD_CASE_TYPE_OPTIONS: Array<{ [key: string]: string }> = [
  { name: 'A single case', value: 'single' },
  { name: 'Multiple cases', value: 'multiple' },
];

@autoBindMethods
@observer
class NewCaseForm extends Component <{}> {
  @observable private caseFormType: string = '';

  private onCaseFormTypeSelect (caseFormType: string) {
    this.caseFormType = caseFormType;
  }

  private renderCaseForm () {
    switch (this.caseFormType) {
      case 'single':
        return <SingleCaseForm />;
      case 'multiple':
        return <MultipleCaseForm />;
      default:
        return (
          <div>
            <Button disabled bsStyle='primary'>Add Case</Button>
          </div>
        );
    }
  }

  public render () {
    return (
      <Grid>
        <Row>
          <Col>
            <RadioButtonGroup
              checker={this.caseFormType}
              fieldName='new-case-count'
              handleRadioClick={this.onCaseFormTypeSelect}
              items={ADD_CASE_TYPE_OPTIONS}
              title={'I am adding'}
            />
            <hr />
            {this.renderCaseForm()}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default NewCaseForm;
