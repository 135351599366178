import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import ReactTable from 'react-table';

import { TableRenderingUtils } from '../../../utils';
import { ActionButton, Icon, Loader } from '../../common';
import { IDefendantInsurer } from '../../../interfaces';

interface IProps {
    defendantInsurers: IDefendantInsurer[] | null;
    onDelete: (id: string) => void;
    onEdit: (data: IDefendantInsurer) => void;
}

const DefendantInsurersTable = observer((props: IProps) => {
  const { defendantInsurers, onDelete, onEdit } = props;

  const renderInsurerName = (row: any) => {
    return row.original.name;
  };

  const renderEditDeleteButtons = (row: any) => {
    return (
      <div>
        <ActionButton
          className='btn-edit-type'
          data={row.original}
          onClick={onEdit}
        >
          <Icon type='pencil' />
        </ActionButton>
        <ActionButton
          className='btn-delete'
          data={row.original.id}
          onClick={onDelete}
        >
          <Icon type='trash' />
        </ActionButton>
      </div>
    );
  };

  const { renderMoney } = TableRenderingUtils
    , COLUMNS = [
      {
        accessor: 'name',
        Cell: renderInsurerName,
        Header: 'Name',
        sortable: false,
      },
      {
        accessor: 'liability_limit_per_accident',
        Cell: renderMoney,
        Header: 'Liability limit per accident',
        sortable: false,
      },
      {
        accessor: 'liability_limit_per_person',
        Cell: renderMoney,
        Header: 'Liability limit per person',
        sortable: false,
      },
      {
        accessor: 'id',
        Cell: renderEditDeleteButtons,
        className: 'col-buttons',
        sortable: false,
      },
    ];

  if (defendantInsurers === null) {
    return (
      <div>
        <Loader className='bloc-loader' />
      </div>
    );
  }

  return (
    <div>
      <ReactTable
        className='-list -no-scroll'
        columns={COLUMNS}
        data={toJS(defendantInsurers)}
        minRows={0}
        noDataText='There are no insurance providers for any defendant on this case.'
        resizable={false}
        sortable={false}
        showPagination={false}
      />
    </div>
  );
});

export default DefendantInsurersTable;
