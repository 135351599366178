import React, { Component, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import { get, omit } from 'lodash';
import { toJS } from 'mobx';

import FunderStoreClass from '../../stores/FunderStoreClass';
import UiStoreClass from '../../stores/UiStoreClass';
import { AppConstants } from '../../constants';
import { FacetOptions } from '../common-facet';
import { Icon, ListSearchFilter } from '../common';
import FormattingUtils from '../../utils/FormattingUtils';
import ClientsClass from '../../clients/ClientsClass';
import { IFacetOption } from '../../interfaces';

const { BRAND_NAME, LAW_FIRM_REGISTRY_ELIGIBILITY_STATUSES } = AppConstants;
const { varToLabel } = FormattingUtils;

interface IProps {
  changeFilters: (onQueryReset?: boolean) => Promise<void>;
  isMedical: boolean;
}

interface IInjected extends IProps {
  Clients: ClientsClass;
  FunderStore: FunderStoreClass;
  UiStore: UiStoreClass;
}

const REGISTRY_ELIGIBILITY_TOOLTIP_MAP = {
  [LAW_FIRM_REGISTRY_ELIGIBILITY_STATUSES.MISSING_DATA]: 'Missing law firm website or address',
  [LAW_FIRM_REGISTRY_ELIGIBILITY_STATUSES.PENDING]: `Pending verification by ${BRAND_NAME}'s internal team`,
  [LAW_FIRM_REGISTRY_ELIGIBILITY_STATUSES.NOT_ELIGIBLE]: 'Law firms associated with only closed cases',
};

const REGISTRY_ELIGIBILITY_OPTIONS = Object.keys(LAW_FIRM_REGISTRY_ELIGIBILITY_STATUSES).map(
  (status: string) => ({ name: varToLabel(status), value: status })
);

@inject(
  'Clients',
  'FunderStore',
  'UiStore',
)
@autoBindMethods
@observer
class OrganizationListSidebar extends Component<IProps> {
  public componentDidMount () {
    this.fetchCounts();
  }

  private get injected () {
    return this.props as IInjected;
  }

  private async fetchCounts () {
    const { Clients } = this.injected
      , counts = await Clients.organizations.counts({ params: toJS(this.pageStore.filters) });

    this.pageStore.sidebarCounts = counts;
  }

  private get pageStore () {
    return this.injected.UiStore.organizationListPage;
  }

  private onOptionChange (fieldName: string, value: string) {
    this.pageStore.filters = { ...this.pageStore.filters, [fieldName]: value };
    this.changeFilters();
  }

  private onReset (fieldName: string) {
    this.pageStore.filters = omit(this.pageStore.filters, fieldName);
    this.changeFilters();
  }

  private changeFilters () {
    const { changeFilters } = this.props;

    changeFilters();
    this.fetchCounts();
  }

  private renderEligibilityStatusOption (optionName: string) {
    const option = REGISTRY_ELIGIBILITY_OPTIONS.find((o: IFacetOption) => o.name === optionName)
      , optionValue = get(option, 'value', '')
      , tooltipText = get(REGISTRY_ELIGIBILITY_TOOLTIP_MAP, optionValue)
      , tooltipId = `registry-eligibility-status-${optionValue.toLowerCase()}`
      ;

    return (
      <div>
        <ReactTooltip
          className='sidebar-tooltip'
          effect='solid'
          id={tooltipId}
          place='right'
          type='info'
        >
          <span>{tooltipText}</span>
        </ReactTooltip>

        <span>
          {optionName}
        </span>

        {tooltipText &&
          <span data-tip data-for={tooltipId}>
            {' '}<Icon type='question-circle'/>
          </span>
        }
      </div>
    );
  }

  private renderFacets () {
    const { isMedical } = this.props
      , { FunderStore } = this.injected;

    if (isMedical || !FunderStore.isRegistryEnabled) {
      return null;
    }

    return (
      <Fragment>
        <hr />
        <FacetOptions
          collapsible={false}
          fieldName='registry_eligibility'
          filters={toJS(this.pageStore.filters)}
          label='Law Firm Eligibility'
          onReset={this.onReset}
          onOptionChange={this.onOptionChange}
          options={REGISTRY_ELIGIBILITY_OPTIONS}
          renderer={this.renderEligibilityStatusOption}
          sidebarCounts={this.pageStore.sidebarCounts || {} as any}
        />
      </Fragment>
    );
  }

  public render () {
    const { isMedical } = this.props;

    return (
      <div>
        <div className='wrapper'>
          <h3>{isMedical ? 'Facility' : 'Law Firm'} Search</h3>
          <ListSearchFilter pageStore={this.pageStore} onInputChange={this.changeFilters} />
        </div>
        {this.renderFacets()}
      </div>
    );
  }
}

export default OrganizationListSidebar;
