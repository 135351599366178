import { extendObservable, toJS } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import FlatStore from '../stores/FlatStore';

@autoBindMethods
class BaseModel {
  public flatStore: FlatStore;

  constructor (flatStore: FlatStore) {
    this.flatStore = flatStore;
  }

  public extend (data: object) {
    extendObservable(this, toJS(data));
  }
}

export default BaseModel;
