import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Button, Modal } from 'react-bootstrap';

interface IProps {
  action: string;
  onClose: () => void;
  onConfirm: () => void;
  target?: React.ReactNode;
}

@autoBindMethods
@observer
class ConfirmationModal extends Component<IProps, {}> {
  public render () {
    const { onClose, onConfirm, action, target } = this.props;
    return (
      <Modal className='modal-confirmation' show onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm {action}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{target}</Modal.Body>

        <Modal.Footer>
          <Button onClick={onClose}>Cancel</Button>
          <Button bsStyle='primary' onClick={onConfirm}>
            {action}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmationModal;
