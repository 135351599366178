import React, { Component } from 'react';
import { toJS, computed } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { get } from 'lodash';
import { Button, InputGroup, Row } from 'react-bootstrap';

import Icon from '../../components/common/Icon';
import { fillInFieldConfig, IFieldConfig, IFieldConfigPartial } from '../../lib/mighty-fields';

interface IProps {
  addNew: (fieldConfig: IFieldConfig, search: string) => void;
  addNewUndo: (fieldConfig: IFieldConfig) => void;
  addNewUndoFieldConfig?: IFieldConfig;
  addNewUndoShow?: boolean;
  fieldConfig: IFieldConfigPartial;
  model: object;
  onChange: (value: any) => void;
}

@autoBindMethods
@observer
class FormField extends Component<IProps, {}> {
  @computed
  get fieldConfig () {
    return fillInFieldConfig(this.props.fieldConfig);
  }

  private addNew (search: string) {
    this.props.addNew(this.fieldConfig, search);
  }

  private addNewUndo () {
    if (this.props.addNewUndoFieldConfig) {
      this.props.addNewUndo(this.props.addNewUndoFieldConfig);
    }
  }

  get value () {
    const { model } = this.props;
    return get(toJS(model), this.fieldConfig.field);
  }

  public render () {
    const { addNewUndoShow, fieldConfig, onChange, model } = this.props
      , EditComponent = fieldConfig.editComponent
      , addonBefore = fieldConfig.icon
          ? (<InputGroup.Addon>{fieldConfig.icon}</InputGroup.Addon>)
          : null;

    return (
      <Row
        key={fieldConfig.field}
        className={cx({ 'field-group-icon': !!fieldConfig.icon })}
      >
        <EditComponent
          {...fieldConfig}
          {...fieldConfig.editProps}
          addNew={this.addNew}
          addonBefore={addonBefore}
          model={model}
          name={fieldConfig.key}
          onChange={onChange}
          value={this.value}
        />

        {addNewUndoShow && (
          <Button
            bsStyle='link'
            className='btn-right add-new-undo'
            onClick={this.addNewUndo}
          >
            <Icon type='angle-double-left' /> Back to search
          </Button>
        )}
      </Row>
    );
  }
}
export default FormField;
