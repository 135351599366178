import React, { Component } from 'react';
import { times } from 'lodash';
import cx from 'classnames';

const DOTS_NUMBER = 3;

interface IProps {
  logo?: boolean;
  className?: any;
}

class Loader extends Component<IProps> {
  public render () {
    const { className, logo } = this.props
      , classNames = cx('loader', className);

    return (
      <div className={classNames}>
        {!!logo && <div className='logo' />}
        <div className='anim'>
          {times(DOTS_NUMBER, (i) => (<div key={i} className='dot' />))}
        </div>
      </div>
    );
  }
}

export default Loader;
