import React, { Component } from 'react';

interface IProps {
  actionText?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  title?: string | React.ReactNode;
}

class EmptyListHelpText extends Component <IProps> {
  public render () {
    const { actionText, subtitle, title } = this.props;

    return (
      <div>
        <div className='illustration'>
          <div />
          <div />
        </div>
        <div className='title'>{title}</div>
        <div>{subtitle}</div>
        <div>{actionText}</div>
      </div>
    );
  }
}

export default EmptyListHelpText;
