import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { browserHistory } from 'react-router';
import autoBindMethods from 'class-autobind-decorator';

import ActionContactPage from './ActionContactPage';
import ClientsClass from '../../clients/ClientsClass';
import { AppConstants } from '../../constants';
import { FormattingUtils } from '../../utils';
import { Loader } from '../common';
import { ContactStoreClass } from '../../stores';

const { getNameOrDefault } = FormattingUtils;
const {
  CONTACT_TYPES,
  TRACKING_CONTACT_TYPES_BY_MODEL,
} = AppConstants;

interface IProps {
  params: {
    id: string;
  };
}

interface IContact {
  type: string;
}

interface IInjected extends IProps {
  Clients: ClientsClass;
  ContactStore: ContactStoreClass;
}

@inject('Clients', 'ContactStore')
@autoBindMethods
@observer
class EditContactPage extends Component<IProps> {
  @observable private contact: IContact | undefined;

  public componentDidMount () {
    this.fetchContact(this.props.params.id);
  }

  private get injected () {
    return this.props as IInjected;
  }

  private get contactType () {
    // istanbul ignore next
    if (!this.contact) {
      return '';
    }

    return TRACKING_CONTACT_TYPES_BY_MODEL[this.contact.type];
  }

  private async fetchContact (contactId: string) {
    const { Clients } = this.injected;

    const contact = await Clients.people.retrieve(contactId);
    this.contact = {
      ...contact,
      fullName: getNameOrDefault(contact),
    };
  }

  public async onSubmit (contactData: any) {
    const { ContactStore } = this.injected;

    await ContactStore.update({
      _case: null,
      contactData,
      contactType: this.contactType,
    });
  }

  private get readableContactType () {
    return CONTACT_TYPES[this.contactType].display;
  }

  private get headerText () {
    return `Edit ${getNameOrDefault(this.contact)} - ${this.readableContactType}`;
  }

  public render () {
    if (!this.contact) {
      return <Loader className='page-loader' logo />;
    }

    return (
      <ActionContactPage
        action='edit'
        browserRedirect={browserHistory.goBack}
        contactType={this.contactType}
        headerText={this.headerText}
        initialData={this.contact}
        onSubmit={this.onSubmit}
        showOptions
      />
    );
  }
}

export default EditContactPage;
