import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observe } from 'mobx';
import { IObjectChange } from 'mobx/lib/types/observableobject';
import { observer } from 'mobx-react';

import { dateToday } from '@mighty-justice/utils';

import { DateInput } from '../common-formsy';
import { IFormInput } from '../../interfaces';

interface IProps extends IFormInput {
  model: object;
}

@autoBindMethods
@observer
class InputDateClosed extends Component<IProps> {
  constructor (props: IProps) {
    super(props);
    observe(props.model, this.onModelChange);
  }

  private onModelChange (change: IObjectChange) {
    const { name, newValue, type } = change
      , { onChange } = this.props;

    if (name !== 'is_open' || type !== 'update') { return; }

    const newDate: string = newValue === 'false' ? dateToday() : '';

    if (onChange) { onChange(newDate); }
  }

  public render () {
    return <DateInput {...this.props} manual />;
  }
}

export default InputDateClosed;
