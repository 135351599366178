import React from 'react';

import {
  NavDropdown as BootstrapNavDropdown,
  NavDropdownProps as BootstrapNavDropdownProps,
} from 'react-bootstrap';

const NavDropdown: React.SFC<BootstrapNavDropdownProps> = props => <BootstrapNavDropdown {...props} />;

export default NavDropdown;
