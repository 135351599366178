import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import ObjectSelectDisplay from './ObjectSelectDisplay';

import {
  IFieldConfigOptionSelect,
  Value,
} from '../../interfaces';

interface IProps extends IFieldConfigOptionSelect {
  value: Value;
}

interface IInjected extends IProps {
  OptionsStore: { [key: string]: Array<{ [key: string]: Value }> };
}

@inject('OptionsStore')
@autoBindMethods
@observer
class OptionSelectDisplay extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public render () {
    const { optionKey, optionType } = this.props
      , { OptionsStore } = this.injected
      , objects = OptionsStore[optionType]
      ;

    return (
      <ObjectSelectDisplay
        {...this.props}
        objects={objects}
        objectKey={optionKey}
      />
    );
  }
}

export function formatOptionSelect (value: Value, fieldConfig: IFieldConfigOptionSelect) {
  return <OptionSelectDisplay value={value} {...fieldConfig} />;
}

export default OptionSelectDisplay;
