import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { kebabCase, identity } from 'lodash';

import { IFacetOption } from '../../interfaces';

import FacetListItem from './FacetListItem';

interface IProps {
  disabled?: boolean;
  facetOptions: IFacetOption[];
  fieldName: string;
  onCheckboxChange: (value: string, checked: boolean, fieldName: string) => void;
  renderer?: (name: string) => string | React.ReactNode;
  type?: 'checkbox' | 'radio';
}

interface IPropDefaults extends IProps {
  renderer: (name: string) => string;
}

@observer
@autoBindMethods
class FacetList extends Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    renderer: identity,
  };

  private onCheckboxChange (value: string, checked: boolean) {
    const { onCheckboxChange, fieldName } = this.props;
    onCheckboxChange(value, checked, fieldName);
  }

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  public render () {
    const { disabled, facetOptions, fieldName, renderer, type } = this.propsWithDefaults
      , className = type === 'radio' ? 'radio-buttons condensed' : 'list-checkbox condensed-checkbox';

    return (
      <ul className={className}>
        {facetOptions.map(facetOption => (
          <FacetListItem
            checked={!!facetOption.checked}
            count={facetOption.count}
            countIsLoading={facetOption.countIsLoading}
            disabled={disabled}
            key={facetOption.value}
            label={renderer(facetOption.name)}
            onChange={this.onCheckboxChange}
            type={type}
            uniqueKey={kebabCase(`${fieldName}-${facetOption.value}`)}
            value={facetOption.value}
          />
        ))}
      </ul>
    );
  }
}

export default FacetList;
