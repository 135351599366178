import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import {observable, action } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { browserHistory } from 'react-router';
import { get } from 'lodash';

import { AppConstants } from '../../constants';
import UpdateRequestOptions from '../page-tasks/UpdateRequestOptions';
import UpdateRequestButton from '../page-tasks/UpdateRequestButton';
import { RadioButtonGroup } from '../common';
import { CaseStoreClass, CaseUpdateStoreClass } from '../../stores';
import { toast } from '../../utils';

const { UPDATE_REQUEST_RADIO_OPTIONS } = AppConstants;

interface IInjected {
  CaseStore: CaseStoreClass;
  CaseUpdateStore: CaseUpdateStoreClass;
}

@inject('CaseUpdateStore', 'CaseStore')
@autoBindMethods
@observer
class UpdateResponseModule extends Component <{}> {
  @observable private valueKey: string = '';

  private get injected () {
    return this.props as IInjected;
  }

  @action
  private handleRadioClick (value: string) { this.valueKey = value; }

  @action
  private async onSubmit (data: any) {
    const { CaseStore, CaseUpdateStore } = this.injected
      , caseId = get(CaseUpdateStore, '_case.id', '')
      , caseRoute = `/case/${caseId}`
      ;

    await CaseStore.updateCaseTrackingStatus(caseId, data);
    toast.success('Success! Case update has been added');
    CaseUpdateStore.clear();
    browserHistory.push(caseRoute);
  }

  public render () {
    const { CaseUpdateStore } = this.injected;

    if (!CaseUpdateStore._case) { return <UpdateRequestButton />; }

    return (
      <Fragment>
        <RadioButtonGroup
          checker={this.valueKey || ''}
          descriptionLookupString='label'
          fieldName='update'
          handleRadioClick={this.handleRadioClick}
          items={UPDATE_REQUEST_RADIO_OPTIONS}
          lookupString='key'
          title={'What\'s the latest update?'}
        />
        <hr />
        <UpdateRequestOptions valueKey={this.valueKey} onSubmit={this.onSubmit} />
      </Fragment>
    );
  }
}

export default UpdateResponseModule;
