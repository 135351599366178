import React, { Component, Fragment } from 'react';
import { toJS, observable } from 'mobx';
import { observer } from 'mobx-react';
import autobindMethods from 'class-autobind-decorator';
import uuid from 'uuid';
import { dropRight, findIndex, sumBy } from 'lodash';

import { Alert, Button, Well } from 'react-bootstrap';
import DocumentTable from './DocumentTable';
import { IDocument } from '../../interfaces';
import { SmartBool, toast } from '../../utils';
import { bytesToMB } from '../../utils/util';

import { AppConstants } from '../../constants';
import {ActionButton} from './Button';
import Icon from './Icon';

const { MAX_ATTACHMENTS_SIZE_MB } = AppConstants;

interface IColumn {
  accessor: string;
  Cell: any;
  className?: string;
  Header: any;
  minWidth?: number;
}

interface IProps {
  accept?: string;
  columns: IColumn[];
  isDeletable?: boolean;
  isSubmitting?: boolean;
  maxFileSizeMB?: number;
  onSubmit: (files: IDocument[]) => any;
  placeholderText?: string;
  uploadButtonText?: string;
}

interface IPropDefaults extends IProps {
  accept: string;
  isDeletable: boolean;
  isSubmitting: boolean;
  maxFileSizeMB: number;
  placeholderText: string;
  uploadButtonText: string;
}

@autobindMethods
@observer
class FileUploader extends Component <IProps> {
  @observable private isMaximumAttachmentSizeExceeded = new SmartBool(false);
  @observable private uploadedDocuments: IDocument[] = [];

  public static defaultProps: Partial<IProps> = {
    isDeletable: true,
    isSubmitting: false,
    maxFileSizeMB: MAX_ATTACHMENTS_SIZE_MB,
    placeholderText: 'Drag files here to upload',
    uploadButtonText: 'Upload',
  };

  private get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  private handleFileUpload (files: File[] = []) {
    files.forEach(file => {
      const document: IDocument = {
        file,
        id: uuid.v4(),
        label: file.name,
      };
      this.uploadedDocuments.push(document);
      this.validateAttachmentsSize();

      if (this.isMaximumAttachmentSizeExceeded.isTrue) {
        this.uploadedDocuments = dropRight(this.uploadedDocuments);
      }
    });
  }

  private validateAttachmentsSize () {
    const rawTotalFileSize = sumBy(this.uploadedDocuments, 'file.size')
      , totalSizeInMB: number = bytesToMB(rawTotalFileSize)
      , isExceeded = totalSizeInMB > this.propsWithDefaults.maxFileSizeMB
      ;

    this.isMaximumAttachmentSizeExceeded.set(isExceeded);
  }

  private onFileDelete (file: any) {
    const searchParams: any = {id: file.value};
    const documentIndex: number = findIndex(this.uploadedDocuments, searchParams);
    if (documentIndex !== -1) {
      this.uploadedDocuments.splice(documentIndex, 1);
      this.validateAttachmentsSize();
    }
  }

  private get columns () {
    const { columns, isDeletable } = this.propsWithDefaults;
    if (!isDeletable) { return columns; }

    const deleteColumn = {
      accessor: 'id',
      Cell: (row: any) => (
        <div>
          <ActionButton bsSize='small' data={row} onClick={this.onFileDelete}>
            <Icon type='trash' />
          </ActionButton>
        </div>
      ),
      className: 'col-buttons',
      Header: 'actions',
      minWidth: 30,
    };

    return [...columns, deleteColumn];
  }

  private onDropRejected () {
    toast.error('File type not supported. Please upload a .csv');
  }

  private handleSubmit () {
    this.props.onSubmit(toJS(this.uploadedDocuments));
  }

  public render () {
    const { accept, isSubmitting, uploadButtonText, maxFileSizeMB, placeholderText } = this.propsWithDefaults;

    return (
      <Fragment>
        <Well className='condensed'>
          <DocumentTable
            accept={accept}
            columns={this.columns}
            documents={toJS(this.uploadedDocuments)}
            dropzoneText={placeholderText}
            onDrop={this.handleFileUpload}
            onDropRejected={this.onDropRejected}
          />
        </Well>

        {this.props.children}

        {this.isMaximumAttachmentSizeExceeded.isTrue &&
          <Alert bsStyle='danger' className='lowercase'>
            {`Attachments exceed the size limit of ${maxFileSizeMB}MB.`}
          </Alert>
        }

        <hr />

        <div>
          <Button onClick={this.handleSubmit} disabled={!this.uploadedDocuments.length || isSubmitting} bsStyle='primary' type='submit'>
            {isSubmitting ? 'Uploading' : uploadButtonText}
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default FileUploader;
