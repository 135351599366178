/* tslint:disable object-literal-sort-keys */
import React from 'react';
import { get, isArray } from 'lodash';

import Checkbox from '../../components/common-formsy/Checkbox';
import DateInput from '../../components/common-formsy/DateInput';
import Dropdown from '../../components/common-formsy/Dropdown';
import File from '../../components/common-formsy/File';
import HtmlInput from '../../components/common-formsy/HtmlInput';
import Input from '../../components/common-formsy/Input';
import MoneyPercent from '../../components/common-formsy/MoneyPercent';
import NestedFormGroup from './NestedFormGroup';
import ObjectSearchCreate from '../../components/common-formsy/ObjectSearchCreate';
import ObjectSelect from '../../components/common-formsy/ObjectSelect';
import OptionSelect from '../../components/common-formsy/OptionSelect';
import Rating from '../../components/common-formsy/Rating';
import Percentage from '../../components/common-formsy/Percentage';
import Textarea from '../../components/common-formsy/Textarea';
import { AppConstants } from '../../constants';
import { ExternalLink } from '../../components/common';
import { formatObjectSelect } from './ObjectSelectDisplay';
import { formatOptionSelect } from './OptionSelectDisplay';

import FormattingUtils from '../../utils/FormattingUtils';
import {
  IFieldConfig,
  IFieldConfigObjectSearchCreate,
  IFieldConfigPartial,
  IFieldSet,
  IFieldSetPartial,
  IFieldSetSimple,
  IFieldSetSimplePartial,
} from './interfaces';

const { BOOLEAN_FILTER_OPTIONS, EMPTY_FIELD } = AppConstants;

const {
  formatAddress,
  formatCommaSeparatedNumber,
  formatDate,
  formatMoney,
  formatParagraphs,
  formatPercentage,
  formatPhoneNumber,
  formatSocialSecurityNumber,
  formatUnderwriterAnalysisSummary,
  getNameOrDefault,
  getOrDefault,
  mapBooleanToText,
  varToLabel,
} = FormattingUtils;

export function stripFieldConfig (func: (...args: any[]) => any) {
  return (value: any) => func(value);
}

function renderDefaultOrLink (url?: string) {
  return !url ? EMPTY_FIELD : <ExternalLink href={url}>{url}</ExternalLink>;
}

export const TYPES: { [key: string]: Partial<IFieldConfig> } = {
  string: {
    editComponent: Input,
    editProps: { type: 'text' },
    render: getOrDefault,
  },
  hidden: {
    editComponent: Input,
    editProps: { type: 'hidden' },
    render: getOrDefault,
  },
  password: {
    editComponent: Input,
    editProps: { type: 'password' },
  },
  text: {
    editComponent: Textarea,
    render: formatParagraphs,
  },
  percentage: {
    editComponent: Percentage,
    editProps: {
      validationErrors: { isPercentage: 'Ownership must be a percentage.' },
      validations: { isPercentage: true },
    },
    render: stripFieldConfig(formatPercentage),
  },
  twoDecimalPercentage: {
    editComponent: Percentage,
    editProps: {
      validationErrors: {
        isPercentage: 'This field must be a percentage.',
        isWithinDecimalPlaces: 'Must be within 2 decimal points',
      },
      validations: {
        isPercentage: true,
        isWithinDecimalPlaces: 4,
      },
    },
    render: stripFieldConfig(formatPercentage),
  },
  textDynamic: {
    editComponent: Textarea,
    editProps: {
      dynamicHeight: true,
    },
    render: formatParagraphs,
  },
  date: {
    editComponent: DateInput,
    render: stripFieldConfig(formatDate),
    nullify: true,
  },
  optionSelect: {
    editComponent: OptionSelect,
    render: formatOptionSelect,
    nullify: true,
    objectRender: stripFieldConfig(getNameOrDefault),
    optionKey: 'id',
  },
  objectSelect: {
    editComponent: ObjectSelect,
    render: formatObjectSelect,
    nullify: true,
    objectRender: stripFieldConfig(getNameOrDefault),
    objectKey: 'id',
  },
  objectSearchCreate: {
    editComponent: ObjectSearchCreate,
    render: stripFieldConfig(getNameOrDefault),
    nullify: true,
  },
  number: {
    editComponent: Input,
    editProps: { type: 'number' },
    render: formatCommaSeparatedNumber,
    nullify: true,
  },
  url: {
    editComponent: Input,
    editProps: { type: 'url' },
    render: renderDefaultOrLink,
    validations: { isUrl: true },
    validationErrors: { isUrl: 'Not a valid website (URLs should start with http:// or https://)' },
  },
  money: {
    editComponent: Input,
    editProps: {
      type: 'number',
      className: 'money',
      validations: {
        isNumeric: true,
        isPositive: true,
        isWithinDecimalPlaces: 2,
      },
      validationErrors: {
        isNumeric: 'Must be a valid number.',
        isPositive: 'Must be positive.',
        isWithinDecimalPlaces: 'Must be within 2 decimal places.',
      },
    },
    render: formatMoney,
    nullify: true,
    icon: '$',
  },
  moneyNegative: {
    editComponent: Input,
    editProps: {
      type: 'number',
      className: 'money',
      validations: {
        isNumeric: true,
        isWithinDecimalPlaces: 2,
      },
      validationErrors: {
        isNumeric: 'Must be a valid number.',
        isWithinDecimalPlaces: 'Must be within 2 decimal places.',
      },
    },
    render: formatMoney,
    nullify: true,
    icon: '$',
  },
  moneyPercent: {
    editComponent: MoneyPercent,
    editProps: {
      validations: {
        isNumeric: true,
        isPositive: true,
        isWithinDecimalPlaces: 2,
      },
      validationErrors: {
        isNumeric: 'Must be a valid number.',
        isPositive: 'Must be positive.',
        isWithinDecimalPlaces: 'Must be within 2 decimal places.',
      },
    },
    render: formatMoney,
    nullify: true,
  },
  percentMoney: {
    editComponent: MoneyPercent,
    editProps: {
      validations: {
        isNumeric: true,
        isPositive: true,
        isWithinDecimalPlaces: 4,
      },
      validationErrors: {
        isNumeric: 'Must be a valid number.',
        isPositive: 'Must be positive.',
        isWithinDecimalPlaces: 'Must be within 2 decimal places.',
      },
    },
    render: stripFieldConfig(formatPercentage),
    nullify: true,
    initialType: 'percent',
  },
  address: {
    editComponent: NestedFormGroup,
    render: formatAddress,
    editProps: {
      fields: [
        {
          field: 'address1',
        },
        {
          field: 'address2',
        },
        {
          field: 'city',
        },
        {
          field: 'state',
          type: 'optionSelect',
          optionKey: 'code',
          optionType: 'stateOptions',
        },
        {
          field: 'zip_code',
        },
      ],
    },
  },
  phone: {
    editComponent: Input,
    render: stripFieldConfig(formatPhoneNumber),
    editProps: {
      type: 'text',
      validationErrors: { isPhoneNumber: 'Must be a valid phone number' },
      validations: { isPhoneNumber: true },
    },
  },
  email: {
    editComponent: Input,
    render: getOrDefault,
    editProps: {
      validationErrors: { isEmail: 'Must be a valid email address' },
      validations: { isEmail: true },
    },
  },
  emailList: {
    editComponent: Input,
    render: getOrDefault,
    editProps: {
      type: 'text',
      validationErrors: { isEmailList: 'Must be a list of comma separated emails' },
      validations: { isEmailList: true },
    },
  },
  ssn: {
    editComponent: Input,
    render: formatSocialSecurityNumber,
    editProps: {
      validations: { isValidSSN: true },
      validationErrors: { isValidSSN: 'Must be a valid social security number' },
      type: 'text',
    },
  },
  boolean: {
    editComponent: Dropdown,
    editProps: {
      allowEmpty: true,
      options: BOOLEAN_FILTER_OPTIONS,
      optionsValueKey: 'value',
    },
    render: mapBooleanToText,
  },
  dropdown: {
    editComponent: Dropdown,
    editProps: {},
  },
  requiredBoolean: {
    editComponent: Dropdown,
    editProps: {
      allowEmpty: false,
      options: BOOLEAN_FILTER_OPTIONS,
      optionsValueKey: 'value',
    },
    render: mapBooleanToText,
  },
  additionalContactInfo: {
    editComponent: NestedFormGroup,
    editProps: {
      fields: [
        {
          field: 'cell_number',
          label: 'Cell',
          type: 'phone',
        },
        {
          field: 'fax_number',
          label: 'Fax',
          type: 'phone',
        },
      ],
    },
  },
  html: {
    editComponent: HtmlInput,
  },
  file: {
    editComponent: File,
    render: getOrDefault,
  },
  rating: {
    editComponent: Rating,
  },
  underwriterAnalysisField: {
    editComponent: NestedFormGroup,
    editProps: {
      fields: [
        {
          field: 'rating',
          inheritParentLabel: true,
          nullify: true,
          type: 'rating',
        },
        {
          dynamicHeight: true,
          field: 'notes',
          nullify: true,
          type: 'text',
        },
      ],
    },
    render: formatUnderwriterAnalysisSummary,
  },
  checkbox: {
    editComponent: Checkbox,
  },
};

export function isPartialFieldSetSimple (fieldSet: IFieldSetPartial): fieldSet is IFieldSetSimplePartial {
  return isArray(fieldSet);
}

export function isFieldSetSimple (fieldSet: IFieldSet): fieldSet is IFieldSetSimple {
  return isArray(fieldSet);
}

export function isTypeFieldConfigObjectSearchCreate (fieldConfig: IFieldConfig): fieldConfig is IFieldConfigObjectSearchCreate {
  return fieldConfig.type === 'objectSearchCreate';
}

export function getCreateFields (fieldConfig: IFieldConfigObjectSearchCreate): IFieldConfig[] {
  return fieldConfig.createFields.map((createField, mapIdx, mapCreateFields) => (
    fillInFieldConfig(createField, mapIdx, mapCreateFields as IFieldConfigPartial[], fieldConfig.field)
  ));
}

export function fillInFieldConfig (fieldConfig: IFieldConfigPartial, _idx?: number, _fieldSet?: IFieldSetPartial, parentKey?: string): IFieldConfig {
  // Using idx/fieldSet arguments allows this function to be passed directly to map
  const key = [parentKey, fieldConfig.field].filter(s => s).join('-')
    , defaultTypeProps = TYPES[fieldConfig.type || 'string'];

  return {
    // Defaults for every type
    key,
    label: varToLabel(fieldConfig.field),
    readOnly: false,
    required: false,
    showLabel: true,
    writeOnly: false,

    // Defaults from specific type
    ...defaultTypeProps,

    // fieldConfig can override EVERYTHING except input type
    ...fieldConfig,

    editProps: {...defaultTypeProps.editProps, ...fieldConfig.editProps},
  } as IFieldConfig;
}

// Fills in the defaults from common so we can keep configurations light
export function fillInFieldSets (fieldSets: IFieldSetPartial[]): IFieldSet[] {
  return fieldSets.map(fieldSet => {
    if (isPartialFieldSetSimple(fieldSet)) {
      return fieldSet.map(fillInFieldConfig);
    }

    return {
      ...fieldSet,
      fields: fieldSet.fields.map(fillInFieldConfig)
    };
  });
}

export function getFieldSetFields (fieldSet: IFieldSet): IFieldConfig[] {
  if (isFieldSetSimple(fieldSet)) {
    return fieldSet;
  }

  return fieldSet.fields;
}

export function fillInFieldConfigs (fieldSets: IFieldSetPartial[]): IFieldConfig[] {
  return ([] as IFieldConfig[]).concat.apply([] as IFieldConfig[], fillInFieldSets(fieldSets).map(getFieldSetFields));
}

export function isFieldSetNotEmpty (fieldSet: IFieldSet) {
  return !!getFieldSetFields(fieldSet).length;
}

export function filterFieldConfigs (fieldConfigs: IFieldConfig[], filters: { [key: string]: any }) {
  return fieldConfigs
    .filter(fieldConfig => (
      Object.keys(filters).every(key => get(fieldConfig, key) === get(filters, key))
    ));
}

// Filters field sets, like filterFieldSets(fieldSets, { writeOnly: false })
export function filterFieldSets (fieldSets: IFieldSet[], filters: { [key: string]: any }) {
  return fieldSets.map(fieldSet => {
    if (isFieldSetSimple(fieldSet)) {
      return filterFieldConfigs(fieldSet, filters);
    }

    return {
      ...fieldSet,
      fields: filterFieldConfigs(fieldSet.fields, filters)
    };
  }).filter(isFieldSetNotEmpty);
}
