import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { FormFieldSets } from '../../lib/mighty-fields';
import { SessionStoreClass } from '../../stores';

interface IInjected {
  SessionStore: SessionStoreClass;
}

@inject('SessionStore')
@observer
class UpdateRequestEmailModule extends Component {
  private get injected () {
    return this.props as IInjected;
  }

  private get fieldSets () {
    const { SessionStore: { isGuest } } = this.injected;
    return [[{ field: 'response_email', label: 'Your Email', required: isGuest, type: 'email' }]];
  }

  public render () {
    if (!this.injected.SessionStore.isGuest) { return null; }

    return (
      <FormFieldSets fieldSets={this.fieldSets} />
    );
  }
}

export default UpdateRequestEmailModule;
