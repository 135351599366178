import React from 'react';
import cx from 'classnames';
import { LinkContainer } from 'react-router-bootstrap';
import { MenuItem, MenuItemProps } from 'react-bootstrap';

interface IProps extends MenuItemProps {
  to?: string;
  subSection?: boolean;
}

const NavMenuItem: React.SFC<IProps> = ({ to, subSection, ...rest }) => {
  const MenuItemComponent = <MenuItem className={cx({'sub-section': subSection})} {...rest} />;
  return to ? <LinkContainer to={to}>{MenuItemComponent}</LinkContainer> : MenuItemComponent;
};

export default NavMenuItem;
