import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import autoBindMethods from 'class-autobind-decorator';
import { action, observable, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { get } from 'lodash';

import {
  Row,
} from 'react-bootstrap';

import {
  RadioButtonGroup,
} from '../common';

import { toast } from '../../utils';
import ClientsClass from '../../clients/ClientsClass';
import AppConstants from '../../constants/AppConstants';
import { SessionStoreClass } from '../../stores';
import { findKeyByValue } from '../../utils/util';
import UpdateRequestOptions from './UpdateRequestOptions';

const {
  CASE_MEDICAL_STATUS,
  CASE_TRACKING_STATUS,
  UPDATE_REQUEST_RADIO_OPTIONS,
  UPDATE_REQUEST_TYPES,
  USER_FLOW,
} = AppConstants;

interface ITask {
  completed_at?: string;
  id: string;
  plaintiff_name: string;
  response_email: string;
  last_medical_status?: any;
  last_tracking_status?: any;
  recipient_email?: string;
  tracking_update: {
    body: string,
    medical_status: string,
    tracking_attributes: string[],
    tracking_follow_up_date?: string,
    tracking_status: string,
  };
}

interface IProps {
  task: ITask;
}

interface IInjected extends IProps {
  Clients: ClientsClass;
  SessionStore: SessionStoreClass;
}

@inject('Clients', 'SessionStore')
@autoBindMethods
@observer
class CaseUpdateTaskResponse extends Component <IProps> {
  @observable private valueKey: string = '';

  constructor (props: any) {
    super(props);

    const { SessionStore } = this.injected
      , queryUpdate: string = get(browserHistory.getCurrentLocation(), 'query.update');

    if (queryUpdate) {
      this.valueKey = queryUpdate;
      SessionStore.trackEvent('TASK_RESPONSE_PAGE_WITH_UPDATE', {update: queryUpdate});
    }
  }

  @computed
  private get isNoUpdate () {
    return this.valueKey === UPDATE_REQUEST_TYPES.NO_UPDATE.key;
  }

  public componentDidMount () {
    const location = browserHistory.getCurrentLocation()
      , { recipient_email } = this.props.task;
    if (location.state === USER_FLOW.FROM_COMPLETED_TASK_RESPONSE) {
      browserHistory.replace({pathname: location.pathname, state: {}});
    }

    if (this.isNoUpdate) {
      this.submitUpdate({
        body: `\n\nEmail: ${recipient_email}`,
        completed: true,
        response_email: recipient_email,
        tracking_attributes: [
          'no_update',
        ],
      }, false);
    }
  }

  private get injected () {
    return this.props as IInjected;
  }

  @action
  private handleRadioClick (value: string) { this.valueKey = value; }

  private addLastStatus (submitData: any) {
    const lastMedicalStatus = findKeyByValue(CASE_MEDICAL_STATUS, this.props.task.last_medical_status)
    , lastTrackingStatus = findKeyByValue(CASE_TRACKING_STATUS, this.props.task.last_tracking_status)
    ;

    if (this.isNoUpdate) {
      submitData.tracking_update = {
        ...submitData.tracking_update,
        medical_status: submitData.tracking_update.medical_status || lastMedicalStatus,
        tracking_status: submitData.tracking_update.tracking_status || lastTrackingStatus,
      };
    }

    return submitData;
  }

  private serializeSubmitData (data: any) {
    const submitData = {
      completed: true,
      mediation_date: data.mediation_date || null,
      response_email: data.response_email,
      tracking_follow_up_date: data.trackingFollowUpDate || null,
      tracking_update: {
        body: data.body || '',
        medical_status: data.medical_status || null,
        tracking_attributes: data.tracking_attributes || [],
        tracking_status: data.tracking_status || null,
      },
      trial_date: data.trial_date || null,
    };

    return this.addLastStatus(submitData);
  }

  private serializeEventData (data: any) {
    return {
      note: data.unappendedBody || null,
      option_selected: this.valueKey,
      task_id: this.props.task.id,
      tracking_follow_up_date: data.trackingFollowUpDate || null,
    };
  }

  @action
  private async submitUpdate (submitData: any, shouldRedirectToTasks: boolean = true) {
    const { Clients, SessionStore } = this.injected;

    await Promise.all([
      Clients.caseTrackingUpdateTasks.update(this.props.task.id, this.serializeSubmitData(submitData)),
      SessionStore.trackEvent('CASE_TRACKING_UPDATE_TASK_SUBMITTED', this.serializeEventData(submitData)),
    ]);

    if (shouldRedirectToTasks) {
      toast.success('Success! Case update has been added.');
      browserHistory.push({pathname: '/tasks'});
    }
  }

  public render () {

    if (this.isNoUpdate) {
      return (
        <h5>Thank you for your response. You can now close this window.</h5>
      );
    }

    return (
      <Row>
        <RadioButtonGroup
          checker={this.valueKey || ''}
          descriptionLookupString='label'
          fieldName='update'
          handleRadioClick={this.handleRadioClick}
          items={UPDATE_REQUEST_RADIO_OPTIONS}
          lookupString='key'
          title={'What\'s the latest update?'}
        />
        <hr />
        <UpdateRequestOptions valueKey={this.valueKey} onSubmit={this.submitUpdate} fromTask />
      </Row>
    );
  }
}

export default CaseUpdateTaskResponse;
