import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import {
  Table,
  Well,
} from 'react-bootstrap';

import { Icon } from '../common';
import RelationshipRow from './RelationshipRow';
import ClientsClass from '../../clients/ClientsClass';

interface IProps {
  model: {
    relationshipsTitle: string;
    isMedical?: boolean;
    isOrganization: boolean;
  };
  relationships: any[];
}

interface IInjected extends IProps {
  Clients: ClientsClass;
}

@inject('Clients')
@autoBindMethods
@observer
class RelationshipTable extends Component<IProps> {
  @observable public relationships: any[] = [];

  constructor (props: IProps) {
    super(props);
    this.relationships = props.relationships;
  }

  private get injected () {
    return this.props as IInjected;
  }

  @action
  public async handleRelationshipDelete (relationshipId: string) {
    const { Clients } = this.injected;
    await Clients.relationships.delete(relationshipId);
    this.relationships = this.relationships.filter(r => r.id !== relationshipId);
  }

  public render () {
    const { model } = this.props
      , type = model.isOrganization ? 'organizations' : 'people';

    return (
      <Well className={`section-${type}`}>
        <h3>{model.relationshipsTitle}</h3>

        <div className='table-wrapper'>
          <Table condensed>
            <thead>
              <tr>
                {!model.isMedical && <th className='col-warning'><Icon type='exclamation-triangle' /></th>}
                <th className='col-name'>Name</th>
                <th className='col-role'>Role</th>
                <th className='col-phone'>Phone Number</th>
                <th className='col-btn' />
              </tr>
            </thead>
            <tbody>
              {this.relationships.map(relationship =>
                (<RelationshipRow
                  key={relationship.id}
                  model={model}
                  relationship={relationship}
                  type={type}
                  onDelete={this.handleRelationshipDelete}
                />),
              )}
            </tbody>
          </Table>
        </div>
      </Well>
    );
  }
}

export default RelationshipTable;
