import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { NavItem } from 'react-bootstrap';

import { Icon } from '../../../components/common';
import { UiStoreClass } from '../../../stores';

interface IProps {
  className?: string;
}

interface IInjected extends IProps{
  UiStore: UiStoreClass;
}

@inject('UiStore')
class SearchNavItem extends Component<IProps> {
  get injected () {
    return this.props as IInjected;
  }

  public render () {
    return (
      <NavItem className={this.props.className} onClick={this.injected.UiStore.isSlidingSearchBarOpen.toggle}>
        <Icon type='search' />
      </NavItem>
      );
    }
  }

export default SearchNavItem;
