import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import { MenuItem } from 'react-bootstrap-typeahead';
import {
  IDisplaySearchResultItemConfiguration,
  getBaseSearchResultDisplayItem,
  ISearchResultItemProps,
} from './SearchResultUtils';

import FormattingUtils from '../../../../utils/FormattingUtils';
import { OptionsStoreClass } from '../../../../stores';

const { pluralize } = FormattingUtils;

interface IProps extends ISearchResultItemProps {
  OptionsStore: OptionsStoreClass;
}

@inject(
  'OptionsStore',
)
@autoBindMethods
@observer
class LawFirmSearchResultItem extends Component<IProps> {

  public render () {
    const displayItemConfiguration: IDisplaySearchResultItemConfiguration = {
        ...this.props,
        entity: 'lawfirm',
        path: '/contacts/organizations/',
      }
      , displayItem = getBaseSearchResultDisplayItem(displayItemConfiguration)
      , stateName = this.props.OptionsStore.formatStateName(this.props.item);

    return (
      <MenuItem key={displayItem.item.id} option={displayItem} position={displayItem.item.index}>
        <div className='search-result-item'>
          <p><strong>{displayItem.name}</strong> {stateName && <span>({stateName})</span>}</p>
          <p><span className='sub-info'>{displayItem.phoneNumber}</span>
            <span className='pull-right'>{pluralize('case', displayItem.item.cases_count, true)}</span>
          </p>
        </div>
      </MenuItem>
    );
  }
}

export default LawFirmSearchResultItem;
