import React, { Component } from 'react';
import NamePhoneSearchResultItem from './NamePhoneSearchResultItem';

import { IDisplaySearchResultItemConfiguration, ISearchResultItemProps } from './SearchResultUtils';

class MedicalFacilityResultItem extends Component<ISearchResultItemProps> {

  public render () {
    const displayItemConfiguration: IDisplaySearchResultItemConfiguration = {
        ...this.props,
        entity: 'medicalfacility',
        path: '/contacts/organizations/',
      };

    return (
      <NamePhoneSearchResultItem { ...displayItemConfiguration } />
    );
  }
}

export default MedicalFacilityResultItem;
