import React, { Component } from 'react';
import { inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { startCase } from 'lodash';

import { GuardedAnalyticsNavDropdown, GuardedAnalyticsPageLink } from './PageLinks';
import { FunderStoreClass } from '../../stores';

interface IInjected {
  FunderStore: FunderStoreClass;
}

@inject('FunderStore')
@autoBindMethods
class AnalyticsNav extends Component {
  private basePathname = '/analytics/';

  get injected () {
    return this.props as IInjected;
  }

  private getDropdownLink (analyticsType: string, key: number) {
    return (
      <GuardedAnalyticsPageLink key={key} to={this.basePathname + analyticsType}>
        {startCase(analyticsType)}
      </GuardedAnalyticsPageLink>
    );
  }

  public render () {
    const { FunderStore } = this.injected
      , analyticsTypes = FunderStore.analyticsTypes
      ;

    if (analyticsTypes.length === 1) {
      return <GuardedAnalyticsPageLink to={this.basePathname + analyticsTypes[0]}>Analytics</GuardedAnalyticsPageLink>;
    }

    return (
      <GuardedAnalyticsNavDropdown title='Analytics'>
        {analyticsTypes.map((type, key) => (this.getDropdownLink(type, key)))}
      </GuardedAnalyticsNavDropdown>
    );
  }
}

export default AnalyticsNav;
