/* tslint:disable max-classes-per-file */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Modal } from 'react-bootstrap';

interface IProps {
  onClose: () => void;
}

class Title extends Component {
  public render () {
    return <h4>{this.props.children}</h4>;
  }
}

class Body extends Component {
  public render () {
    return <div className='body'>{this.props.children}</div>;
  }
}

@autoBindMethods
@observer
class MessageModal extends Component<IProps> {
  public static Body = Body;
  public static Title = Title;

  public render () {
    const { children, onClose } = this.props;

    return (
      <Modal className='modal-message' onHide={onClose} show>
        <Modal.Body>
          {children}
        </Modal.Body>
      </Modal>
    );
  }
}

export default MessageModal;
