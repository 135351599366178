import { omitBy } from 'lodash';
import autoBindMethods from 'class-autobind-decorator';
import { observable, computed, toJS } from 'mobx';

import { formatSortingParams, isEmptyOrNil } from '../utils/util';

@autoBindMethods
class ListPageStore {
  @observable public loading = true;
  @observable public nextPageURL?: string;
  @observable public previousPageURL?: string;
  @observable public total?: number;
  @observable public sidebarCounts?: object;
  @observable public tableData: any[] = [];
  @observable public tableType = '';
  @observable public filters: { [key: string]: string } = {};
  @observable public ordering: Array<{ id: string, desc: boolean }> = [];

  public async fromRequest (request: Promise<any>) {
    this.loading = true;
    const data = await request;

    this.total = data.count;
    this.tableData = data.results;
    this.nextPageURL = data.next;
    this.previousPageURL = data.previous;
    this.loading = false;

    return data;
  }

  @computed
  public get query () {
    const query = {
      ...toJS(this.filters),
      ordering: formatSortingParams(this.ordering),
    };

    return omitBy(query, isEmptyOrNil);
  }

  @computed
  public get queryToLog () {
    return {
      filters: toJS(this.filters),
      ordering: toJS(this.ordering),
    };
  }
}

export default ListPageStore;
