import React, { Component } from 'react';
import { observer } from 'mobx-react';

interface IProps {
  label: string;
  show?: boolean;
}

@observer
class Separator extends Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    show: true,
  };

  public render () {
    if (!this.props.show) { return null; }

    return (
      <div className='separator'>
        <span>{this.props.label}</span>
      </div>
    );
  }
}

export default Separator;
