import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import { MenuItem } from 'react-bootstrap';
import autoBindMethods from 'class-autobind-decorator';

import { CaseStoreClass, UiStoreClass } from '../../stores';
import { ConfirmationModal } from '../../components/common';

import { ICase } from '../../interfaces';
import FormattingUtils from '../../utils/FormattingUtils';

const { pluralize } = FormattingUtils;

interface IProps {
  _case: ICase;
  isDisabled: boolean;
}

interface IInjected extends IProps {
  CaseStore: CaseStoreClass;
  UiStore: UiStoreClass;
}

@inject('CaseStore', 'UiStore')
@observer
@autoBindMethods
class DeleteCaseMenuItem extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private async onDeleteCase () {
    const { CaseStore } = this.injected;

    await CaseStore.delete(this.props._case.id);
    browserHistory.replace('/case-tracking');
  }

  private get dialogInstructions () {
    const numLiens = this.props._case.getLiens().length;

    return (
      <p>This case and its {pluralize('lien', numLiens, true)} will be deleted. <strong>This action cannot be undone.</strong></p>
    );
  }

  public render () {
    const { isDisabled } = this.props
      , { UiStore } = this.injected
    ;

    return (
      <Fragment>
        <MenuItem disabled={isDisabled} onSelect={UiStore.modals.DeleteCase.open}>Delete Case</MenuItem>
        {UiStore.modals.DeleteCase.showing() &&
          <ConfirmationModal
            action='Delete Case'
            target={this.dialogInstructions}
            onClose={UiStore.modals.DeleteCase.close}
            onConfirm={this.onDeleteCase}
          />
        }
      </Fragment>
    );
  }
}

export default DeleteCaseMenuItem;
