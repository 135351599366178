import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { get, includes } from 'lodash';

import { Mention, MentionsInput } from 'react-mentions';

import {
  Button,
} from 'react-bootstrap';

import {
  IMention,
} from '../../../interfaces';

import {
  Form,
  Input,
} from '../../common-formsy';
import { CaseStoreClass, SessionStoreClass, FunderStoreClass } from '../../../stores';
import { SmartBool } from '../../../utils';

const ENTER_CHARCODE = 13
  , ENTER_CTRL_CHARCODE = 10
  , SUBMIT_CHARCODES = [ENTER_CHARCODE, ENTER_CTRL_CHARCODE];

interface IMentionsInput {
  getWrappedInstance: () => {
    refs: {
      input: HTMLInputElement,
    },
  };
}

interface IProps {
  caseId: string;
}

interface IInjected extends IProps {
  CaseStore: CaseStoreClass;
  FunderStore: FunderStoreClass;
  SessionStore: SessionStoreClass;
}

@inject(
  'CaseStore',
  'FunderStore',
  'SessionStore',
)
@autoBindMethods
@observer
class NewCaseNote extends Component<IProps> {
  @observable private body = '';
  @observable private submitText = '';
  @observable private isFocused = false;
  @observable private isSubmitting = new SmartBool();
  @observable private submitErrors = false;
  @observable private mentions: string[] = [];

  private refForm?: Form;
  private refMention?: IMentionsInput;

  private get injected () {
    return this.props as IInjected;
  }

  private async onCaseNoteCreate (data: object) {
    const { CaseStore } = this.injected;
    this.isSubmitting.setTrue();
    await CaseStore.createCaseNote({ data });
    this.isSubmitting.setFalse();
  }

  private mapInputs (inputs: { case_id: string, body: string }) {
    const {
      body,
      case_id,
    } = inputs;

    return {
      body,
      case: case_id,
    };
  }

  private resetForm () {
    this.body = '';
    this.mentions = [];

    if (this.refForm) { this.refForm.reset(); }
  }

  private onKeyPress (event: KeyboardEvent) {
    if (includes(SUBMIT_CHARCODES, event.charCode) && !event.shiftKey && !this.isSubmitting.isTrue) {
      event.preventDefault();
      if (this.refForm) { this.refForm.submit(); }
    }
  }

  private onBodyInputChange (_event: KeyboardEvent, newValue: string, text: string, mentions: IMention[]) {
    this.body = newValue;
    this.mentions = mentions.map(mention => mention.id);
    this.submitText = text;
  }

  private async handleValidSubmit (model: object, _resetForm: () => void, _invalidateForm: () => void) {
    if (this.body.trim().length) {
      await this.onCaseNoteCreate({
        ...model,
        body: this.submitText,
        mentions_write: this.mentions,
      });
    }
    this.resetForm();
  }

  private trackMentionTriggerEvent () {
    const { SessionStore } = this.injected;
    if (!SessionStore.user) { return; }

    SessionStore.trackEvent('CASE_NOTE_COMMENT_TAG_TRIGGERED', {
      case_id: this.props.caseId,
      user_id: SessionStore.user.id,
    });
  }

  private handleSubmitErrorsChange (submitErrors: boolean) {
    this.submitErrors = submitErrors;
  }

  private onFocus () {
    this.isFocused = true;
  }

  private onBlur (event: FocusEvent) {
    // If we lose focus because we clicked the 'hint' <button>, skip it.
    if (get(event, 'relatedTarget.className', '').includes('hint')) { return; }
    this.isFocused = false;
  }

  private renderMention (_id: any, display: string) {
    return `@${display}`;
  }

  private triggerMention (event: any) {
    const cleanBody = this.body.replace(/\s?$/, '')
      , mentionInstance = !!this.refMention && this.refMention.getWrappedInstance();

    this.trackMentionTriggerEvent();

    if (mentionInstance) {
      event.preventDefault();
      this.body = `${cleanBody} @`;
      mentionInstance.refs.input.focus();
    }
  }

  private setRefForm (comp: Form) { this.refForm = comp; }
  private setRefMention (comp: IMentionsInput) { this.refMention = comp; }

  public render () {
    const { caseId } = this.props
      , { FunderStore } = this.injected
      , options = FunderStore.funderStaffDisplayMentionOptions
      , isExpanded = (this.body.length !== 0 || this.isFocused)
      , mentionClassNames = cx(
        'mention-input',
        {expanded: isExpanded},
      );

    return (
      <Form
        mapping={this.mapInputs}
        onSubmitErrorsChange={this.handleSubmitErrorsChange}
        onValidSubmit={this.handleValidSubmit}
        ref={this.setRefForm}
      >
        <Input
          field='case_id'
          name='case_id'
          type='hidden'
          value={caseId}
        />

        <MentionsInput
          className={mentionClassNames}
          disabled={this.isSubmitting.isTrue}
          displayTransform={this.renderMention}
          name='body'
          onBlur={this.onBlur}
          onChange={this.onBodyInputChange}
          onFocus={this.onFocus}
          onKeyPress={this.onKeyPress}
          placeholder='Add case note...'
          ref={this.setRefMention}
          required
          value={this.body}
        >
          <Mention className='mention' trigger='@' data={options} />
        </MentionsInput>

        {isExpanded &&
          <div className='form-footer'>
            <Button
              bsStyle='link'
              className='hint'
              disabled={this.isSubmitting.isTrue}
              onClick={this.triggerMention}
            >
              <span>@</span> to notify team members
            </Button>
            <Button
              bsSize='small'
              bsStyle='primary'
              disabled={this.submitErrors || this.isSubmitting.isTrue}
              type='submit'
            >
              Save
            </Button>
          </div>
        }

      </Form>
    );
  }
}

export default NewCaseNote;
