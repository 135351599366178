import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { DropdownButton, MenuItem } from 'react-bootstrap';

import RepresentativeDropdownAssign from './RepresentativeDropdownAssign';
import { FormattingUtils } from '../../utils';
import { IHeaderProps } from './CaseDetailHeader';
import { CaseStoreClass, UiStoreClass } from '../../stores';
import { AppConstants } from '../../constants';

const { formatLeadSource } = FormattingUtils;
const { MODEL_TYPES } = AppConstants;

interface IInjected extends IHeaderProps {
  CaseStore: CaseStoreClass;
  UiStore: UiStoreClass;
}

@inject('UiStore', 'CaseStore')
@autoBindMethods
@observer
class CaseDetailHeaderAssignments extends Component <IHeaderProps> {
  private async onRepresentativeChanged (type: string, representative: string) {
    const { _case } = this.props
      , { CaseStore } = this.injected
      , request = CaseStore.update({
        caseId: _case.id,
        data: { [type]: representative },
      });

    await CaseStore.caseActivities.loadNew(MODEL_TYPES.representativeAssignment.key, request);
  }

  private async onPrimaryRepresentativeChanged (representative: string) {
    await this.onRepresentativeChanged('primary_representative', representative);
  }

  private async onSecondaryRepresentativeChanged (representative: string) {
    await this.onRepresentativeChanged('secondary_representative', representative);
  }

  private async onTertiaryRepresentativeChanged (representative: string) {
    await this.onRepresentativeChanged('tertiary_representative', representative);
  }

  private async onRemoveLeadSourceFromCase () {
    const { _case } = this.props
      , { CaseStore } = this.injected
      ;

    CaseStore.update({caseId: _case.id, data: {lead_source: null}});
  }

  private get injected () {
    return this.props as IInjected;
  }

  public render () {
    const { _case } = this.props
      , { UiStore } = this.injected
      , leadSource = _case.getLeadSource()
      ;

    return (
      <Fragment>
        <dl>
          <dt>Primary Owner</dt>
          <dd>
            <RepresentativeDropdownAssign
              omit={[_case.secondary_representative, _case.tertiary_representative]}
              onChange={this.onPrimaryRepresentativeChanged}
              selected={_case.primary_representative}
            />
          </dd>
        </dl>
        <dl>
          <dt>Secondary Owner</dt>
          <dd>
            <RepresentativeDropdownAssign
              omit={[_case.primary_representative, _case.tertiary_representative]}
              onChange={this.onSecondaryRepresentativeChanged}
              selected={_case.secondary_representative}
            />
          </dd>
        </dl>
        <dl>
          <dt>Tertiary Owner</dt>
          <dd>
            <RepresentativeDropdownAssign
              omit={[_case.primary_representative, _case.secondary_representative]}
              onChange={this.onTertiaryRepresentativeChanged}
              selected={_case.tertiary_representative}
            />
          </dd>
        </dl>
        <dl className='section-lead-source'>
          <dt>Lead Source</dt>
          <dd className='dropdown-arrow right'>
            <DropdownButton bsStyle='link' id='lead-source-menu' title={formatLeadSource(leadSource)} pullRight>
              <MenuItem onClick={UiStore.modals.LeadSource.open}>Edit Lead Source Info</MenuItem>
              {leadSource && <MenuItem onClick={this.onRemoveLeadSourceFromCase}>Remove Lead Source</MenuItem>}
            </DropdownButton>
          </dd>
        </dl>
      </Fragment>
    );
  }
}

export default CaseDetailHeaderAssignments;
