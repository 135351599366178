import React, { Component, Fragment } from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { browserHistory } from 'react-router';
import { get } from 'lodash';

import { Spacer } from '../common';

import { CaseUpdateStoreClass, ContactStoreClass } from '../../stores';
import RequestFooter from './RequestFooter';
import { CaseContactSelect, Form } from '../common-formsy';
import ClientsClass from '../../clients/ClientsClass';
import { FormattingUtils, SmartBool, toast } from '../../utils';

const { splitName } = FormattingUtils;

interface IInjected {
  CaseUpdateStore: CaseUpdateStoreClass;
  Clients: ClientsClass;
  ContactStore: ContactStoreClass;
}

@inject('Clients', 'CaseUpdateStore', 'ContactStore')
@autoBindMethods
@observer
class UpdateRequestModule extends Component <{}> {
  @observable private isSubmitting = new SmartBool();

  private get injected () {
    return this.props as IInjected;
  }

  private async upsertContact (_case: any, model: any) {
    const { ContactStore } = this.injected
      , { contactType, newContactFullName, newContactEmail } = model
      , [ firstName, lastName ] = splitName(newContactFullName)
      , contactData = {
          contact_type: model.contactType,
          email: newContactEmail,
          first_name: firstName,
          last_name: lastName,
        }
      ;

    if (model.recipient) { return model.recipient; }

    const recipient = await ContactStore.create({ _case, contactData, contactType });
    return recipient.id;
  }

  private async handleValidSubmit (model: any) {
    const { CaseUpdateStore, Clients } = this.injected
      , caseId = get(CaseUpdateStore, '_case.id');

    if (!caseId) {
      return;
    }

    this.isSubmitting.setTrue();
    const recipientId = await this.upsertContact(CaseUpdateStore._case, model);
    await Clients.caseTrackingUpdateTasks.create({ case: caseId, recipient: recipientId });
    this.isSubmitting.setFalse();

    CaseUpdateStore.clear();
    toast.success('Success! Update request sent');
    browserHistory.push(`/case/${caseId}`);
  }

  private renderContactSelect () {
    const { CaseUpdateStore: { _case } } = this.injected;

    if (!_case) {
      return;
    }

    return (
      <Fragment>
        <Spacer large />
        <CaseContactSelect _case={_case} />
      </Fragment>
    );
  }

  public render () {
    return (
      <Form className='modal-form-style' onValidSubmit={this.handleValidSubmit}>
        {this.renderContactSelect()}
        <hr />
        <RequestFooter
          isSubmitting={this.isSubmitting}
          typeName='Update'
        />
      </Form>
    );
  }
}

export default UpdateRequestModule;
