import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Button, Modal, Table } from 'react-bootstrap';
import { startCase } from 'lodash';

import { IBulkAddField } from '../../../stores/OptionsStoreClass';
import { FunderStoreClass, OptionsStoreClass } from '../../../stores';
import { Loader } from '../../common';

interface IProps {
  onClose: () => void;
}

interface IInjected extends IProps {
  FunderStore: FunderStoreClass;
  OptionsStore: OptionsStoreClass;
}

@inject('FunderStore', 'OptionsStore')
@autoBindMethods
@observer
class BulkAddFieldsModal extends Component<IProps, {}> {
  @observable private isLoading: boolean = true;
  @observable private fields: any;

  private get injected () {
    return this.props as IInjected;
  }

  private mapCustomFields = (fields: string[]) => fields.map(fieldName => ({
    field_name: fieldName,
    options: [],
    required: false,
  }))

  public async componentDidMount () {
    const { FunderStore, OptionsStore } = this.injected
    , fields = OptionsStore.bulkAddFields
    , customCaseFields = FunderStore.funder?.custom_case_fields || []
    , customLienFields = FunderStore.funder?.custom_lien_fields || [];

    this.fields = {
      ...fields,
      ...(customCaseFields.length > 0 && { custom_case_fields: this.mapCustomFields(customCaseFields) }),
      ...(customLienFields.length > 0 && { custom_lien_fields: this.mapCustomFields(customLienFields) }),
    };

    this.isLoading = false;
  }

  public renderFieldsSection (sectionName: string, fields: IBulkAddField[]) {
    return (
      <React.Fragment>
      <div className='fields-table-container'>
        <h3>{startCase(sectionName)}</h3>
        <Table bordered condensed hover responsive className='fields-table'>
          <thead>
            <tr>
              <th>Field</th>
              <th>Expected Format</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field: IBulkAddField) => (
              <tr key={field.field_name}>
                <td>{field.required ? field.field_name.concat('*') : field.field_name}</td>
                <td>{field.options.join(', ')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      </React.Fragment>
    );
  }

  public render () {
    const { onClose } = this.props;

    return (
      <Modal bsSize='large' className='modal-bulk-add-fields' show onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bulk Add Fields</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.isLoading ? (
            <Loader className='modal-loader' />
          ) : (
            <React.Fragment>
              <p className='note'><strong>Note:</strong> Fields marked with (*) are required.</p>
              {Object.keys(this.fields).map((key) => (
                this.renderFieldsSection(key, this.fields[key]))
              )}
          </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BulkAddFieldsModal;
