import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { startCase } from 'lodash';

import { Button } from 'react-bootstrap';

import { Link } from '../common';
import { AppConstants } from '../../constants';
import { SessionStoreClass } from '../../stores';
import { FormattingUtils } from '../../utils';

const { CASE_TRACKING_STATUS, EMPTY_FIELD } = AppConstants;
const { getOrDefault, formatDate } = FormattingUtils;

interface IProps {
  data: {
    [key: string]: string | undefined;

    attorney?: string;
    case: string;
    date_of_loss?: string;
    lawfirm?: string;
    plaintiff_name: string;
    last_tracking_status?: string;
  };
}

interface IInjected extends IProps {
  SessionStore: SessionStoreClass;
}

const formatCaseTrackingStatus = (status: string) => {
  if (!status) { return EMPTY_FIELD; }
  return CASE_TRACKING_STATUS[status] || status;
};

const caseInfoConfig = [
  { fieldName: 'date_of_loss', formatter: formatDate, className: 'fs-mask' },
  { fieldName: 'attorney' },
  { fieldName: 'lawfirm' },
  { fieldName: 'last_tracking_status', formatter: formatCaseTrackingStatus, label: 'Status'},
];

interface IConfigGroup { fieldName: string; formatter?: (value?: any) => string; label?: string; className?: string; }

@inject('SessionStore')
@autoBindMethods
@observer
class UpdateSidebarCaseDetails extends Component <IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private renderGoToCaseButton () {
    const { data } = this.props;

    if (this.injected.SessionStore.isGuest) { return null; }

    return (
      <Link to={{pathname: `/case/${data.case}`}}>
        <Button className='no-padding' bsSize='small' bsStyle='link'>View</Button>
      </Link>
    );
  }

  public render () {
    const { data } = this.props;

    return (
      <Fragment>
        <hr />
        <div className='wrapper case-info'>
          <div className='plaintiff flex-between'>
            <span className='fs-mask'>{data.plaintiff_name}</span>
            {this.renderGoToCaseButton()}
          </div>

          {caseInfoConfig.map((configGroup: IConfigGroup) => {
            const { className, fieldName, formatter, label } = configGroup
              , formatOrDefault = formatter || getOrDefault
              , value = data[fieldName]
              ;

            return (
              <p key={fieldName}>
                <span className='clearfix'>{label || startCase(fieldName)}:</span>
                <b className={className}>{formatOrDefault(value)}</b>
              </p>
            );
          })}

        </div>
        <hr />
      </Fragment>
    );
  }
}

export default UpdateSidebarCaseDetails;
