import React, { Component } from 'react';
import { Button, Row } from 'react-bootstrap';
import autoBindMethods from 'class-autobind-decorator';
import { autorun, IReactionDisposer, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';

import { FunderStoreClass, OptionsStoreClass } from '../../../stores';
import { IBulkEditConfiguration } from '../../../stores/OptionsStoreClass';
import { Collapsible, toast } from '../../../utils';
import { Icon } from '../../common';
import { AppConstants } from '../../../constants';

const { BULK_EDIT_TEMPLATE_SECTION_TITLES, REQUIRED_FIELD_BY_CONFIG } = AppConstants;

interface IInjected {
  FunderStore: FunderStoreClass;
  OptionsStore: OptionsStoreClass;
}

@inject('FunderStore', 'OptionsStore')
@autoBindMethods
@observer
class BulkEditTemplateDownloadSection extends Component {
  @observable private selectedFields: string[] = [];
  @observable private openSectionId: string = '';
  private autorunDisposer: IReactionDisposer | null = null;

  private get injected () {
    return this.props as IInjected;
  }

  public componentDidMount () {
    this.autorunDisposer = autorun(() => {
      const { bulkEditConfigurations } = this.injected.OptionsStore;
      if (bulkEditConfigurations.length > 0) {
        this.openSectionId = bulkEditConfigurations[0].id;
      }
    });
  }

  public componentWillUnmount () {
    if (this.autorunDisposer) {
      this.autorunDisposer();
    }
  }

  private handleDownload (configName: string) {
    try {
      this.selectedFields.unshift(REQUIRED_FIELD_BY_CONFIG[configName]);

      const csvContent = this.selectedFields.join(',') + '\n' + '\n'
        , blob = new Blob([csvContent], { type: 'text/csv' })
        , link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = 'template.csv';

      link.click();
    } catch (error) {
      toast.error('There was an error downloading the template.');
    }
  }

  private handleToggleSection (configurationId: string) {
    this.openSectionId = this.openSectionId === configurationId ? '' : configurationId;

    // Reset selected fields to avoid user selecting fields across configuration types
    this.selectedFields = [];
  }

  private handleCheckboxChange (e: any) {
    const { id, checked } = e.target;

    if (checked) {
      if (!this.selectedFields.includes(id)) {
        this.selectedFields.push(id);
      }
    } else {
      this.selectedFields = this.selectedFields.filter(field => field !== id);
    }
  }

  private handleSelectAllFields (configurationFields: string[]) {
    const selectedFieldsSet = new Set(this.selectedFields)
    , requiredFields = new Set(Object.values(REQUIRED_FIELD_BY_CONFIG))
    , allSelected = configurationFields.every(field => selectedFieldsSet.has(field) || requiredFields.has(field));

    if (allSelected) {
      this.selectedFields = [];
    } else {
      configurationFields.forEach(field => {
        if (!requiredFields.has(field) && !selectedFieldsSet.has(field)) {
          this.selectedFields.push(field);
        }
      });
    }
  }

  private checkFieldChecked (field: string) {
    return this.selectedFields.includes(field) || Object.values(REQUIRED_FIELD_BY_CONFIG).includes(field);
  }

  private renderConfiguration (configuration: IBulkEditConfiguration) {
    const { FunderStore } = this.injected
      , title = get(BULK_EDIT_TEMPLATE_SECTION_TITLES, configuration.name, 'Section Title Not Found')
      , onClick = () => this.handleDownload(configuration.name)
      , handleToggle = () => this.handleToggleSection(configuration.id)
      , allFields = Array.from(new Set([
        ...configuration.fields,
        ...(title === 'Case Fields' ? FunderStore.funder?.custom_case_fields || [] :
          (title === 'Medical Lien Fields' || title === 'Plaintiff Lien Fields') ? FunderStore.funder?.custom_lien_fields || [] : [])
      ]))
      , handleSelectAll = () => this.handleSelectAllFields(allFields);

    return (
      <div className='configuration-section' key={configuration.name}>
        <Collapsible
          title={title}
          handleToggle={handleToggle}
          sectionOpened={this.openSectionId === configuration.id}
        >
          <div className='flex-left select-all-button-container'>
            <Button
              bsStyle='link'
              className='no-padding'
              onClick={handleSelectAll}
            >
              Select All
            </Button>
          </div>
          <Row className='configuration-fields'>
            <ul className='list-checkbox condensed-checkbox two-columns'>
              {allFields.map(field => (
                <li key={field}>
                  <input
                    id={field}
                    onChange={this.handleCheckboxChange}
                    type='checkbox'
                    checked={this.checkFieldChecked(field)}
                    disabled={Object.values(REQUIRED_FIELD_BY_CONFIG).includes(field)}
                  />
                  <label htmlFor={field}>{field}</label>
                </li>
              ))}
            </ul>
          </Row>
          <Row className='btn-download-template'>
            <Button bsStyle='link' onClick={onClick} disabled={!this.selectedFields.length}>
              <Icon type='download' /> Download Template
            </Button>
          </Row>
        </Collapsible>
      </div>
    );
  }

  public render () {
    const { bulkEditConfigurations } = this.injected.OptionsStore;

    return (
      <div className='download-template'>
        <h2>Template Configuration</h2>
        {bulkEditConfigurations.length
          ? bulkEditConfigurations.map(this.renderConfiguration)
          : <span>Loading...<span className='spinner' /></span>
        }
      </div>
    );
  }
}

export default BulkEditTemplateDownloadSection;
