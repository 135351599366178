import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { insertIf } from '../../../utils/util';

import CaseModel from '../../../models/CaseModel';
import FormModal from '../../../lib/mighty-fields/FormModal';
import { UiStoreClass, FunderStoreClass } from '../../../stores';
import { AppConstants } from '../../../constants';

const { APPLICATION_STATUS } = AppConstants;

interface IProps {
  _case: CaseModel;
}

interface IInjected extends IProps {
  ApplicationStore: any;
  UiStore: UiStoreClass;
  FunderStore: FunderStoreClass;
}

@inject('UiStore', 'ApplicationStore', 'FunderStore')
@autoBindMethods
@observer
class NewPlaintiffApplicationModal extends Component {
  private get injected () {
    return this.props as IInjected;
  }

  private async onSubmit (data: object) {
    const { _case, ApplicationStore } = this.injected;

    await ApplicationStore.create({
      caseId: _case.id,
      data: {
        status: APPLICATION_STATUS.new.toLowerCase(),
        ...data,
      },
    });
  }

  private get fieldsets () {
    const { FunderStore: { isFinanceCustomer }} = this.injected;

    return [[
      { field: 'requested_amount', required: true, type: 'money' },
      ...insertIf(isFinanceCustomer, { field: 'is_available_to_coop', label: 'Available To CoOp Capital', type: 'boolean', required: true }),
    ]];
  }

  public render () {
    const { UiStore } = this.injected;

    return (
      <FormModal
        fieldSets={this.fieldsets}
        title='New Application'
        onSave={this.onSubmit}
        onClose={UiStore.modals.NewPlaintiffApplication.close}
      />
    );
  }
}

export default NewPlaintiffApplicationModal;
