import React from 'react';
import { Button } from 'react-bootstrap';
import { SmartBool } from '../../utils';

interface IProps {
  isSubmitting: SmartBool;
  typeName?: string;
}

const RequestFooter = (props: IProps) => {
  const { isSubmitting, typeName } = props;

  return (
    <div className='footer'>
      <Button
        bsStyle='primary'
        bsSize='large'
        disabled={!isSubmitting}
        type='submit'
      >
        {isSubmitting.isTrue ? 'Requesting' : 'Request'} {typeName || ''}
      </Button>
      <span className='submit-info'>Recipients will receive<br />an email for this request.</span>
    </div>
  );
};

export default RequestFooter;
