import React, { Component } from 'react';
import { observable, toJS, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import Decimal from 'decimal.js';

import ReactTable from 'react-table';
import { Well } from 'react-bootstrap';

import { TableRenderingUtils } from '../../../utils';
import { sumMoney } from '../../../utils/util';

import {
  IAggregateSchedule,
} from '../../../interfaces';
import AppConstants from '../../../constants/AppConstants';
import CaseModel from '../../../models/CaseModel';
import { CaseStoreClass, FunderStoreClass } from '../../../stores';

const{
    renderDate,
    renderMoney,
  } = TableRenderingUtils
  , { FUNDING_TYPES } = AppConstants
  , COLUMNS = [
    {
      accessor: 'end_date',
      Cell: renderDate,
      Header: 'On or before',
      sortable: false,
    },
    {
      accessor: 'amount',
      Cell: renderMoney,
      Header: 'Current Contract Value',
      sortable: false,
    },
    {
      accessor: 'lienReturnsSum',
      Cell: renderMoney,
      Header: 'Returned Amount',
      sortable: false,
    },
    {
      accessor: 'amountMinusReturns',
      Cell: renderMoney,
      Header: 'Current Lien Balance',
      sortable: false,
    },
  ]
  , SCHEDULABLE = {
    funding_type: FUNDING_TYPES.PLAINTIFF,
    is_confirmed: true,
    is_open: true,
  };

interface IProps {
  _case: CaseModel;
  CaseStore: CaseStoreClass;
  FunderStore: FunderStoreClass;
}

@inject('CaseStore', 'FunderStore')
@autoBindMethods
@observer
class AggregateScheduleTable extends Component<IProps> {
  @observable private aggregateSchedule: IAggregateSchedule[] = [];
  @observable private isLoading = false;
  private lastModifiedAt = '';

  public componentDidMount () {
    this.componentWillReceiveProps(this.props);
  }

  public componentWillReceiveProps (nextProps: IProps) {
    if (this.shouldLoadSchedule(nextProps)) {
      this.loadSchedule();
    }
  }

  private shouldLoadSchedule (nextProps: IProps) {
    const { _case } = nextProps
      , schedulableLiens = _case.getLiens(SCHEDULABLE);

    if (!schedulableLiens.length) { return false; }

    const newModifiedAt = schedulableLiens.map(lien => lien.modified_at).sort().join('_')
      , isUnchanged = newModifiedAt === this.lastModifiedAt;

    if (isUnchanged) { return false; }

    this.lastModifiedAt = newModifiedAt;
    return true;
  }

  private async loadSchedule () {
    const { _case, CaseStore } = this.props;

    this.isLoading = true;
    this.aggregateSchedule = await CaseStore.fetchAggregateSchedule(_case.id);
    this.isLoading = false;
  }

  private get showAggregateSchedule () {
    const hasSchedulableLiens = this.props._case.hasLiens(SCHEDULABLE);
    return hasSchedulableLiens && (this.isLoading || !!this.aggregateSchedule.length);
  }

  @computed
  private get lienReturnsSum () {
    return sumMoney(this.props._case
      .getLiens(SCHEDULABLE)
      .map(lien => lien.returnAmountSum));
  }

  public render () {
    if (!this.showAggregateSchedule) { return null; }

    const { FunderStore: { useExperimentalContracts } } = this.props;

    const aggregateSchedule = this.aggregateSchedule.map((schedule: IAggregateSchedule) => {
      if (useExperimentalContracts) {
        return {
          amount: new Decimal(schedule.amount).plus(new Decimal(this.lienReturnsSum)),
          amountMinusReturns: schedule.amount,
          end_date: schedule.end_date,
          lienReturnsSum: this.lienReturnsSum,
        };
      } else {
        return {
          amount: schedule.amount,
          amountMinusReturns: new Decimal(schedule.amount).minus(new Decimal(this.lienReturnsSum)),
          end_date: schedule.end_date,
          lienReturnsSum: this.lienReturnsSum,
        };
      }
    });

    return (
      <Well className='aggregate-schedule-well well-table'>
        {this.isLoading
          ? <h3>Loading Aggregate Schedule...</h3>
          : (
              <div>
                <h3>Payoff for the next {this.aggregateSchedule.length} periods</h3>
                <ReactTable
                  columns={COLUMNS}
                  data={toJS(aggregateSchedule)}
                  minRows={0}
                  resizable={false}
                  showPagination={false}
                />
              </div>
          )
        }
      </Well>
    );
  }
}

export default AggregateScheduleTable;
