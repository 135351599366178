import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { kebabCase, get, map } from 'lodash';

import FacetHeader from './FacetHeader';
import FacetListItem from './FacetListItem';

import { IFacetOption } from '../../interfaces';

interface IProps {
  facetOptionGroups?: { [key: string]: IFacetOption[] };
  fieldName: string;
  label: string;
  onChange: (value: string, checked: boolean) => void;
  reset: (fieldName: string) => void;
  resetDisabled?: boolean;
  sidebarCounts?: object;
}

@autoBindMethods
@observer
class FacetListGroups extends Component<IProps, {}> {
  public render () {
    const {
      facetOptionGroups,
      fieldName,
      label,
      onChange,
      reset,
      resetDisabled,
      sidebarCounts,
    } = this.props;

    return (
      <div className='facet facet-group'>
        <FacetHeader
          fieldName={fieldName}
          hasValue={!resetDisabled}
          label={label}
          reset={reset}
        />
        {map(facetOptionGroups, (options: any, category: any) => {
          return (
            <div className='facet-body' key={category}>
              <h4>{category}</h4>
              <ul className='list-checkbox condensed-checkbox'>
                {map(options, (option: IFacetOption) => {
                  return (
                    <FacetListItem
                      checked={option.checked}
                      count={get(sidebarCounts, option.value, 0)}
                      countIsLoading={sidebarCounts === undefined}
                      key={option.value}
                      label={option.name}
                      onChange={onChange}
                      uniqueKey={`${kebabCase(category)}-${option.value}`}
                      value={option.value}
                    />
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }
}

export default FacetListGroups;
