import React, { Component, Fragment } from 'react';
import { inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { LinkContainer } from 'react-router-bootstrap';

import {
  MenuItem,
  Nav,
  Navbar,
  NavDropdown,
} from 'react-bootstrap';

import { HelpPageLink } from './PageLinks';
import { Icon } from '../common';
import { SearchBar, SearchNavItem } from './search';
import SettingsNavDropdown from './SettingsNavDropdown';
import CaseTrackingNavDropdown from './CaseTrackingNavDropdown';

export interface IProps {
  onLogoutClick: () => void;
}

@inject('CaseUpdateStore', 'UiStore')
@autoBindMethods
class FreeUserNavbar extends Component <IProps> {
  public render () {
    return (
      <Fragment>
        <Nav className='navbar-menu'>
          <CaseTrackingNavDropdown />
        </Nav>

        <Nav className='navbar-menu' pullRight>
          <SearchNavItem className='visible-sm' />
          <HelpPageLink />
          <SettingsNavDropdown onLogoutClick={this.props.onLogoutClick} />
        </Nav>

        <Navbar.Form className='hidden-xs hidden-sm' pullRight>
          <SearchBar />
        </Navbar.Form>

        <Nav pullRight>
          <NavDropdown id='btn-navbar-add' className='hidden-xs' title={<Icon type='plus' />} pullRight>
            <LinkContainer to={{pathname: '/case-update/request'}}>
              <MenuItem eventKey='request'>Request a Case Update</MenuItem>
            </LinkContainer>
            <MenuItem divider />
            <LinkContainer to={{pathname: '/case-update/add'}}>
              <MenuItem eventKey='add'>Add a Case Update</MenuItem>
            </LinkContainer>
            <MenuItem divider />
            <LinkContainer to={{pathname: '/new-case'}}>
              <MenuItem eventKey='add-new-case'>Add a New Case</MenuItem>
            </LinkContainer>
            <MenuItem divider />
            <LinkContainer to={{pathname: '/request-documents'}}>
              <MenuItem eventKey='request-documents'>Request Documents</MenuItem>
            </LinkContainer>
          </NavDropdown>
        </Nav>
      </Fragment>
    );
  }
}

export default FreeUserNavbar;
