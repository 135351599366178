import React, { Component } from 'react';
import { isNumber, isNaN } from 'lodash';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { observer } from 'mobx-react';

interface IProps {
  checked: boolean;
  count?: number;
  countIsLoading: boolean;
  label: string;
  onChange: (value: string) => void;
  uniqueKey: string;
  value: string;
}

@autoBindMethods
@observer
class FacetRadioItem extends Component<IProps> {
  private onChange () {
    const { value, onChange } = this.props;
    onChange(value);
  }

  public render () {
    const { checked, count, countIsLoading, label, uniqueKey, value } = this.props
      , isDisabled = !checked && (count === 0 || isNaN(count))
      , classNames = cx({ disabled: isDisabled});

    return (
      <li className={classNames} key={uniqueKey}>
        <input
          checked={checked}
          disabled={isDisabled}
          id={uniqueKey}
          onChange={this.onChange}
          type='radio'
          value={value}
        />
        <label htmlFor={uniqueKey}>{label}</label>
        {isNumber(count) &&
          <span className='count'>({countIsLoading ? '...' : count || 0})</span>}
      </li>
    );
  }
}

export default FacetRadioItem;
