import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { noop, debounce } from 'lodash';
import cx from 'classnames';

import {
  Button,
  Dropdown,
  FormGroup,
  HelpBlock,
  InputGroup,
  MenuItem,
} from 'react-bootstrap';

import FormattingUtils from '../../utils/FormattingUtils';
import { AppConstants } from '../../constants';
import { createInputContainer } from '../../containers';

import Icon from '../common/Icon';
import { IFormInput, IInputContainer, Value } from '../../interfaces';
import { OptionsStoreClass } from '../../stores';

const { truncate } = FormattingUtils;

const {
  DEBOUNCE_DELAY,
  TRUNCATE_OBJECT_SEARCH,
} = AppConstants;

const MenuHeader = (props: any) => <li {...props} className='dropdown-header' />;

interface IProps extends IFormInput {
  addNew: (search: string) => void;
  addNewSearchMinimum?: number;
  endpoint?: string;
  getOptions?: (search: string) => Array<{ id: string }>;
  OptionsStore?: OptionsStoreClass;
  render: (value: any) => string;
  setRef?: (ref: any) => void;
}

interface IPropDefaults extends IProps {
  addNewSearchMinimum: number;
  onChange: (value: Value) => void;
  OptionsStore: OptionsStoreClass;
  setRef: (ref: any) => void;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@inject('OptionsStore')
@autoBindMethods
@observer
class ObjectSearchCreate extends Component<IProps, {}> {
  @observable private isLoading = false;
  @observable private open = false;
  @observable private options: Array<{ id: string }> = [];
  @observable private search = '';
  @observable private value: Value = null;

  private debouncedGetOptions: any;
  private refDropdown: any;
  private refsSearch: any;

  public static defaultProps: Partial<IProps> = {
    addNewSearchMinimum: 0,
    disabled: false,
    required: false,
    setRef: noop,
  };

  get formsy () {
    return this.props as IPropsFormsy;
  }

  constructor (props: IPropsFormsy) {
    super(props);
    this.value = props.value || null;
    this.debouncedGetOptions = debounce(this.getOptions, DEBOUNCE_DELAY);
    props.OptionsStore.registerDebouncer(this.debouncedGetOptions);

    this.formsy.setRef(this);
  }

  public componentWillUnmount () {
    this.formsy.OptionsStore.removeDebouncer(this.debouncedGetOptions);
  }

  public componentWillReceiveProps (nextProps: IPropsFormsy) {
    if (nextProps.value === '') { this.value = null; }
  }

  public componentDidUpdate () {
    if (this.refsSearch) { this.refsSearch.focus(); }
  }

  private async onSearchChange (e: any) {
    this.search = e.target.value;
    this.isLoading = true;
    this.debouncedGetOptions();
  }

  private async getOptions () {
    this.isLoading = true;
    const {
      endpoint,
      getOptions,
      OptionsStore,
    } = this.formsy;

    if (getOptions) {
      this.options = await getOptions(this.search);
      this.isLoading = false;
      return;
    }

    if (this.search === '') {
      this.options = [];
      this.isLoading = false;
      return;
    }

    if (endpoint) {
      this.options = await OptionsStore.search(endpoint, this.search);
    }

    this.isLoading = false;
  }

  private handleToggle (open: boolean, _target?: any, data?: { source?: string }) {
    if (data && data.source !== 'select') {
      this.open = open;

      if (this.open && this.props.getOptions) {
        this.getOptions();
      }
    }
  }

  private onLabelClick (event: any) {
    if (!this.open && !this.formsy.isDisabled) {
      this.handleToggle(true, event.target, {});
    }
  }

  private onSelect (valueArg: any) {
    const value: Value = valueArg; // Bootstrap.MenuItem callback is changed by eventKey prop
    this.open = false;
    this.value = value;
    this.options = [];
    this.search = '';

    this.formsy.onChange(value);
  }

  // istanbul ignore next
  private dontClose (e: any) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  private onClear () {
    this.onSelect('');
  }

  private addNew () {
    this.props.addNew(this.search);
  }

  private setSearchRef (component: any) {
    this.refsSearch = component;
  }

  private setRefDropdown (component: any) {
    this.refDropdown = component;
  }

  public async openDropdown () {
    debounce(() => {
      if (this.refDropdown) {
        this.refDropdown.getControlledInstance().handleClick(true, {});
      }
    }, DEBOUNCE_DELAY)();
  }

  private get noResultsFound () {
    return !this.isLoading && !!this.search.length && !this.options.length;
  }

  private get addNewDisabled () {
    return this.isLoading || this.search.length < this.formsy.addNewSearchMinimum;
  }

  private renderDropdown () {
    const {
      controlId,
      errorMessage,
      render,
      isDisabled,
      label,
      showErrorMessage,
      showRequiredMessage,
      validationState,
    } = this.formsy;

    return (
      <FormGroup validationState={validationState}>
        <Dropdown
          disabled={isDisabled}
          id={`dropdown-${controlId}`}
          onBlur={noop}
          onToggle={this.handleToggle}
          open={this.open}
          ref={this.setRefDropdown}
        >
          <Dropdown.Toggle className='dropdown-search-create-toggle'>
            {this.value && truncate(render(this.value), TRUNCATE_OBJECT_SEARCH)}
          </Dropdown.Toggle>

          <div className='search'>
            <input
              className={`dropdown-search-${controlId}`}
              id={`dropdown-search-${controlId}`}
              name={`search_${controlId}`}
              onChange={this.onSearchChange}
              onClick={this.dontClose}
              ref={this.setSearchRef}
              value={this.search}
            />

            <Button
              bsStyle='link'
              className='btn-add ellipsis'
              disabled={this.addNewDisabled}
              id={`btn-add-${controlId}`}
              onClick={this.addNew}
            >
              <Icon type='plus-square-o' />
              New {truncate(label)}
            </Button>
          </div>
          <Dropdown.Menu>
            {this.isLoading && (
              <MenuHeader>
                Loading...
              </MenuHeader>
            )}

            {this.noResultsFound && (
              <MenuHeader>
                No results found
              </MenuHeader>
            )}

            {this.options.map(option => (
              <MenuItem
                disabled={this.isLoading}
                eventKey={option}
                key={option.id}
                onSelect={this.onSelect}
              >
                {render(option)}
              </MenuItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {showRequiredMessage &&
          <HelpBlock>{label || 'This field'} is required.</HelpBlock>}

        {showErrorMessage &&
          <HelpBlock>{errorMessage}</HelpBlock>}
      </FormGroup>
    );
  }

  public render () {
    const {
        controlId,
        formGroupId,
        isDisabled,
        label,
        required,
      } = this.formsy
      , className = cx(
        'form-group-dropdown',
        { disabled: isDisabled },
        'dropdown-search-create',
        { focused: this.open },
        { 'has-value': !!this.value },
      );

    return (
      <FormGroup id={formGroupId} controlId={`dropdown-search-${controlId}`} className={className}>
        {!!this.value &&
          <Button bsStyle='link' className='btn-clear' disabled={isDisabled} onClick={this.onClear}>
            <Icon type='times-circle' />
          </Button>
        }

        {label &&
          <label htmlFor={`dropdown-search-${controlId}`} onClick={this.onLabelClick}>
            {label}
            {required &&
              <span className='required'>*</span>}
          </label>
        }

        <InputGroup>
          {this.renderDropdown()}
        </InputGroup>
      </FormGroup>
    );
  }
}

export default ObjectSearchCreate;
