function createField (optionType: string): any {
  return {
    field: 'configuration',
    label: 'Template',
    optionType,
    required: true,
    type: 'optionSelect',
  };
}

const LABEL = { required: true, field: 'label' }
, INPUT_FILE = { required: true, field: 'input_file', type: 'file' }
, BULK_ADD_CONFIG = createField('importConfigurations')
, BULK_EDIT_CONFIG = createField('bulkEditConfigurations')
;

export const CREATE_IMPORT = [[LABEL, BULK_ADD_CONFIG]]
  , CREATE_BULK_EDIT = [[LABEL, BULK_EDIT_CONFIG]]
  , UPDATE_IMPORT = [[LABEL, INPUT_FILE]]
  , RESTART_IMPORT = [[INPUT_FILE]]
  ;
