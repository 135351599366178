import React from 'react';
import { Button } from 'react-bootstrap';

import { Icon } from '../components/common';

interface IProps {
  title: string;
  children: React.ReactNode;
  sectionOpened: boolean;
  handleToggle: () => void;
}

const Collapsible = (props: IProps) => {
  const { title, children, sectionOpened, handleToggle } = props
    , toggleIconType = sectionOpened ? 'angle-double-up' : 'angle-double-down'
    ;

  return (
    <div>
      <div className='collapsible'>
        <span className='collapsible-title'>{title}</span>
        <Button bsStyle='link' onClick={handleToggle} >
          <Icon type={toggleIconType} />
        </Button>
      </div>
      {sectionOpened && <div className='content'>{children}</div>}
    </div>
  );
};

export default Collapsible;
