import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { stubFalse } from 'lodash';

import { fillInFieldConfig, getCreateFields, getFieldSetFields, isFieldSetSimple } from './common';

import FormField from './FormField';
import NestedFormGroup from './NestedFormGroup';
import { IFieldConfig, IFieldSet } from '../../interfaces';
import { IFieldConfigObjectSearchCreate } from './interfaces';

interface IProps {
  addNew: (fieldConfig: IFieldConfig, search: string) => void;
  addNewUndo: (fieldConfig: IFieldConfig) => void;
  className?: any;
  fieldSet: IFieldSet;
  model: object;
  onChange: { [key: string]: (value: any) => void };
  showNew: (field: string) => boolean;
}

@autoBindMethods
@observer
class FormFields extends Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    showNew: stubFalse,
  };

  public render () {
    const {
        addNew,
        addNewUndo,
        className,
        fieldSet,
        model,
        onChange,
        showNew,
      } = this.props
      , fieldConfigs = getFieldSetFields(fieldSet).map(fillInFieldConfig)
      , legend = !isFieldSetSimple(fieldSet) && fieldSet.legend;

    return (
      <div className={className}>
        {legend && <h3>{legend}</h3>}

        {fieldConfigs.map(fieldConfig => {
          if (fieldConfig.readOnly) { return null; }

          const fieldProps = { addNew, addNewUndo, model };

          // For the object search field, users can choose to create their own objects
          // If so, show those fields instead of the original object search
          if (showNew(fieldConfig.field)) {
            return (
              <FormField
                {...fieldProps}
                fieldConfig={{
                  editComponent: NestedFormGroup,
                  editProps: {
                    fields: getCreateFields(fieldConfig as IFieldConfigObjectSearchCreate),
                  },
                  field: fieldConfig.field,
                }}
                addNewUndoFieldConfig={fieldConfig}
                addNewUndoShow
                key={fieldConfig.field}
                onChange={onChange[fieldConfig.field]}
              />
            );
          }

          // Normal field rendering
          return (
            <FormField
              {...fieldProps}
              fieldConfig={fieldConfig}
              key={fieldConfig.field}
              onChange={onChange[fieldConfig.field]}
            />
          );
        })}
      </div>
    );
  }
}

export default FormFields;
