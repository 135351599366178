import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import {
  Col,
  Row,
} from 'react-bootstrap';

import TaskCompletedModal from './TaskCompletedModal';
import { Spacer } from '../common';
import { SmartBool, FormattingUtils } from '../../utils';

import AppConstants from '../../constants/AppConstants';

const { USER_FLOW } = AppConstants;

const {
  formatCaseLabelList,
  formatDate,
  formatDateTime,
  getOrDefault,
  parseCaseNote,
} = FormattingUtils;

interface ITask {
  completed_at?: string;
  plaintiff_name: string;
  response_email: string;
  tracking_update: {
    body: string,
    medical_status: string,
    tracking_attributes: string[],
    tracking_follow_up_date?: string,
    tracking_status: string,
  };
}

interface IProps {
  task: ITask;
}

@autoBindMethods
@observer
class CaseUpdateTaskDetail extends Component <IProps> {
  @observable private showCompletedMessage = new SmartBool();

  public componentDidMount () {
    const location = browserHistory.getCurrentLocation();
    if (location.state === USER_FLOW.FROM_COMPLETED_TASK_RESPONSE) {
      this.showCompletedMessage.setTrue();
    }
  }

  private renderUpdateFrom (responseEmail: string) {
    if (!responseEmail) { return null; }

    return (
      <div>
        <h3>Case Update From</h3>
        <p>{getOrDefault(responseEmail)}</p>
        <Spacer />
      </div>
    );
  }

  private renderTrackingStatus (trackingStatus: string) {
    const text = !trackingStatus
      ? 'There is no case status update at this time'
      : <span>Case has moved forward to <b>{formatCaseLabelList([trackingStatus])}</b>.</span>;

    return (
      <div>
        <p>{text}</p>
        <Spacer />
      </div>
    );
  }

  private renderMedicalStatus (medicalStatus: string) {
    if (!medicalStatus) { return null; }
    return (
      <div>
        <h3>Medical status</h3>
        <p>{formatCaseLabelList([medicalStatus])}</p>
        <Spacer />
      </div>
    );
  }

  private renderTrackingAttributes (trackingAttributes: string[]) {
    if (isEmpty(trackingAttributes)) { return null; }
    return (
      <div>
        <h3>Plaintiff Attributes</h3>
        <p>{formatCaseLabelList(trackingAttributes)}</p>
        <Spacer />
      </div>
    );
  }

  public render () {
    const {
      completed_at,
      response_email,
      tracking_update: {
        body,
        medical_status,
        tracking_attributes,
        tracking_follow_up_date,
        tracking_status,
      },
    } = this.props.task;

    return (
      <Row>
        <Col xs={10} sm={8} lg={6}>
          {this.renderUpdateFrom(response_email)}
          <h3>Latest Update -- {formatDateTime(completed_at)}</h3>
          {this.renderTrackingStatus(tracking_status)}
          {this.renderMedicalStatus(medical_status)}
          {this.renderTrackingAttributes(tracking_attributes)}

          <hr/>

          {body &&
            <div>
              <div className='pad-y-half'>
                <h3>Note</h3>
                <blockquote className='styled'>{parseCaseNote(body)}</blockquote>
              </div>

              <hr/>
            </div>
          }

          {tracking_follow_up_date &&
            <div>
              <h3>Next Update</h3>
              <div>{formatDate(tracking_follow_up_date)}</div>
            </div>
          }
        </Col>
        <TaskCompletedModal
          showCompletedMessage={this.showCompletedMessage}
        />
      </Row>
    );
  }
}

export default CaseUpdateTaskDetail;
