import React from 'react';
import ReactTooltip from 'react-tooltip';

import FormattingUtils from '../../utils/FormattingUtils';
import { IAddress } from '../../interfaces';

const { getNameOrDefault, formatAddress, pluralize } = FormattingUtils;

export function renderLawfirmOption (lawfirm: {name: string, address: IAddress}) {
  return (
    <div className='law-firm-item'>
      <p><strong>{getNameOrDefault(lawfirm)}</strong></p>
      <p>{formatAddress(lawfirm.address)}</p>
    </div>
  );
}

export function renderAttorneyOption (attorney: any) {
  return (
    <div className='search-result-item'>
      <p><strong>{attorney.first_name} {attorney.last_name}</strong></p>
      <p>{attorney.phone_number}, {attorney.email}
        <span className='pull-right'>{pluralize('case', attorney.cases_count, true)}</span>
      </p>
    </div>
  );
}

export function renderWarningTooltip (message: string, id: string) {
  return (
    <ReactTooltip id={id} type='info' effect='solid' place='right' offset={{ right: 2, top: 1 }}>
      <span>{message}</span>
    </ReactTooltip>
  );
}
