import React from 'react';
import { inject } from 'mobx-react';

import NavDropdown from './NavDropdown';
import { GuardedCaseTrackingPageLink, TasksPageLink, UpdatesPageLink } from './PageLinks';
import { FunderStoreClass, SessionStoreClass } from '../../stores';

interface IInjected {
  SessionStore: SessionStoreClass;
  FunderStore: FunderStoreClass;
}

const CaseTrackingNavDropdown: React.SFC = props => {
  const { SessionStore, FunderStore } = props as IInjected
    , title = SessionStore.isGuest ? 'Tasks' : 'Case Tracking';

  if (!FunderStore.canUseCommunicationTools) {
    return <GuardedCaseTrackingPageLink />;
  }

  return (
    <NavDropdown id='case-tracking-dropdown' title={title}>
      <GuardedCaseTrackingPageLink />
      <TasksPageLink query={null} />
      <UpdatesPageLink query={null} />
    </NavDropdown>
  );
};

export default inject('SessionStore', 'FunderStore')(CaseTrackingNavDropdown);
