import React, { Component } from 'react';
import { inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Button, ButtonToolbar } from 'react-bootstrap';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';

import { createGuardedContainer } from '../../containers';
import { AppConstants } from '../../constants';
import { SessionStoreClass } from '../../stores';

const { PERMISSIONS, ROUTING } = AppConstants;

interface IProps {
  title: string;
}

interface IInjected extends IProps {
  SessionStore: SessionStoreClass;
}

const BasePageLink = ({ pathname, label }: { pathname: any, label: string }) =>
  (
    <LinkContainer to={{ pathname }}>
      <Button bsSize='small'>{label}</Button>
    </LinkContainer>
  )
  , ImportsPageLink = (props: any) => <BasePageLink {...props} label='Bulk Add' pathname={ROUTING.imports} />
  , DedupePageLink = (props: any) => <BasePageLink {...props} label='Dedupe Tool' pathname={ROUTING.deduplication} />
  , ExportsPageLink = (props: any) => <BasePageLink {...props} label='Export' pathname={ROUTING.exports} />
  , BulkEditPageLink = (props: any) => <BasePageLink {...props} label='Bulk Edit' pathname={ROUTING.bulkEdit} />
  , GuardedImportsPageLink = createGuardedContainer({
    enabledComponent: ImportsPageLink,
    permissionName: PERMISSIONS.ADD_SELF_IMPORT,
    shouldAlwaysUpdate: true,
  })
  , GuardedDedupePageLink = createGuardedContainer({
    enabledComponent: DedupePageLink,
    permissionName: PERMISSIONS.CHANGE_DEDUPE,
    shouldAlwaysUpdate: true,
  })
  , GuardedExportsPageLink = createGuardedContainer({
    enabledComponent: ExportsPageLink,
    permissionName: PERMISSIONS.ADD_FUNDER_DATA_EXPORT,
    shouldAlwaysUpdate: true,
  })
  , GuardedBulkEditPageLink = createGuardedContainer({
    enabledComponent: BulkEditPageLink,
    permissionName: PERMISSIONS.ADD_BULK_EDIT,
    shouldAlwaysUpdate: true,
  });

@inject('SessionStore')
@autoBindMethods
class DataAdminHeader extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get isNavigationVisible () {
    const { SessionStore } = this.injected;
    return [
      PERMISSIONS.ADD_SELF_IMPORT,
      PERMISSIONS.CHANGE_DEDUPE,
      PERMISSIONS.ADD_FUNDER_DATA_EXPORT,
      PERMISSIONS.ADD_BULK_EDIT,
    ].map(SessionStore.userHasPermission)
    .filter(bool => bool)
    .length > 1;
  }

  public render () {
    return (
      <React.Fragment>
        <header className='condensed flex-between'>
          <h1>Data Admin</h1>

          {this.isNavigationVisible && (
            <ButtonToolbar>
              <GuardedImportsPageLink />
              <GuardedBulkEditPageLink />
              <GuardedDedupePageLink />
              <GuardedExportsPageLink />
            </ButtonToolbar>
          )}
        </header>
        <div className='subheader'>
          <h2 style={{padding: 0}}>{this.props.title}</h2>
        </div>
      </React.Fragment>

    );

  }
}

export default DataAdminHeader;
