import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Button } from 'react-bootstrap';

import { Icon } from '../../common';
import { ICase, IDefendant, IDefendantInsurer } from '../../../interfaces';
import { DefendantInsurerStoreClass, InsurerStore } from '../../../stores';
import { SmartBool } from '../../../utils';

import AddDefendantInsurerModal from './AddDefendantInsurerModal';
import DefendantInsurersTable from './DefendantInsurersTable';

interface IProps {
  _case: ICase;
  defendant: IDefendant;
}

interface IInjected extends IProps {
  DefendantInsurerStore: DefendantInsurerStoreClass;
  InsurerStore: InsurerStore;
}

@inject('DefendantInsurerStore', 'InsurerStore')
@autoBindMethods
@observer
class DefendantInsurers extends Component<IProps> {
  @observable public isAddModalShowing: SmartBool = new SmartBool();
  @observable public currentDefendantInsurer: IDefendantInsurer | null = null;

  private get injected () {
    return this.props as IInjected;
  }

  public async componentDidMount () {
    const { DefendantInsurerStore } = this.injected;
    await DefendantInsurerStore.fetch(this.props._case.id);
  }

  private onEdit (data: IDefendantInsurer) {
    /*
      TODO: This is currently necessary because the structure of the post data doesn't match the structure of the fieldsets for the modal,
      in which 'name' is a nested property of 'insurer'. Thus, the insurer name isn't found and populated in the edit modal without
      manipulating the data in this way. A future fix would be welcome.
    */
    data.insurer = {
      id: data.insurer,
      name: data.name,
    };

    this.currentDefendantInsurer = data;
    this.isAddModalShowing.setTrue();
  }

  private onClose () {
    this.isAddModalShowing.setFalse();
    this.currentDefendantInsurer = null;
  }

  private async onSave (data: {[key: string]: any}) {
    const { DefendantInsurerStore } = this.injected;

    if (!data.insurer.id) {
      const insurer = await this.injected.InsurerStore.upsert(
        this.props._case,
        data.insurer,
        { type: 'defendant' },
      );

      data.insurer = insurer.id;
    }
    else {
      data.insurer = data.insurer.id;
    }

    data.defendant = this.props.defendant.id;
    await DefendantInsurerStore.upsert(data);
  }

  public render () {
    const { DefendantInsurerStore } = this.injected;

    return (
      <div>
        <h3>
          Defendant Insurance Info
          <div className='pull-right'>
            <Button bsStyle='link' className='btn-add' onClick={this.isAddModalShowing.setTrue}>
                <Icon type='plus-square-o' /> Add new insurance provider
            </Button>
          </div>
        </h3>
        <DefendantInsurersTable
          defendantInsurers={DefendantInsurerStore.defendantInsurers}
          onDelete={DefendantInsurerStore.delete}
          onEdit={this.onEdit}
        />
        {this.isAddModalShowing.isTrue && (
          <AddDefendantInsurerModal
            defendantInsurer={this.currentDefendantInsurer}
            onClose={this.onClose}
            onSave={this.onSave}
          />
        )}
      </div>
    );
  }
}

export default DefendantInsurers;
