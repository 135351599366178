import React, { useCallback, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Button } from 'react-bootstrap';

import AppConstants from '../../../constants/AppConstants';
import { Icon } from '../../common';
import BulkAddFieldsModal from './BulkAddFieldsModal';

const { SUPPORT_EMAIL } = AppConstants;

interface IBulkInstructions {
  isBulkEdit: boolean;
}

interface IInstructionItem {
  body: React.ReactNode;
  tooltip?: string;
}

interface IBulkInstruction {
  instructions: IInstructionItem[];
  title: string;
  note?: string;
}

function BulkInstructions ({ isBulkEdit }: IBulkInstructions) {
  const [modalShow, setModalShow] = useState(false)
    , handleCloseModal = useCallback(() => {
      setModalShow(false);
    }, []);

  function handleOpenModal (event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    setModalShow(true);
    event.currentTarget.blur();
  }

  const BULK_EDIT_INSTRUCTIONS: IBulkInstruction[] = [
    {
      instructions: [
        { body: 'Choose whether you want to bulk edit Cases or Liens.' },
        { body: 'Select the specific data fields you want to edit.' },
        { body: 'Click the "Download Template" button to generate a CSV file with your selected columns.' },
      ],
      title: 'Step 1: Set up the Template',
    },
    {
      instructions: [
        {
          body: 'You can retrieve existing Case or Lien IDs using the Export feature in the “Reports” tab.',
          tooltip: `If the "Reports" tab is not visible to you, please have an admin contact ${SUPPORT_EMAIL} to have your permissions updated.`,
        },
        { body: 'Populate the CSV file with the updates you want to make. Please make sure to include information for every selected column.' },
      ],
      note: 'Leaving any column entries empty will overwrite existing data with an empty value for that case or lien. Make sure to fill in the relevant information accurately.',
      title: 'Step 2: Prepare the Updates',
    },
    {
      instructions: [
        { body: 'Once your updates are ready, click "+ New Import" and attach the appropriate CSV file.' },
        { body: 'Review the preview carefully to confirm that it reflects your intended edits.' },
        { body: 'Once the import is confirmed, case or lien details will be updated according to your CSV file and a case note will be added to the activity feed.' },
      ],
      title: 'Step 3: Upload and Review Changes',
    },
  ]
    , BULK_ADD_INSTRUCTIONS: IBulkInstruction[] = [
      {
        instructions: [
          {
            body: (
              <span>
                A pre-configured template is required for the bulk add process. If you do not have a template set up yet, please contact{' '}
                <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> to assist you. A list of all fields that can be included in your{' '}
                templates is available <a href='#' onClick={handleOpenModal}>here</a>.
              </span>
            ),
          },
        ],
        title: 'Step 1: Set up the Template',
      },
      {
        instructions: [
          { body: 'You can view your available templates by clicking on "New Import".'},
          { body: 'Select the desired template from the dropdown menu and click “Download Template” to get a CSV file formatted with the relevant columns.' },
          { body: 'Fill in the CSV file with your new data. Ensure all information is accurate and corresponds to the template\'s fields.' },
        ],
        title: 'Step 2: Prepare the Updates',
      },
      {
        instructions: [
          { body: 'Attach your prepared CSV file and click "Save" to initiate the import process.' },
          { body: 'A preview of your import will be displayed. Review this carefully to ensure it accurately reflects the data you intend to import.' },
          { body: 'Once the import is approved, data will be added according to your CSV file and a note of the import will be added to the activity feed of each case.' },
        ],
        title: 'Step 3: Upload and Review Changes',
      },
    ]
    , instructions = isBulkEdit ? BULK_EDIT_INSTRUCTIONS : BULK_ADD_INSTRUCTIONS;

  return (
    <div>
      {modalShow && <BulkAddFieldsModal onClose={handleCloseModal} />}
      {instructions.map((step: IBulkInstruction) => {
        return (
          <div key={step.title} style={{marginBottom: '25px'}}>
            <h3>{step.title}</h3>
            <ul className='bulk-instructions-list'>
              {step.instructions.map((instruction: IInstructionItem, idx: number) => (
                <li id={`${idx}`} key={idx}>
                  {instruction.body}
                  {instruction.tooltip && (
                    <span className='instruction-tooltip'>
                      <Button bsStyle='link' data-tip data-for={`instruction-${idx}`}>
                        <Icon type='question-circle-o' />
                      </Button>
                      <ReactTooltip id={`instruction-${idx}`} effect='solid' place='right' class='full-width-tooltip'>
                        <span>{instruction.tooltip}</span>
                      </ReactTooltip>
                    </span>
                  )}
                </li>
              ))}
            </ul>
            {step.note && (
              <p className='callout-note'>
                <Icon type='exclamation-triangle' />{' '}
                <b>Note:</b> {step.note}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default BulkInstructions;
