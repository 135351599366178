import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Button } from 'react-bootstrap';
import { Icon } from '../common';

interface IProps {
  onClick: () => void | any;
}

@autoBindMethods
@observer
class FacetResetAll extends Component<IProps, {}> {
  public render () {
    const { onClick } = this.props;

    return (
      <Button className='reset-all' bsStyle='link' onClick={onClick}>
        <Icon type='undo' /> Reset All Filters
      </Button>
    );
  }
}

export default FacetResetAll;
