import React, { Component, Fragment } from 'react';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { isEmpty, kebabCase } from 'lodash';

import {
  Dropdown,
  MenuItem,
  Well,
} from 'react-bootstrap';

import SmartBool from '../../utils/SmartBool';

import {
  EmptyStateButton,
  Icon,
} from '../../components/common';

import Loader from '../../components/common/Loader';

import {
  fillInFieldSets,
} from './common';

import Card from './Card';
import FormModal from './FormModal';
import { IFieldSetPartial } from './interfaces';

interface IProps {
  fieldSets: IFieldSetPartial[];
  isLoading?: boolean;
  model?: object;
  onSave: (model: object) => object;
  title: string;
  wrapperName: string;
  writeFields: IFieldSetPartial[];
}

interface IPropDefaults extends IProps {
  model: object;
}

@autoBindMethods
@observer
class CRUDCard extends Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    model: {},
  };

  @observable public isEditing = new SmartBool();
  @observable public isSearching = new SmartBool();

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  @computed
  get fieldSets () {
    if (this.isEmpty) { return []; }
    return fillInFieldSets(this.props.fieldSets);
  }

  get isEmpty () {
    return isEmpty(this.props.model);
  }

  private async onEditSave (data: object) {
    await this.props.onSave(data);
  }

  private async onCreateSave (data: { [key: string]: any }) {
    const { wrapperName, onSave } = this.props
      , filteredData = data[wrapperName];
    await onSave({[wrapperName]: filteredData[wrapperName]});
  }

  private async onRemove () {
    const { wrapperName, onSave } = this.props;
    await onSave({[wrapperName]: null});
  }

  private renderEdit () {
    const { title } = this.props
      , id = `well-${kebabCase(title)}`;

    return (
      <Dropdown className='pull-right' id={`edit-btn-${id}`} pullRight>
        <Dropdown.Toggle bsStyle='link' noCaret={true} className='btn-link btn-edit pull-right'>
          <Icon type='pencil' />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem onSelect={this.isEditing.setTrue}>Edit this {title}&#39;s info</MenuItem>
          <MenuItem onSelect={this.isSearching.setTrue}>Change {title}</MenuItem>
          <MenuItem onSelect={this.onRemove}>Remove {title}</MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  private renderTopRight () {
    if (this.isEmpty) { return null; }
    return this.renderEdit();
  }

  private renderChildren () {
    const {
        isLoading,
        title,
        writeFields,
      } = this.propsWithDefaults
      , UnTypedEmptyStateButton: any = EmptyStateButton;

    if (isLoading) {
      return (
        <Well>
          <h3>{title}</h3>
          <Loader className='bloc-loader' />
        </Well>
      );
    }

    return (
      <Fragment>
        {this.isEditing.isTrue &&
          <FormModal
            {...this.propsWithDefaults}
            fieldSets={writeFields}
            onClose={this.isEditing.setFalse}
            onSave={this.onEditSave}
          />
        }

        {this.isSearching.isTrue &&
          <FormModal
            {...this.propsWithDefaults}
            onClose={this.isSearching.setFalse}
            onSave={this.onCreateSave}
          />
        }

        {this.isEmpty && (
          <UnTypedEmptyStateButton
            emptyText={`Add ${title}`}
            onClick={this.isSearching.setTrue}
          />
        )}
      </Fragment>
    );
  }

  public render () {
    return (
      <Card
        {...this.props}
        fieldSets={this.fieldSets}
        renderTopRight={this.renderTopRight}
      >
        {this.renderChildren()}
      </Card>
    );
  }
}

export default CRUDCard;
