import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';

import { DropdownFilter } from '../../common';
import AppConstants from '../../../constants/AppConstants';

const { APPLICATION_MODELS } = AppConstants;

import { FunderStoreClass } from '../../../stores';

interface IProps {
  changeTableType: (change: object) => void;
  tableType: string;
}

interface IInjected extends IProps {
  FunderStore: FunderStoreClass;
}

@inject('FunderStore')
@observer
class ApplicationListTypeDropdown extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  @computed
  private get dropdownOptions () {
    return this.injected.FunderStore.applicationWorkflows
      .filter((workflow: any) => !!workflow.application_key)
      .map((workflow: any) => ({
        id: APPLICATION_MODELS[workflow.application_key].model,
        name: APPLICATION_MODELS[workflow.application_key].label,
      }));
  }

  public render () {
    const { tableType, changeTableType } = this.props;

    if (this.dropdownOptions.length < 2) { return null; }

    return (
      <div className='wrapper green-dropdowns'>
        <h3>Application Type:</h3>
        <DropdownFilter
          filterName='type'
          onFilterChange={changeTableType}
          options={this.dropdownOptions}
          selectedId={tableType}
          showNullOption={false}
        />
      </div>
    );
  }
}

export default ApplicationListTypeDropdown;
