import React, { Component, Fragment } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { browserHistory } from 'react-router';
import { action, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { map } from 'lodash';
import cx from 'classnames';

import {
  Alert,
  Button,
  HelpBlock,
  Row,
  Well,
} from 'react-bootstrap';

import { ActionButton, DocumentTable, DocumentUploadModal, Icon, Spacer } from '../common';
import { Form, Textarea } from '../common-formsy';

import { SmartBool, toast } from '../../utils';
import FormDataUtils from '../../utils/FormDataUtils';
import FormattingUtils from '../../utils/FormattingUtils';
import TableRenderingUtils from '../../utils/TableRenderingUtils';
import { OptionsStoreClass, SessionStoreClass } from '../../stores';
import Client from '../../clients/Client';
import ClientsClass from '../../clients/ClientsClass';

const {
  formatDateTime,
  parseCaseNote,
} = FormattingUtils;

const { renderFileType, renderUnformatted } = TableRenderingUtils;

interface ITask {
  case: string;
  created_at: string;
  document_types_requested: number[];
  id: string;
  plaintiff_name: string;
  recipient: string;
  requester: string;
  requester_name: string;
  requester_note?: string;
}

interface IProps {
  client: Client;
  task: ITask;
}

interface IInjected extends IProps {
  Clients: ClientsClass;
  OptionsStore: OptionsStoreClass;
  SessionStore: SessionStoreClass;
}

@inject(
  'Clients',
  'OptionsStore',
  'SessionStore',
)
@autoBindMethods
@observer
class DocumentSharingTaskResponse extends Component <IProps> {
  @observable private isSaving: boolean = false;
  @observable private isUploading: boolean = false;
  @observable private uploadedDocuments: any[] = [];
  @observable private responderNoteValue: any = '';
  @observable private isUploadModalShowing: SmartBool = new SmartBool(false);
  @observable private currentDocument: any = null;
  @observable private showRequiredError = false;

  private get injected () {
    return this.props as IInjected;
  }

  private get documentsColumns () {
    return [
      {
        accessor: 'name',
        Cell: renderFileType,
        className: 'col-file',
        Header: 'File Type',
        maxWidth: 25,
      },
      {
        accessor: 'name',
        Cell: renderUnformatted,
        Header: 'Name',
        maxWidth: 260,
        minWidth: 200,
      },
      {
        accessor: 'type',
        Cell: (row: any) => (
          <Fragment>
            <span className='ellipsis'>{this.getDocumentNameFromId(row.value)}</span>
            <ActionButton bsStyle='link' className='btn-edit-type' data={row.original} onClick={this.onEditDocument}>
              <Icon type='pencil' />{!row.value && 'Add Type'}
            </ActionButton>
          </Fragment>
        ),
        className: 'col-style-gray flex-right',
        Header: 'Document Type',
        minWidth: 100,
      },
    ];
  }

  private getDocumentNameFromId (id: number) {
    const { OptionsStore } = this.injected
      , documentType = OptionsStore.documentTypeOptions.find((type: any) => type.id === id);

    return documentType && documentType.name;
  }

  @action
  private onEditDocument (document: any) {
    this.currentDocument = document;
    this.isUploadModalShowing.setTrue();
  }

  private async onDrop (files: File[]) {
    const { Clients, SessionStore } = this.injected
      , newDocuments: any[] = [];

    this.isUploading = true;

    try {
      for (const file of files) {
        const formData = FormDataUtils.toForm({ file, name: file.name });
        const newDocument = await Clients.documentsUploaded.create(formData);
        newDocuments.push(newDocument);
      }

      this.uploadedDocuments = [...this.uploadedDocuments, ...newDocuments];
      this.showRequiredError = false;
      SessionStore.trackEvent('BULK_REQUESTED_CASE_DOCUMENT_UPLOAD', { file_count: files.length });
    }
    catch (e) {
      SessionStore.trackEvent('BULK_REQUESTED_CASE_DOCUMENT_UPLOAD_FAILURE', { file_count: files.length });
    }
    finally {
      this.isUploading = false;
    }
  }

  private onResponderNoteChange (value: any) {
    this.responderNoteValue = value || '';
    if (value) {
      this.showRequiredError = false;
    }
  }

  private serializeEventData (submitData: any) {
    return submitData;
  }

  private async handleValidSubmit (_formData: any) {
    const { client, task } = this.props
      , { SessionStore } = this.injected
      , isValid = (this.uploadedDocuments.length || !!this.responderNoteValue)
      , submitData: any = {
        case: task.case,
        completed: true,
        documents_shared: {
          document_ids: map(this.uploadedDocuments, 'id'),
          person_shared_with_id: task.requester,
          sharer_note: this.responderNoteValue,
        },
      };

    if (!isValid) {
      this.showRequiredError = true;
      return;
    }

    this.isSaving = true;

    await Promise.all([
      client.update(task.id, submitData),
      SessionStore.trackEvent('DOCUMENT_REQUEST_TASK_SUBMITTED', this.serializeEventData(submitData)),
    ]);

    this.isSaving = false;

    toast.success('Success! Documents have been shared.');
    browserHistory.push({pathname: '/tasks'});
  }

  private async handleDocumentUploadSubmit (data: any) {
    const { Clients } = this.injected;

    const updatedDocument = await Clients.documentsUploaded.update(data.id, data);

    this.uploadedDocuments = this.uploadedDocuments
      .map((document) => {
        if (document.id === updatedDocument.id) {
          return updatedDocument;
        }
        return document;
    });
  }

  private renderRequestedDocuments () {
    const { task: { document_types_requested } } = this.props;

    return (
      <Fragment>
        <h3>Requested Documents</h3>
        <HelpBlock>
          The following documents have been requested.<br />
          Uploading and setting their document type will mark them as approved.
        </HelpBlock>

        <Spacer />

        <Well className='light condensed'>
        <ul className='documents-requested list-unstyled'>
          {document_types_requested.map((documentTypeId: number) => {
            const documentTypeLabel = this.getDocumentNameFromId(documentTypeId)
              , isUploaded = !!this.uploadedDocuments.find((document) => (document.type === documentTypeId))
              ;

            return (
              <li key={documentTypeId}>
                <Icon type='check' className={cx({active: isUploaded})} />
                {documentTypeLabel}
              </li>
            );
          })}
        </ul>
        </Well>

        <Spacer large />
      </Fragment>
    );
  }

  public render () {
    const {
      task: {
        created_at,
        requester_name,
        requester_note,
      },
    } = this.props;

    return (
      <Row>
        <h3>Documents Requested By</h3>
        <p>
          {requester_name}
          <br />
          {formatDateTime(created_at)}
        </p>

        <hr />

        {requester_note && (
          <Fragment>
            <h3>Requester Note</h3>
            <blockquote className='styled'>{parseCaseNote(requester_note)}</blockquote>
            <hr />
          </Fragment>
        )}

        {this.renderRequestedDocuments()}

        <h3>Upload Requested Case Documents</h3>
        <Spacer />
        <div className='modal-form-style'>
          <Form onValidSubmit={this.handleValidSubmit}>
            <DocumentTable
              columns={this.documentsColumns}
              documents={toJS(this.uploadedDocuments)}
              emptyDropLarge
              isUploading={this.isUploading}
              onDrop={this.onDrop}
            />

            <Spacer large />

            <Textarea
              disabled={this.isSaving}
              field='responder_note'
              label='Notes'
              name='responder_note'
              onChange={this.onResponderNoteChange}
              value={this.responderNoteValue}
            />

            {this.showRequiredError &&
              <Alert bsStyle='danger'><p>You must provide documents and/or a note.</p></Alert>
            }

            <hr />

            <div className='footer'>
              <Button bsStyle='primary' bsSize='large' disabled={this.showRequiredError || this.isSaving} type='submit'>
                {this.isSaving ? 'Sending Documents...' : 'Send Documents'}
              </Button>
              <div className='submit-info'>
                Requester will receive an<br />email with the latest documents
              </div>
            </div>
          </Form>
          {this.isUploadModalShowing.isTrue &&
            <DocumentUploadModal
              _document={this.currentDocument}
              onClose={this.isUploadModalShowing.setFalse}
              onSubmit={this.handleDocumentUploadSubmit}
              showDocumentTypes
            />
          }
        </div>
      </Row>
    );
  }
}

export default DocumentSharingTaskResponse;
