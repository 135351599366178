import React, { Component } from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { browserHistory } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { get } from 'lodash';

import { Button } from 'react-bootstrap';

import { Icon, Page } from '../common';
import UpdateSidebarCaseDetails from '../page-case-update/UpdateSidebarCaseDetails';
import Client from '../../clients/Client';
import { FormattingUtils } from '../../utils';

const { getNameOrDefault } = FormattingUtils;

interface IProps {
  client?: Client;
  data: any;
  isCaseObject?: boolean;
  page: string;
  onArchiveClick?: () => void;
}

interface IPropDefaults extends IProps {
  isCaseObject: boolean;
}

@autoBindMethods
@observer
class CaseReferenceSidebar extends Component <IProps> {
  public static defaultProps: Partial<IProps> = {
    isCaseObject: false,
  };

  private get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  private get sidebarData () {
    const { isCaseObject, data } = this.propsWithDefaults;

    if (isCaseObject) {
      return {
        attorney: getNameOrDefault(data.attorney),
        case: data.id,
        date_of_loss: data.date_of_loss,
        last_tracking_status: data.tracking_summary.tracking_status,
        lawfirm: getNameOrDefault(data.lawfirm),
        plaintiff_name: getNameOrDefault(data.plaintiff),
      };
    }

    return data;
  }

  @action
  private async handleArchiveClick () {
    const taskId = get(this.props.data, 'id')
      , { client } = this.props
      ;

    // istanbul ignore next
    if (!taskId || !client) {
      return;
    }

    await client.update(taskId, {archived: true});
    browserHistory.push({pathname: '/updates/new'});
  }

  private renderArchiveButton () {
    const { data: { archived_at } } = this.props;

    if (archived_at) { return null; }

    return (
      <Button onClick={this.handleArchiveClick} bsStyle='link' className='no-padding btn-archive'>
        <Icon type='archive' /> Archive Update
      </Button>
    );
  }

  public render () {
    const { page } = this.props;
    return (
      <Page.Sidebar className='sidebar-type-task'>
        <div className='wrapper no-y-padding'>
          <LinkContainer to={{pathname: page }}>
            <Button bsStyle='link' className='go-back'><Icon type='angle-double-left' /> Back</Button>
          </LinkContainer>
          <h3>For Case</h3>
        </div>
        <UpdateSidebarCaseDetails data={this.sidebarData}/>
        {page === 'updates' &&
          <div className='wrapper text-center'>
            {this.renderArchiveButton()}
          </div>
        }
      </Page.Sidebar>
    );
  }
}

export default CaseReferenceSidebar;
