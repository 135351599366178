import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

interface IProps {
  border?: boolean;
  className?: any;
  fixwidth?: boolean;
  flip?: 'horizontal' | 'vertical';
  rotate?: '90' | '180' | '270';
  size?: 'lg' | '2x' | '3x' | '4x' | '5x';
  spin?: boolean;
  title?: string;
  type: string;
}

@autoBindMethods
class Icon extends Component<IProps> {
  public render () {
    const { border, fixwidth, flip, rotate, size, spin, title, type } = this.props
      , extraProps: any = {};
    let className = cx(
      'fa',
      `fa-${type}`,
      {
        'fa-border': !!border,
        'fa-fw': !!fixwidth,
        'fa-spin': !!spin,
      },
      this.props.className,
    );

    if (flip) {
      className = cx(className, `fa-flip-${flip}`);
    }

    if (rotate) {
      className = cx(className, `fa-rotate-${rotate}`);
    }

    if (size) {
      className = cx(className, `fa-${size}`);
    }

    if (title) {
      extraProps.title = title;
    }

    return <i className={className} {...extraProps} />;
  }
}

export default Icon;
