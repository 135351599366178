import React from 'react';

interface IContentToolTip {
  children: any;
  'data-for'?: string | null;
  'data-tip'?: any;
}

const ContentToolTip = (props: IContentToolTip) => {
  const { children, ...rest } = props;
  return React.cloneElement(<div className='ellipsis'>{children}</div>, rest);
};

export default ContentToolTip;
