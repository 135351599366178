import autoBindMethods from 'class-autobind-decorator';

import Client from './Client';
import ClientDedupe from './ClientDedupe';
import ClientImports from './ClientImports';
import ClientView from './ClientView';
import { ITransport } from '../interfaces';

@autoBindMethods
class ClientsClass {
  public attorneys: Client;
  public capitalProviders: Client;
  public caseDocuments: Client;
  public caseDocumentsShareToRegistry: Client;
  public lienDocumentsShareToRegistry: Client;
  public casePortfolio: Client;
  public cases: Client;
  public caseSearch: Client;
  public caseTrackingUpdateTasks: Client;
  public documentSharingTask: Client;
  public documentsUploaded: Client;
  public dedupe: ClientDedupe;
  public exports: Client;
  public exportsDownload: Client;
  public imports: ClientImports;
  public importConfigurations: Client;
  public intakeWidget: Client;
  public lawFirms: Client;
  public lienBalances: Client;
  public liens: Client;
  public medicalCodes: Client;
  public medicalFacilities: Client;
  public medicalFacilityContacts: Client;
  public medicalProviders: Client;
  public openCaseSummary: Client;
  public openCaseSummaryCounts: Client;
  public openCaseSummarySearch: Client;
  public organizations: Client;
  public people: Client;
  public periscopeEmbeddedDashboard: ClientView;
  public plaintiffs: Client;
  public relationships: Client;
  public search: Client;
  public tasksReceived: Client;
  public tasksRequested: Client;
  public templates: Client;

  constructor (transport: ITransport) {
    this.attorneys = new Client('/attorneys/', transport);
    this.capitalProviders = new Client('/capital-providers/', transport);
    this.caseDocuments = new Client('/case-documents/', transport);
    this.caseDocumentsShareToRegistry = new Client('/case-documents/share-to-registry/', transport);
    this.casePortfolio = new Client('/case-portfolio/', transport);
    this.cases = new Client('/cases/', transport);
    this.caseSearch = new Client('/case-search/', transport);
    this.caseTrackingUpdateTasks = new Client('/case-tracking-update-tasks/', transport);
    this.documentSharingTask = new Client('/document-sharing-tasks/', transport);
    this.documentsUploaded = new Client('/documents-uploaded/', transport);
    this.dedupe = new ClientDedupe('/dedupe/', transport);
    this.exports = new Client('/exports/', transport);
    this.exportsDownload = new Client('/exports/download/', transport);
    this.imports = new ClientImports('/imports/', transport);
    this.importConfigurations = new Client('/funder-import-configurations/', transport);
    this.intakeWidget = new Client('/intake-widget/', transport);
    this.lawFirms = new Client('/law-firms/', transport);
    this.lienBalances = new Client('/liens/balances/', transport);
    this.liens = new Client('/liens/', transport);
    this.lienDocumentsShareToRegistry = new Client('/lien-documents/share-to-registry/', transport);
    this.medicalCodes = new Client('/medical-codes/', transport);
    this.medicalFacilities = new Client('/medical-facilities/', transport);
    this.medicalFacilityContacts = new Client('/medical-facility-contacts/', transport);
    this.medicalProviders = new Client('/medical-providers/', transport);
    this.openCaseSummary = new Client('/open-case-summary/', transport);
    this.openCaseSummaryCounts = new Client('/open-case-summary/counts/', transport);
    this.openCaseSummarySearch = new Client('/open-case-summary/search/', transport);
    this.organizations = new Client('/organizations/', transport);
    this.people = new Client('/people/', transport);
    this.periscopeEmbeddedDashboard = new ClientView('/periscope-embedded-dashboard', transport);
    this.plaintiffs = new Client('/plaintiffs/', transport);
    this.relationships = new Client('/relationships/', transport);
    this.search = new Client('/search/', transport);
    this.tasksReceived = new Client('/tasks-received/', transport);
    this.tasksRequested = new Client('/tasks-requested/', transport);
    this.templates = new Client('/import-configurations/', transport);
  }
}

export default ClientsClass;
