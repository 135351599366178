import React, { Component } from 'react';
import { noop, get, toString } from 'lodash';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import cx from 'classnames';
import Decimal from 'decimal.js';

import {
  ControlLabel,
  Button,
  FormControl,
  FormGroup,
  HelpBlock,
} from 'react-bootstrap';

import { AppConstants } from '../../constants';
import { createInputContainer } from '../../containers';
import FormattingUtils from '../../utils/FormattingUtils';
import { withinDecimalPlaces } from '../../utils/util';
import { IFormInput, IInputContainer } from '../../interfaces';
import Value from '../../interfaces/Value';

const { formatMoney, formatPercentage } = FormattingUtils;
const { CENT_DECIMAL } = AppConstants;
const PLACES = 2
  , PLACES_PER_CENT = 4; // 25% vs .25

type IType = 'money' | 'percent';

interface IProps extends IFormInput {
  canChangeType?: boolean;
  initialType?: IType;
  model: object;
  percentOf: string;
}

interface IPropDefaults extends IProps {
  canChangeType: boolean;
  initialType: IType;
  onChange: (value: string | null) => void;
  required: boolean;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@autoBindMethods
@observer
class MoneyPercent extends Component<IProps, {}> {
  @observable private isFocused = false;
  @observable private type: IType = 'money';

  @observable private money = '';
  @observable private percent = '';

  public static defaultProps: Partial<IProps> = {
    canChangeType: true,
    initialType: 'money',
    onChange: noop,
    required: false,
    value: '',
  };

  get formsy () {
    return this.props as IPropsFormsy;
  }

  constructor (props: IPropsFormsy) {
    super(props);

    const { initialType, value } = props
      , isInitialPercent = value && initialType === 'percent'
      , initialValue = isInitialPercent ? (new Decimal(toString(value))).times(CENT_DECIMAL) : value;

    this.type = initialType;
    this.setFrom(initialType, toString(initialValue));
  }

  public componentWillReceiveProps (nextProps: IProps) {
    if (nextProps.value === '' && this[this.type] !== '') {
      this.reset();
      return;
    }
    this.setFrom(this.type, this[this.type]);
  }

  private setFrom (type: IType, value: Value) {
    const {
        model,
        percentOf,
      } = this.props
      , percentOfNum = get(toJS(model), percentOf)
      ;

    if (!withinDecimalPlaces(toString(value))) {
      // istanbul ignore next
      return;
    }

    switch (type) {
      case 'money': {
        this.money = toString(value);
        this.percent = '';

        if (this.money && percentOfNum) {
          const money = new Decimal(this.money)
            , percentOfDecimal = new Decimal(percentOfNum);

          this.percent = money.times(CENT_DECIMAL).div(percentOfDecimal).toFixed(PLACES);
        }
        break;
      }

      case 'percent': {
        this.percent = toString(value);
        this.money = '';

        if (this.percent && percentOfNum) {
          const percent = new Decimal(this.percent)
            , percentOfDecimal = new Decimal(percentOfNum);

          this.money = percent.times(percentOfDecimal).div(CENT_DECIMAL).toFixed(PLACES);
        }
        break;
      }

      /* istanbul ignore next */
      default: {
        // istanbul ignore next
        throw new Error('Invalid type');
      }
    }
  }

  private onChange (e: any) {
    const input = e.target.value;
    this.setFrom(this.type, input);
    switch (this.props.initialType) {
      case 'money': {
        this.formsy.onChange(this.money);
        break;
      }

      case 'percent': {
        this.formsy.onChange(this.percentValue);
        break;
      }

      /* istanbul ignore next */
      default: {
        // istanbul ignore next
        throw new Error('Invalid type');
      }
    }
  }

  private reset () {
    this.money = '';
    this.percent = '';
    this.type = 'money';
    this.isFocused = false;
  }

  private onBlur () {
    this.formsy.setValue(this.money);
    this.isFocused = false;
  }

  private onFocus () {
    this.isFocused = true;
  }

  private setTypeMoney () {
    this.type = 'money';
  }

  private setTypePercent () {
    this.type = 'percent';
  }

  private get formattedMoney () {
    if (!this.money.length) {
      return null;
    }
    return formatMoney(this.money);
  }

  private get formattedPercent () {
    if (!this.percent.length) {
      return null;
    }
    return formatPercentage(this.percentValue);
  }

  get percentValue () {
    return !this.percent.length ? null : new Decimal(this.percent).div(CENT_DECIMAL).toFixed(PLACES_PER_CENT);
  }

  public render () {
    const {
        canChangeType,
        controlId,
        errorMessage,
        formGroupId,
        isDisabled,
        label,
        required,
        showErrorMessage,
        showRequiredMessage,
        validationState,
      } = this.formsy
      , className = cx(
        'money-percent',
        { focused: this.isFocused },
        { 'has-value': !!this[this.type] },
      )
      , moneyClassName = cx('btn-gray', { active: this.type === 'money' }, 'btn-money')
      , percentClassName = cx('btn-gray', { active: this.type === 'percent' }, 'btn-percent')
      ;

    return (
      <FormGroup id={formGroupId} controlId={controlId} className={className} validationState={validationState}>
        <ControlLabel>
          {label}
          {required && <span className='required'>*</span>}
        </ControlLabel>

        <FormControl
          disabled={isDisabled}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          placeholder=''
          type='number'
          value={this[this.type]}
        />

        <div className='total'>
          {(this.type === 'money')
            ? this.formattedPercent
            : this.formattedMoney
          }
        </div>

        {canChangeType &&
          <div className='types'>
            <Button bsSize='small' onClick={this.setTypeMoney} className={moneyClassName}>$</Button>
            <Button bsSize='small' onClick={this.setTypePercent} className={percentClassName}>%</Button>
          </div>
        }

        {showRequiredMessage &&
          <HelpBlock>{label || 'This field'} is required.</HelpBlock>}

        {showErrorMessage &&
          <HelpBlock>{errorMessage}</HelpBlock>}
      </FormGroup>
    );
  }
}

export default MoneyPercent;
