import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action, toJS } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { noop } from 'lodash';

import { ObjectSelect } from '../common-formsy';
import ClientsClass from '../../clients/ClientsClass';
import { CaseUpdateStoreClass } from '../../stores';
import { FormattingUtils } from '../../utils';
import { ICase } from '../../interfaces';

const { getNameOrDefault } = FormattingUtils;

interface IProps {
  disabled?: boolean;
  onCaseSelect?: (selectedCase: ICase | null) => void;
  preselectedCase?: ICase;
  updateAction: 'add' | 'request';
}

interface IInjected extends IProps {
  CaseUpdateStore: CaseUpdateStoreClass;
  Clients: ClientsClass;
}

interface IPropDefaults extends IProps {
  onCaseSelect: (selectedCase: ICase | null) => void;
}

@inject('CaseUpdateStore', 'Clients')
@autoBindMethods
@observer
class CaseSelectionModule extends Component <IProps> {

  public static defaultProps: Partial<IProps> = {
    onCaseSelect: noop,
  };

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  private get injected () {
    return this.props as IInjected;
  }

  public componentWillUnmount () {
    const { CaseUpdateStore } = this.injected;
    CaseUpdateStore._case = null;
  }

  private formatResult (object: any) {
    return getNameOrDefault(object && object.plaintiff);
  }

  @action
  private async handleSearch (value: string) {
    const { Clients, CaseUpdateStore } = this.injected
      , response = await Clients.caseSearch.list({params: {search: value}})
      ;

    CaseUpdateStore.caseSearchResults = response.results;
  }

  @action
  private async handleCaseSelect (caseId: string) {
    const { CaseUpdateStore, Clients } = this.injected;

    if (!caseId) {
      CaseUpdateStore._case = null;
      this.propsWithDefaults.onCaseSelect(null);
      return;
    }

    CaseUpdateStore._case = await Clients.cases.retrieve(caseId);
    this.propsWithDefaults.onCaseSelect(CaseUpdateStore._case);
  }

  public render () {
    const { CaseUpdateStore: { _case, caseSearchResults } } = this.injected
      , { disabled, preselectedCase, updateAction } = this.props
      ;

    return (
      <ObjectSelect
        async
        className='fs-mask'
        defaultValue={preselectedCase}
        disabled={disabled}
        field='plaintiff'
        id='case-selection-module'
        label={`Which case would you like to ${updateAction} an update for? (search by plaintiff)`}
        name='case-search'
        objectRender={this.formatResult}
        objectKey='id'
        objects={toJS(caseSearchResults)}
        onChange={this.handleCaseSelect}
        onSearchInputChange={this.handleSearch}
        value={_case && _case.id}
      />
    );
  }
}

export default CaseSelectionModule;
