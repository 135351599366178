import React, { Component } from 'react';
import { noop, toString, get } from 'lodash';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import cx from 'classnames';

import {
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  InputGroup,
} from 'react-bootstrap';

import { AppConstants } from '../../constants';
import { createInputContainer } from '../../containers';
import { IFormInput, IInputContainer } from '../../interfaces';
const { KEYBOARD_ENTER_CODE, ACCEPTED_INPUT_TYPES } = AppConstants;

interface IProps extends IFormInput {
  addonAfter?: JSX.Element;
  addonBefore?: JSX.Element;
  icon?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  type?: 'hidden' | 'text' | 'number' | 'email' | 'password' | 'url' | 'checkbox';
  validateOnBlur?: boolean;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

interface IPropDefaults extends IProps {
  disabled: boolean;
  onBlur: () => void;
  onChange: (value: any) => void;
  onFocus: () => void;
  placeholder: string;
  required: boolean;
  type: 'hidden' | 'text' | 'number' | 'email' | 'password' | 'url' | 'checkbox';
  validateOnBlur: boolean;
}

@createInputContainer
@autoBindMethods
@observer
class Input extends Component<IProps, {}> {
  @observable private isFocused = false;
  @observable private value = '';

  public static defaultProps: Partial<IProps> = {
    disabled: false,
    onBlur: noop,
    onChange: noop,
    onFocus: noop,
    placeholder: '',
    required: false,
    type: 'text',
    validateOnBlur: true,
  };

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  get formsy () {
    return this.props as IPropsFormsy;
  }

  constructor (props: IProps) {
    super(props);
    this.setValueFromProps(props);
  }

  public componentWillReceiveProps (nextProps: IProps) {
    this.setValueFromProps(nextProps);
  }

  private setValueFromProps (props: IProps) {
    // toString allows input to show number 0
    if (
      typeof props.value !== 'undefined' &&
      this.value !== toString(props.value)
    ) {
      this.value = toString(props.value);
    }
  }

  private setValue () {
    this.formsy.setValue(this.value);
  }

  private onChange (event: any) {
    const { onChange, type, validateOnBlur } = this.propsWithDefaults;
    let value = event.target[type === 'checkbox' ? 'checked' : 'value'];
    this.value = value;
    if (type === 'number' && value === '') {
      value = null;
    }
    if (!validateOnBlur) {
      this.setValue();
    }
    onChange(value);
  }

  private onBlur () {
    const { onBlur, validateOnBlur } = this.propsWithDefaults;
    if (validateOnBlur) {
      this.setValue();
    }
    this.isFocused = false;
    onBlur();
  }

  private onFocus () {
    this.isFocused = true;
    this.propsWithDefaults.onFocus();
  }

  private onKeyDown (event: any) {
    const { validateOnBlur } = this.props;
    // set value when 'enter' key is pressed
    // this allows form to be re-submitted even when field has not blurred yet
    if (validateOnBlur && KEYBOARD_ENTER_CODE === event.keyCode) {
      this.setValue();
    }
  }

  // tslint:disable-next-line cyclomatic-complexity
  public render () {
    const {
      addonAfter,
      addonBefore,
      label,
      placeholder,
      required,
    } = this.propsWithDefaults
    , {
      controlId,
      errorMessage,
      formGroupId,
      isDisabled,
      showErrorMessage,
      showRequiredMessage,
      validationState,
    } = this.formsy
    , type = get(ACCEPTED_INPUT_TYPES, this.propsWithDefaults.type, 'text');

    if (type === 'hidden') {
      return <input type='hidden' value={this.value} />;
    }

    const className = cx(
        this.props.className,
        { disabled: isDisabled },
        { focused: this.isFocused },
        { 'has-value': !!this.value },
      )
      , formElement = (
        <FormControl
          disabled={isDisabled}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onKeyDown={this.onKeyDown}
          placeholder={placeholder}
          type={type}
          step='any'
          value={this.value}
        />
      );

    return (
      <FormGroup
        className={className}
        controlId={controlId}
        id={formGroupId}
        validationState={validationState}
      >
        {label && (
          <ControlLabel>
            {label}
            {required && <span className='required'>*</span>}
          </ControlLabel>
        )}

        {addonBefore || addonAfter ? (
          <InputGroup>
            {addonBefore}
            {formElement}
            {addonAfter}
          </InputGroup>
        ) : (
          formElement
        )}

        {showRequiredMessage && (
          <HelpBlock>{label || 'This field'} is required.</HelpBlock>
        )}

        {showErrorMessage && <HelpBlock>{errorMessage}</HelpBlock>}
      </FormGroup>
    );
  }
}

export default Input;
