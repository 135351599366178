import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';
import { browserHistory } from 'react-router';

import {
  Button,
  ButtonToolbar,
} from 'react-bootstrap';

import { MessageModal } from '../common';
import AppConstants from '../../constants/AppConstants';
import { SmartBool } from '../../utils';

const { ROUTING } = AppConstants;

interface IProps {
  showCompletedMessage: SmartBool;
}

@autoBindMethods
@observer
class TaskCompletedModal extends Component<IProps> {
  private handleTasksClick () {
    browserHistory.push({pathname: ROUTING.tasks});
  }

  public render () {
    const { showCompletedMessage } = this.props;

    if (!showCompletedMessage.isTrue) {
      return null;
    }

    return (
      <MessageModal onClose={showCompletedMessage.setFalse}>
        <MessageModal.Title>Task Completed</MessageModal.Title>
        <MessageModal.Body>
          <p>This task has already been completed.</p>
          <p>View all your tasks, including what's outstanding and complete, by navigating to your tasks page.</p>
          <div>
            <ButtonToolbar>
              <Button className='no-padding' bsStyle='primary' bsSize='small' onClick={this.handleTasksClick}>Go to Tasks</Button>
              <Button bsSize='small' onClick={showCompletedMessage.setFalse}>See Completed Task</Button>
            </ButtonToolbar>
          </div>
        </MessageModal.Body>
      </MessageModal>
    );
  }
}

export default TaskCompletedModal;
