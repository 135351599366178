import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { noop } from 'lodash';
import cx from 'classnames';
import { Button } from 'react-bootstrap';
import { Icon } from '../common';

interface IProps {
  fieldName: string;
  hasValue: boolean;
  isCollapsed?: boolean;
  label: string;
  reset?: (fieldName: string) => void;
  toggleCollapsed?: () => boolean;
}

@autoBindMethods
@observer
class FacetHeader extends Component<IProps, {}> {
  private renderButton (onClick: () => void, icon: string, buttonProps = {}) {
    return (
      <Button bsStyle='link' onClick={onClick} bsSize='small' {...buttonProps}>
        <Icon type={icon} />
      </Button>
    );
  }

  private onClickHeader () {
    const { hasValue, toggleCollapsed } = this.props;
    if (!hasValue && toggleCollapsed) {
      toggleCollapsed();
    }
  }

  private reset () {
    const { reset, fieldName } = this.props;
    if (reset) {
      reset(fieldName);
    }
  }

  private renderCollapseIcon () {
    const { hasValue, isCollapsed, toggleCollapsed } = this.props;

    // Not collapsible
    if (!toggleCollapsed) {
      return null;
    }
    // [+] Expand icon
    if (isCollapsed) {
      return this.renderButton(noop, 'plus-square-o', { className: 'btn-collapser' });
    }
    // [-] Collapse icon
    return this.renderButton(noop, 'minus-square-o', { className: 'btn-collapser', disabled: hasValue });
  }

  private renderResetIcon () {
    const { hasValue } = this.props;
    return this.renderButton(this.reset, 'undo', {
      className: 'btn-reset',
      disabled: !hasValue,
    });
  }

  public render () {
    const { hasValue, label } = this.props
      , titleClassNames = cx({disabled: hasValue});

    return (
      <div className='facet-header'>
        <h3 className={titleClassNames} onClick={this.onClickHeader}>
          {this.renderCollapseIcon()}
          {label}
        </h3>
        {this.renderResetIcon()}
      </div>
    );
  }
}

export default FacetHeader;
