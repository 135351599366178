import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { FormModal } from '../../../lib/mighty-fields';

interface IProps {
  _note: object;
  onClose: () => boolean;
  onSubmit: (model: object) => Promise<void>;
}

@autoBindMethods
@observer
class EditCaseNoteModal extends Component<IProps> {

  public render () {
    const {
      _note,
      onClose,
      onSubmit,
    } = this.props;

    return (
      <FormModal
        className='modal-edit-case-notes'
        fieldSets={[[{field: 'body', label: 'Note', required: true, type: 'textDynamic'}]]}
        model={_note}
        onClose={onClose}
        onSave={onSubmit}
        title='Note'
      />
    );
  }

}

export default EditCaseNoteModal;
