import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { noop, toString } from 'lodash';
import autoBindMethods from 'class-autobind-decorator';

import {
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
} from 'react-bootstrap';

import { AppConstants } from '../../constants';
import { createInputContainer } from '../../containers';
import { IFormInput, IInputContainer, Value } from '../../interfaces';
const { KEYBOARD_ENTER_CODE } = AppConstants;

interface IProps extends IFormInput {
  className?: any;
  dynamicHeight?: boolean;
  onChange?: (value: Value) => void;
  placeholder?: string;
}

interface IPropDefaults extends IProps {
  className: any;
  dynamicHeight: boolean;
  onChange: (value: Value) => void;
  placeholder: string;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@autoBindMethods
@observer
class Textarea extends Component<IProps, {}> {
  @observable private isFocused = false;
  @observable private styles = {};
  @observable private value = '';

  private refTextarea: any;

  public static defaultProps = {
    className: '',
    dynamicHeight: false,
    onChange: noop,
    placeholder: '',
  };

  get formsy () {
    return this.props as IPropsFormsy;
  }

  constructor (props: IProps) {
    super(props);
    this.setValueFromProps({}, props);
  }

  public componentWillReceiveProps (nextProps: IProps) {
    this.setValueFromProps(this.props, nextProps);
  }

  private setValueFromProps (props: IProps | { value?: any }, nextProps: IProps) {
    // toString allows input to show number 0
    const valueDefined = typeof nextProps.value !== 'undefined'
      , valueChanged = props.value !== nextProps.value
      , valueDiffers = this.value !== nextProps.value;

    if (valueDefined && valueChanged && valueDiffers) {
      this.value = toString(nextProps.value);
    }
  }

  private setValue () {
    this.formsy.setValue(this.value);
  }

  public componentDidMount () {
    if (this.props.dynamicHeight) {
      this.setDynamicHeight();
    }
  }

  private onChange (e: any) {
    const { dynamicHeight, onChange } = this.formsy,
      value = e.target.value;
    this.value = value;
    this.setValue();

    if (dynamicHeight) {
      this.setDynamicHeight();
    }

    onChange(value);
  }

  private onKeyDown (e: any) {
    if (KEYBOARD_ENTER_CODE === e.keyCode) {
      this.setValue();
    }
  }

  private onFocus () {
    this.isFocused = true;
  }

  private onBlur () {
    this.isFocused = false;
  }

  private setDynamicHeight () {
    /* Based on https://github.com/javierjulio/textarea-autosize , without the use of jQuery */
    this.styles = { height: 0, overflowY: 'hidden' };
    this.styles = { height: this.refTextarea.scrollHeight, overflowY: 'auto' };
  }

  private setRefTextarea (component: any) {
    this.refTextarea = component;
  }

  public render () {
    const {
        controlId,
        dynamicHeight,
        errorMessage,
        formGroupId,
        isDisabled,
        label,
        placeholder,
        required,
        showErrorMessage,
        showRequiredMessage,
      } = this.formsy
      , className = cx(
        'textarea-group',
        { 'dynamic-height': dynamicHeight },
        { disabled: isDisabled },
        { focused: this.isFocused },
        { 'has-value': !!this.value },
        { 'has-error': showErrorMessage || showRequiredMessage },
      )
      , formElement = (
        <FormControl
          className={this.props.className}
          componentClass='textarea'
          disabled={isDisabled}
          inputRef={this.setRefTextarea}
          label={label}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onKeyDown={this.onKeyDown}
          placeholder={placeholder}
          style={this.styles}
          value={this.value}
        />
      );

    return (
      <FormGroup id={formGroupId} controlId={controlId} className={className}>
        {label &&
          <ControlLabel>
            {label}
            {required && <span className='required'>*</span>}
          </ControlLabel>
        }

        {formElement}

        {showRequiredMessage &&
          <HelpBlock>{label || 'This field'} is required.</HelpBlock>}

        {showErrorMessage &&
          <HelpBlock>{errorMessage}</HelpBlock>}
      </FormGroup>
    );
  }
}

export default Textarea;
