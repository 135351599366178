import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Modal, ButtonToolbar, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { debounce } from 'lodash';

import { pluralize } from '@mighty-justice/utils';

import { toast } from '../../utils';
import { ContactStoreClass } from '../../stores';
import { AppConstants } from '../../constants';

const {
  CONTACT_TYPES,
  DEBOUNCE_DELAY,
  MAX_TYPEAHEAD_CONTACT_RESULT_DISPLAY,
} = AppConstants;

interface IRowData {
  case_id: string;
}

interface IProps {
 isVisible: boolean;
 onHide: () => void;
 onSave: (model: object) => void;
 onToggleMode: () => void;
 selectedRows: IRowData[];
}

interface IInjected extends IProps {
  ContactStore: ContactStoreClass;
}

interface IContact {
  id: string;
  first_name: string;
  fullName: string;
  last_name: string;
  email: string;
  contact_type: string;
  contactType: IContactTypeItem;
}

interface IContactTypeItem {
  baseUrl: string;
  display: string;
  model: string;
  route: string;
  shortDisplay: string;
  type: string;
}

@inject('ContactStore')
@autoBindMethods
@observer
class BulkUpdateTrackingContactForm extends Component<IProps> {
  @observable private isSubmitting: boolean = false;
  @observable private searchResults: IContact[] = [];
  @observable private selectedContact: IContact[] = [];
  private readonly debouncedOnContactSearch: (event: any) => void;

  constructor (props: IProps) {
    super(props);

    this.debouncedOnContactSearch = debounce(this.onContactSearch, DEBOUNCE_DELAY);
  }

  private get injected () {
    return this.props as IInjected;
  }

  private get caseText () {
    const { selectedRows } = this.props
      , caseCount = selectedRows.length
      , casePlural = pluralize('case', 's', caseCount)
    ;

    return `${caseCount} selected ${casePlural}`;
  }

  private onSearchChange (contactName: string) {
    this.debouncedOnContactSearch(contactName);

    if (contactName.trim() === '') {
      this.selectedContact = [];
    }
  }

  private async onContactSearch (contactName: string) {
    const { ContactStore } = this.injected;

    if (!!contactName.trim()) {
      const searchAndTransformContacts = async (contactType: string, isAttorney: boolean) => {
        const response = await ContactStore.list({ search: contactName }, contactType);
        return response.results.map((contact: IContact) => ({
          ...contact,
          contactType: isAttorney ? CONTACT_TYPES.ATTORNEY : CONTACT_TYPES[contact.contact_type],
          email: contact.email,
          fullName: `${contact.first_name} ${contact.last_name}`,
        }));
      };

      const attorneyResults = await searchAndTransformContacts(CONTACT_TYPES.ATTORNEY.type, true)
      , otherResults = await searchAndTransformContacts(CONTACT_TYPES.OTHER.type, false);

      if (attorneyResults.length > MAX_TYPEAHEAD_CONTACT_RESULT_DISPLAY) {
        attorneyResults.length = MAX_TYPEAHEAD_CONTACT_RESULT_DISPLAY;
      }

      if (otherResults.length > MAX_TYPEAHEAD_CONTACT_RESULT_DISPLAY) {
        otherResults.length = MAX_TYPEAHEAD_CONTACT_RESULT_DISPLAY;
      }

      const combinedResults = [...attorneyResults, ...otherResults];
      this.searchResults = combinedResults;
    } else {
      this.searchResults = [];
    }
  }

  private onContactSelect = (selected: any[]) => {
    if (selected.length > 0) {
      this.selectedContact = selected;
    }
  }

  private async onBulkChangeTrackingContact () {
    const { onHide, onSave } = this.props
    , selectedContactId = this.selectedContact[0]?.id;

    if (!selectedContactId) { return; }

    try {
      const data = { tracking_contact: selectedContactId };

      this.isSubmitting = true;
      await onSave(data);
      this.isSubmitting = false;

      toast.success(`Success! You have changed tracking contact on the ${this.caseText}!`);
      onHide();
    } catch (error) {
      toast.error('There was an error updating the case tracking contact.');
      throw error;
    }
  }

  private renderMenuItemChildren = (option: IContact) => {
    return (
      <div>
        <div><strong>{option.fullName}</strong></div>
        <div>Type: {option.contactType.display}</div>
        <div>Email: {option.email}</div>
      </div>
    );
  }

  public render () {
    const { isVisible, onHide, onToggleMode } = this.props;

    if (!isVisible) {
      return null;
    }

    return (
      <Modal show onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Update Case Tracking Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typeahead
            id='contact-typeahead'
            labelKey='fullName'
            onChange={this.onContactSelect}
            onInputChange={this.onSearchChange}
            options={this.searchResults}
            placeholder='Search for a contact...'
            renderMenuItemChildren={this.renderMenuItemChildren}
            selected={this.selectedContact}
            emptyLabel='No existing contacts found'
          />
          <span className='pull-right text-red'>
            *Selecting an attorney will also assign that attorney to all selected cases
          </span>
          <div className='new-contact-button'>
            <Button
              bsSize='sm'
              bsStyle='link no-padding pull-right'
              className='btn-open-new-contact-form'
              onClick={onToggleMode}
            >
              Create a new contact instead
            </Button>
          </div>
        </Modal.Body>

      <Modal.Footer>
        <ButtonToolbar className='pull-right'>
          <Button onClick={onHide}>Cancel</Button>
          <Button
            disabled={!this.selectedContact.length || this.isSubmitting}
            onClick={this.onBulkChangeTrackingContact}
          >
            Update
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
      </Modal>
    );
  }
}

export default BulkUpdateTrackingContactForm;
