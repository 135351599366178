import { toJS } from 'mobx';
import qs from 'qs';
import { get, uniqBy, sortBy, isObject, isEmpty, isNil, round, without, find } from 'lodash';
import Decimal from 'decimal.js';
import URI from 'urijs';

import { insertIf } from '@mighty-justice/utils';

import { AppConstants } from '../constants';
import { ICase } from '../interfaces';

import FormattingUtils from './FormattingUtils';
import Storage from './Storage';

const {
  BYTES_BASE,
  CONTACT_TYPES,
  CUSTOM_TYPES_TO_FIELDSETS,
  DATE_FORMATS,
  JWT_COOKIE_KEY,
  MONEY_DECIMAL_PLACES,
} = AppConstants;

const { formatDate } = FormattingUtils;

function getInitials (firstName: string, lastName: string) {
  return firstName.substring(0, 1) + lastName.substring(0, 1);
}

function nullifyEmptyStrings (data: object) {
  return JSON.parse(JSON.stringify(data).replace(/""/g, 'null'));
}

function isHttpClientError (error: object) {
  return Boolean(String(get(error, 'response.status')).match(/4\d{2}/));
}

function formatSortingParams (params: Array<{ id: string, desc: boolean }>) {
  const sortingParams = params.map(param => {
    return param.desc ? `-${param.id}` : param.id;
  });
  return sortingParams.join(',') || null;
}

function unformatSortingParams (params: string) {
  return params.split(',').map(param => {
    const desc = param[0] === '-'
      , id = desc ? param.slice(1) : param;
    return { desc, id };
  });
}

type IReportsCounts = Array<{ id: string, name: any, count: any }>;

function mergeReportCounts (current: IReportsCounts, next: IReportsCounts) {
  const INITIAL_FILTER_RESULTS = 4;
  const checked = current.map(item => {
    const found = next.find(el => item.id === el.id);
    return found ? {...item, count: found.count, name: found.name} : {...item, count: 0};
  });
  const facetOptions = checked
    .concat(next.slice(0, INITIAL_FILTER_RESULTS))
    .map(obj => ({ ...obj, value: obj.id }));

  return uniqBy(facetOptions, 'value');
}

function sortAndRemoveNullFacets (facets: Array<{ count: number, name?: string }>) {
  const validFacets = facets.filter(facet => facet.name !== undefined);
  return sortBy(validFacets, (validFacet => 0 - validFacet.count));
}

function getPageRangeStart (pageNumber: number, pageSize: number) {
  return (pageNumber - 1) * pageSize + 1;
}

function setPagination (query: string) {
  return get(URI.parseQuery(query), 'page', '1');
}

function sortArrayByArray (unsortedArray: any[], sortedArray: any[]) {
  return unsortedArray.sort((a, b) => {
    // Comparator function that sorts unsortedArray according to the index number
    // assigned to each item in sortedArray. Items in unsortedArray that don't appear
    // in sortedArray are included last in the order they appear in unsortedArray.
    const arrayLength = unsortedArray.length;
    let indexOfA = sortedArray.indexOf(a)
      , indexOfB = sortedArray.indexOf(b);
    if (indexOfA === -1) {
      indexOfA = arrayLength;
    }
    if (indexOfB === -1) {
      indexOfB = arrayLength;
    }
    return indexOfA - indexOfB;
  });
}

function joinCustomFields (funderFields: any[], dataFields: any[]) {
  const fieldSet = new Set([...funderFields, ...dataFields]);
  return sortArrayByArray(Array.from(fieldSet), funderFields);
}

function getCustomFieldset (customFields: { [key: string]: string } | undefined, field: string) {
  const funderFieldType = customFields && customFields[field];

  if (funderFieldType) {
    const mappedFieldset = CUSTOM_TYPES_TO_FIELDSETS[funderFieldType];
    if (mappedFieldset) { return { ...mappedFieldset, field }; }
  }

  return { field, type: 'textDynamic' };
}

function isEmptyOrNil (data: any) {
  return (isObject(data) && isEmpty(data)) || isNil(data);
}

function getExtension (fileName?: string) {
  return fileName && fileName.split('?')[0].split('#')[0].split('.').pop();
}

function getFilename (path?: string) {
  return path && String(path.split('/').pop()).split('#')[0].split('?')[0];
}

function getFilenameFromUrl (url?: string) {
  if (!url) { return null; }
  const urlParams = qs.parse(url.split('?')[1]);
  return urlParams.file_name || null;
}

function withinDecimalPlaces (value: number | string, places = 2) {
  const strValue = String(value);
  return isNil(strValue)
    || strValue.split('.').length < 2
    || strValue.split('.')[1].length <= places;
}

function roundMoney (value?: number): undefined | number {
  if (value && Number.isFinite(value)) {
    return round(value, MONEY_DECIMAL_PLACES);
  }
  return value;
}

function bytesToMB (value: number | string) {
  return new Decimal(value).div(BYTES_BASE).div(BYTES_BASE).toNumber();
}

function mapObjectKeys (object: { [key: string]: any }, mapper: { [key: string]: string }) {
  const newObject: { [key: string]: any } = {};

  Object.keys(object).forEach(key => {
    const newKey = get(mapper, key, key);
    newObject[newKey] = object[key];
  });

  return newObject;
}

function sumMoney (moneyStringArray?: any[]) {
  const ZERO = new Decimal('0');
  return (moneyStringArray || []).reduce((a, b) => Decimal.add(a || ZERO, b || ZERO), ZERO).toFixed(2);
}

function stubNull (..._args: any[]) {
  return null;
}

function toggleArrayIncludes (arrayArg: any[], value: any) {
  if (arrayArg.includes(value)) {
    return without(arrayArg, value);
  }

  arrayArg.push(value);
  return arrayArg;
}

function findKeyByValue (list: {[key: string]: string}, value: string) {
  return find(Object.keys(list), (key: string) => list[key] === value) || null;
}

function getContactsFromCase (_case: ICase) {
  const attorney = _case.attorney,
    contacts = toJS(get(_case, 'contacts', []))
    ;

  if (attorney) {
    contacts.push({...attorney, contact_type: CONTACT_TYPES.ATTORNEY.type});
  }

  return sortBy(contacts, 'contact_type');
}

function getToken () {
  const jwtStorage = Storage.getCookie(JWT_COOKIE_KEY);
  return get(jwtStorage, 'jwt.token');
}

function getCurrentDate () {
  return formatDate((new Date()).toISOString(), DATE_FORMATS.date_value);
}

export {
  bytesToMB,
  findKeyByValue,
  formatSortingParams,
  getContactsFromCase,
  getCurrentDate,
  getCustomFieldset,
  getExtension,
  getFilename,
  getFilenameFromUrl,
  getInitials,
  getPageRangeStart,
  getToken,
  insertIf,
  isEmptyOrNil,
  isHttpClientError,
  joinCustomFields,
  mapObjectKeys,
  mergeReportCounts,
  nullifyEmptyStrings,
  roundMoney,
  setPagination,
  sortAndRemoveNullFacets,
  sortArrayByArray,
  stubNull,
  sumMoney,
  toggleArrayIncludes,
  unformatSortingParams,
  withinDecimalPlaces,
};
