import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import CaseActivity from './CaseActivity';

interface IProps {
  activity: {
    id: string;
    type: string;
  };
}

@autoBindMethods
@observer
class LienDeleted extends Component<IProps> {

  public render () {
    const body = `Lien deleted`;

    return (
      <CaseActivity
        activity_body={body}
        dot='small'
        {...this.props}
      />
    );
  }
}

export default LienDeleted;
