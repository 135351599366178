import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { browserHistory } from 'react-router';
import autoBindMethods from 'class-autobind-decorator';

import { Button, Col, Row, HelpBlock } from 'react-bootstrap';

import { FormFieldSets, IFieldSetPartial } from '../../lib/mighty-fields';
import { FormManager, FormattingUtils, SmartBool, toast } from '../../utils';
import { Form } from '../common-formsy';
import { Icon, Page, Spacer } from '../common';
import { SessionStoreClass } from '../../stores';

const { getNameOrDefault } = FormattingUtils;

interface IProps {
  action: 'create' | 'edit';
  browserRedirect: () => void;
  contactType: string;
  headerText: string;
  initialData?: object;
  onSubmit: (data: any) => Promise<any>;
  showOptions?: boolean;
}

interface IInjected extends IProps {
  SessionStore: SessionStoreClass;
}

@inject('SessionStore')
@autoBindMethods
@observer
class ActionContactPage extends Component<IProps> {
  @observable private isShowingMoreOptions = new SmartBool();
  private formManager: FormManager;

  constructor (props: IProps) {
    super(props);

    if (props.showOptions) {
      this.isShowingMoreOptions.setTrue();
    }
    this.formManager = new FormManager({
      fieldSets: this.fieldSets,
      model: props.initialData,
      onSave: this.onValidSubmit,
    });
  }

  private get injected () {
    return this.props as IInjected;
  }

  private get isEditing () {
    return this.props.action === 'edit';
  }

  private get idFieldSet (): IFieldSetPartial {
    if (!this.isEditing) {
      return [];
    }

    return [{
      field: 'id',
      type: 'hidden',
    }];
  }

  private get fullNameFieldSet (): IFieldSetPartial {
    return [
      {
        field: 'first_name',
        required: true,
      },
      {
        field: 'last_name',
        required: true,
      },
    ];
  }

  private get emailFieldSet (): IFieldSetPartial {
    return [
      {
        field: 'email',
        label: 'Email Address',
        type: 'email',
      },
    ];
  }

  private get moreOptionsGroup (): IFieldSetPartial {
    return [
      {
        field: 'phone_number',
        label: 'Phone #',
        required: false,
        type: 'phone',
      },
      {
        field: 'additional_contact_info.cell_number',
        label: 'Cell #',
        required: false,
        type: 'phone',
      },
      {
        field: 'additional_contact_info.fax_number',
        label: 'Fax #',
        required: false,
        type: 'phone',
      },
    ];
  }

  private get fieldSets () {
    return [
      this.idFieldSet,
      this.fullNameFieldSet,
      this.emailFieldSet,
      this.moreOptionsGroup,
    ];
  }

  private toggleMoreOptions () {
    this.isShowingMoreOptions.toggle();
  }

  @action
  private async onValidSubmit (formData: any) {
    const { SessionStore } = this.injected
      , { browserRedirect, contactType, onSubmit } = this.props
      , actionVerb = this.isEditing ? 'edited' : 'created'
      , contactName = getNameOrDefault(formData)
      ;

    await onSubmit(formData);

    SessionStore.trackEvent('MANUAL_ADD_CASE_CONTACT_INFO', {...formData, contactDisplayType: contactType});
    toast.success(`Success! ${contactName} has been ${actionVerb}.`);

    browserRedirect();
  }

  private renderFieldSet (fieldSet: IFieldSetPartial) {
    const formManagerProps = this.formManager && this.formManager.formGroupsProps;

    return (
      <FormFieldSets fieldSets={[fieldSet]} {...formManagerProps}/>
    );
  }

  private renderMoreOptionsGroup () {
    const className = !this.isShowingMoreOptions.isTrue ? 'hidden' : '';

    return (
      <div className={className}>
        {this.renderFieldSet(this.moreOptionsGroup)}
        <HelpBlock>Format: (000) 000-0000</HelpBlock>
      </div>
    );
  }

  private renderToggleButton () {
    const optionDescriber = this.isShowingMoreOptions.isTrue ? 'Fewer' : 'More'
      , iconType = this.isShowingMoreOptions.isTrue ? 'angle-up' : 'angle-down'
      ;

    return (
      <Button bsStyle='link' onClick={this.toggleMoreOptions}>
        {optionDescriber} options
        {' '}<Icon type={iconType} />
      </Button>
    );
  }

  private renderHeader () {
    const { headerText } = this.props;

    return (
      <header className='condensed'>
        <div>
          <Button bsStyle='link' className='go-back no-padding' onClick={browserHistory.goBack}>
            <Icon type='angle-double-left' /> Back
          </Button>
          <h1 className='fs-mask'>{headerText}</h1>
        </div>
      </header>
    );
  }

  private renderMain () {
    return (
      <div className='main'>
        <Col className='col-main' xs={12}>
          <Row>
            <Col xs={10} sm={8} lg={6}>
              <Form className='form-page modal-form-style' {...this.formManager.formProps}>
                {this.renderFieldSet(this.idFieldSet)}
                {this.renderFieldSet(this.fullNameFieldSet)}

                <hr />
                <h5>Contact Information</h5>
                <Spacer />

                {this.renderFieldSet(this.emailFieldSet)}

                <Spacer />
                {this.renderToggleButton()}
                <Spacer />

                {this.renderMoreOptionsGroup()}

                <hr />
                {this.formManager.errorMessages.map(error => (
                  <Row className='text-danger' key={error.field}>
                    <strong>{error.field}</strong> - {error.message}
                  </Row>
                ))}
                {!!this.formManager.errorMessages.length && <br/> }
                <div className='footer'>
                  <Button bsSize='large' bsStyle='primary' disabled={this.formManager.isSubmitting} type='submit'>
                    {this.formManager.isSubmitting ? 'Saving...' : 'Save'}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </div>
    );
  }

  public render () {
    return (
      <Page name='edit-contact' type='detail'>
        <Page.Content>
          {this.renderHeader()}
          {this.renderMain()}
        </Page.Content>
      </Page>
    );
  }
}

export default ActionContactPage;
