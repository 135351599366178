import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import autoBindMethods from 'class-autobind-decorator';

import ActionContactPage from './ActionContactPage';
import ClientsClass from '../../clients/ClientsClass';
import ContactExistsModal from './ContactExistsModal';
import ICase from '../../interfaces/models/ICase';
import { AppConstants } from '../../constants';
import { FormattingUtils, SmartBool } from '../../utils';
import { Loader } from '../common';
import { ContactStoreClass } from '../../stores';

const { getNameOrDefault, splitName } = FormattingUtils;
const {
  CONTACT_TYPES,
  CONTACT_URL_TYPE_MAP,
} = AppConstants;

interface IProps {
  location: {
    state?: string;
  };
  params: {
    id: string;
    type: string;
  };
}

interface IInjected extends IProps {
  ContactStore: ContactStoreClass;
  Clients: ClientsClass;
}

@inject('Clients', 'ContactStore')
@autoBindMethods
@observer
class CreateContactPage extends Component<IProps> {
  @observable private _case: ICase | undefined;
  @observable private showContactExistsModal = new SmartBool();
  private initialName: string[] = [];

  public componentDidMount () {
    if (!Object.keys(CONTACT_URL_TYPE_MAP).includes(this.props.params.type)) {
      browserHistory.push(`/case/${this.props.params.id}/contacts`);
      return;
    }

    this.initialName = splitName(this.props.location.state || '');
    this.fetchCase(this.props.params.id);
  }

  private get injected () {
    return this.props as IInjected;
  }

  private get contactType () {
    return CONTACT_URL_TYPE_MAP[this.props.params.type];
  }

  private async fetchCase (caseId: string) {
    const { Clients } = this.injected;

    const _case = await Clients.cases.retrieve(caseId)
      , contactExists = this.atContactTypeMax(_case)
      ;

    if (contactExists) {
      this.showContactExistsModal.setTrue();
    }
    this._case = _case;
  }

  private atContactTypeMax (_case: ICase) {
    if (this.contactType === CONTACT_TYPES.ATTORNEY.type) {
      return !!_case.attorney;
    }

    return false;
  }

  public browserRedirect () {
    browserHistory.push(`case/${this.props.params.id}/contacts`);
  }

  private get readableContactType () {
    return CONTACT_TYPES[this.contactType].display;
  }

  private get headerText () {
    return `Add New ${this.readableContactType} for ${getNameOrDefault(this._case && this._case.plaintiff)}`;
  }

  public async onSubmit (formData: any) {
    const { ContactStore } = this.injected
      , contactData = {...formData, contact_type: this.contactType};

    await ContactStore.create({
      _case: this._case,
      contactData,
      contactType: this.contactType,
    });
  }

  public render () {
    const initialData = {
      first_name: this.initialName[0],
      last_name: this.initialName[1],
    };

    if (!this._case) {
      return <Loader className='page-loader' logo />;
    }

    if (this.showContactExistsModal.isTrue) {
      return (
        <ContactExistsModal
          contactType={this.readableContactType}
          linkPath={`case/${this.props.params.id}/contacts`}
          plaintiffName={getNameOrDefault(this._case.plaintiff)}
        />
      );
    }

    return (
      <ActionContactPage
        action='create'
        browserRedirect={this.browserRedirect}
        contactType={this.contactType}
        headerText={this.headerText}
        initialData={initialData}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default CreateContactPage;
