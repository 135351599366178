/* tslint:disable max-classes-per-file */
import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';
import { omit, noop } from 'lodash';
import { Button } from 'react-bootstrap';

import SmartBool from '../../utils/SmartBool';
import { Icon } from '../common';

interface IBootstrapButton {
  bsSize?: 'lg' | 'xs' | 'small' | 'medium' | 'large' | 'xsmall' | 'sm';
  bsStyle?: string;
  className?: string;
}

interface IActionButtonProps extends IBootstrapButton {
  data?: any;
  onClick?: (data?: any) => void;
}

interface IActionButtonPropDefaults extends IActionButtonProps {
  onClick: (data?: any) => void;
}

@autoBindMethods
class ActionButton extends Component<IActionButtonProps> {
  public static defaultProps: Partial<IActionButtonProps> = {
    onClick: noop,
  };

  get propsWithDefaults () {
    return this.props as IActionButtonPropDefaults;
  }

  public handleClick () {
    const { onClick, data } = this.propsWithDefaults;
    onClick(data);
  }

  public render () {
    return <Button bsStyle='link' {...this.propsWithDefaults} onClick={this.handleClick} />;
  }
}

interface IAwaitButtonProps extends IBootstrapButton {
  addonAfter?: JSX.Element;
  addonBefore?: JSX.Element;
  awaitText?: string;
  data?: any;
  disabled?: boolean;
  onClick: (data?: any) => Promise<any>;
  text: string;
}

interface IAwaitButtonPropDefaults extends IAwaitButtonProps {
  awaitText: string;
}

@observer
@autoBindMethods
class AwaitButton extends Component<IAwaitButtonProps> {
  private isAwaiting = new SmartBool();

  public static defaultProps: Partial<IAwaitButtonProps> = {
    awaitText: 'Loading...',
  };

  get propsWithDefaults () {
    return this.props as IAwaitButtonPropDefaults;
  }

  get buttonProps () {
    const OMIT = ['awaitText', 'text', 'addonAfter', 'addonBefore', 'data', 'onClick', 'disabled'];
    return omit(this.props, OMIT);
  }

  public async handleClick () {
    const { onClick, data } = this.propsWithDefaults;
    await this.isAwaiting.until(onClick(data));
  }

  public render () {
    const {
      addonAfter,
      addonBefore,
      awaitText,
      disabled,
      text,
    } = this.propsWithDefaults;

    return (
      <Button
        bsStyle='link'
        disabled={disabled || this.isAwaiting.isTrue}
        onClick={this.handleClick}
        {...this.buttonProps}
      >
        {addonBefore}
        {this.isAwaiting.isTrue ? awaitText : text}
        {addonAfter}
      </Button>
    );
  }
}

@autoBindMethods
class LinkButton extends Component<any> {
  public render () {
    return (
      <Button {...this.props} bsStyle='link'>
        {this.props.children}
      </Button>
    );
  }
}

@autoBindMethods
class PrimaryButton extends Component<any> {
  public render () {
    return (
      <Button bsSize='small' {...this.props} bsStyle='primary'>
        {this.props.children}
      </Button>
    );
  }
}

@autoBindMethods
class DangerButton extends Component<any> {
  public render () {
    return (
      <Button bsSize='small' {...this.props} bsStyle='danger'>
        {this.props.children}
      </Button>
    );
  }
}

@autoBindMethods
class DownloadButton extends Component<any> {
  public render () {
    return (
      <LinkButton {...this.props}>
        <Icon type='download' />
        {this.props.children}
      </LinkButton>
    );
  }
}

const DeleteButton = (props: any) => (
  <Button {...props} bsStyle='link'>
    <Icon type='trash-o' />
  </Button>
);

export {
  ActionButton,
  AwaitButton,
  DangerButton,
  DeleteButton,
  DownloadButton,
  LinkButton,
  PrimaryButton,
};
