import React, { Component } from 'react';
import _ from 'lodash';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { getDisplayName, renderNothing } from 'recompose';

import FunderStoreClass from '../stores/FunderStoreClass';

export interface IProps {
  disabledComponent: React.ComponentClass;
  enabledComponent: React.ComponentClass;
}

interface IInjected extends IProps {
  FunderStore: FunderStoreClass;
}

function createGuardedMedicalContainer ({ enabledComponent, disabledComponent = renderNothing() }: IProps) {
  @inject('FunderStore')
  @observer
  class GuardedContainer extends Component {
    public static displayName = `GuardedContainer(${getDisplayName(enabledComponent)})`;

    get injected () {
      return this.props as IInjected;
    }

    @computed
    get userHasPermission () {
      const { FunderStore } = this.injected;

      return FunderStore.doesMedicalFunding;
    }

    public render () {
      const passThroughProps = _.omit(this.props, 'FunderStore')
        , GuardedComponent = this.userHasPermission ? enabledComponent : disabledComponent;

      return <GuardedComponent {...passThroughProps} />;
    }
  }

  return GuardedContainer;
}

export default createGuardedMedicalContainer;
