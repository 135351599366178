import React from 'react';
import { inject } from 'mobx-react';
import { UiStoreClass } from '../../stores';

import NavDropdown from './NavDropdown';
import {
  AttorneyListPageLink,
  GuardedFacilityListPageLink,
  GuardedProviderListPageLink,
  LawFirmListPageLink,
} from './PageLinks';

interface IInjected {
  UiStore: UiStoreClass;
}

const UnTypedGuardedFacilityListPageLink = GuardedFacilityListPageLink as any;
const UnTypedGuardedProviderListPageLink = GuardedProviderListPageLink as any;

const ContactsNavDropdown: React.SFC = props => {
  const { UiStore } = props as IInjected;

  return (
    <NavDropdown id='contacts-dropdown' title='Contacts'>
      <LawFirmListPageLink query={UiStore.organizationListPage.query} />
      <AttorneyListPageLink query={UiStore.contactListPage.query} />
      <UnTypedGuardedFacilityListPageLink query={UiStore.organizationListPage.query} />
      <UnTypedGuardedProviderListPageLink query={UiStore.contactListPage.query} />
    </NavDropdown>
  );
};

export default inject('UiStore')(ContactsNavDropdown);
