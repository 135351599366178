/* eslint-disable sort-keys */

const FIELD_SETS_PLAINTIFF = [
  [
    {
      className: 'fs-mask',
      editProps: {
        validationErrors: { isValidPlaintiffName: 'First name cannot be greater than 30 characters' },
        validations: { isValidPlaintiffName: true },
      },
      field: 'first_name',
      required: true,
      writeOnly: true,
    },
    {
      className: 'fs-mask',
      editProps: {
        validationErrors: { isValidPlaintiffName: 'Last name cannot be greater than 30 characters' },
        validations: { isValidPlaintiffName: true },
      },
      field: 'last_name',
      required: true,
      writeOnly: true,
    },
  ],
  [
    { field: 'phone_number', label: 'Phone #', type: 'phone' },
    { field: 'email', type: 'email' },
    { field: 'birthdate', type: 'date', className: 'fs-mask' },
    { field: 'sex', type: 'optionSelect', optionType: 'sexOptions', optionKey: 'value', nullify: false },
    { field: 'social_security_number', label: 'SSN', type: 'ssn', className: 'fs-mask' },
  ],
  [
    { field: 'address', type: 'address' },
  ],
];

export default FIELD_SETS_PLAINTIFF;
