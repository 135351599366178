import { IFieldSetPartial } from '../../../lib/mighty-fields';

const FIELD_SETS_PLAINTIFF_INFO: IFieldSetPartial[] = [[
  {
    className: 'fs-mask',
    editProps: {
      validationErrors: { isValidPlaintiffName: 'First name cannot be greater than 30 characters' },
      validations: { isValidPlaintiffName: true },
    },
    field: 'first_name',
    required: true,
  },
  {
    className: 'fs-mask',
    editProps: {
      validationErrors: { isValidPlaintiffName: 'Last name cannot be greater than 30 characters' },
      validations: { isValidPlaintiffName: true },
    },
    field: 'last_name',
    required: true,
  },
  {
    field: 'state',
    optionKey: 'code',
    optionType: 'stateOptions',
    required: true,
    type: 'optionSelect',
  },
]];

const FIELD_SETS_CASE_INFO: IFieldSetPartial[] = [[
  {
    field: 'type',
    optionType: 'caseTypeOptions',
    required: true,
    type: 'optionSelect',
  },
  {
    field: 'state_of_incident',
    optionKey: 'code',
    optionType: 'stateOptions',
    required: true,
    type: 'optionSelect',
  },
]];

const FIELD_SETS_CASE_SHARING = [[{
  field: 'migrate_to_registry',
  label: 'Share this case with the associated law firm',
  type: 'checkbox',
}]];

export {
  FIELD_SETS_CASE_INFO,
  FIELD_SETS_CASE_SHARING,
  FIELD_SETS_PLAINTIFF_INFO,
};
