import autoBindMethods from 'class-autobind-decorator';
import { observable, toJS, action } from 'mobx';
import { noop, get, isBoolean, has, set, toString } from 'lodash';
import httpStatus from 'http-status-codes';

import { IFieldConfig, IFieldSetPartial } from './interfaces';

import FormattingUtils from '../../utils/FormattingUtils';

import {
  fillInFieldConfigs,
  fillInFieldSets,
  getCreateFields,
  isTypeFieldConfigObjectSearchCreate,
} from './common';

import backendValidation from './backendValidation';

const { splitName } = FormattingUtils;

interface IArgs {
  fieldSets: IFieldSetPartial[];
  model: { [key: string]: any, id?: string };
  onChange: (data: object) => void;
  onSave: (data: { [key: string]: any }) => void;
  onSuccess: () => void;
  onSuccessClear: boolean;
  wrapperName: string | null;
}

@autoBindMethods
class FormManager {
  @observable public isSubmitting = false;
  @observable public errorMessages: Array<{ field: string, message: string }> = [];

  @observable private isValid = false;
  @observable private isAddNew = observable.map({});
  @observable private model = observable.map({});
  @observable private submitErrors = false;

  public onChange: { [key: string]: (value: any) => void } = {};

  private refForm?: {
    updateInputsWithError: (errors: object) => void,
    reset: () => void;
    refForm: {
      getCurrentValues: () => object,
    }
  };

  private args: IArgs = {
    fieldSets: [],
    model: {},
    onChange: noop,
    onSave: noop,
    onSuccess: noop,
    onSuccessClear: false,
    wrapperName: null,
  };

  private initField (model: object, fieldConfig: IFieldConfig) {
    let val = toJS(get(model, fieldConfig.field, ''));

    if (val === null) {
      val = '';
    }

    // <Dropdown> does not allow non-string values.
    if (isBoolean(val)) {
      val = toString(val);
    }

    this.model.set(fieldConfig.field, val);
  }

  constructor (args: Partial<IArgs>) {
    this.args = {
      ...this.args,
      ...args,
    };

    this.args.fieldSets = fillInFieldSets(this.args.fieldSets);
    const fieldConfigs = fillInFieldConfigs(this.args.fieldSets);
    fieldConfigs.forEach(fieldConfig => {
      this.initField(this.args.model, fieldConfig);
      this.onChange[fieldConfig.field] = this.handleChange.bind(this, fieldConfig);
    });

    // Save ID for editing
    if (has(this.args.model, 'id')) {
      this.model.set('id', toJS(this.args.model.id));
    }
  }

  public reset () {
    const fieldConfigs = fillInFieldConfigs(this.args.fieldSets);
    fieldConfigs.forEach(fieldConfig => {
      if (isTypeFieldConfigObjectSearchCreate(fieldConfig) && fieldConfig.createFields) { this.addNewUndo(fieldConfig); }
      this.initField(this.args.model, fieldConfig);
    });

    if (this.refForm) {
      this.refForm.reset();
    }
    this.submitErrors = false;
    this.errorMessages = [];
  }

  private handleChange (fieldConfig: IFieldConfig, value: any) {
    this.model.set(fieldConfig.field, value);
    this.args.onChange(this.submitData);

    const { required } = fieldConfig
      , isAddNew = this.isAddNew.get(fieldConfig.field);

    const shouldSubmitOnChange = (
      isTypeFieldConfigObjectSearchCreate(fieldConfig)
      && fieldConfig.onChangeSubmit
      && !isAddNew
      && (!required || !!value)
    );
    if (shouldSubmitOnChange) {
      this.onValidSubmit();
    }
  }

  get formProps () {
    return {
      disabled: this.isSubmitting,
      onInvalidSubmit: this.handleInvalidSubmit,
      onValidChange: this.handleValidChange,
      onValidSubmit: this.onValidSubmit,
      ref: this.setRefForm,
    };
  }

  get formGroupsProps () {
    return {
      addNew: this.addNew,
      addNewUndo: this.addNewUndo,
      model: this.model,
      onChange: this.onChange,
      showNew: this.showNew,
    };
  }

  private handleInvalidSubmit () {
    this.submitErrors = true;
  }

  private handleValidChange (isValid: boolean) {
    this.isValid = isValid;
  }

  public submitDisabled () {
    return this.isSubmitting || (this.submitErrors && !this.isValid);
  }

  public setRefForm (comp: any) {
    this.refForm = comp;
  }

  get submitData () {
    const dataFlat: { [key: string]: any } = toJS(this.model)
      , fieldConfigs = fillInFieldConfigs(this.args.fieldSets);

    // Nullify fields, as specified by field config
    fieldConfigs.forEach(fieldConfig => {
      if (fieldConfig.nullify && !dataFlat[fieldConfig.field]) {
        dataFlat[fieldConfig.field] = null;
      }
    });

    // Expend dot notation like 'a.b': v into 'a': { 'b': v }
    const data = {};
    Object.keys(dataFlat).forEach(key => {
      set(data, key, dataFlat[key]);
    });

    if (this.args.wrapperName) {
      return { [this.args.wrapperName]: data };
    }

    return data;
  }

  private addNewUndo (fieldConfig: IFieldConfig) {
    this.isAddNew.set(fieldConfig.field, false);
    this.initField(this.args.model, fieldConfig);
  }

  @action
  private addNew (fieldConfig: IFieldConfig, search: string) {
    if (!isTypeFieldConfigObjectSearchCreate(fieldConfig)) {
      // istanbul ignore next
      return;
    }

    // If the user wants to add an object instead of searching,
    // show the fields for that object
    this.isAddNew.set(fieldConfig.field, true);

    // Initialize the fields for that object in the data
    const emptyObj: { [key: string]: any } = {};
    getCreateFields(fieldConfig).forEach(createConfig => {
      emptyObj[createConfig.field] = '';
      if (createConfig.populateFromSearch && search.length) {
        emptyObj[createConfig.field] = search;
      }
      else if (createConfig.populateNameFromSearch && search.length) {
        [emptyObj.first_name, emptyObj.last_name] = splitName(search);
      }
    });
    this.model.set(fieldConfig.field, observable.map(emptyObj));
  }

  private showNew (field: string) {
    return !!this.isAddNew.get(field);
  }

  @action
  private async onValidSubmit (_model?: any, _resetForm?: any, _invalidateForm?: any) {
    const { onSave, onSuccess } = this.args;
    this.isSubmitting = true;
    this.errorMessages = [];

    try {
      await onSave(this.submitData);
      onSuccess();
      if (this.args.onSuccessClear) {
       this.reset();
      }
    }
    catch (err) {
      if (get(err, 'response.status') === httpStatus.BAD_REQUEST && this.refForm) {
        const fieldNames = Object.keys(this.refForm.refForm.getCurrentValues());
        const { foundOnForm, errorMessages } = backendValidation(fieldNames, err.response.data);

        this.errorMessages = errorMessages;
        this.refForm.updateInputsWithError(foundOnForm);
      }
      else {
        // tslint:disable-next-line no-console
        console.error(err);
      }

      if (!this.errorMessages.length) {
        this.errorMessages.push({ field: 'Error', message: 'An error occurred saving.' });
      }
    }
    this.isSubmitting = false;
  }
}

export default FormManager;
