import React, { Component } from 'react';
import { observable, toJS, action } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { stubArray, get, isBoolean, identity, negate } from 'lodash';
import { Button } from 'react-bootstrap';

import { Icon } from '../common';
import { Form, Typeahead } from '../common-formsy';
import { FormattingUtils, SmartBool } from '../../utils';
import { IFacetOption } from '../../interfaces';

import Facet from './Facet';
import FacetList from './FacetList';

const { getNameOrDefault, getOrDefault, toKey } = FormattingUtils;

interface IProps {
  collapsible?: boolean;
  disabled?: boolean;
  fetchOptions: (params: object, fieldName: string) => IFacetOption[];
  fetchSearch?: (params: object, fieldName: string) => Promise<IFacetOption[]>;
  fieldName: string;
  filters: { [key: string]: string[] };
  isCollapsed?: boolean;
  label: string;
  loading?: boolean;
  maxExpanded?: number | boolean;
  maxShowing?: number | boolean;
  metaFields?: any[];
  onCheckboxChange: (value: string, checked: boolean, fieldName: string) => void;
  onSelect?: (item: object, fieldName: string) => void;
  renderer?: (name: string) => string;
  reset?: (fieldName: string) => void;
  showSearch?: boolean;
  sorted?: boolean;
}

interface IPropDefaults extends IProps {
  collapsible: boolean;
  fetchSearch: (params: object, fieldName: string) => Promise<IFacetOption[]>;
  isCollapsed: boolean;
  maxExpanded: number;
  maxShowing: number;
  renderer: (name: string) => string;
}

@autoBindMethods
@observer
class FacetComplete extends Component<IProps, {}> {
  @observable private facetOptions: IFacetOption[] = [];
  @observable private searchOptions: IFacetOption[] = [];
  @observable private isLoadingInitial: boolean = true;
  @observable private isCollapsed: boolean = true;
  @observable private showMore = new SmartBool();

  public static defaultProps: Partial<IProps> = {
    collapsible: true,
    fetchSearch: stubArray as any,
    isCollapsed: true,
    maxExpanded: 15,
    maxShowing: 5,
    renderer: identity,
  };

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  constructor (props: IPropDefaults) {
    super(props);
    this.isCollapsed = props.collapsible && props.isCollapsed;
  }

  public componentDidMount () {
    // When facet is open on mount
    if (this.showingList()) {
      this.loadFacetOptions();
    }
  }

  public componentWillReceiveProps (nextProps: IProps) {
    const { filters } = this.props
      , openingList = !this.showingList() && this.showingList(nextProps)
      , filterChange = this.showingList() && toKey(nextProps.filters) !== toKey(filters);

    // When facet is opened by props
    if (openingList || filterChange) {
      this.loadFacetOptions(nextProps);
    }
  }

  private get loading () {
    return this.isLoadingInitial || this.props.loading;
  }

  private async loadFacetOptions (propsArg?: IProps) {
    const props = propsArg || this.props
      , { filters, fieldName, fetchOptions } = props
      , facetOptions = await fetchOptions(filters, fieldName);

    this.facetOptions = facetOptions;
    this.isLoadingInitial = false;
  }

  private optionChecked (facetOption: IFacetOption) {
    const { filters, fieldName } = this.props
      , currentFilters = get(filters, fieldName, []) as string[];
    return currentFilters.includes(facetOption.value);
  }

  private get options () {
    return this.facetOptions
      .map(facetOption => ({ ...facetOption, checked: this.optionChecked(facetOption) }));
  }

  private get optionsChecked () {
    return this.facetOptions
      .filter(this.optionChecked)
      .map(facetOption => ({ ...facetOption, checked: true }));
  }

  private get optionsUnchecked () {
    return this.facetOptions
      .filter(negate(this.optionChecked))
      .map(facetOption => ({ ...facetOption, checked: false }))
      ;
  }

  private get showingOptions () {
    const { maxShowing, maxExpanded, sorted } = this.propsWithDefaults
      , max = this.showMore.isTrue ? maxExpanded : maxShowing
      , checked = this.optionsChecked;

    const unchecked = this.optionsUnchecked
      , optionsSorted = sorted ? this.options : checked.concat(unchecked);

    // Show all if max is set to false, or length is less than max
    if (!max || optionsSorted.length <= max) {
      return optionsSorted;
    }

    // Show all checked if greater than max
    if (max && checked.length >= max) {
      return checked;
    }

    return optionsSorted.slice(0, max);
  }

  private get showSearch () {
    const { maxExpanded, showSearch } = this.propsWithDefaults;
    if (isBoolean(showSearch)) { return showSearch; }
    return !!this.optionsUnchecked.length && maxExpanded && this.facetOptions.length > maxExpanded;
  }

  private get showExpand () {
    const { maxShowing } = this.propsWithDefaults;
    return !this.showMore.isTrue && maxShowing && this.facetOptions.length > maxShowing;
  }

  private hasValue (props?: IProps) {
    const { filters, fieldName } = props || this.props;
    return !!get(filters, fieldName, []).length;
  }

  private showingList (propsArg?: IProps) {
    const props = propsArg || this.props
      , open = (this.hasValue(props) || !this.isCollapsed);
    return (open && !props.loading);
  }

  private async onToggleCollapse (isCollapsed: boolean) {
    this.isCollapsed = isCollapsed;
    // When facet is opened by header
    if (!isCollapsed) {
      this.loadFacetOptions();
    }
  }

  private typeaheadOnChange (item: any) {
    const { onSelect, fieldName } = this.propsWithDefaults;
    if (item === undefined || !onSelect) {
      return;
    }
    onSelect(item, fieldName);
  }

  @action
  private async onInputChange (search: string) {
    const { filters, fetchSearch, fieldName } = this.propsWithDefaults;
    this.searchOptions = await fetchSearch({ search, ...filters }, fieldName);
  }

  private renderTypeaheadItem (option: IFacetOption) {
    const { metaFields, renderer } = this.propsWithDefaults,
      metadata = (metaFields || [])
        .map(field => getOrDefault(get(option, field)))
        .join(', ');

    return (
      <div className='contact-item'>
        <div>{renderer(getNameOrDefault(option))}</div>
        <div>{metadata}</div>
      </div>
    );
  }

  private renderLoader () {
    if (!this.loading || !this.isLoadingInitial) {
      return;
    }

    return (
      <div className='facet-loader'>
        <div className='spinner' />
      </div>
    );
  }

  private renderEmpty () {
    if (this.loading || this.facetOptions.length) {
      return;
    }

    return (
      <div className='facet-loader'>No filters applicable</div>
    );
  }

  private renderExpand () {
    if (!this.showExpand) {
      return;
    }

    return (
      <Button className='btn-more' bsStyle='link' onClick={this.showMore.setTrue}>
        <Icon type='angle-double-down' />Show more
      </Button>
    );
  }

  public render () {
    const {
        collapsible,
        disabled,
        fieldName,
        label,
        onCheckboxChange,
        renderer,
        reset,
      } = this.propsWithDefaults;

    return (
      <Form>
        <Facet
          collapsible={collapsible}
          fieldName={fieldName}
          hasValue={this.hasValue()}
          label={label}
          onToggleCollapse={this.onToggleCollapse}
          reset={reset}
        >
          {this.showSearch && (
            <Typeahead
              disabled={this.loading}
              fuzzySearch
              name={`facet-api_${fieldName}_search`}
              onChange={this.typeaheadOnChange}
              onInputChange={this.onInputChange}
              options={toJS(this.searchOptions)}
              placeholder={`Search ${label}...`}
              renderItem={this.renderTypeaheadItem}
            />)}

          {this.renderLoader()}
          {this.renderEmpty()}

          <FacetList
            disabled={disabled}
            facetOptions={this.showingOptions}
            fieldName={fieldName}
            onCheckboxChange={onCheckboxChange}
            renderer={renderer}
          />

          {this.renderExpand()}
        </Facet>
      </Form>
    );
  }
}
export default FacetComplete;
