/* tslint:disable max-classes-per-file */
import React, { Component } from 'react';
import cx from 'classnames';

import { Col, Grid } from 'react-bootstrap';
import { AppConstants } from '../../constants';
const { WELL_COL_LABEL, WELL_COL_VALUE } = AppConstants;

interface IPropsValue {
  className?: any;
}

class Value extends Component<IPropsValue, {}> {
  public render () {
    return (
      <Col
        xs={WELL_COL_VALUE}
        className={cx(this.props.className, 'col-value')}
      >
        {this.props.children}
      </Col>
    );
  }
}

interface IPropsLabel {
  className?: any;
}

class Label extends Component<IPropsLabel, {}> {
  public render () {
    return (
      <Col
        xs={WELL_COL_LABEL}
        className={cx(this.props.className, 'col-label')}
      >
        {this.props.children}
      </Col>
    );
  }
}

interface IPropsInfoWell {
  className?: any;
}

class InfoWell extends Component<IPropsInfoWell, {}> {
  public static Label = Label;
  public static Value = Value;

  public render () {
    return (
      <Grid fluid className={cx(this.props.className, 'info-well')}>
        {this.props.children}
      </Grid>
    );
  }
}

export default InfoWell;
