import React, { Component } from 'react';
import { toJS } from 'mobx';
import { inject } from 'mobx-react';

import {
  Row,
} from 'react-bootstrap';

import CaseActivity from './CaseActivity';

import {
  IActivity,
} from '../../interfaces';
import { OptionsStoreClass } from '../../stores';

import FormattingUtils from '../../utils/FormattingUtils';

const { parseAndPreserveNewlines } = FormattingUtils;

interface IDocumentSharingRequestActivity extends IActivity {
  document_sharing_task: {
    document_types_requested: number[];
    recipient_email: string;
    recipient_name: string;
    requester_note: string;
  };
}

interface IProps {
  activity: IDocumentSharingRequestActivity;
}

interface IInjected extends IProps {
  OptionsStore: OptionsStoreClass;
}

@inject('OptionsStore')
class DocumentsSharingRequest extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public render () {
    const { document_types_requested, recipient_email, recipient_name, requester_note } = this.props.activity.document_sharing_task
      , { OptionsStore } = this.injected
      , documentOptions = toJS(OptionsStore.documentTypeOptions)
      , activityBody = (
        <div>
          <h3>Documents Requested</h3>
          <p><span className='status'>To: </span>{`${recipient_name} (${recipient_email})`}</p>
          <Row className='status'>Documents Requested:</Row>
          <ul>
            {document_types_requested.map((type, idx) => {
              const documentOption = documentOptions.find((option) => option.id === type);

              return (
                <li key={idx}>
                {documentOption && documentOption.name}
                </li>
              );
            })}
          </ul>
          <div>{parseAndPreserveNewlines(requester_note)}</div>
        </div>
      );

    return (
      <CaseActivity
        activity_body={activityBody}
        dot='icon'
        icon='file'
        {...this.props}
      />
    );
  }
}

export default DocumentsSharingRequest;
