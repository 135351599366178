/* ignoring file for coverage as we'll be testing this in the document request page */
/* TODO - remove next line */
/* istanbul ignore file */
import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Typeahead } from 'react-bootstrap-typeahead';
import {
  ControlLabel,
  FormGroup,
  HelpBlock,
} from 'react-bootstrap';
import { noop } from 'lodash';

import { createInputContainer } from '../../containers';
import { IFormInput, IInputContainer } from '../../interfaces';
import { SmartBool } from '../../utils';

interface IProps extends IFormInput {
  defaultSelected?: any[];
  emptyLabel?: string;
  labelKey?: string;
  minLength?: number;
  options?: any[];
  placeholder?: string;
}

interface IPropDefaults extends IProps {
  defaultSelected: any[];
  onChange: (value: any) => void;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@autoBindMethods
@observer
class MultiSelectTypeahead extends Component<IProps> {
  @observable private value: any[];
  @observable private isFocused = new SmartBool();

  public static defaultProps: Partial<IProps> = {
    defaultSelected: [],
    disabled: false,
    emptyLabel: 'No results found.',
    labelKey: 'name',
    minLength: 0,
    onChange: noop,
    options: [],
    placeholder: '',
    required: false,
  };

  constructor (props: IProps) {
    super(props);

    this.value = this.propsWithDefaults.defaultSelected;
  }

  get formsy () {
    return this.props as IPropsFormsy;
  }

  private get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  private onChange (value: any[]) {
    this.value = value;

    this.formsy.setValue(value.length ? value : '');
    this.propsWithDefaults.onChange(value);
  }

  public render () {
    const {
        disabled,
        label,
        labelKey,
        options,
        placeholder,
        required,
      } = this.propsWithDefaults,
      {
        controlId,
        formGroupId,
        showRequiredMessage,
      } = this.formsy
      , className = cx(
        'form-group-typeahead',
        'typeahead-multi',
        'has-value',
        {focused: this.isFocused.isTrue},
        {'has-error': showRequiredMessage},
      );

    return (
      <FormGroup
        id={formGroupId}
        controlId={controlId}
        className={className}
      >
        {label &&
          <ControlLabel>
            {label}
            {required &&
              <span className='required'>*</span>
            }
          </ControlLabel>
        }

        <Typeahead
          disabled={disabled}
          labelKey={labelKey}
          multiple
          onBlur={this.isFocused.setFalse}
          onChange={this.onChange}
          onFocus={this.isFocused.setTrue}
          options={options}
          placeholder={placeholder}
          selected={toJS(this.value)}
        />

        {showRequiredMessage &&
          <HelpBlock>{label || 'This field'} is required.</HelpBlock>}
      </FormGroup>
    );
  }
}

export default MultiSelectTypeahead;
