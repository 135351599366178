/* tslint:disable no-magic-numbers object-literal-sort-keys */

/*
  Backend Statuses:

  NEW = 'NEW'
  RESTARTED = 'RESTARTED'
  VALIDATION_QUEUED = 'VALIDATION_QUEUED'
  VALIDATION_IN_PROGRESS = 'VALIDATION_IN_PROGRESS'
  PASSED_VALIDATION = 'PASSED_VALIDATION'
  PASSED_VALIDATION_WITH_WARNINGS = 'PASSED_VALIDATION_WITH_WARNINGS'
  FAILED_VALIDATION = 'FAILED_VALIDATION'
  FAILED_VALIDATION_LENGTH = 'FAILED_VALIDATION_LENGTH'
  TEST_COMMIT_QUEUED = 'TEST_COMMIT_QUEUED'
  TEST_COMMIT_IN_PROGRESS = 'TEST_COMMIT_IN_PROGRESS'
  PASSED_TEST_COMMIT = 'PASSED_TEST_COMMIT'
  FAILED_TEST_COMMIT = 'FAILED_TEST_COMMIT'
  PREVIEW_APPROVED = 'PREVIEW_APPROVED'
  PREVIEW_REJECTED = 'PREVIEW_REJECTED'
  COMMIT_QUEUED = 'COMMIT_QUEUED'
  COMMIT_IN_PROGRESS = 'COMMIT_IN_PROGRESS'
  COMMITTED = 'COMMITTED'
  FAILED_COMMIT = 'FAILED_COMMIT'
  POST_COMMIT_PROCESSING_QUEUED = 'POST_COMMIT_PROCESSING_QUEUED'
  POST_COMMIT_PROCESSING_IN_PROGRESS = 'POST_COMMIT_PROCESSING_IN_PROGRESS'
  PASSED_POST_COMMIT_PROCESSING = 'PASSED_POST_COMMIT_PROCESSING'
  FAILED_POST_COMMIT_PROCESSING = 'FAILED_POST_COMMIT_PROCESSING'
  REVERT_QUEUED = 'REVERT_QUEUED'
  REVERT_IN_PROGRESS = 'REVERT_IN_PROGRESS'
  REVERTED = 'REVERTED'
  FAILED_REVERT = 'FAILED_REVERT'
  SPLIT_QUEUED = 'SPLIT_QUEUED'
  SPLIT_IN_PROGRESS = 'SPLIT_IN_PROGRESS'
  SPLIT = 'SPLIT'
  FAILED_SPLIT = 'FAILED_SPLIT'
 */

// Visual Steps used by the <StepProgress> Component.
export const PROGRESS_STEPS = [
  { key: 'upload_file', displayName: 'Upload\nFile' },
  { key: 'validate_data', displayName: 'Validate\nData' },
  { key: 'test_import', displayName: 'Test\nImport' },
  { key: 'preview_approval', displayName: 'Preview\nApproval' },
  { key: 'final_import', displayName: 'Final\nImport' },
  { key: 'import_complete', displayName: 'Import\nComplete' },
];

// Since FS imports don't have any post-commit processing, we treat those steps the same
// way as COMMITTED in the UI.
export const TERMINAL_STEPS = [
  'COMMITTED',
  'FAILED_COMMIT',
  'POST_COMMIT_PROCESSING_QUEUED',
  'POST_COMMIT_PROCESSING_IN_PROGRESS',
  'PASSED_POST_COMMIT_PROCESSING',
  'FAILED_POST_COMMIT_PROCESSING',
  'REVERTED',
  'SPLIT',
];

/*
  This associative array is used to define how a specific step is displayed.
  - progressStep -> The associated PROGRESS_STEPS to update the <StepProgress>
  - progressInfo -> The associated ImportDetailPage.stepsInfo to display under the <StepProgress>
  - style -> The <StepProgress> bsStyle (danger for error type steps, success for others)
  - active -> Whether the <StepProgress> is animating (active mode).
  - complete -> Simply used to know that the import is complete, hide the <StepProgress> and show the "Import Complete" display.
 */
export const IMPORT_STEPS: { [s: string]: any; } = {
  NEW: {
    key: 'NEW',
    progressInfo: 'validate_data',
    progressStep: PROGRESS_STEPS[1].key,
    style: 'success',
  },
  RESTARTED: {
    key: 'RESTARTED',
    progressInfo: 'validate_data',
    progressStep: PROGRESS_STEPS[1].key,
    style: 'success',
  },
  VALIDATION_QUEUED: {
    active: true,
    key: 'VALIDATION_QUEUED',
    progressInfo: 'validate_data_active',
    progressStep: PROGRESS_STEPS[1].key,
    style: 'success',
  },
  VALIDATION_IN_PROGRESS: {
    active: true,
    key: 'VALIDATION_IN_PROGRESS',
    progressInfo: 'validate_data_active',
    progressStep: PROGRESS_STEPS[1].key,
    style: 'success',
  },
  PASSED_VALIDATION: {
    key: 'PASSED_VALIDATION',
    progressInfo: 'test_import',
    progressStep: PROGRESS_STEPS[2].key,
    style: 'success',
  },
  PASSED_VALIDATION_WITH_WARNINGS: {
    key: 'PASSED_VALIDATION_WITH_WARNINGS',
    progressInfo: 'test_import',
    progressStep: PROGRESS_STEPS[2].key,
    style: 'success',
  },
  FAILED_VALIDATION: {
    key: 'FAILED_VALIDATION',
    progressInfo: 'validate_data_failed',
    progressStep: PROGRESS_STEPS[1].key,
    style: 'danger',
  },
  FAILED_VALIDATION_LENGTH: {
    key: 'FAILED_VALIDATION_LENGTH',
    progressInfo: 'validate_length_failed',
    progressStep: PROGRESS_STEPS[1].key,
    style: 'danger',
  },
  TEST_COMMIT_QUEUED: {
    active: true,
    key: 'TEST_COMMIT_QUEUED',
    progressInfo: 'test_import_active',
    progressStep: PROGRESS_STEPS[2].key,
    style: 'success',
  },
  TEST_COMMIT_IN_PROGRESS: {
    active: true,
    key: 'TEST_COMMIT_IN_PROGRESS',
    progressInfo: 'test_import_active',
    progressStep: PROGRESS_STEPS[2].key,
    style: 'success',
  },
  PASSED_TEST_COMMIT: {
    key: 'PASSED_TEST_COMMIT',
    progressInfo: 'preview_approval',
    progressStep: PROGRESS_STEPS[3].key,
    style: 'success',
  },
  FAILED_TEST_COMMIT: {
    key: 'FAILED_TEST_COMMIT',
    progressInfo: 'test_import_failed',
    progressStep: PROGRESS_STEPS[2].key,
    style: 'danger',
  },
  PREVIEW_APPROVED: {
    key: 'PREVIEW_APPROVED',
    progressInfo: 'final_import',
    progressStep: PROGRESS_STEPS[4].key,
    style: 'success',
  },
  PREVIEW_REJECTED: {
    key: 'PREVIEW_REJECTED',
    progressInfo: 'preview_approval_failed',
    progressStep: PROGRESS_STEPS[3].key,
    style: 'danger',
  },
  COMMIT_QUEUED: {
    active: true,
    key: 'COMMIT_QUEUED',
    progressInfo: 'final_import_active',
    progressStep: PROGRESS_STEPS[4].key,
    style: 'success',
  },
  COMMIT_IN_PROGRESS: {
    active: true,
    key: 'COMMIT_IN_PROGRESS',
    progressInfo: 'final_import_active',
    progressStep: PROGRESS_STEPS[4].key,
    style: 'success',
  },
  COMMITTED: {
    complete: true,
    key: 'COMMITTED',
    progressStep: PROGRESS_STEPS[5].key,
    style: 'success',
  },
  FAILED_COMMIT: {
    key: 'FAILED_COMMIT',
    progressInfo: 'final_import_failed',
    progressStep: PROGRESS_STEPS[4].key,
    style: 'danger',
  },
  POST_COMMIT_PROCESSING_QUEUED: {
    complete: true,
    key: 'POST_COMMIT_PROCESSING_QUEUED',
    progressStep: PROGRESS_STEPS[5].key,
    style: 'success',
  },
  POST_COMMIT_PROCESSING_IN_PROGRESS: {
    complete: true,
    key: 'POST_COMMIT_PROCESSING_IN_PROGRESS',
    progressStep: PROGRESS_STEPS[5].key,
    style: 'success',
  },
  PASSED_POST_COMMIT_PROCESSING: {
    complete: true,
    key: 'PASSED_POST_COMMIT_PROCESSING',
    progressStep: PROGRESS_STEPS[5].key,
    style: 'success',
  },
  FAILED_POST_COMMIT_PROCESSING: {
    complete: true,
    key: 'FAILED_POST_COMMIT_PROCESSING',
    progressStep: PROGRESS_STEPS[5].key,
    style: 'success',
  },
  REVERT_QUEUED: {
    key: 'REVERT_QUEUED',
    progressInfo: 'revert_active',
  },
  REVERT_IN_PROGRESS: {
    key: 'REVERT_IN_PROGRESS',
    progressInfo: 'revert_active',
  },
  REVERTED: {
    key: 'REVERTED',
    progressInfo: 'reverted',
  },
  FAILED_REVERT: {
    key: 'FAILED_REVERT',
    progressInfo: 'revert_failed',
  },
  SPLIT_QUEUED: {
    key: 'SPLIT_QUEUED',
    progressInfo: 'split_active',
  },
  SPLIT_IN_PROGRESS: {
    key: 'SPLIT_IN_PROGRESS',
    progressInfo: 'split_active',
  },
  SPLIT: {
    key: 'SPLIT',
    progressInfo: 'split',
  },
  FAILED_SPLIT: {
    key: 'FAILED_SPLIT',
    progressInfo: 'split_failed',
  },
};
