import React, { Component } from 'react';
import { noop } from 'lodash';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import cx from 'classnames';
import Decimal from 'decimal.js';
import {
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  InputGroup,
} from 'react-bootstrap';

import { AppConstants } from '../../constants';
import { createInputContainer } from '../../containers';
import { IFormInput, IInputContainer, Value } from '../../interfaces';

const { CENT_DECIMAL, KEYBOARD_ENTER_CODE } = AppConstants;

interface IProps extends IFormInput {
  onBlur?: () => void;
  onFocus?: () => void;
}

interface IPropDefaults extends IProps {
  onBlur: () => void;
  onChange: (value: Value) => void;
  onFocus: () => void;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@autoBindMethods
@observer
class Percent extends Component<IProps, {}> {
  @observable private isFocused = false;
  @observable private value = '';

  public static defaultProps: Partial<IProps> = {
    disabled: false,
    onBlur: noop,
    onChange: noop,
    onFocus: noop,
    required: false,
  };

  get formsy () {
    return this.props as IPropsFormsy;
  }

  constructor (props: IPropsFormsy) {
    super(props);
    this.setValueFromProps(props);
  }

  public componentWillReceiveProps (nextProps: IPropsFormsy) {
    this.setValueFromProps(nextProps);
  }

  private setValueFromProps (props: IPropsFormsy) {
    if (typeof props.value === 'undefined' || this.value !== '') {
      return;
    }

    try {
      const value = (new Decimal(String(props.value))).times(CENT_DECIMAL).toString();

      if (this.value !== value) {
        this.value = value;
      }
    }
    catch (e) {
      // Do nothing
    }
  }

  private setValue () {
    let value;
    try {
      value = (new Decimal(this.value)).div(CENT_DECIMAL).toString();
    }
    catch (e) {
      value = this.value;
    }

    this.formsy.setValue(value);
    this.formsy.onChange(value);
  }

  private onChange (e: any) {
    this.value = e.target.value;
    this.setValue();
  }

  private onBlur () {
    this.setValue();
    this.isFocused = false;
    this.formsy.onBlur();
  }

  private onFocus () {
    this.isFocused = true;
    this.formsy.onFocus();
  }

  // istanbul ignore next
  private onKeyDown (e: any) {
    // set value when 'enter' key is pressed
    // this allows form to be re-submitted even when field has not blurred yet
    if (KEYBOARD_ENTER_CODE === e.keyCode) {
      this.setValue();
    }
  }

  public render () {
    const {
        controlId,
        errorMessage,
        formGroupId,
        isDisabled,
        label,
        required,
        showErrorMessage,
        showRequiredMessage,
        validationState,
      } = this.formsy
      , className = cx(
        this.props.className,
        { focused: this.isFocused },
        { 'has-value': !!this.value },
      )
      ;

    return (
      <FormGroup id={formGroupId} controlId={controlId} className={className} validationState={validationState}>
        {label &&
        <ControlLabel>
          {label}
          {required && <span className='required'>*</span>}
        </ControlLabel>
        }

        <InputGroup>
          <FormControl
            disabled={isDisabled}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onKeyDown={this.onKeyDown}
            type={'text'}
            value={this.value}
          />
          <InputGroup.Addon>%</InputGroup.Addon>
        </InputGroup>

        {showRequiredMessage &&
          <HelpBlock>{label || 'This field'} is required.</HelpBlock>}

        {showErrorMessage &&
          <HelpBlock>{errorMessage}</HelpBlock>}

      </FormGroup>
    );
  }
}

export default Percent;
