import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { browserHistory } from 'react-router';
import { noop } from 'lodash';

import { Button, ButtonToolbar } from 'react-bootstrap';

import { MessageModal } from '../common';

interface IProps {
  contactType: string;
  linkPath: string;
  plaintiffName: string;
}

@autoBindMethods
class ContactExistsModal extends Component<IProps> {
  public handleCaseContactClick () {
    browserHistory.push(this.props.linkPath);
  }

  public render () {
    const { contactType, plaintiffName } = this.props;

    return (
      <MessageModal onClose={noop}>
        <MessageModal.Title>Contact Type Exists</MessageModal.Title>
        <MessageModal.Body>
          <p className='fs-mask'>The {contactType} type already exists for {plaintiffName}.</p>
          <p>Only one of each contact type is allowed on a case.</p>
          <div>
            <ButtonToolbar>
              <Button bsStyle='primary' bsSize='small' onClick={this.handleCaseContactClick}>
                Manage Case Contacts
              </Button>
            </ButtonToolbar>
          </div>
        </MessageModal.Body>
      </MessageModal>
    );
  }
}

export default ContactExistsModal;
