import React, { Component, Fragment } from 'react';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { get, identity, isEqual } from 'lodash';

import { DateRange } from '../common';
import { Form } from '../common-formsy';
import { FormattingUtils } from '../../utils';
import { IFacetOption } from '../../interfaces';

import Facet from './Facet';
import FacetRadioGroup from './FacetRadioGroup';

const { toKey } = FormattingUtils;

interface IProps {
  fetchOptions: (params: object, fieldName: string) => IFacetOption[];
  fieldName: string;
  filters: { [key: string]: string[] };
  isCollapsed?: boolean;
  label: string;
  limited?: boolean;
  loading?: boolean;
  onFilterChange: (filterChange: { [key: string]: string | null }) => void;
  rangeFieldName: string;
  renderer?: (name: string) => string;
}

interface IPropDefaults extends IProps {
  isCollapsed: boolean;
  renderer: (name: string) => string;
}

const ALL = {
    name: 'All',
    value: 'all',
  }
  , CUSTOM = {
    name: 'Custom',
    value: 'custom',
  };

@autoBindMethods
@observer
class FacetDateList extends Component<IProps, {}> {
  @observable private facetOptions: IFacetOption[] = [];
  @observable private isLoadingInitial: boolean = true;
  @observable private isCollapsed: boolean = true;
  @observable private nullSelected: string = ALL.value;
  @observable private rangeValueCache = {};

  public static defaultProps: Partial<IProps> = {
    isCollapsed: true,
    renderer: identity,
  };

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  constructor (props: IPropDefaults) {
    super(props);
    this.isCollapsed = props.isCollapsed;
  }

  public componentDidMount () {
    // When facet is open on mount
    if (this.showingList()) {
      this.loadFacetOptions();
    }
  }

  public componentWillReceiveProps (nextProps: IProps) {
    const { filters } = this.props
      , openingList = !this.showingList() && this.showingList(nextProps)
      , filterChange = this.showingList() && toKey(nextProps.filters) !== toKey(filters);

    // When facet is opened by props
    if (openingList || filterChange) {
      this.loadFacetOptions(nextProps);
    }
  }

  private get loading () {
    return this.isLoadingInitial || this.props.loading;
  }

  private async loadFacetOptions (propsArg?: IProps) {
    const props = propsArg || this.props
      , { filters, fieldName, fetchOptions } = props
      , facetOptions = await fetchOptions(filters, fieldName);

    if (!this.fieldNames.some(filterName => !!filters[filterName])) {
      this.nullSelected = ALL.value;
    }

    this.facetOptions = facetOptions;
    this.isLoadingInitial = false;
  }

  private optionChecked (facetOption: IFacetOption) {
    const { filters, fieldName } = this.props
      , currentFilters = get(filters, fieldName, []) as string[];
    return currentFilters.includes(facetOption.value);
  }

  private get showingOptions () {
    const { filters, fieldName } = this.props
      , radioFilter = get(filters, fieldName, []) as string[]
      , facetOptions = this.facetOptions.map(facetOption => (
        { ...facetOption, checked: this.optionChecked(facetOption) }
      ))
      , custom = {
        ...CUSTOM,
        checked: !radioFilter.length && this.nullSelected === CUSTOM.value,
        count: undefined,
      }
      , all = {
        ...ALL,
        checked: !radioFilter.length && this.nullSelected === ALL.value,
        count: undefined,
      };

    return [all, ...facetOptions, custom];
  }

  private onRadioChange (value: string, fieldName: string) {
    const { onFilterChange } = this.props;

    switch (value) {
      case (ALL.value): {
        this.nullSelected = value;
        onFilterChange(this.fieldNamesNull);
        break;
      }

      case (CUSTOM.value): {
        this.nullSelected = value;
        onFilterChange({ [fieldName]: null, ...this.rangeValueCache });
        break;
      }

      default: {
        onFilterChange({ ...this.fieldNamesNull, [fieldName]: value });
        break;
      }
    }
  }

  private get rangeValue () {
    const { filters, rangeFieldName } = this.props;
    return filters[rangeFieldName];
  }

  private get fieldNames () {
    const { fieldName, rangeFieldName } = this.props;
    return [fieldName, `${rangeFieldName}_after`, `${rangeFieldName}_before`];
  }

  private get fieldNamesNull () {
    const filterChange: { [key: string]: string | null } = {};
    this.fieldNames.forEach(fn => filterChange[fn] = null);
    return filterChange;
  }

  private hasValue (props?: IProps) {
    const { filters } = props || this.props;
    return this.fieldNames.some(fn => !!filters[fn]);
  }

  private showingList (propsArg?: IProps) {
    const props = propsArg || this.props
      , open = (this.hasValue(props) || !this.isCollapsed);
    return (open && !props.loading);
  }

  private async onToggleCollapse (isCollapsed: boolean) {
    this.isCollapsed = isCollapsed;
    // When facet is opened by header
    if (!isCollapsed) {
      this.loadFacetOptions();
    }
  }

  private onRangeChange (newValue: { [key: string]: string }) {
    const { onFilterChange } = this.props,
      newValueArray = Object.keys(newValue).map(key => newValue[key]),
      [newStart, newEnd] = newValueArray,
      isEmpty = !newStart && !newEnd,
      hasNotChanged = isEqual(toJS(this.rangeValue), newValueArray);

    if (isEmpty || hasNotChanged) {
      return;
    }

    this.nullSelected = CUSTOM.value;
    this.rangeValueCache = newValue;
    onFilterChange({ ...this.fieldNamesNull, ...newValue });
  }

  private reset () {
    this.props.onFilterChange(this.fieldNamesNull);
    this.nullSelected = ALL.value;
  }

  public render () {
    const {
        fieldName,
        label,
        limited,
        rangeFieldName,
        renderer,
      } = this.propsWithDefaults;

    return (
      <Facet
        className='facet-date-list'
        fieldName={fieldName}
        hasValue={this.hasValue()}
        label={label}
        onToggleCollapse={this.onToggleCollapse}
        reset={this.reset}
      >

        {this.loading && (
          <div className='facet-loader'><div className='spinner' /></div>)}

        {(!this.loading && !this.facetOptions.length) && (
          <div className='facet-loader'>No filters applicable</div>)}

        {!this.loading && (
          <Fragment>
            <Form>
              <FacetRadioGroup
                facetOptions={this.showingOptions}
                fieldName={fieldName}
                onRadioChange={this.onRadioChange}
                renderer={renderer}
              />
            </Form>

            <DateRange
              fieldName={rangeFieldName}
              limited={limited}
              onQueryChange={this.onRangeChange}
              required={false}
              showReset={false}
              value={this.rangeValue}
            />
          </Fragment>
        )}
      </Facet>
    );
  }
}
export default FacetDateList;
