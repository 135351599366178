import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { DropdownButton, MenuItem } from 'react-bootstrap';

import AppConstants from '../../constants/AppConstants';
import { SessionStoreClass } from '../../stores';
import { ICase } from '../../interfaces';
import PayoffMenuItems from './PayoffMenuItems';
import GuardedCloseAllLiensMenuItem from './GuardedCloseAllLiensMenuItem';
import GuardedLogPaymentMenuItem from './GuardedLogPaymentMenuItem';
import DeleteCaseMenuItem from './DeleteCaseMenuItem';

const { PERMISSIONS } = AppConstants;

interface IProps {
  _case: ICase;
}

interface IInjected extends IProps {
  SessionStore: SessionStoreClass;
}

@inject('UiStore', 'SessionStore')
@observer
class CaseHeaderPayments extends Component <IProps> {

  public render () {
    const { _case, SessionStore } = this.props as IInjected
      , arePayoffAndLogPaymentDisabled = !_case.hasLiensAcceptingReturns
      , isDeleteCaseDisabled = !SessionStore.userHasPermission(PERMISSIONS.DELETE_CASE)
      , isLogPaymentDisabled = !SessionStore.userHasPermission(PERMISSIONS.LOG_PAYMENT)
      , canCloseLiens = !!SessionStore.userHasPermission(PERMISSIONS.CLOSE_LIEN)
      ;

    return (
      <div className='buttons'>
        <DropdownButton className='border-green' id='case-actions' title='Case Actions'>
          <PayoffMenuItems
            _case={_case}
            isDisabled={arePayoffAndLogPaymentDisabled}
          />
          <GuardedLogPaymentMenuItem
            _case={_case}
            isDisabled={isLogPaymentDisabled || arePayoffAndLogPaymentDisabled}
          />
          {!!canCloseLiens && (
            <GuardedCloseAllLiensMenuItem
              _case={_case}
              isDisabled={!canCloseLiens}
            />
          )}
          <MenuItem divider />
          <DeleteCaseMenuItem
            _case={_case}
            isDisabled={isDeleteCaseDisabled}
          />
        </DropdownButton>
      </div>
    );
  }
}

export default CaseHeaderPayments;
