import { isArray, toString } from 'lodash';
import { format, isSameDay, isBefore } from 'date-fns';
import { validationRules } from 'formsy-react';

import { AppConstants } from '../../constants';
import FormattingUtils from '../../utils/FormattingUtils';
import { withinDecimalPlaces } from '../../utils/util';

const {
  splitCommaList,
} = FormattingUtils;

const {
  DATE_FORMATS,
  DEFENDANT_NAME_LIMIT,
  LIEN_TYPE_LIMIT,
  PLAINTIFF_NAME_LIMIT,
} = AppConstants;

function notFilledIn (val: any) {
  return typeof(val) === 'undefined'
    || val === null
    || val === '';
}

function isDateFormat (_values: { [key: string]: any }, value: any) {
  return notFilledIn(value)
    || format(value, DATE_FORMATS.date_value) === value;
}

function validateStartEnd (startDate: string, endDate: string) {
  return notFilledIn(startDate)
    || notFilledIn(endDate)
    || isSameDay(startDate, endDate)
    || isBefore(startDate, endDate);
}

function isPercentage (_values: { [key: string]: any }, value: any) {
  return notFilledIn(value)
    || !!String(value).match(/^(1(\.0{0,8})?|0?(\.\d{0,8})?)$/);
}

function isValidDefendantName (_values: { [key: string]: any }, value: any) {
  return notFilledIn(value)
  || value.trim().length !== 0
  && value.length <= DEFENDANT_NAME_LIMIT;
}

function isValidPlaintiffName (_values: { [key: string]: any }, value: any) {
  return notFilledIn(value)
  || value.trim().length !== 0
  && value.length <= PLAINTIFF_NAME_LIMIT;
}

function isValidLienType (_values: { [key: string]: any }, value: any) {
  return notFilledIn(value)
  || value.length <= LIEN_TYPE_LIMIT;
}

function isRequiredFor (values: { [key: string]: any }, value: any, otherField: string) {
  if (isArray(otherField)) {
    return otherField.every(key => notFilledIn(values[key]) || !notFilledIn(value));
  }
  return notFilledIn(values[otherField]) || !notFilledIn(value);
}

function isRequiredOr (values: { [key: string]: any }, value: any, otherField: string) {
  return !notFilledIn(value) || !notFilledIn(values[otherField]);
}

function isRequiredForMultiple (values: { [key: string]: any }, value: any, otherFields: string[]) {
  return notFilledIn(value) || otherFields.some(otherField => !notFilledIn(values[otherField]));
}

function isLessThan (values: { [key: string]: any }, value: any, otherField: string) {
  return notFilledIn(values[otherField])
    || notFilledIn(value)
    || Number(value) < Number(values[otherField]);
}

function isMoreThan (values: { [key: string]: any }, value: any, otherField: string) {
  return notFilledIn(values[otherField])
    || notFilledIn(value)
    || Number(value) > Number(values[otherField]);
}

function isPositive (_values: { [key: string]: any }, value: any) {
  return notFilledIn(value)
    || Number(value) >= 0;
}

/* istanbul ignore next */
function isGreaterThanZero (_values: { [key: string]: any }, value: any) {
  return notFilledIn(value)
    || Number(value) > 0;
}

function isPhoneNumber (_values: { [key: string]: any }, value: any) {
  return notFilledIn(value)
    || !!value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
}

function isValidURL (_values: { [key: string]: any }, value: any) {
  return notFilledIn(value)
    || /^(http|https):\/\/[^ "]+$/.test(value);
}

function isValidSSN (_values: { [key: string]: any }, value: any) {
  return notFilledIn(value)
    || !!value.match(/^\d{4}$/)
    || !!value.match(/^\d{3}[-\s]?\d{2}[-\s]?\d{4}$/);
}

function isZipCode (_values: { [key: string]: any }, value: any) {
  return notFilledIn(value)
    || !!value.match(/^\d{5}(?:-\d{4})?$/);
}

function isValidStartDate (values: { [key: string]: any }, value: any, otherField: string) {
  const endDate = values[otherField]
    , startDate = value;

  return (
    notFilledIn(startDate)
      || isDateFormat({}, startDate)
      && validateStartEnd(startDate, endDate));
}

function isValidEndDate (values: { [key: string]: any }, value: any, otherField: string) {
  const endDate = value
    , startDate = values[otherField];

  return (
    notFilledIn(endDate)
      || isDateFormat({}, endDate)
      && validateStartEnd(startDate, endDate));
}

function isEmailList (_values: { [key: string]: any }, value: any) {
  return notFilledIn(value)
    || splitCommaList(value).every(addr => validationRules.isEmail([value], addr));
}

function isWithinDecimalPlaces (_values: { [key: string]: any }, value: any, precision: number) {
  return notFilledIn(value) || withinDecimalPlaces(toString(value), precision);
}

export default {
  isDateFormat,
  isEmailList,
  isGreaterThanZero,
  isLessThan,
  isMoreThan,
  isPercentage,
  isPhoneNumber,
  isPositive,
  isRequiredFor,
  isRequiredForMultiple,
  isRequiredOr,
  isValidDefendantName,
  isValidEndDate,
  isValidLienType,
  isValidPlaintiffName,
  isValidSSN,
  isValidStartDate,
  isValidURL,
  isWithinDecimalPlaces,
  isZipCode,
  notFilledIn,
  validateStartEnd,
};
