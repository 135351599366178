import React, { Component } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { stubFalse, noop } from 'lodash';

import { IFieldSetPartial, IFieldSet, IFieldConfig } from './interfaces';
import { fillInFieldSets, filterFieldSets } from './common';
import FormFields from './FormFields';

interface IProps {
  addNew?: (fieldConfig: IFieldConfig, search: string) => void;
  addNewUndo?: (fieldConfig: IFieldConfig) => void;
  fieldSets: IFieldSetPartial[];
  model?: object;
  onChange?: { [key: string]: (value: any) => void };
  showNew?: (field: string) => boolean;
}

interface IPropDefaults extends IProps {
  addNew: (fieldConfig: IFieldConfig, search: string) => void;
  addNewUndo: (fieldConfig: IFieldConfig) => void;
  model: object;
  onChange: {};
  showNew: (field: string) => boolean;
}

@autoBindMethods
@observer
class FormFieldSets extends Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    addNew: noop,
    addNewUndo: noop,
    model: {},
    onChange: {},
    showNew: stubFalse,
  };

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  @computed
  get fieldSets () {
    return filterFieldSets(fillInFieldSets(this.props.fieldSets), { readOnly: false });
  }

  public render () {
    return (
      <div className='form-groups'>
        {this.fieldSets.map((fieldSet: IFieldSet, idx: number) => (
          <div key={idx} className='form-group-merged'>
            <FormFields {...this.propsWithDefaults} fieldSet={fieldSet} />
          </div>
        ))}
      </div>
    );
  }
}
export default FormFieldSets;
