import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Button } from 'react-bootstrap';

import { Icon } from '../../common';
import SearchBar from './SearchBar';
import { UiStoreClass } from '../../../stores';

interface IInjected {
  UiStore: UiStoreClass;
}

@inject('UiStore')
@observer
@autoBindMethods
class SlidingSearchBar extends Component {
  get injected () {
    return this.props as IInjected;
  }

  public render () {
    const { UiStore } = this.injected
      , className = cx(
      'sliding-searchbar',
      `env-${UiStore.env}`,
      { open: UiStore.isSlidingSearchBarOpen.isTrue },
    );

    return (
      <div className={className}>
        <SearchBar />
        <Button bsStyle='link' className='btn-close' onClick={UiStore.isSlidingSearchBarOpen.setFalse}>
          <Icon type='close' />
        </Button>
      </div>
    );
  }
}

export default SlidingSearchBar;
