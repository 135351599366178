import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import autoBindMethods from 'class-autobind-decorator';

import { LienModel } from '../../models';
import {
  SessionStoreClass,
  UiStoreClass,
  CaseStoreClass,
  FunderStoreClass,
} from '../../stores';

import { Dropdown } from '../common-formsy';
import { EditableCard } from '../../lib/mighty-fields';
import InputDateClosed from './InputDateClosed';
import AppConstants from '../../constants/AppConstants';
import { insertIf } from '../../utils/util';

const { PERMISSIONS } = AppConstants;

interface IProps {
  lien: LienModel | null;
  priorFunding: any;
}

interface IInjected extends IProps {
  CaseStore: CaseStoreClass;
  FunderStore: FunderStoreClass;
  LienStore: any;
  SessionStore: SessionStoreClass;
  UiStore: UiStoreClass;
}

@inject(
  'CaseStore',
  'FunderStore',
  'LienStore',
  'SessionStore',
  'UiStore',
)
@autoBindMethods
@observer
class LienInfoEditableCard extends Component<IProps> {

    private get injected () {
        return this.props as IInjected;
    }

    private async onLienStatusChange (lienFormModel: any) {
      if (!this.props.lien) { return; }
      const { CaseStore, LienStore, SessionStore, UiStore } = this.injected
        , eventData = { lien: this.props.lien.serialize(), priorFunding: this.props.priorFunding }
        ;

      UiStore.modals.FundingDetails.close();

      await Promise.all([
        LienStore.update(this.props.lien, lienFormModel),
        SessionStore.trackEvent('LIEN_CHANGED_EVENT', eventData),
      ]);
      await CaseStore.refresh(this.props.lien.case);
    }

    public static fieldSet ({
      isFinanceCustomer = false,
      finalize = true,
      canCloseLiens = false,
    }) {
      const BOOLEAN_FILTER_OPTIONS: Array<{ name: string, value: string }> = [
        {name: 'Open', value: 'true'},
        {name: 'Closed', value: 'false'},
      ];

      return [
        {
          disabled: !canCloseLiens,
          editComponent: Dropdown,
          editProps: {
            allowEmpty: false,
            options: BOOLEAN_FILTER_OPTIONS,
            optionsValueKey: 'value',
          },
          field: 'is_open',
          label: 'Lien Status',
          render: (isOpen: boolean) => isOpen ? 'Open' : 'Closed',
          type: 'boolean',
        },
        {
          disabled: !canCloseLiens,
          editComponent: InputDateClosed,
          field: 'date_closed',
          type: 'date',
        },
        ...insertIf(isFinanceCustomer && finalize, {
          field: 'is_available_to_coop',
          label: 'Available To CoOp Capital',
          type: 'boolean',
        })
      ];
    }

    public render () {
      const { FunderStore: { isFinanceCustomer }, SessionStore } = this.injected
        , canCloseLiens = !!SessionStore.userHasPermission(PERMISSIONS.CLOSE_LIEN)
        ;

      return (
        <EditableCard
          className='space-between'
          fieldSets={[LienInfoEditableCard.fieldSet({isFinanceCustomer, canCloseLiens})]}
          model={this.props.lien || {}}
          onSave={this.onLienStatusChange}
          title='Lien Info'
        />
      );
    }
}

export default LienInfoEditableCard;
