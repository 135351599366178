import React, { Component } from 'react';
import { toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { isEqual } from 'lodash';
import { DateRange } from '../common';

import Facet from './Facet';

interface IProps {
  fieldName: string;
  collapsible?: boolean;
  isCollapsed?: boolean;
  limited?: boolean;
  onQueryChange: (fieldName: string, value: object) => void;
  reset: (fieldName: string) => void;
  title: string;
  value?: string[];
}

@inject('UiStore')
@autoBindMethods
@observer
class FacetDate extends Component<IProps, {}> {
  private onQueryChange (newValue: { [key: string]: any[] }) {
    const { value } = this.props,
      newValueArray = Object.keys(newValue).map(key => newValue[key]),
      [newStart, newEnd] = newValueArray,
      isEmpty = !newStart && !newEnd,
      hasNotChanged = isEqual(toJS(value), newValueArray);

    if (isEmpty || hasNotChanged) {
      return;
    }

    this.props.onQueryChange(this.props.fieldName, newValue);
  }

  public render () {
    const { fieldName, collapsible, isCollapsed, limited, reset, title, value } = this.props;
    let hasValue = false;

    if (!!value && value.length === 2) {
      hasValue = !!value[0] || !!value[1];
    }

    return (
      <div key={fieldName}>
        <Facet
          fieldName={fieldName}
          hasValue={hasValue}
          isCollapsed={isCollapsed}
          collapsible={collapsible}
          label={title}
          reset={reset}
        >
          <div className='date-range-wrapper'>
            <DateRange
              fieldName={fieldName}
              limited={limited}
              onQueryChange={this.onQueryChange}
              required={false}
              showReset={false}
              value={value}
            />
          </div>
        </Facet>
      </div>
    );
  }
}

export default FacetDate;
