import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Modal, ButtonToolbar, Button } from 'react-bootstrap';

import { pluralize } from '@mighty-justice/utils';

import { AppConstants } from '../../constants';
import { SmartBool, toast } from '../../utils';

import RepresentativeDropdownAssign from '../page-case/RepresentativeDropdownAssign';
import { observable } from 'mobx';

const { NO_CHANGE_FIELD } = AppConstants;

interface IRowData {
  case_id: string;
}

interface IProps {
 isVisible: SmartBool;
 onSave: (model: object) => void;
 selectedRows: IRowData[];
}

@autoBindMethods
@observer
class ChangeOwnersModal extends Component<IProps> {
  @observable private selectedOwner: string | null = NO_CHANGE_FIELD;
  @observable private selectedSecondaryOwner: string | null = NO_CHANGE_FIELD;
  @observable private selectedTertiaryOwner: string | null = NO_CHANGE_FIELD;
  @observable private isSubmitting: boolean = false;

  private get caseText () {
    const { selectedRows } = this.props
      , caseCount = selectedRows.length
      , casePlural = pluralize('case', 's', caseCount)
    ;

    return `${caseCount} selected ${casePlural}`;
  }

  private onPrimaryRepresentativeChanged (representative: string) {
    this.selectedOwner = representative;
  }

  private onSecondaryRepresentativeChanged (representative: string) {
    this.selectedSecondaryOwner = representative;
  }

  private onTertiaryRepresentativeChanged (representative: string) {
    this.selectedTertiaryOwner = representative;
  }

  private async onBulkChangeOwners () {
    const { isVisible, onSave } = this.props;

    this.isSubmitting = true;

    try {
      const data = {
        ...(this.selectedOwner !== NO_CHANGE_FIELD && { primary_representative: this.selectedOwner }),
        ...(this.selectedSecondaryOwner !== NO_CHANGE_FIELD && { secondary_representative: this.selectedSecondaryOwner }),
        ...(this.selectedTertiaryOwner !== NO_CHANGE_FIELD && { tertiary_representative: this.selectedTertiaryOwner })
      };

      await onSave(data);

      toast.success(`Success! You have changed owners on the ${this.caseText}!`);
      isVisible.setFalse();
    } catch (error) {
      toast.error('There was an error updating the case representatives.');
      throw error;
    } finally {
      this.isSubmitting = false;
    }
  }

  public render () {
    const { isVisible } = this.props;

    if (!isVisible.isTrue) {
      return null;
    }

    return (
      <Modal className='modal-change-owners' show onHide={isVisible.setFalse}>
        <Modal.Header closeButton>
          <Modal.Title>Change Case Owners</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <dl>
            <dt>Primary Owner</dt>
            <dd>
              <RepresentativeDropdownAssign
                className='dropdown-bulk-owner'
                isBulkUpdate
                onChange={this.onPrimaryRepresentativeChanged}
                selected={this.selectedOwner}
              />
            </dd>
          </dl>
          <dl>
            <dt>Secondary Owner</dt>
            <dd>
              <RepresentativeDropdownAssign
                className='dropdown-bulk-secondary-owner'
                isBulkUpdate
                onChange={this.onSecondaryRepresentativeChanged}
                selected={this.selectedSecondaryOwner}
              />
            </dd>
          </dl>
          <dl>
            <dt>Tertiary Owner</dt>
            <dd>
              <RepresentativeDropdownAssign
                className='dropdown-bulk-tertiary-owner'
                isBulkUpdate
                onChange={this.onTertiaryRepresentativeChanged}
                selected={this.selectedTertiaryOwner}
              />
            </dd>
          </dl>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className='pull-right'>
            <Button onClick={isVisible.setFalse}>Cancel</Button>
            <Button
              bsStyle='primary'
              disabled={this.isSubmitting || (this.selectedOwner === NO_CHANGE_FIELD && this.selectedSecondaryOwner === NO_CHANGE_FIELD && this.selectedTertiaryOwner === NO_CHANGE_FIELD)}
              className='btn-save'
              type='submit'
              onClick={this.onBulkChangeOwners}
            >
              {this.isSubmitting ? 'Saving...' : 'Save'}
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ChangeOwnersModal;
