import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { browserHistory } from 'react-router';
import autoBindMethods from 'class-autobind-decorator';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import URI from 'urijs';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  Loader,
  Page,
} from '../common';

import ClientsClass from '../../clients/ClientsClass';
import SmartBool from '../../utils/SmartBool';

import CaseReferenceSidebar from '../common-collaboration/CaseReferenceSidebar';
import { SessionStoreClass } from '../../stores';
import CaseUpdateTaskDetail from './CaseUpdateTaskDetail';
import DocumentSharingTaskDetail from './DocumentSharingTaskDetail';
import Client from '../../clients/Client';

interface IProps {
  params: {
    id: string;
  };
}

interface IInjected extends IProps {
  Clients: ClientsClass;
  SessionStore: SessionStoreClass;
}

@inject('Clients', 'SessionStore')
@autoBindMethods
@observer
class TaskDetailPage extends Component<IProps> {
  @observable private isLoading = new SmartBool(true);
  @observable private task: any = null;

  private get injected () {
    return this.props as IInjected;
  }

  public componentDidMount () {
    this.fetch();
  }

  private get parsedURL () {
    return new URI(browserHistory.getCurrentLocation().pathname);
  }

  private get page () {
    return this.parsedURL.segmentCoded(0);
  }

  private get client () {
    const { Clients } = this.injected;

    if (this.page === 'tasks') { return Clients.tasksReceived; }
    return Clients.tasksRequested;
  }

  @action
  private async fetch () {
    const { params: { id } } = this.props;
    this.isLoading.set(true);
    this.task = await this.client.retrieve(id);
    this.isLoading.set(false);
  }

  private get pageConfiguration () {
    const { Clients } = this.injected;

    return {
      'tasks.casetrackingupdatetask': {
        client: Clients.caseTrackingUpdateTasks,
        DetailComponent: CaseUpdateTaskDetail,
        titleString: 'Case Update',
      },
      'tasks.documentsharingtask': {
        client: Clients.documentSharingTask,
        DetailComponent: DocumentSharingTaskDetail,
        titleString: 'Documents',
      },
    } as {[key: string]: {client: Client, DetailComponent: any, titleString: string}};
  }

  public render () {
    if (this.isLoading.isTrue) {
      return <Loader className='page-loader' logo />;
    }
    const { client, DetailComponent, titleString } = this.pageConfiguration[this.task.type];

    return (
      <Page name='task-detail' type='detail' hasSidebar>
        <Helmet className='fs-mask' title={`Tasks - ${titleString} for ${this.task.plaintiff_name}`} />
        <CaseReferenceSidebar page={this.page} data={this.task} client={client} />
        <Page.Content>
          <header className='condensed'>
            <h1 className='fs-mask'>{titleString} for {this.task.plaintiff_name}</h1>
          </header>
          <div className='main'>
            <Row>
              <Col className='col-main' xs={12}>
                <DetailComponent task={this.task} />
              </Col>
            </Row>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default TaskDetailPage;
