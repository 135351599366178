import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { ProgressBar } from 'react-bootstrap';
import FormattingUtils from '../../utils/FormattingUtils';

const { parseAndPreserveNewlines } = FormattingUtils;

const PROGRESS_TOTAL = 100;

interface IStep {
  key: string;
  displayName: string;
}

interface IProps {
  active?: boolean;
  bsStyle?: 'success' | 'danger' | 'warning';
  currentStep: number;
  steps: IStep[];
}

@autoBindMethods
@observer
class StepProgress extends Component<IProps> {

  private stepPercentage (idx: number) {
    return (PROGRESS_TOTAL / (this.props.steps.length - 1)) * idx;
  }

  private get currentPercentage () {
    return this.stepPercentage(this.props.currentStep) as number;
  }

  private renderStep (step: IStep, idx: number) {
    return (
      <li
        className={cx({active: this.props.currentStep === idx})}
        key={idx}
        style={{left: `${this.stepPercentage(idx)}%`}}
      >
        {parseAndPreserveNewlines(step.displayName)}
      </li>
    );
  }

  public render () {
    const { active, bsStyle, steps } = this.props
      , classNames = cx('step-progress', bsStyle);

    return (
      <div className={classNames}>
        <ul className='steps'>
          {steps.map(this.renderStep)}
        </ul>
        <ProgressBar active={active} bsStyle={bsStyle} now={this.currentPercentage} />
      </div>
    );
  }

}

export default StepProgress;
