import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

interface IProps {
  message: string;
  onClose: () => void;
}

@autoBindMethods
@observer
class Alert extends Component<IProps, {}> {
  public render () {
    const {
      message,
      onClose,
    } = this.props;

    return (
      <div className='update-notification'>
        <p>{message}</p>
        <p><a onClick={onClose}>Dismiss</a></p>
      </div>
    );
  }
}

export default Alert;
