import React from 'react';

import {
  Button,
  Table,
  Well,
} from 'react-bootstrap';

import { Icon } from '../../common';
import { AppConstants } from '../../../constants';

const { S3_STATIC_BUCKET_URL } = AppConstants
  , hcfaDownloadUrl = `${S3_STATIC_BUCKET_URL}/medical-documents/HCFA.pdf`;

const HCFAFormWell = () => {
  return (
    <Well className='documents-templates-list'>
      <h3>HCFA Form</h3>
      <Table className='no-side-padding fix-layout' condensed>
        <tbody>
          <tr>
            <td className='col-sm-8 col-name'>HCFA Form Template</td>
            <td className='col-sm-4 col-btn'>
              <Button bsStyle='link' target='_blank' rel='noopener noreferrer' href={hcfaDownloadUrl}>
                <Icon type='external-link' />
              </Button>
              <Button bsStyle='link' target='_blank' rel='noopener noreferrer' href={hcfaDownloadUrl} download>
                <Icon type='download' />
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </Well>
  );
};

export default HCFAFormWell;
