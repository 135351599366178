import React from 'react';
import { observer } from 'mobx-react';

import { FormModal } from '../../../lib/mighty-fields';
import { IDefendantInsurer } from '../../../interfaces';

import { FIELD_SETS_DEFENDANT_INSURER } from './FieldSets';

interface IProps {
  defendantInsurer: IDefendantInsurer | null;
  onClose: () => void;
  onSave: (model: {}) => void;
}

const AddDefendantInsurerModal = observer((props: IProps) => {
  return (
    <FormModal
      fieldSets={FIELD_SETS_DEFENDANT_INSURER}
      model={props.defendantInsurer || undefined}
      onClose={props.onClose}
      onSave={props.onSave}
      title='Defendant Insurer'
    />
  );
});

export default AddDefendantInsurerModal;
