import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { browserHistory } from 'react-router';

import AppConstants from '../../constants/AppConstants';
import { SmartBool, TableRenderingUtils, toast} from '../../utils';
import { FileUploader, Spacer } from '../common';
import { IDocument } from '../../interfaces';
import { CaseStoreClass } from '../../stores';

const { ROUTING } = AppConstants;

const { renderFileType, renderUnformatted } = TableRenderingUtils;

const ATTACHMENT_COLUMNS = [
  {
    accessor: 'label',
    Cell: renderFileType,
    className: 'col-file',
    Header: 'File Type',
    maxWidth: 20,
  },
  {
    accessor: 'label',
    Cell: renderUnformatted,
    Header: 'Name',
    minWidth: 200,
  },
];

interface IInjected {
  CaseStore: CaseStoreClass;
}

@inject('CaseStore')
@autoBindMethods
@observer
class MultipleCaseForm extends Component <{}> {
  @observable private isSubmitting = new SmartBool(false);

  private get injected () {
    return this.props as IInjected;
  }

  private async handleSubmit (documents: IDocument[]) {
    const { CaseStore } = this.injected
      , files = documents.map(doc => doc.file);

    try {
      this.isSubmitting.setTrue();
      await CaseStore.sendBulkCaseUploadRequest(files);
      this.isSubmitting.setFalse();
      toast.success('Success! Multiple cases have been uploaded. \
                     Once processed, they will appear in your Case Portfolio');
      browserHistory.push(ROUTING.caseTracking);
    }
    catch (err) {
      toast.error(err.statusText || 'An error occurred uploading the cases.');
    }
  }

  public render () {
    return (
      <div>
        <h3>Upload completed case .csv</h3>

        <FileUploader
          columns={ATTACHMENT_COLUMNS}
          isSubmitting={this.isSubmitting.isTrue}
          onSubmit={this.handleSubmit}
          placeholderText='Drag .CSV here to upload'
          uploadButtonText='Upload Cases'
        >
          <p>Need a template? You can download it <a href='https://s3.amazonaws.com/mighty-v2-static/free-mighty-import-template.xlsx'>here</a>.</p>
          <Spacer />
        </FileUploader>

      </div>
    );
  }
}

export default MultipleCaseForm;
