import React, { Component } from 'react';

import { Nav } from 'react-bootstrap';

import {
  LoginPageLink,
} from './PageLinks';

class AnonymousNavbar extends Component <{}> {
  public render () {
    return (
      <Nav className='navbar-menu' pullRight>
        <LoginPageLink />
      </Nav>
    );
  }
}

export default AnonymousNavbar;
