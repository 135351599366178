import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { kebabCase, identity } from 'lodash';

import { IFacetOption } from '../../interfaces';

import FacetRadioItem from './FacetRadioItem';

interface IProps {
  facetOptions: IFacetOption[];
  fieldName: string;
  onRadioChange: (value: string, fieldName: string) => void;
  renderer?: (name: string) => string;
}

interface IPropDefaults extends IProps {
  renderer: (name: string) => string;
}

@observer
@autoBindMethods
class FacetRadioGroup extends Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    renderer: identity,
  };

  private onRadioChange (value: string) {
    const { onRadioChange, fieldName } = this.props;
    onRadioChange(value, fieldName);
  }

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  public render () {
    const { facetOptions, fieldName, renderer } = this.propsWithDefaults;

    return (
      <ul className='radio-buttons condensed'>
        {facetOptions.map(facetOption => (
          <FacetRadioItem
            checked={!!facetOption.checked}
            count={facetOption.count}
            countIsLoading={facetOption.countIsLoading}
            key={facetOption.value}
            label={renderer(facetOption.name)}
            onChange={this.onRadioChange}
            uniqueKey={kebabCase(`${fieldName}-${facetOption.value}`)}
            value={facetOption.value}
          />
        ))}
      </ul>
    );
  }
}

export default FacetRadioGroup;
