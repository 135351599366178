import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { SmartBool} from '../../utils';

import BulkCreateTrackingContactForm from './BulkCreateTrackingContactForm';
import BulkUpdateTrackingContactForm from './BulkUpdateTrackingContactForm';

interface IRowData {
  case_id: string;
}

interface IProps {
 isVisible: SmartBool;
 onSave: (model: object) => void;
 selectedRows: IRowData[];
}

@autoBindMethods
@observer
class ChangeTrackingContactModal extends Component<IProps> {
  @observable private isUpdateMode = new SmartBool(true);

  private onHide () {
    const { isVisible } = this.props;

    isVisible.setFalse();
    this.isUpdateMode.setTrue();
  }

  private toggleMode () {
    this.isUpdateMode.toggle();
  }

  public render () {
    const { isVisible, onSave, selectedRows } = this.props;

    if (!isVisible.isTrue) {
      return null;
    }

    return (
      <div>
      {this.isUpdateMode.isTrue ? (
        <BulkUpdateTrackingContactForm
          isVisible={this.isUpdateMode.isTrue}
          onHide={this.onHide}
          onSave={onSave}
          onToggleMode={this.toggleMode}
          selectedRows={selectedRows}
        />
      ) : (
        <BulkCreateTrackingContactForm
          isVisible={!this.isUpdateMode.isTrue}
          onHide={this.onHide}
          onSave={onSave}
          onToggleMode={this.toggleMode}
          selectedRows={selectedRows}
        />
      )}
      </div>
    );
  }
}

export default ChangeTrackingContactModal;
