import React, { Component } from 'react';
import { action, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import {
  Button,
  ButtonToolbar,
} from 'react-bootstrap';

import { Form } from '../common-formsy';

import FormFieldSets from '../../lib/mighty-fields/FormFieldSets';
import FormManager from '../../lib/mighty-fields/FormManager';
import MedicalCodesTable from './MedicalCodesTable';
import { IMedicalLienMedicalCode } from '../../interfaces';
import medicalDetailsConfig from './medicalDetailsConfig';
import { FunderStoreClass } from '../../stores';

const { medicalCodeFieldSets } = medicalDetailsConfig;

interface IProps {
  deleteMedicalCode: (code: IMedicalLienMedicalCode) => void;
  medicalCodes: IMedicalLienMedicalCode[];
  onClose?: () => void;
  onSubmit: (codes: IMedicalLienMedicalCode[]) => void;
}

interface ICodeRef {
  openDropdown: () => void;
}

interface IInjected extends IProps {
  FunderStore: FunderStoreClass;
}

@inject('FunderStore')
@autoBindMethods
@observer
class MedicalCodesFormGroup extends Component<IProps> {
  private formManager: FormManager;
  private codeRef?: ICodeRef;

  public constructor (props: IProps) {
    super(props);

    this.formManager = new FormManager({
      fieldSets: this.fieldSets,
      onSave: this.onSave,
      onSuccess: this.onSuccess,
      onSuccessClear: true,
    });
  }

  private get injected () {
    return this.props as IInjected;
  }

  private get fieldSets () {
    const { FunderStore: { isMedicalProvider } } = this.injected
     , fieldSets = medicalCodeFieldSets({ isMedicalProvider });
    // Set <ObjectSearchCreate> field ref
    fieldSets[0][0].editProps = {setRef: this.setCodeRef};
    return fieldSets;
  }

  private setCodeRef (ref: any) {
    this.codeRef = ref;
  }

  @action
  private async onSave (formData: object) {
    const { medicalCodes } = this.props
      , data = formData as IMedicalLienMedicalCode
      , submitCodes = [data, ...medicalCodes];
    await this.props.onSubmit(toJS(submitCodes));
  }

  @action
  private onSuccess () {
    if (this.codeRef) {
      this.codeRef.openDropdown();
    }
  }

  public render () {
    const { onClose, medicalCodes, deleteMedicalCode } = this.props;

    return (
      <div id='form-medical-codes'>
        <h3>Medical Codes and descriptions</h3>
        <Form {...this.formManager.formProps} className='content'>
          <FormFieldSets
            fieldSets={this.fieldSets}
            {...this.formManager.formGroupsProps}
          />

          <ButtonToolbar className='flex-right'>
            <div className='tip'>Tip: hit <b>Enter</b> to submit.</div>

            {onClose && <Button className='btn-close-medical-code-form' onClick={onClose}>Done</Button>}

            <Button bsStyle='primary' disabled={this.formManager.submitDisabled()} type='submit'>Add</Button>
          </ButtonToolbar>
        </Form>

        <MedicalCodesTable
          deleteMedicalCode={deleteMedicalCode}
          isEditing
          medicalCodes={medicalCodes}
        />
      </div>
    );
  }
}

export default MedicalCodesFormGroup;
