import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { get } from 'lodash';

import { varToLabel } from '@mighty-justice/utils';

import { IFacetOption } from '../../interfaces';

import { Facet, FacetList } from './index';
import { ICounts, ISidebarCounts } from '../page-applications/ApplicationsSidebar';

interface IProps {
  collapsible?: boolean;
  defaultValue?: any;
  fieldName: string;
  filters: { [key: string]: string };
  label?: string;
  onOptionChange: (fieldName: string, value: any) => void;
  onReset: (fieldName: string) => void;
  options: IFacetOption[];
  renderer?: (name: string) => string | React.ReactNode;
  sidebarCounts?: ISidebarCounts;
  value?: any;
}

@autoBindMethods
@observer
class FacetOptions extends Component<IProps> {
  private get options () {
    const { fieldName, filters, options, sidebarCounts } = this.props
      , facetCounts = get(sidebarCounts, fieldName, [])
      , selectedOption = filters[fieldName];

    return options.map(option => {
      const foundCount = facetCounts.find((facetCount: ICounts) => facetCount.id === option.value)
        , count = get(foundCount, 'count', 0)
        ;

      return {
        ...option,
        checked: selectedOption === option.value,
        count,
      };
    });
  }

  private get value () {
    const { fieldName, filters, value } = this.props;

    return value || get(filters, fieldName);
  }

  private get hasValue () {
    const { defaultValue } = this.props;

    return !!this.value && this.value !== defaultValue;
  }

  private onCheckboxChange (value: any, _checked: boolean, fieldName: string) {
    const { onOptionChange } = this.props;

    onOptionChange(fieldName, value);
  }

  public render () {
    const { collapsible, fieldName, label, onReset, renderer } = this.props;

    return (
      <Facet
        collapsible={collapsible}
        fieldName={fieldName}
        hasValue={this.hasValue}
        label={label || varToLabel(fieldName)}
        reset={onReset}
      >
        <FacetList
          facetOptions={this.options}
          fieldName={fieldName}
          onCheckboxChange={this.onCheckboxChange}
          type='radio'
          renderer={renderer}
        />
      </Facet>
    );

  }
}

export default FacetOptions;
