import { find } from 'lodash';

import AppConstants from '../../../constants/AppConstants';
import FormattingUtils from '../../../utils/FormattingUtils';
import { IDedupeColumn } from '../../../interfaces/dedupeInterfaces';
import { stripFieldConfig } from '../../../lib/mighty-fields/common';

const {
  MODEL_TYPES,
} = AppConstants;

const {
  formatAddressMultiline,
  getNameOrDefault,
} = FormattingUtils;

// These lines are covered in the render tests in `DeduplicationPage.test.js`
// istanbul ignore next
const DEDUPE_DISPLAY_TYPES: { [key: string]: IDedupeColumn } = {
  CASE: {
    data: [
      [
        { field: 'plaintiff.birthdate', type: 'date' },
        { field: 'date_of_loss', type: 'date' },
        { field: 'type', optionType: 'caseTypeOptions', type: 'optionSelect' },
        {
          field: 'state_of_incident',
          optionKey: 'code',
          optionType: 'stateOptions',
          type: 'optionSelect',
        },
      ],
      [
        { field: 'lawfirm', render: stripFieldConfig(getNameOrDefault) },
        { field: 'attorney', render: stripFieldConfig(getNameOrDefault) },
        { field: 'lien_summary.medical_facilities' },
      ],
    ],
    title: (data: any) => {
      return getNameOrDefault(data.plaintiff);
    },
    wellTitle: (data: any[]) => {
      const caseWithPlaintiff = find(data, 'plaintiff');
      return getNameOrDefault(caseWithPlaintiff && caseWithPlaintiff.plaintiff);
    },
  },
  CONTACT: {
    data: [
      [
        { field: 'phone_number', type: 'phone'},
        { field: 'additional_contact_info.cell_number', type: 'phone'},
        { field: 'additional_contact_info.fax_number', type: 'phone'},
        { field: 'email', type: 'email' },
      ],
      [
        { field: 'address', render: formatAddressMultiline, type: 'address' },
        { field: 'website', type: 'url' },
      ],
    ],
    title: (data: object) => {
      return getNameOrDefault(data);
    },
    wellTitle: (data: object[]) => {
      const itemWithName = find(data, 'name');
      return getNameOrDefault(itemWithName);
    },
  },
  DEFAULT: {
    data: [],
    title: (data: object) => {
      return getNameOrDefault(data);
    },
    wellTitle: (data: object[]) => {
      return getNameOrDefault(find(data, 'name'));
    },
  },
  PERSON: {
    data: [
      [
        { field: 'phone_number', type: 'phone'},
        { field: 'additional_contact_info.fax_number', type: 'phone'},
      ],
      [
        { field: 'additional_contact_info.cell_number', type: 'phone'},
        { field: 'email', type: 'email' },
      ],
    ],
    title: (data: object) => {
      return getNameOrDefault(data);
    },
    wellTitle: (data: object[]) => {
      const itemWithName = find(data, 'first_name');
      return getNameOrDefault(itemWithName);
    },
  },
};

export const DEDUPE_MODELS_DATA_DISPLAY: { [key: string]: IDedupeColumn } = {
  DEFAULT: DEDUPE_DISPLAY_TYPES.DEFAULT,
  [MODEL_TYPES.attorney.key]: DEDUPE_DISPLAY_TYPES.PERSON,
  [MODEL_TYPES.capitalProvider.key]: DEDUPE_DISPLAY_TYPES.DEFAULT,
  [MODEL_TYPES.case.key]: DEDUPE_DISPLAY_TYPES.CASE,
  [MODEL_TYPES.medicalFacility.key]: DEDUPE_DISPLAY_TYPES.CONTACT,
  [MODEL_TYPES.medicalProvider.key]: DEDUPE_DISPLAY_TYPES.PERSON,
  [MODEL_TYPES.partyOwnedLawFirm.key]: DEDUPE_DISPLAY_TYPES.CONTACT,
};
