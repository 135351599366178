import React, { Component } from 'react';
import { Menu } from 'react-bootstrap-typeahead';
import { groupBy, sortBy, take, isEmpty } from 'lodash';
import { Loader } from '../../../common';
import { AppConstants } from '../../../../constants';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import AttorneySearchResultItem from './AttorneySearchResultItem';
import LawFirmSearchResultItem from './LawFirmSearchResultItem';
import MedicalFacilityContactResultItem from './MedicalFacilityContactResultItem';
import MedicalFacilityResultItem from './MedicalFacilityResultItem';
import MedicalProviderSearchResultItem from './MedicalProviderSearchResultItem';
import PlaintiffSearchResultItem from './PlaintiffSearchResultItem';
import SearchResultMenuSection from './SearchResultMenuSection';
import { SessionStoreClass } from '../../../../stores';

const { MODEL_TYPES } = AppConstants
  , MAX_VISIBLE_SEARCH_RESULTS_PER_SECTION = 10;

interface IProps {
  isLoading: boolean;
  results: Array<{ [key: string]: any }>;
  searchValue: string;
}

interface IInjected extends IProps {
  SessionStore: SessionStoreClass;
}

interface IGlobalSearchSupportedModel {
  component: React.ComponentClass<any>;
  title: string|undefined;
}

interface IGlobalSearchSupportedModels {
  [modelKey: string]: IGlobalSearchSupportedModel;
}

const SUPPORTED_MODELS_BY_NAME: IGlobalSearchSupportedModels = {
  [MODEL_TYPES.attorney.key]: {
    component: AttorneySearchResultItem,
    title: MODEL_TYPES.attorney.display,
  },
  [MODEL_TYPES.partyOwnedLawFirm.key]: {
    component: LawFirmSearchResultItem,
    title: MODEL_TYPES.lawFirm.display,
  },
  [MODEL_TYPES.medicalFacility.key]: {
    component: MedicalFacilityResultItem,
    title: MODEL_TYPES.medicalFacility.display,
  },
  [MODEL_TYPES.medicalFacilityContact.key]: {
    component: MedicalFacilityContactResultItem,
    title: MODEL_TYPES.medicalFacilityContact.display,
  },
  [MODEL_TYPES.case.key]: {
    component: PlaintiffSearchResultItem,
    title: MODEL_TYPES.plaintiff.display,
  },
  [MODEL_TYPES.medicalProvider.key]: {
    component: MedicalProviderSearchResultItem,
    title: MODEL_TYPES.medicalProvider.display,
  },
};

@inject(
  'OptionsStore',
  'SessionStore',
)
@autoBindMethods
@observer
class GroupedSearchResultsMenu extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private getGroups (results: any) {
    if (this.injected.SessionStore.isMightyFreeUser) { return {[MODEL_TYPES.case.key]: results}; }

    return groupBy(results, (result: any) => result.model.toLowerCase());
  }

  private getSafeGroupKeys (results: any) {
    const groupKeys = Object.keys(this.getGroups(results))
      , sortedGroupKeys = sortBy(groupKeys, (key: string) => key !== MODEL_TYPES.case.key);

    return sortedGroupKeys.filter(key => !!key && SUPPORTED_MODELS_BY_NAME.hasOwnProperty(key));
  }

  private renderSearchResultItems (results: Array<{ [key: string]: any }>, searchValue: string) {
    return this.getSafeGroupKeys(results).map((groupKey: string) => {
      const groupItems = this.getGroups(results)[groupKey] as any[]
        , firstTenResults = take(groupItems, MAX_VISIBLE_SEARCH_RESULTS_PER_SECTION)
        , supportedModel = SUPPORTED_MODELS_BY_NAME[groupKey]
        , { component: ModelComponent, title } = supportedModel;

      return (
        <SearchResultMenuSection key={title} title={title}>
          {firstTenResults && firstTenResults.map((item, index) => (
            <ModelComponent
              index={index}
              key={item.id}
              item={item}
              itemsCount={groupItems.length}
              searchValue={searchValue}
            />
          ))}
        </SearchResultMenuSection>
      );
    });
  }

  public render () {
    const {isLoading, results, searchValue} = this.props;

    if (isLoading) {
      return (
        <Menu paginate={false}>
          <li className='loading disabled'>
            <a role='button'><Loader className='button-loader' /></a>
          </li>
        </Menu>
      );
    }

    if (isEmpty(results)) {
      return (
        <Menu paginate={false}>
          <li className='loading disabled'>
            <a role='button'>No results found.</a>
          </li>
        </Menu>
      );
    }

    return (
      <Menu paginate={false}>
        {this.renderSearchResultItems(results, searchValue)}
      </Menu>
    );
  }
}

export default GroupedSearchResultsMenu;
