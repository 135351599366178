import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';

import {
  Link,
} from '../common';

import { FormattingUtils } from '../../utils';

import FlagContactButton from './FlagContactButton';
import FlagContactModal from './FlagContactModal';
import PerformanceAnalytics from './PerformanceAnalytics';
import RelationshipTable from './RelationshipTable';

import {
  FunderStoreClass,
  SessionStoreClass,
  UiStoreClass,
} from '../../stores';
import { EditableCard } from '../../lib/mighty-fields';

import AppConstants from '../../constants/AppConstants';
import Client from '../../clients/Client';
import ClientsClass from '../../clients/ClientsClass';
import { IFieldSet } from '../../interfaces';

const {
    getNameOrDefault,
  } = FormattingUtils
  , { MODEL_TYPES } = AppConstants
  , WIDTH_ONE_THIRD_OF_SCREEN = 4
  , WIDTH_TWO_THIRDS_OF_SCREEN = 8
  , WIDTH_FULL_SCREEN = 12
  , FIELD_SETS_ORGANIZATION = [
      [
        { field: 'name', required: true },
      ],
      [
        { field: 'address', type: 'address' },
      ],
      [
        { field: 'phone_number', type: 'phone' },
        { field: 'fax_number', type: 'phone' },
        { field: 'website', type: 'url' },
      ],
    ]
  , FIELD_SETS_PERSON = [
    [
      { field: 'first_name' },
      { field: 'last_name' },
    ],
    [
      { field: 'phone_number', type: 'phone' },
      {
        field: 'additional_contact_info.cell_number',
        label: 'Cell',
        type: 'phone',
      },
      {
        field: 'additional_contact_info.fax_number',
        label: 'Fax',
        type: 'phone',
      },
      { field: 'email', type: 'email' },
    ],
  ]
  ;

interface IPerson {
  id: string;
  summary: string;
  type: string;
  warning: { id: string } | null;
}

interface IProps {
  contact: IPerson;
  organizations?: any[];
  people?: any[];
  setOrganizationOnSave?: (organization: object) => void;
}

interface IInjected extends IProps {
  Clients: ClientsClass;
  FunderStore: FunderStoreClass;
  SessionStore: SessionStoreClass;
  UiStore: UiStoreClass;
}

interface IModel {
  allCasesIdKey?: string;
  client: Client;
  fieldSets: IFieldSet[];
  isMedical?: boolean;
  isOrganization: boolean;
  isPerson: boolean;
  relationshipsTitle: string;
}

@inject(
  'Clients',
  'FunderStore',
  'SessionStore',
  'UiStore',
)
@autoBindMethods
@observer
class ContactDetail extends Component<IProps> {
  @observable private contact: IPerson | Partial<IPerson> = {};
  private canDeleteFlag: boolean;

  constructor (props: IProps) {
    super(props);
    this.contact = props.contact;
    this.canDeleteFlag = this.injected.SessionStore.userHasPermission('parties.delete_partywarning');
  }

  public componentWillReceiveProps (nextProps: IProps) {
    this.contact = nextProps.contact;
  }

  private get injected () {
    return this.props as IInjected;
  }

  private get models () {
    const { Clients } = this.injected;

    const organization = {
        client: Clients.organizations,
        fieldSets: FIELD_SETS_ORGANIZATION,
        isOrganization: true,
        isPerson: false,
        relationshipsTitle: 'people',
      }
      , person = {
        client: Clients.people,
        fieldSets: FIELD_SETS_PERSON,
        isOrganization: false,
        isPerson: true,
      };

    return {
      [MODEL_TYPES.partyOwnedLawFirm.key]: {
        ...organization,
        allCasesIdKey: 'lawfirm',
      },
      [MODEL_TYPES.attorney.key]: {
        ...person,
        allCasesIdKey: 'attorney',
        relationshipsTitle: 'law firms',
      },
      [MODEL_TYPES.contact.key]: {
        ...person,
        allCasesIdKey: 'contacts',
        relationshipsTitle: 'law firms',
      },
      [MODEL_TYPES.medicalFacilityContact.key]: {
        allCasesIdKey: 'facility_contact',
        isMedical: true,
        relationshipsTitle: 'medical facilities',
        ...person,
      },
      [MODEL_TYPES.medicalProvider.key]: {
        allCasesIdKey: 'provider',
        isMedical: true,
        relationshipsTitle: 'medical facilities',
        ...person,
      },
      [MODEL_TYPES.medicalFacility.key]: {
        allCasesIdKey: 'facility',
        isMedical: true,
        ...organization,
      },
    } as { [key: string]: IModel };
  }

  private get model () {
    return this.models[this.props.contact.type];
  }

  get viewAllCasesLink () {
    const { contact: { id } } = this.props;

    if (!this.model.allCasesIdKey) { return null; }

    return {
      pathname: '/case-search-results/',
      query: {
        [this.model.allCasesIdKey]: id,
      },
    };
  }

  @action
  public async submitWarning (data: object) {
    const { FunderStore } = this.injected;

    const warning = (await FunderStore.flagContact(data));
    this.contact = {...this.contact, warning};
  }

  @action
  public async onConfirmDeleteWarning () {
    const { FunderStore } = this.injected;

    if (!this.contact || !this.contact.warning) { return; }

    await FunderStore.removeContactFlag(this.contact.warning.id);
    this.contact.warning = null;
  }

  @action
  public async saveContact (data: object) {
    const { setOrganizationOnSave } = this.props
      , { id, type } = this.contact;

    if (!id) { return; }

    const response = await this.model.client.update(id, { ...data, type });

    if (response) {
      this.contact = { ...this.contact, ...response };
    }

    if (setOrganizationOnSave) {
      setOrganizationOnSave(this.contact);
    }
  }

  private renderFlagContactButton () {
    return !this.model.isMedical && (
      <div className='col-btns'>
        <FlagContactButton
          canDeleteFlag={this.canDeleteFlag}
          onConfirmDeleteWarning={this.onConfirmDeleteWarning}
          warning={this.contact.warning}
        />
      </div>
    );
  }

  private renderViewAllCasesLink () {
    return this.viewAllCasesLink && (
      <div className='col-btns'>
        <Link to={this.viewAllCasesLink}>
          <Button bsSize='small'>
            View All Cases
          </Button>
        </Link>
      </div>
    );
  }

  public renderHeader () {
    return (
      <header>
        <div className='col-title'>
          <h1>{getNameOrDefault(this.contact)}</h1>
          {this.renderFlagContactButton()}
        </div>
        {this.renderViewAllCasesLink()}
      </header>
    );
  }

  public renderRelationshipTable () {
    const { organizations, people } = this.props
      , relationships = people || organizations || [];

    if (!relationships) { return; }

    return (
      <RelationshipTable
        model={this.model}
        relationships={relationships}
      />
    );
  }

  public render () {
    const { UiStore, FunderStore: { funder } } = this.injected
      , informationSectionWidth = this.model.isMedical ? WIDTH_FULL_SCREEN : WIDTH_TWO_THIRDS_OF_SCREEN
      ;

    return (
      <div className='contact-content'>
        {this.renderHeader()}
        <div className='main'>
          <Row>
            <Col className='col-main' xs={informationSectionWidth}>
              <EditableCard
                fieldSets={this.model.fieldSets}
                model={this.contact}
                onSave={this.saveContact}
                title='Contact Information'
              />
              <EditableCard
                fieldSets={[[{ field: 'summary', type: 'textDynamic' }]]}
                model={this.contact}
                onSave={this.saveContact}
                title='Notes'
              />
              {this.renderRelationshipTable()}
            </Col>
            {!this.model.isMedical &&
              <Col className='col-aside' xs={WIDTH_ONE_THIRD_OF_SCREEN}>
                <PerformanceAnalytics contact={this.contact} />
              </Col>
            }
          </Row>
        </div>
        {UiStore.modals.FlagContact.showing() && funder &&
          <FlagContactModal
            contact={this.contact}
            funderId={funder.id}
            submitWarning={this.submitWarning}
          />
        }
      </div>
    );
  }
}

export default ContactDetail;
