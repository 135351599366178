import React, { Component } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Button } from 'react-bootstrap';

import { Icon } from '../../../src/components/common';
import { getPageRangeStart } from '../../utils/util';

interface IProps {
  currentPageNumber: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNextPageClick: (...args: any[]) => any;
  onPreviousPageClick: (...args: any[]) => any;
  pageSize?: number;
  total: number;
}

interface IPropDefaults extends IProps {
  pageSize: number;
}

@autoBindMethods
@observer
class TablePaginationFooter extends Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    pageSize: 50,
  };

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  @computed
  get paginationText () {
    const { pageSize, currentPageNumber, total } = this.propsWithDefaults
      , pageRangeStart = getPageRangeStart(Number(currentPageNumber), pageSize);

    let pageRangeEnd = Number(currentPageNumber) * pageSize;
    if (total < Number(currentPageNumber) * pageSize) {
      pageRangeEnd = total;
    }
    return `Displaying: ${pageRangeStart}-${pageRangeEnd} of ${total}`;
  }

  public render () {
    const { hasPreviousPage, hasNextPage, onPreviousPageClick, onNextPageClick } = this.props;

    return (
      <div className='pages'>
        <div className='count'>{this.paginationText}</div>
        <div className='buttons'>
          <Button className='btn-prev' bsStyle='primary' disabled={!hasPreviousPage} onClick={onPreviousPageClick}>
            <Icon type='angle-left' />
          </Button>
          <Button className='btn-next' bsStyle='primary' disabled={!hasNextPage} onClick={onNextPageClick}>
            <Icon type='angle-right' />
          </Button>
        </div>
      </div>
    );
  }
}

export default TablePaginationFooter;
