import React, { Component } from 'react';
import { omit } from 'lodash';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { getDisplayName, renderNothing } from 'recompose';
import { SessionStoreClass } from '../stores';

export interface IProps {
  disabledComponent: React.ComponentClass;
  enabledComponent: React.ComponentClass;
}

interface IInjected extends IProps {
  SessionStore: SessionStoreClass;
}

function createGuardedPaidContainer ({ enabledComponent, disabledComponent = renderNothing() }: IProps) {
  @inject('SessionStore')
  @observer
  class GuardedContainer extends Component {
    public static displayName = `GuardedContainer(${getDisplayName(enabledComponent)})`;

    get injected () {
      return this.props as IInjected;
    }

    @computed
    get userHasPermission () {
      const { SessionStore } = this.injected;

      return !SessionStore.isMightyFreeUser;
    }

    public render () {
      const passThroughProps = omit(this.props, 'SessionStore')
        , GuardedComponent = this.userHasPermission ? enabledComponent : disabledComponent;

      return <GuardedComponent {...passThroughProps} />;
    }
  }

  return GuardedContainer;
}

export default createGuardedPaidContainer;
