/* tslint:disable max-classes-per-file */
import React, { Component } from 'react';
import cx from 'classnames';

interface IProps {
  hasSidebar?: boolean;
  name: string;
  standalone?: boolean;
  type?: 'detail' | 'list';
}

interface ISidebarProps {
  className?: any;
}

class Sidebar extends Component<ISidebarProps, {}> {
  public render () {
    const classNames = cx('sidebar', this.props.className);
    return <div className={classNames}>{this.props.children}</div>;
  }
}

class Content extends Component<{}, {}> {
  public render () {
    return <div className='content'>{this.props.children}</div>;
  }
}

class Page extends Component<IProps, {}> {
  public static Content = Content;
  public static Sidebar = Sidebar;

  public static defaultProps: Partial<IProps> = {
    hasSidebar: false,
  };

  public render () {
    const { type, name, hasSidebar, standalone } = this.props
      , pageTypeClassName = type ? `page-type-${type}` : null
      , classNames = cx('page', `page-${name}`, pageTypeClassName, {
        'has-sidebar': hasSidebar,
        standalone,
      });
    return <div className={classNames}>{this.props.children}</div>;
  }
}

export default Page;
