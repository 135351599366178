import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import ICase from '../../../interfaces/models/ICase';
import { CaseStoreClass, FunderStoreClass, SessionStoreClass } from '../../../stores';
import { EditableCard } from '../../../lib/mighty-fields';

interface IProps {
  _case: ICase;
}

interface IInjected extends IProps {
  CaseStore: CaseStoreClass;
  FunderStore: FunderStoreClass;
  SessionStore: SessionStoreClass;
}

@inject(
  'CaseStore',
  'FunderStore',
  'SessionStore',
)
@autoBindMethods
@observer
class ServicingInfo extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get fieldSets () {
    return [[
      { field: 'case_servicing_info.is_serviced', label: 'Is Serviced', type: 'requiredBoolean' },
    ]];
  }

  public async onCaseUpdate (data: any) {
    const { _case } = this.props
      , { CaseStore } = this.injected
      ;

    await CaseStore.update({ caseId: _case.id, data });
  }

  public render () {
    const { _case } = this.props
      , { FunderStore, SessionStore } = this.injected
      ;

    if (!SessionStore.isSessionHijacked || !FunderStore.isServiced) {
      return null;
    }

    return (
      <EditableCard
        fieldSets={this.fieldSets}
        model={_case}
        onSave={this.onCaseUpdate}
        title='Servicing Information'
      />
    );
  }
}

export default ServicingInfo;
