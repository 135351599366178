import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import CaseActivity from './CaseActivity';

interface IProps {
  activity: {
    id: string;
    lien_document_name: string;
    type: string;
  };
}

@autoBindMethods
@observer
class DeletedLienDocument extends Component<IProps> {

  public render () {
    const { activity } = this.props
      , body = `Deleted lien document '${activity.lien_document_name}'`;

    return (
      <CaseActivity
        activity_body={body}
        dot='small'
        {...this.props}
      />
    );
  }
}

export default DeletedLienDocument;
