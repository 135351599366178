import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import uuid from 'uuid';
import { map, sumBy, fromPairs, findIndex, noop, get } from 'lodash';

import {
  Alert,
  Button,
  ButtonToolbar,
  Row,
  Well,
} from 'react-bootstrap';

import { ActionButton, Icon, DocumentTable } from '../../common';
import { FormFieldSets, IFieldSetPartial } from '../../../lib/mighty-fields';
import { Form } from '../../common-formsy';

import AppConstants from '../../../constants/AppConstants';

import {
  FormManager,
  FormattingUtils,
  SmartBool,
  TableRenderingUtils,
} from '../../../utils';

import { bytesToMB } from '../../../utils/util';

import {
  ICase,
  IDocument,
} from '../../../interfaces';
import { CaseStoreClass, FunderStoreClass } from '../../../stores';
import SessionStoreClass from '../../../stores/SessionStoreClass';

const { MAX_ATTACHMENTS_SIZE_MB } = AppConstants;

const { splitCommaList } = FormattingUtils;

const { renderFileType, renderUnformatted } = TableRenderingUtils;

const UnTypedDocumentTable: any = DocumentTable;

interface IProps {
  _case: ICase;
}

interface IInjected extends IProps {
  CaseStore: CaseStoreClass;
  FunderStore: FunderStoreClass;
  SessionStore: SessionStoreClass;
}

interface IModel {
  [key: string]: string;

  bcc_emails: string;
  cc_emails: string;
  generated_email: string;
  subject: string;
  template: string;
  to_emails: string;
}

@inject('FunderStore', 'CaseStore', 'SessionStore')
@autoBindMethods
@observer
class TabEmail extends Component<IProps> {
  @observable private documents: IDocument[] = [];
  @observable private maxAttachmentsSize = new SmartBool(false);
  @observable private showBcc = new SmartBool(false);

  @observable private selectedTemplate: string | null = null;
  private formManager: FormManager;

  private get injected () {
    return this.props as IInjected;
  }

  public constructor (props: IProps) {
    super(props);
    const currentUser = (props as IInjected).SessionStore.user || {}
      , currentUserEmail = get(currentUser, 'email', '')
      ;
    this.formManager = new FormManager({
      fieldSets: this.fieldSets,
      model: { cc_emails: currentUserEmail },
      onChange: this.onChange,
      onSave: this.onSave,
      onSuccess: this.onSuccess,
    });
  }

  private get fieldSets (): IFieldSetPartial[] {
    const { FunderStore } = this.injected
      , toEmailsClassName = !this.showBcc.isTrue ? 'last' : '';

    return [
      [
        {
          disabled: !toJS(FunderStore.caseEmailTemplates).length,
          field: 'template',
          objects: toJS(FunderStore.caseEmailTemplates),
          type: 'objectSelect',
        },
      ],
      [
        {
          className: toEmailsClassName,
          field: 'to_emails',
          label: 'To',
          required: true,
          type: 'emailList',
        },
        {
          field: 'cc_emails',
          label: 'Cc',
          type: 'emailList',
        },
        {
          field: 'bcc_emails',
          label: 'Bcc',
          type: this.showBcc.isTrue ? 'emailList' : 'hidden',
        },
      ],
      [
        { field: 'subject', required: true },
        {
          field: 'generated_email',
          type: 'html',
        },
      ],
    ];
  }

  private get attachmentColumns () {
    return [
      {
        accessor: 'label',
        Cell: renderFileType,
        className: 'col-file',
        Header: 'File Type',
        maxWidth: 20,
      },
      {
        accessor: 'label',
        Cell: renderUnformatted,
        Header: 'Name',
        minWidth: 200,
      },
      {
        accessor: 'id',
        Cell: (row: any) => (
          <div>
            <ActionButton bsSize='small' data={row} onClick={this.onFileDelete}>
              <Icon type='trash' />
            </ActionButton>
          </div>
        ),
        className: 'col-buttons',
        Header: 'actions',
        minWidth: 30,
      },
    ];
  }

  private async onChange (formData: object) {
    const data = formData as IModel
      , { _case } = this.props
      , { FunderStore } = this.injected;

    if (this.selectedTemplate !== data.template) {
      this.selectedTemplate = data.template;

      if (this.selectedTemplate) {
        const template = FunderStore.caseEmailTemplates.find(t => t.id === this.selectedTemplate)
          , generatedEmail = await FunderStore.fetchEmailTemplate(_case.id, this.selectedTemplate);

        this.formManager.onChange.subject(get(template, 'name', ''));
        this.formManager.onChange.generated_email(generatedEmail);
      }
    }
  }

  private handleFileUpload (files: File[]) {
    map(files, (file) => {
      const document: IDocument = {
        file,
        id: uuid.v4(),
        label: file.name,
      };
      this.documents.push(document);
    });
    this.validateAttachmentsSize();
  }

  private validateAttachmentsSize () {
    let totalSize: number = sumBy(this.documents, 'file.size');
    totalSize = bytesToMB(totalSize);
    totalSize > MAX_ATTACHMENTS_SIZE_MB ? this.maxAttachmentsSize.setTrue() : this.maxAttachmentsSize.setFalse();
  }

  private async onSave (formData: object) {
    const data = formData as IModel
      , EMAILS = ['bcc_emails', 'cc_emails', 'to_emails']
      , { _case } = this.props
      , { CaseStore } = this.injected
      , emailsAsArray = fromPairs(EMAILS.map(attr => [attr, splitCommaList(data[attr])]))
      , files: { files?: File[] } = {}
      ;

    if (this.documents.length) {
      files.files = this.documents.map((document) => document.file);
    }

    await CaseStore.createGeneratedEmail({
      ...data,
      ...emailsAsArray,
      ...files,
      case: _case.id,
    });
  }

  private onSuccess () {
    this.formManager.reset();
    this.documents = [];
  }

  public onFileDelete (file: any) {
    const searchParams: any = {id: file.value};
    const documentIndex: number = findIndex(this.documents, searchParams);
    if (documentIndex !== -1) {
      this.documents.splice(documentIndex, 1);
      this.validateAttachmentsSize();
    }
  }

  private renderToMore (smartBool: SmartBool, label: string) {
    if (smartBool.isTrue) {
      return;
    }

    return (
      <Button bsStyle='link' onClick={smartBool.toggle}>
        <Icon type='plus-square-o' />{label}
      </Button>
    );
  }

  private renderAttachments () {
    return (
      <Well className='well-attachments condensed'>
        <UnTypedDocumentTable
          columns={this.attachmentColumns}
          documents={toJS(this.documents)}
          dropzoneText='Drag attachments here'
          onDrop={this.handleFileUpload}
        />
      </Well>
    );
  }

  public render () {
    return (
      <Form {...this.formManager.formProps}>
        <div className='tab-email'>
          <Row>
            <div className='buttons-to-more'>
              {this.renderToMore(this.showBcc, 'BCC')}
            </div>
          </Row>

          <FormFieldSets
            fieldSets={this.fieldSets}
            onChange={noop}
            {...this.formManager.formGroupsProps}
          />

          {this.renderAttachments()}

          {!!this.formManager.errorMessages.length &&
            (<Alert bsStyle='danger' className='lowercase'>
              <ul>
                {this.formManager.errorMessages.map((error: { field: string, message: string }, idx) => <li key={idx}>{error.message}</li>)}
              </ul>
            </Alert>)
          }

          {this.maxAttachmentsSize.isTrue &&
            <Alert bsStyle='danger' className='lowercase'>
              {`Attachments exceed the size limit of ${MAX_ATTACHMENTS_SIZE_MB}MB.`}
            </Alert>
          }

          <ButtonToolbar className='flex-right'>
            <Button
              bsSize='small'
              bsStyle='primary'
              disabled={this.formManager.submitDisabled() || this.maxAttachmentsSize.isTrue}
              type='submit'
            >
              {this.formManager.isSubmitting ? 'Sending...' : 'Send'}
            </Button>
          </ButtonToolbar>
        </div>
      </Form>
    );
  }
}

export default TabEmail;
