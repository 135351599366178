import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { AppConstants } from '../../constants';
import { LinkCopyStoreClass } from '../../stores';

interface IInjected {
  LinkCopyStore: LinkCopyStoreClass;
}

@inject('LinkCopyStore')
@autoBindMethods
@observer
class TermsOfServiceLink extends Component {
  get injected () {
    return this.props as IInjected;
  }

  public render () {
    const { LinkCopyStore: { termsOfService } } = this.injected;

    return <a href={termsOfService.link} {...AppConstants.EXTERNAL_URL_PROPS}>{termsOfService.copy}</a>;
  }
}

export default TermsOfServiceLink;
