import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { noop, get } from 'lodash';

import {
  MenuItem,
  DropdownButton,
  FormGroup,
  ControlLabel,
  InputGroup,
  HelpBlock,
} from 'react-bootstrap';

import { AppConstants } from '../../constants';
import { createInputContainer } from '../../containers';

import { IFormInput, IInputContainer, Value } from '../../interfaces';
const { EMPTY_FIELD } = AppConstants;

const EMPTY_OPTION = { id: '', name: EMPTY_FIELD };

interface IProps extends IFormInput {
  addonAfter?: JSX.Element;
  addonBefore?: JSX.Element;
  allowEmpty?: boolean;
  icon?: string;
  options?: Array<{ [key: string]: any }>;
  optionsLabelKey?: string;
  optionsValueKey?: string;
}

interface IPropDefaults extends IProps {
  allowEmpty: boolean;
  onChange: (value: any) => void;
  options: Array<{ [key: string]: any }>;
  optionsLabelKey: string;
  optionsValueKey: string;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@autoBindMethods
@observer
class Dropdown extends Component<IProps, {}> {
  @observable private value: Value;
  @observable private isFocused = false;

  public static getOptionsFromObject: (obj: { [key: string]: any }) => Array<{ id: string, name: string }>;

  public static defaultProps: Partial<IProps> = {
    allowEmpty: false,
    className: '',
    disabled: false,
    onChange: noop,
    options: [],
    optionsLabelKey: 'name',
    optionsValueKey: 'id',
    required: false,
    value: '',
  };

  get formsy () {
    return this.props as IPropsFormsy;
  }

  constructor (props: IPropsFormsy) {
    super(props);
    this.value = props.value;
  }

  private onChange (valueArg: any) {
    const value: string = valueArg // Bootstrap.MenuItem callback is changed by eventKey prop
      , { onChange, setValue } = this.formsy;
    this.value = value;
    setValue(value);
    onChange(value);
  }

  private onToggle (isOpen: boolean) {
    this.isFocused = isOpen;
  }

  public render () {
    const {
        addonAfter,
        addonBefore,
        allowEmpty,
        className,
        controlId,
        errorMessage,
        formGroupId,
        isDisabled,
        label,
        options,
        optionsLabelKey,
        optionsValueKey,
        required,
        showErrorMessage,
        showRequiredMessage,
        validationState,
      } = this.formsy
      , selectedOption = options.find(option => option[optionsValueKey] === this.value)
      , title = get(selectedOption, optionsLabelKey, '')
      , groupClassName = cx(
        className,
        'form-group-dropdown',
        { focused: this.isFocused },
        { 'has-value': !!(this.value && this.value !== EMPTY_OPTION.name) },
        { disabled: isDisabled },
      )

      , dropdownElement = (
        <DropdownButton disabled={isDisabled} id={`dropdown-${controlId}`} title={title} onToggle={this.onToggle}>
          {allowEmpty &&
            <MenuItem
              active={!this.value}
              eventKey={EMPTY_OPTION.id}
              onSelect={this.onChange}
            >
              {EMPTY_OPTION.name}
            </MenuItem>
          }

          {options.map((i, idx) => (
            <MenuItem
              disabled={!!i.disabled}
              key={idx}
              active={i[optionsValueKey] === this.value}
              eventKey={i[optionsValueKey]}
              onSelect={this.onChange}
            >
              {i[optionsLabelKey]}
            </MenuItem>
          ))}
        </DropdownButton>
      );

    return (
      <FormGroup id={formGroupId} controlId={controlId} className={groupClassName} validationState={validationState}>
        {label &&
          <ControlLabel>
            {label}
            {required &&
              <span className='required'>*</span>
            }
          </ControlLabel>
        }

        <InputGroup>
          {addonBefore}
          {dropdownElement}
          {addonAfter}
        </InputGroup>

        {showRequiredMessage &&
          <HelpBlock>{label || 'This field'} is required.</HelpBlock>}

        {showErrorMessage &&
          <HelpBlock>{errorMessage}</HelpBlock>}
      </FormGroup>
    );
  }
}

Dropdown.getOptionsFromObject = (obj: { [key: string]: any }) => {
  return Object.keys(obj).map(k => {
    return { id: k, name: obj[k] };
  });
};

export default Dropdown;
