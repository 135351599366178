import autoBindMethods from 'class-autobind-decorator';
import { action, computed, observable } from 'mobx';
import { get } from 'lodash';

import CaseSearchResultsPageStore from './CaseSearchResultsPageStore';
import ListPageStore from './ListPageStore';
import ReportPageStore from './ReportPageStore';
import { SmartBool } from '../utils/';

const ALL_MODALS = [
  'AdditionalLiens',
  'Attorney',
  'CaseAlertAdditionalContacts',
  'CaseDocument',
  'CaseLogPayment',
  'CaseValue',
  'CloseAllLiens',
  'ConfigurableReports',
  'DeleteCase',
  'EditCaseNote',
  'FaxDocumentTemplate',
  'FinalizeMedicalApplication',
  'FlagContact',
  'FundingDetails',
  'GenericFundingDetails',
  'GenericFundingForm',
  'LawFirm',
  'LawFirmContact',
  'LeadSource',
  'LegalFundingLien',
  'MedicalCodes',
  'MedicalDetails',
  'MedicalFacilityContact',
  'MedicalProvider',
  'NewCase',
  'NewMedicalApplication',
  'NewPlaintiffApplication',
  'Payoff',
  'PlaintiffInfo',
  'PostFunding',
  'PriorFunding',
  'ProcessingDate',
  'RejectApplication',
  'SaveReport',
  'SaveSMSReply',
  'SecondaryLawFirmContact',
  'WorkflowProcessingDate',
];

@autoBindMethods
class UiStoreClass {
  public isStoreReady = true;
  public rejectApplicationOnSave = null;
  @observable private showModalState = observable.map({});
  @observable public isSlidingSearchBarOpen = new SmartBool();

  public applicationsPage: ListPageStore;
  public caseSearchResultsPage: CaseSearchResultsPageStore;
  public caseTrackingPage: ListPageStore;
  public contactListPage: ListPageStore;
  public organizationListPage: ListPageStore;
  public rejectedApplicationsPage: ListPageStore;
  public reportPage: ReportPageStore;
  public tasksPage: ListPageStore;
  public updatesPage: ListPageStore;

  // This object contains three functions for every modal
  // defined in the ALL_MODALS constructor above:
  public modals: { [key: string]: {
   close: () => void,
   open: () => void,
   showing: () => boolean,
  }} = {};

  // Closes the Case Document Modal:
  //     UiStore.modals.CaseDocument.close()

  // Opens the Case Document Modal:
  //     UiStore.modals.CaseDocument.open()

  // Returns boolean on if Case Document should be showing:
  //     UiStore.modals.CaseDocument.showing()

  constructor () {
    this.applicationsPage = new ListPageStore();
    this.caseSearchResultsPage = new CaseSearchResultsPageStore();
    this.caseTrackingPage = new ListPageStore();
    this.contactListPage = new ListPageStore();
    this.organizationListPage = new ListPageStore();
    this.rejectedApplicationsPage = new ListPageStore();
    this.reportPage = new ReportPageStore();
    this.tasksPage = new ListPageStore();
    this.updatesPage = new ListPageStore();

    ALL_MODALS.forEach(modalName => {
      const modalNameFull = `${modalName}Modal`;
      this.modals[`${modalName}`] = {
        close: this.closeModal.bind(this, modalNameFull),
        open: this.openModal.bind(this, modalNameFull),
        showing: this.modalShowing.bind(this, modalNameFull),
      };
      this.showModalState.set(modalNameFull, false);
    });
  }

  @computed
  get env () {
    return get(window, 'Mighty.ENV', 'unknown').toLowerCase();
  }

  @action
  private openModal (modalName: string) {
    this.showModalState.set(modalName, true);
  }

  @action
  private closeModal (modalName: string) {
    this.showModalState.set(modalName, false);
  }

  private modalShowing (modalName: string): boolean {
    return !!this.showModalState.get(modalName);
  }
}

export default UiStoreClass;
