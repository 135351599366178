import React, { Component } from 'react';
import { observable, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { format } from 'date-fns';
import { pick, sumBy, find, get, isEmpty, isNil, omit } from 'lodash';
import numeral from 'numeral';

import {
  Alert,
  Button,
  ButtonToolbar,
  Col,
  Modal,
  Row,
} from 'react-bootstrap';

import AppConstants from '../../../constants/AppConstants';
import { Form } from '../../common-formsy';
import { FormattingUtils, FormManager } from '../../../utils';
import { FormFieldSets } from '../../../lib/mighty-fields';
import { ICase, ILien } from '../../../interfaces';
import { Icon } from '../../common';
import { insertIf, roundMoney } from '../../../utils/util';
import { LienStore as LienStoreClass, UiStoreClass } from '../../../stores';

import LiensTable, { ITableType } from './LiensTable';
import ClientsClass from '../../../clients/ClientsClass';

const { DATE_FORMATS } = AppConstants;
const { formatMoney } = FormattingUtils;

const TYPE_LOST_DEAL = 'LOST_DEAL';

interface IProps {
  _case: ICase;
}

interface IInjected extends IProps {
  Clients: ClientsClass;
  LienStore: LienStoreClass;
  UiStore: UiStoreClass;
}

interface IFormManagerData {
  lien_is_open: string;
  matured_date: string;
  recalculateOnChange: boolean;
  total_amount_received: string;
  type: string;
}

type IPartialLien = Pick<ILien,
  'acquired_date' |
  'adjustedLienBalance' |
  'agreement_date' |
  'amount' |
  'amount_owed_today' |
  'current_lien_balance' |
  'experimental_lien_balance' |
  'id' |
  'isGenericLien' |
  'isMedicalFunding' |
  'isPlaintiffFunding' |
  'label' |
  'principal_amount' |
  'subtype'
>;

export interface ILienState extends IPartialLien {
  checked: boolean;
  return_actual_amount: string;
  return_expected_amount: string;
}

@inject('Clients', 'LienStore', 'UiStore')
@autoBindMethods
@observer
class CaseLogPaymentModal extends Component<IProps> {
  @observable private currentMaturedDate: string | null = null;
  @observable private errorMessage = '';
  @observable private liens = new Map<string, ILienState>();
  private formManager: FormManager;

  private get injected () {
    return this.props as IInjected;
  }

  public constructor (props: IProps) {
    super(props);

    const { _case } = this.props;

    const LIEN_FIELDS = [
      'acquired_date',
      'adjustedLienBalance',
      'agreement_date',
      'amount',
      'amount_owed_today',
      'current_lien_balance',
      'experimental_lien_balance',
      'id',
      'isGenericLien',
      'isMedicalFunding',
      'isPlaintiffFunding',
      'label',
      'principal_amount',
      'subtype',
    ];

    _case.liensAcceptingReturns.forEach((lien: ILien) =>
      this.liens.set(lien.id, {
        ...pick(lien, LIEN_FIELDS) as IPartialLien,
        checked: false,
        return_actual_amount: '0',
        return_expected_amount: numeral(lien.amount_owed_today).format('0.00'),
      })
    );
    const formDefaults = {
      lien_is_open: false,
      matured_date: format(new Date(), DATE_FORMATS.date_value),
    };
    this.currentMaturedDate = formDefaults.matured_date;

    this.formManager = new FormManager({
      fieldSets: this.fieldSets,
      model: formDefaults,
      onChange: this.onFormChange as (data: object) => void,
    });
  }

    private get formManagerData (): IFormManagerData {
    if (!this.formManager) {
      // This is necessary for the initial calculation of this.fieldSets
      return {
        lien_is_open: '',
        matured_date: '',
        recalculateOnChange: true,
        total_amount_received: '',
        type: '',
      };
    }

    return this.formManager.submitData as IFormManagerData;
  }

  private get fieldSets () {
    const { type } = this.formManagerData
      , disablePayerName = (type === TYPE_LOST_DEAL)
      , hasPlaintiffLiens = !isEmpty(this.filterLiensByTypecheck('isPlaintiffFunding'))
      , recalculateFieldSet = [{
        field: 'recalculateOnChange',
        label: 'Recalculate Lien Balances When Date of Payment Changes',
        tooltip: `By default, lien balance and expected amount are calculated based on today's date. Enable this option to calculate the fields based on the specified date of payment.`,
        type: 'checkbox',
      }]
      ;

    return [
      [
        {
          field: 'lien_is_open',
          label: 'Keep Lien Open',
          required: true,
          type: 'requiredBoolean',
        },
      ],
      [
        {
          field: 'type',
          optionKey: 'value',
          optionType: 'returnTypes',
          required: true,
          type: 'optionSelect',
        },
        {
          disabled: disablePayerName,
          field: 'payer_name',
        },
        {
          field: 'matured_date',
          label: 'Date of Payment',
          required: true,
          type: 'date',
        },
      ],
      ...insertIf(hasPlaintiffLiens, recalculateFieldSet),
      [
        {
          field: 'total_amount_received',
          label: 'Amount Received',
          required: true,
          type: 'money',
        },
      ],
      [
        {
          field: 'notes',
          type: 'text',
        },
      ],
    ];
  }

  private get totalAmountReceived () {
    return Number(this.formManagerData.total_amount_received);
  }

  private get showError () {
    return !!this.errorMessage;
  }

  private get showAllocateButton () {
    return !isNil(this.totalAmountReceived) && this.checkedLiens.length > 1;
  }

  private get totalReturn () {
    return this.checkedLiens.map(lien => Number(lien.return_actual_amount)).reduce((a, b) => a + b, 0);
  }

  @computed
  private get allLiens (): ILienState[] {
    return Array.from(this.liens.values());
  }

  private filterLiensByTypecheck (typecheck: keyof ILienState) {
    return this.allLiens.filter(lien => lien[typecheck]);
  }

  @computed
  private get checkedLiens (): ILienState[] {
    return this.allLiens.filter(lien => lien.checked);
  }

  @computed
  private get checkedLiensWithReturns () {
    return this.checkedLiens.filter(lien => {
      const amount = Number(lien.return_actual_amount);
      return (Number.isFinite(amount) && !!lien.return_actual_amount.length);
    });
  }

  @computed
  private get returnData () {
    const omitFields = ['total_amount_received'];

    return this.checkedLiensWithReturns.map(lien => ({
      actual_amount: lien.return_actual_amount,
      expected_amount: lien.return_expected_amount,
      lien: lien.id,
      ...omit(this.formManager.submitData, omitFields),
    }));
  }

  private onInputChange (id: string, value: unknown, key?: string) {
    const lien = this.liens.get(id);
    // istanbul ignore next
    if (!lien) { return; }

    if (key === 'return_expected_amount') {
      lien.return_expected_amount = String(value);
    }
    else {
      lien.return_actual_amount = String(value);
    }
  }

  private onCheckboxChange (id: string, checked: boolean) {
    const lien = this.liens.get(id);
    // istanbul ignore next
    if (!lien) { return; }
    lien.checked = checked;
  }

  private onFormChange (data: IFormManagerData) {
    this.recalculateLienBalances(data);
    this.handleSingleAllocation();
  }

  private async recalculateLienBalances (data: IFormManagerData) {
    const { _case, Clients } = this.injected
      , maturedDate = data.matured_date
      , isNewValidDate = format(maturedDate, DATE_FORMATS.date_value) === maturedDate && maturedDate !== this.currentMaturedDate
      ;

    if (!!data.recalculateOnChange && isNewValidDate) {
      this.currentMaturedDate = maturedDate;

      const results = await Clients.lienBalances.list({ params: { case: _case.id, date_of_balance: maturedDate }})
        , liensAcceptingReturns = results.filter((lien: ILien) => {
          return !!find(this.allLiens, ['id', lien.id]);
        })
        ;

      liensAcceptingReturns.forEach((lien: ILien) => {
        const originalLien = this.liens.get(lien.id) as ILienState
          , adjustedLienBalance = originalLien.adjustedLienBalance(lien.balance);

        this.liens.set(lien.id, {
          ...originalLien,
          amount_owed_today: lien.balance,
          current_lien_balance: adjustedLienBalance,
          return_expected_amount: lien.balance,
        });
      });
    }
  }

  private handleSingleAllocation () {
    if (this.liens.size === 1) {
      const lien = this.allLiens[0];
      this.onInputChange(lien.id, this.totalAmountReceived);
      this.onCheckboxChange(lien.id, true);
    }
  }

  private handleAllocateButtonClick () {
    const allocations = this.allocateAcrossLiens(this.checkedLiens, this.totalAmountReceived);
    allocations.forEach(allocation => this.onInputChange(allocation.id, allocation.amount));
  }

  private allocateAcrossLiens (lienStates: ILienState[], total: number) {
    const FIELD = 'amount_owed_today';

    // Allocate total across liens in proportion to the lien's value of field
    const totalFieldAmount = sumBy(lienStates, FIELD)
      , allocations: Array<{ amount: number, id: string }> = [];

    lienStates.forEach(lienState => {
      const amountAllocated = roundMoney(total * get(lienState, FIELD) / totalFieldAmount)
        , amount: number = (amountAllocated && !isNaN(amountAllocated)) ? amountAllocated : 0;

      allocations.push({ amount, id: lienState.id });
    });

    // Adjust first entry so that allocations add up to the total
    const totalAmountAllocated = sumBy(allocations, 'amount');
    allocations[0].amount = roundMoney(allocations[0].amount - (totalAmountAllocated - total)) || 0;

    return allocations;
  }

  private onValidSubmit () {
    this.handleErrorMessages();
    if (this.showError) { return; }

    const {
        _case,
        LienStore,
        UiStore,
      } = this.injected
      , caseId = _case.id
      , data = this.returnData;

    LienStore.createLienReturn({ caseId, data });
    UiStore.modals.CaseLogPayment.close();
  }

  private handleErrorMessages () {
    this.errorMessage = '';

    if (this.checkedLiens.length === 0) {
      this.errorMessage = 'You must select a lien';
    }
    else if (this.checkedLiens.length > this.checkedLiensWithReturns.length) {
      this.errorMessage = 'Payment Amount is required';
    }
    else if (this.checkedLiensWithReturns.some(lien => Number(lien.return_actual_amount) < 0)) {
      this.errorMessage = 'Payment Amount must be a positive number';
    }
    else if (roundMoney(this.totalReturn) !== roundMoney(this.totalAmountReceived)) {
      this.errorMessage = 'Sum of all lien returns must equal amount received';
    }
  }

  private handleAlertDismiss () {
    this.errorMessage = '';
  }

  private get disableExpectedAmount (): boolean {
    return this.formManagerData.lien_is_open === 'true';
  }

  private onChangeLienIsOpen (lienIsOpenValue: string) {
    this.formManager.onChange.lien_is_open(lienIsOpenValue);
    this.allLiens.forEach(lienState => {
      lienState.return_expected_amount = (
        this.disableExpectedAmount
          ? '0'
          : numeral(lienState.amount_owed_today).format('0.00')
      );
    });
  }

  private onChangeType (typeValue: string) {
    const { onChange } = this.formManager;
    if (typeValue === TYPE_LOST_DEAL) {
      onChange.payer_name('');
    }
    onChange.type(typeValue);
  }

  public render () {
    const onClose = this.injected.UiStore.modals.CaseLogPayment.close
      /* eslint-disable sort-keys */
      , lienTypeConfigs: { [key: string]: { header: string, liens: ILienState[] } } = {
        generic: { header: 'Liens', liens: this.filterLiensByTypecheck('isGenericLien') },
        medical: { header: 'Medical Liens', liens: this.filterLiensByTypecheck('isMedicalFunding') },
        plaintiff: { header: 'Plaintiff Funding Liens', liens: this.filterLiensByTypecheck('isPlaintiffFunding') },
      }
      /* eslint-enable sort-keys */
      , formGroupsProps = {
        ...this.formManager.formGroupsProps,
        fieldSets: this.fieldSets,
        onChange: {
          ...this.formManager.formGroupsProps.onChange,
          lien_is_open: this.onChangeLienIsOpen,
          type: this.onChangeType,
        },
      }
      ;

    return (
      <Modal className='modal-log-payment right side-modal' show onHide={onClose}>
        <Form onValidSubmit={this.onValidSubmit}>

          <Modal.Header closeButton>
            <Modal.Title>Log Payment</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormFieldSets {...formGroupsProps} />

            <h3>Return by Open Lien</h3>

            {(Object.keys(lienTypeConfigs) as ITableType[]).map(tableType => {
              const { header, liens } = lienTypeConfigs[tableType];

              if (!liens.length) { return null; }

              return (
                <div key={tableType} className={`fundings-${tableType}`}>
                  <h6>{header}</h6>
                  <LiensTable
                    disableExpectedAmount={this.disableExpectedAmount}
                    liens={liens}
                    onCheckboxChange={this.onCheckboxChange}
                    onInputChange={this.onInputChange}
                    tableType={tableType}
                  />
                </div>
              );
            })}

            <Row>
              <Col xs={6}>
                {this.showAllocateButton &&
                  <div className='allocation-returns'>
                    <Button bsStyle='link' onClick={this.handleAllocateButtonClick}>
                      <Icon type='code-fork' />&nbsp;&nbsp;Allocate Proportionally
                    </Button>
                  </div>
                }
              </Col>

              <Col xs={6}>
                <div className='fees-total'>
                  Sum of Returns <span className='number'>{formatMoney(this.totalReturn)}</span>
                </div>
              </Col>
            </Row>
          </Modal.Body>

          {this.showError &&
            <Alert bsStyle='danger' className='modal-alert' onDismiss={this.handleAlertDismiss}>
              <p>{this.errorMessage}</p>
            </Alert>
          }

          <Modal.Footer>
            <ButtonToolbar className='pull-right'>
              <Button onClick={onClose}>Cancel</Button>
              <Button bsStyle='primary' type='submit'>Save</Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default CaseLogPaymentModal;
