import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { isString } from 'lodash';

import FormManager from './FormManager';

import FormFields from './FormFields';

interface IProps {
  fields: any[];
  label?: string;
  onChange: (value: any) => void;
  value: object | string;
}

@autoBindMethods
@observer
class NestedFormGroup extends Component<IProps, {}> {
  private formManager: FormManager;

  constructor (props: IProps) {
    super(props);
    // istanbul ignore next
    if (isString(props.value) && props.value.length > 0) {
      // tslint:disable-next-line no-console
      console.warn(`String of length greater than 0 passed as value to NestedFormGroup: '${props.value}'.`);
    }

    this.formManager = new FormManager({
      fieldSets: [props.fields],
      model: isString(props.value) ? undefined : props.value,
      onChange: this.onChange,
    });
  }

  public get fieldSet () {
    const { label, fields } = this.props;
    if (!label) { return fields; }

    return fields.map(field => ({
      ...field,
      ...(field.inheritParentLabel ? { label } : {}),
    }));
  }

  public componentDidMount () {
    this.onChange();
  }

  private onChange () {
    this.props.onChange(this.formManager.submitData);
  }

  public render () {
    return (
      <FormFields
        className='form-group-nested'
        fieldSet={this.fieldSet}
        {...this.formManager.formGroupsProps}
      />
    );
  }
}
export default NestedFormGroup;
