import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { action, observable, toJS, computed } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { some, sortBy } from 'lodash';

import { HelpBlock } from 'react-bootstrap';

import { AppConstants } from '../../constants';
import { Dropdown, Input, MultiSelectTypeahead } from '../common-formsy';
import FormattingUtils from '../../utils/FormattingUtils';
import { getContactsFromCase } from '../../utils/util';
import { IFormInput } from '../../interfaces';

const { getNameOrDefault } = FormattingUtils
  , { CONTACT_TYPES } = AppConstants
  , CONTACT_OPTIONS = [
      {name: CONTACT_TYPES.LAW_FIRM.display, id: CONTACT_TYPES.LAW_FIRM.type},
      {name: CONTACT_TYPES.OTHER.display, id: CONTACT_TYPES.OTHER.type},
    ]
  ;

type PartialIFormInput = Pick<IFormInput, Exclude<keyof IFormInput, 'value'>>;

interface IProps {
  _case: any;
  dropdownProps: PartialIFormInput;
  multiple: boolean;
  newContactLabel: string;
}

interface IPropDefaults extends IProps {
  dropdownProps: PartialIFormInput;
  multiple: boolean;
}

@autoBindMethods
@observer
class CaseContactSelect extends Component <IProps> {
  @observable private contactId: string = '';
  @observable private contactType: string = '';

  public static defaultProps: Partial<IProps> = {
    dropdownProps: {
      field: 'case-contact',
      label: 'Who should respond to this request?',
      name: 'recipient',
      required: true,
    },
    multiple: false,
  };

  constructor (props: IProps) {
    super(props);

    const { _case: { tracking_contact } } = props;

    if (tracking_contact) {
      const trackingContact = this.contacts.find(contact => contact.id === tracking_contact);

      if (trackingContact && !!trackingContact.email) {
        this.contactId = tracking_contact;
      }
    }
  }

  get contacts () {
    return getContactsFromCase(this.propsWithDefaults._case);
  }

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  @computed
  private get options () {
    const options: Array<{disabled: boolean, name: string, id: string}> = []
      , doesContactHaveEmail = (contact: any) => !contact.email
      , sortedContacts = sortBy(this.contacts, [doesContactHaveEmail, 'first_name'])
      ;

    sortedContacts.forEach((contact: any) => {
      options.push({
        disabled: !contact.email,
        id: contact.id,
        name: this.formatContactDisplay(contact),
      });
    });

    return options;
  }

  @computed
  private get hasCaseContacts () {
    return some(this.contacts, 'email');
  }

  private isTrackingContact (contact: any) {
    return contact.id === this.propsWithDefaults._case.tracking_contact;
  }

  private formatContactDisplay (contact: any) {
    const emailDisplay = contact.email ? `<${contact.email}>` : 'No Email';
    return `${getNameOrDefault(contact)} - ${emailDisplay}${this.isTrackingContact(contact) ? ' (Tracking Contact)' : ''}`;
  }

  @action
  private async handleContactSelect (contactId: string) {
    this.contactId = contactId;
  }

  public renderCaseContactSelect () {
    if (this.props.multiple) {
      return this.renderMultipleCaseContactSelect();
    }

    return this.renderSingleCaseContactSelect();
  }

  private renderHelpBlock () {
    return <HelpBlock>Only contacts with email addresses can be selected.</HelpBlock>;
  }

  public renderSingleCaseContactSelect () {
    return (
      <Fragment>
        <Dropdown
          {...this.propsWithDefaults.dropdownProps}
          options={toJS(this.options)}
          onChange={this.handleContactSelect}
          value={this.contactId}
        />
        {this.renderHelpBlock()}
      </Fragment>
    );
  }

  public renderMultipleCaseContactSelect () {
    return (
      <Fragment>
        <MultiSelectTypeahead
          {...this.propsWithDefaults.dropdownProps}
          options={toJS(this.options)}
          required
          value={''}
        />
        {this.renderHelpBlock()}
      </Fragment>
    );
  }

  @action
  private setContactType (value: string) {
    this.contactType = value;
  }

  private renderFullNameAndEmail () {
    const { dropdownProps } = this.propsWithDefaults;

    return (
      <Fragment>
        <h5>{this.props.newContactLabel || dropdownProps.label}</h5>
        <Input
          field='newContactFullName'
          label='Full Name'
          name='newContactFullName'
          required
        />
        <Input
          field='newContactEmail'
          label='Email'
          name='newContactEmail'
          required
          type='email'
        />
        <Dropdown
          field='contactType'
          label='Contact Type'
          name='contactType'
          onChange={this.setContactType}
          options={CONTACT_OPTIONS}
          required
          value={this.contactType}
        />
      </Fragment>
    );
  }

  public render () {
    return this.hasCaseContacts
      ? this.renderCaseContactSelect()
      : this.renderFullNameAndEmail();
  }
}

export default CaseContactSelect;
