import React, { Component } from 'react';

import CaseActivity from './CaseActivity';

interface IProps {
  activity: {
    liens_acknowledged: boolean;
  };
}

class LienAcknowledgment extends Component<IProps> {
  public render () {
    const isAcknowledged = this.props.activity.liens_acknowledged
      , activityBody = (
        <div>
          Lien Acknowledgment -
          {
            isAcknowledged
            ? ' Liens have been acknowledged.'
            : ' Liens are not acknowledged.'
          }
        </div>
      )
      ;

    return (
      <CaseActivity
        activity_body={activityBody}
        dot='icon'
        icon={isAcknowledged ? 'check' : 'times'}
        {...this.props}
      />
    );
  }
}

export default LienAcknowledgment;
