import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { noop } from 'lodash';
import { Button, ControlLabel, FormGroup } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

import { Icon } from '../common';
import { createInputContainer } from '../../containers';
import { IFormInput, IInputContainer, Value } from '../../interfaces';

interface IPropDefaults extends IFormInput {
  onChange: (value: Value) => void;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@autoBindMethods
@observer
class Checkbox extends Component<IFormInput, {}> {
  @observable private value: boolean;

  public static defaultProps: Partial<IFormInput> = {
    onChange: noop,
  };

  get formsy () {
    return this.props as IPropsFormsy;
  }

  constructor (props: IPropsFormsy) {
    super(props);

    this.value = !!props.value;
    this.setValue();
    props.onChange(this.value);
  }

  private setValue () {
    this.formsy.setValue(this.value);
  }

  private onChange (event: any) {
    const { onChange } = this.formsy;

    this.value = event.target['checked'];
    this.setValue();
    onChange(this.value);
  }

  public render () {
    const {
      controlId,
      field,
      formGroupId,
      isDisabled,
      label,
      tooltip,
    } = this.formsy
    , className = cx(
      'form-group-checkbox',
      'original-styling',
      {'has-value': this.value},
    );

    return (
      <FormGroup id={formGroupId} controlId={controlId} className={className}>
        <div data-tip data-for={controlId}>
          <input
            className='form-control'
            checked={this.value || false}
            disabled={isDisabled}
            id={controlId}
            name={field}
            onChange={this.onChange}
            type='checkbox'
          />
          {label &&
          <ControlLabel style={{display: 'flex', alignItems: 'center'}}>
            {label}
            {tooltip &&
              <Button bsStyle='link' style={{marginLeft: '5px'}}>
                <Icon type='question-circle-o' />
              </Button>
            }

          </ControlLabel>}
        </div>
        {tooltip &&
        <ReactTooltip id={controlId} type='info' effect='solid' place='right'>
          {tooltip}
        </ReactTooltip>
        }
      </FormGroup>
    );
  }
}

export default Checkbox;
