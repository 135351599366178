import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import {
  Table,
  Well,
} from 'react-bootstrap';

import {
  AwaitButton,
  Icon,
} from '../../common';

import {
  IDedupableObject,
  IDedupeMatch,
} from '../../../interfaces/dedupeInterfaces';

import DedupeMatchRow from './DedupeMatchRow';
import DedupePageState from './dedupePageState';

interface IProps {
  dedupeMatch: IDedupeMatch;
  pageState: DedupePageState;
}

@autoBindMethods
@observer
class DedupeMatch extends Component<IProps> {
  private async dismiss () {
    const { dedupeMatch, pageState } = this.props;
    await pageState.dismiss(dedupeMatch);
  }

  private async merge () {
    const { dedupeMatch, pageState } = this.props;
    await pageState.merge(dedupeMatch);
  }

  private get mergingCount () {
    const { dedupeMatch, pageState } = this.props;
    return pageState.getMergingCount(dedupeMatch);
  }

  public render () {
    const {
      dedupeMatch,
      pageState,
      } = this.props
      , data = pageState.getMatchObjects(dedupeMatch)
      , tooFewRecords = (this.mergingCount < 2)
      ;

    return (
      <Well className='well-match' key={dedupeMatch.id}>
        <h3 className='flex-between'>
          {pageState.modelDisplay.wellTitle(data)}

          <AwaitButton
            addonBefore={<Icon type='times' />}
            bsStyle='link'
            className='btn-dismiss'
            disabled={pageState.isLoading}
            onClick={this.dismiss}
            text='Dismiss'
          />
        </h3>
        <Table bordered>
          <tbody>
            {data.map((dedupableObject: IDedupableObject) => (
              <DedupeMatchRow
                dedupableObject={dedupableObject}
                dedupeMatch={dedupeMatch}
                key={dedupableObject.id}
                pageState={pageState}
              />
            ))}
          </tbody>
        </Table>

        <div className='flex-right'>
          <AwaitButton
            bsSize='small'
            bsStyle='primary'
            className='btn-merge'
            disabled={tooFewRecords || pageState.isLoading}
            onClick={this.merge}
            text={`Merge ${this.mergingCount}`}
          />
        </div>
      </Well>
    );
  }
}

export default DedupeMatch;
