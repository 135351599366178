import React, { Component } from 'react';

import { Nav } from 'react-bootstrap';
import CaseTrackingNavDropdown from './CaseTrackingNavDropdown';

class GuestNavbar extends Component <{}> {
  public render () {
    return (
      <Nav className='navbar-menu'>
        <CaseTrackingNavDropdown />
      </Nav>
    );
  }
}

export default GuestNavbar;
