import React, { Component } from 'react';
import Helmet from 'react-helmet';
import autoBindMethods from 'class-autobind-decorator';
import { computed, toJS, observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import ReactTable from 'react-table';
import { get } from 'lodash';
import cx from 'classnames';
import { browserHistory } from 'react-router';
import Dropzone from 'react-dropzone';
import fileSaver from 'browser-filesaver';

import {
  Button,
  Col,
  Row,
  Well,
} from 'react-bootstrap';

import {
  ActionButton,
  Icon,
  Link,
  LinkButton,
  Page,
} from '../../common';

import { AppConstants } from '../../../constants';

import ClientsClass from '../../../clients/ClientsClass';
import { OptionsStoreClass } from '../../../stores';
import { FormattingUtils, SmartBool, TableRenderingUtils, toast } from '../../../utils';
import { FormModal } from '../../../lib/mighty-fields';

import DataAdminHeader from '../DataAdminHeader';
import { CREATE_IMPORT, CREATE_BULK_EDIT } from './FieldSets';
import Loader from '../../common/Loader';
import BulkInstructions from './BulkInstructions';
import BulkEditTemplateDownloadSection from './BulkEditTemplateDownloadSection';
import { IMPORT_STEPS } from './Steps';
import { getOrDefault } from '@mighty-justice/utils';
import { formatSortingParams } from '../../../utils/util';

const { varToLabel } = FormattingUtils;
const { renderDate } = TableRenderingUtils;
const { IMPORTS_PAGE_SIZE, ROUTING } = AppConstants;

const COLUMNS = [
    {
      accessor: 'label',
      Header: 'Label',
      sortKey: 'label',
    },
    {
      accessor: 'configuration_label',
      Header: 'Template',
      sortKey: 'configuration__label',
    },
    {
      accessor: 'last_status_update.status',
      Cell: (item: any) => {
        const RED_STATES = [
            IMPORT_STEPS.FAILED_VALIDATION.key,
            IMPORT_STEPS.FAILED_VALIDATION_LENGTH.key,
            IMPORT_STEPS.PREVIEW_REJECTED.key,
            IMPORT_STEPS.FAILED_TEST_COMMIT.key,
            IMPORT_STEPS.FAILED_COMMIT.key,
            IMPORT_STEPS.FAILED_REVERT.key,
            IMPORT_STEPS.FAILED_SPLIT.key,
          ]
          , GREEN_STATES = [
            IMPORT_STEPS.COMMITTED.key,
            IMPORT_STEPS.PASSED_POST_COMMIT_PROCESSING.key,
            IMPORT_STEPS.SPLIT.key,
          ]
          , classNames = cx(
          'ellipsis',
          {'text-red': RED_STATES.includes(item.value)},
          {'text-green': GREEN_STATES.includes(item.value)},
        );

        return <h4 className={classNames}>{getOrDefault(item.value && varToLabel(item.value))}</h4>;
      },
      Header: 'Status',
      sortKey: 'last_status_update__status',
    },
    {
      accessor: 'created_at',
      Cell: renderDate,
      Header: 'Date Created',
      sortKey: 'created_at',
    },
    {
      accessor: 'created_by_user_name',
      Header: 'Imported By',
      sortKey: 'created_by_user__full_name',
    },
  ];

interface IInjected {
  Clients: ClientsClass;
  OptionsStore: OptionsStoreClass;
}

interface ISortedItem {
  id: string;
  desc: boolean;
}

@inject('Clients', 'OptionsStore')
@autoBindMethods
@observer
class ImportsPage extends Component {
  private isLoading = new SmartBool(true);
  private isFetching = new SmartBool(false);
  private showingNew = new SmartBool(false);
  private isBulkEdit = new SmartBool(false);
  private isDropActive = new SmartBool(false);
  private refDropzone: any;
  @observable private imports = [];
  @observable private totalImports = 0;
  @observable private currentPage = 1;
  @observable private sorted: ISortedItem[] = [];
  @observable private uploadedFile: any = null;
  @observable private configurationId = '';

  private get injected () {
    return this.props as IInjected;
  }

  public async componentDidMount () {
    const location = browserHistory.getCurrentLocation();

    if (location.pathname === ROUTING.bulkEdit) {
      this.isBulkEdit.setTrue();
    }

    this.injected.OptionsStore.fetchImportConfigurationOptions();
    await this.fetchImports();
    this.isLoading.set(false);
  }

  private get client () {
    return this.injected.Clients.imports;
  }

  @computed get totalPages () {
    return Math.ceil(this.totalImports / IMPORTS_PAGE_SIZE);
  }

  @action
  private async fetchImports () {
    this.isFetching.set(true);

    const sortingParams = formatSortingParams(
      this.sorted.map(sort => ({
        desc: sort.desc,
        id: COLUMNS.find(col => col.accessor === sort.id)?.sortKey || sort.id,
      }))
    );

    const params = {
      ordering: sortingParams,
      page: this.currentPage,
      page_size: IMPORTS_PAGE_SIZE,
      type: this.isBulkEdit.isTrue ? 'bulk-edit' : 'bulk-add',
    };

    const response = await this.client.list({ params });

    this.imports = get(response, 'results', []);
    this.totalImports = get(response, 'count', 0);

    this.isFetching.set(false);
  }

  @action
  private async handleFetch (state: any) {
    if (this.isFetching.isTrue) { return; }

    const { page } = state;
    this.currentPage = page + 1;
    await this.fetchImports();
  }

  @action
  private async handleSort (newSorted: ISortedItem[]) {
    this.currentPage = 1;
    this.sorted = newSorted;
  }

  private getUrlPath () {
    return this.isBulkEdit.isTrue ? 'bulk-edit' : 'imports';
  }

  private async onCreate (data: object) {
    const submitData = {...data, input_file: this.uploadedFile}
      , urlPath = this.getUrlPath()
      , importObj = await this.injected.Clients.imports.createForm(submitData)
      , pathname = `/data-admin/${urlPath}/${importObj.id}`;

    browserHistory.push({ pathname });
  }

  private getTdProps (_state: any, rowInfo: object, _column: any, _instance: any) {
    const importId = get(rowInfo, 'original.id')
      , urlPath = this.isBulkEdit.isTrue ? 'bulk-edit' : 'imports'
      , to = importId && { pathname: `/data-admin/${urlPath}/${importId}` };

    return { to };
  }

  private tdComponent ({ className, children, to, ...rest }: { [key: string]: any }) {
    return <Link className={cx(className, 'rt-td')} to={to} {...rest}>{children}</Link>;
  }

  private setRefDropzone (ref: any) {
    this.refDropzone = ref;
  }

  public onDrop = (acceptedFiles: File[]) => {
    this.uploadedFile = acceptedFiles[0];
    this.isDropActive.setFalse();
  }

  private onDropRejected () {
    toast.error('File type not supported. Please upload a .csv');
  }

  private onBrowseClick () {
    if (this.refDropzone) {
      this.refDropzone.open();
    }
  }

  private onClose () {
    this.showingNew.setFalse();
    this.uploadedFile = null;
  }

  private onFileDelete () {
    this.uploadedFile = null;
  }

  private renderOverlay () {
    const className = cx(
        'dropzone-area',
        'dropzone-overlay',
        {disabled: this.isDropActive.isTrue},
        {uploading: this.isDropActive.isTrue},
      );

    return (
      <div className={className}>
        <Icon className='main-icon' type='files-o' />
        {!this.isDropActive.isTrue ? 'Drop files here' : 'Uploading...'}
      </div>
    );
  }

  public renderWell () {
    if (this.isLoading.isTrue) {
      return <Loader className='bloc-loader' />;
    }

    if (this.imports.length < 1) {
      return (
        <Well className='maxwidth-lg center-block'>
          <h3>Previous Updates</h3>
        </Well>
      );
    }

    return (
      <Well className='maxwidth-lg center-block'>
        <h3>
          Previous Updates
        </h3>
        <div className='imports-table-container'>
          {this.isFetching.isTrue && (
              <Loader className='overlay-loader'/>
          )}
          <ReactTable
            className={cx('-highlight')}
            columns={COLUMNS}
            data={toJS(this.imports)}
            getTdProps={this.getTdProps}
            loading={this.isFetching.isTrue}
            manual
            minRows={0}
            onFetchData={this.handleFetch}
            pages={this.totalPages}
            pageSize={IMPORTS_PAGE_SIZE}
            resizable={false}
            showPageSizeOptions={false}
            showPagination={this.totalImports > IMPORTS_PAGE_SIZE}
            TdComponent={this.tdComponent}
            sorted={this.sorted}
            onSortedChange={this.handleSort}
          />
        </div>
      </Well>
    );
  }

  private renderBulkEditSection () {
    return (
      <React.Fragment>
        <Well className='maxwidth-lg center-block'>
          <h2>Upload Case or Lien Updates</h2>
          {this.isBulkEdit.isTrue && <BulkInstructions isBulkEdit />}
          <div className='new-import-btn-wrapper'>
            <Button className='btn-add' bsStyle='primary' onClick={this.showingNew.setTrue}>
              <Icon type='plus-square-o' /> New Import
            </Button>
          </div>
        </Well>
        <Well className='maxwidth-lg center-block'>
          {this.isBulkEdit.isTrue && <BulkEditTemplateDownloadSection/>}
        </Well>
      </React.Fragment>
    );
  }

  private renderBulkAddSection () {
    return (
      <Well className='maxwidth-lg center-block'>
        <h2>Upload New Imports</h2>
        <BulkInstructions isBulkEdit={false} />
        <div className='new-import-btn-wrapper'>
          <Button className='btn-add' bsSize='medium' bsStyle='primary' onClick={this.showingNew.setTrue}>
            <Icon type='plus-square-o' /> New Import
          </Button>
        </div>
      </Well>
    );
  }

  private async downloadTemplate () {
    const response = await this.injected.Clients.templates.downloadTemplateCSV(this.configurationId)
      , csvBlob = new window.Blob([response], { type: 'text/csv' })
      ;

    fileSaver.saveAs(csvBlob, `bulk_add_template.csv`);
  }

  private async onChange (data: any) {
    if (!this.configurationId && !!data.configuration) {
      this.configurationId = data.configuration;
    }
  }

  private renderDropzone () {
    const dropzoneText = 'Drag & drop your input file'
    , dropClassNames = cx(
      'dropzone',
      'small',
      { active: (this.isDropActive.isTrue) },
    );

    if (!!this.uploadedFile) {
      const fileUrl = URL.createObjectURL(this.uploadedFile);

      return (
        <Well className='maxwidth-lg center-block'>
          <h3>Input File</h3>
          <div className='uploaded-file'>
            <a href={fileUrl} rel='noopener noreferrer'>{this.uploadedFile.name}</a>
            <ActionButton className='remove-file' bsSize='small' onClick={this.onFileDelete}>
              <Icon type='trash' />
            </ActionButton>
          </div>
        </Well>
      );
    }

    return (
      <React.Fragment>
        {!this.isBulkEdit.isTrue && !!this.configurationId && (
          <Well className='download-template-section'>
            <LinkButton onClick={this.downloadTemplate}>
              Download Template
              <Icon type='download' />
            </LinkButton>
          </Well>
        )}
        <Well className='maxwidth-lg center-block'>
          <Dropzone
            accept='.csv'
            className={dropClassNames}
            disabled={false}
            disableClick
            onDragEnter={this.isDropActive.setTrue}
            onDragLeave={this.isDropActive.setFalse}
            onDrop={this.onDrop}
            onDropRejected={this.onDropRejected}
            ref={this.setRefDropzone}
            style={{}}
            maxFiles={1}
          >
            {this.renderOverlay()}
            <div className='dropzone-area'>
              <Icon className='main-icon' type='files-o' />
              <div><a className='btn-browse' onClick={this.onBrowseClick}><Icon type='plus-square-o' /> Upload</a> or ({dropzoneText})</div>
            </div>
          </Dropzone>
        </Well>
      </React.Fragment>
    );
  }

  public render () {
    const title = this.isBulkEdit.isTrue ? 'Bulk Edit' : 'Bulk Add'
      , fileUpload = this.renderDropzone();

    return (
      <Page name='imports' type='detail'>
        <Helmet title={title} />
        <Page.Content>
          <DataAdminHeader title={title}/>
          <div className='main'>
            <Row>
              <Col className='col-main' xs={12}>
                {this.isBulkEdit.isTrue ? this.renderBulkEditSection() : this.renderBulkAddSection()}
                {this.renderWell()}
              </Col>
            </Row>
          </div>

          {this.showingNew.isTrue && (
            <FormModal
              className='modal-new-self-import'
              fieldSets={this.isBulkEdit.isTrue ? CREATE_BULK_EDIT : CREATE_IMPORT}
              onClose={this.onClose}
              onSave={this.onCreate}
              title='New Import'
              childrenAfter={fileUpload}
              onChange={this.onChange}
            />
          )}
        </Page.Content>
      </Page>
    );
  }
}

export default ImportsPage;
