import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Loader from './Loader';
import SmartBool from '../../utils/SmartBool';
import FormattingUtils from '../../utils/FormattingUtils';

const {
  toKey,
} = FormattingUtils;

interface IAction {
  params?: object;
  method: (params: object) => any;
}

interface IActions {
  [key: string]: IAction;
}

interface IParams {
  actions?: (props: any) => { [key: string]: IAction };
  className?: any;
  logo?: boolean;
  Wrapper?: React.ComponentType<any>;
}

export default ({
  actions = (_props: any) => ({}),
  className = '',
  logo = false,
  Wrapper = (data: any) => <div>{data.children}</div>,
}: IParams = {}) => {
  return (LoadedComponent: React.ComponentClass) => {
    return observer(
      class LoaderDecorator extends Component<any> {
        private isLoading = new SmartBool(true);
        private data: { [key: string]: any } = observable({});
        private actions: IActions;

        constructor (props: any) {
          super(props);
          this.actions = actions(this.props);
        }

        public componentDidMount () { this.runActions(); }

        public componentDidUpdate () {
          const newActions = actions(this.props);
          if (this.actionsKey(this.actions) !== this.actionsKey(newActions)) {
            this.actions = newActions;
            this.runActions();
          }
        }

        private actionsKey (actionsDict: IActions) {
          return Object.keys(actionsDict).map(key => toKey(actionsDict[key].params || {})).join();
        }

        private async runAction (key: string) {
          const data = await this.actions[key].method(this.actions[key].params || {});
          this.data[key] = data;
        }

        private async runActions () {
          this.isLoading.set(true);
          await Promise.all(Object.keys(this.actions).map(this.runAction.bind(this)));
          this.isLoading.set(false);
        }

        public render () {
          if (this.isLoading.isTrue) {
            return <Wrapper {...this.props} {...this.data}><Loader logo={logo} className={className} /></Wrapper>;
          }
          return <Wrapper {...this.props} {...this.data}><LoadedComponent {...this.props} {...this.data} /></Wrapper>;
        }
      },
    );
  };
};
