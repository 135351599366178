import React, { Component } from 'react';
import axios from 'axios';
import { browserHistory } from 'react-router';

import { Loader, Page } from '../common';
import { toast } from '../../utils';
import { IWindow } from '../../interfaces';

interface IProps {
  location: {
    query: {
      id: string;
    };
  };
}

class NewAccountPage extends Component<IProps> {
  public componentDidMount () {
    this.updateAccount();
  }

  private get window () {
    return window as unknown as IWindow;
  }

  private async updateAccount () {
    const { location: { query } } = this.props
      , endpoint = `${this.window.Mighty.API_HOST}/api/v1/new-accounts/${query.id}/`
      ;

    try {
      await axios({method: 'patch', url: endpoint, data: {email_confirmed: true}});
      toast.success('Success! Email confirmed, your new account is ready!');
    }
    catch (error) {
      // tslint:disable-next-line no-console
      console.error(error);
      toast.error('Something went wrong!');
    }
    finally {
      browserHistory.push('/accounts/login');
    }
  }

  public render () {
    return (
      <Page name='new-account'>
        <Loader className='page-loader' logo />
      </Page>
    );
  }
}

export default NewAccountPage;
