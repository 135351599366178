import React, { Component } from 'react';
import Helmet from 'react-helmet';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import URI from 'urijs';
import { LinkContainer } from 'react-router-bootstrap';
import { noop } from 'lodash';
import cx from 'classnames';

import {
  Button,
  Nav,
  NavItem,
} from 'react-bootstrap';

import EmptyListHelpText from './EmptyListHelpText';
import BaseList from '../common/BaseList';
import ClientsClass from '../../clients/ClientsClass';
import { SessionStoreClass, UiStoreClass } from '../../stores';
import { AppConstants } from '../../constants';
import { FormattingUtils } from '../../utils';
import { Icon, Link, Page } from '../common';
import { setPagination, stubNull } from '../../utils/util';

const { MODEL_TYPES } = AppConstants;
const { formatDateTime } = FormattingUtils;

const TABS = [['new', 'New'], ['archived', 'Archived']]
  , PAGE_SLUG = 'updates'
  , TITLE = 'Updates'
  , COLUMNS = [
    {
      accessor: 'id',
      Cell: (item: any) => {
        const isDocumentTask = item.original.type === MODEL_TYPES.documentSharingTask.key;

        return(
          <div className='row-item clickable'>
            <Link to={`/${PAGE_SLUG}/details/${item.value}`}>
              <div className='flex-between'>
                <div className='flex-grow'>
                  <div className={cx('title', 'fs-mask')}>
                    {isDocumentTask ? 'Documents Shared' : 'Case Update'} for {item.original.plaintiff_name}
                  </div>
                  <div className='date'>{formatDateTime(item.original.completed_at)}</div>
                </div>
                <div className='actions'>
                  <Button bsStyle='link'>
                    {item.original.completed_at ? 'View' : 'Respond'} <Icon type='angle-double-right'/>
                  </Button>
                </div>
              </div>
            </Link>
          </div>
        );
      },
    },
  ]
  ;

interface IProps {
  Clients: ClientsClass;
  SessionStore: SessionStoreClass;
  UiStore: UiStoreClass;
  params: {
    main: string,
  };
}

@inject('Clients', 'SessionStore', 'UiStore')
@autoBindMethods
@observer
class UpdatesPage extends Component<IProps> {
  @observable private params: {
    archived: boolean,
    completed: boolean,
    ordering?: string,
    page: string,
  } = {
    archived: false,
    completed: true,
    ordering: '-archived_at',
    page: '1',
  };

  private get client () {
    return this.props.Clients.tasksRequested;
  }

  private get pageStore () {
    return this.props.UiStore.updatesPage;
  }

  public componentDidMount () {
    this.componentWillReceiveProps(this.props as IProps);
  }

  public componentWillReceiveProps (nextProps: IProps) {
    this.setTab(nextProps);
    this.list();
  }

  private setTab (props: IProps) {
    this.params.archived = props.params.main === 'archived';
  }

  private async list () {
    const request = this.client.list({ params: this.params });
    await this.pageStore.fromRequest(request);
  }

 private handlePagination (page: string) {
    const query = URI.parse(page).query;
    this.params.page = setPagination(query);
    this.list();
 }

 private get emptyText () {
    const { SessionStore, params } = this.props;

    if (!SessionStore.isGuest) {
      return <EmptyListHelpText title='Updates is your new home for Case Updates.' />;
    }

    if (params.main === 'archived') {
      return <div>There are no archived updates</div>;
    }
    return <div>No updates at this time</div>;
 }

 private renderHeader () {
   const { params } = this.props
     , renderLinkContainer = ([path, label]: string[]) => (
       <LinkContainer key={path} to={{ pathname: `/${PAGE_SLUG}/${path}`}}>
         <NavItem className={`nav-${PAGE_SLUG}-main-${path}`}>{label}</NavItem>
       </LinkContainer>
     );

   return (
     <header>
       <h1>{TITLE}</h1>
       <Nav bsStyle='tabs' activeKey={params.main}>
         {TABS.map(renderLinkContainer)}
       </Nav>
     </header>
   );
 }

  public render () {
    return (
      <Page name={PAGE_SLUG} type='list'>
        <Helmet title={TITLE} />
        <Page.Content>
          <BaseList
            columns={COLUMNS}
            currentPageNumber={this.params.page}
            emptyText={this.emptyText}
            entityType={PAGE_SLUG}
            handlePagination={this.handlePagination}
            handleSort={noop}
            pageStore={this.pageStore}
            paginated
            reactTableProps={{ TheadComponent: stubNull }}
            renderHeader={this.renderHeader}
            tableClassName='-no-td-padding'
          />
        </Page.Content>
      </Page>
    );
  }
}

export default UpdatesPage;
