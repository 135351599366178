import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject } from 'mobx-react';

import { startCase } from 'lodash';

import {
  Button,
  Row,
} from 'react-bootstrap';

import {
  Icon,
  InfoWell,
} from '../../../common';

import { FormattingUtils } from '../../../../utils';
import { FunderStoreClass } from '../../../../stores';

import CapitalFundingForm from './CapitalFundingForm';

const {
  formatPercentage,
  getNameOrDefault,
  getOrDefault,
} = FormattingUtils
  , REJECTED_STATUS = 'rejected'
  ;

interface IProps {
  edit: boolean;
  funding: any;
  onEditClick: (id: string) => void;
  onEditClose: () => void;
}

interface IInjected extends IProps {
  FunderStore: FunderStoreClass;
}

@inject('FunderStore')
@autoBindMethods
class CapitalFundingRow extends Component<IProps> {
  private onEditClick () {
    this.props.onEditClick(this.props.funding.id);
  }

  private get injected () {
    return this.props as IInjected;
  }

  private get editable () {
    const { funding } = this.props
      , { FunderStore } = this.injected;

    // istanbul ignore next
    if (!FunderStore.funder) { return false; }

    return funding.capital_provider.owner === FunderStore.funder.id;
  }

  public render () {
    const { edit, funding} = this.props
      , { underwriting_evaluation } = funding
      , status = underwriting_evaluation && underwriting_evaluation.underwriter_decision_status
      , notes = status === REJECTED_STATUS ? underwriting_evaluation.notes : funding.notes;

    if (edit) {
      return (
        <CapitalFundingForm
          capitalFunding={funding}
          lienId={funding.lien}
          onClose={this.props.onEditClose}
        />
      );
    }

    return (
      <InfoWell key={funding.id} className='space-between'>
        <Row>
          <InfoWell.Label>Capital Provider</InfoWell.Label>
          <InfoWell.Value>
            {getNameOrDefault(funding.capital_provider)}

            {this.editable && (
              <Button onClick={this.onEditClick} bsStyle='link'>
                <Icon type='pencil' className='icon-edit' />
              </Button>
            )}
          </InfoWell.Value>
        </Row>
        <Row>
          <InfoWell.Label>Status</InfoWell.Label>
          <InfoWell.Value>{(getOrDefault(startCase(status)))}</InfoWell.Value>
        </Row>
        <Row>
          <InfoWell.Label>Ownership</InfoWell.Label>
          <InfoWell.Value>{formatPercentage(funding.ownership_percentage)}</InfoWell.Value>
        </Row>
        <Row>
          <InfoWell.Label>Notes</InfoWell.Label>
          <InfoWell.Value>{getOrDefault(notes)}</InfoWell.Value>
        </Row>
      </InfoWell>
    );
  }
}

export default CapitalFundingRow;
