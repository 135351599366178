import React, { Component } from 'react';
import { noop } from 'lodash';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import cx from 'classnames';

import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';

import { createInputContainer } from '../../containers';
import { IFormInput, IInputContainer, Value } from '../../interfaces';
import MightyReactRating from '../common/MightyReactRating';

interface IPropDefaults extends IFormInput {
  onChange: (value: any) => void;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@autoBindMethods
@observer
class Rating extends Component<IFormInput, {}> {
  @observable private value: Value = null;

  public static defaultProps: Partial<IFormInput> = {
    onChange: noop,
    value: 0,
  };

  get formsy () {
    return this.props as IPropsFormsy;
  }

  constructor (props: IPropsFormsy) {
    super(props);
    this.value = Number(props.value);
  }

  private onChange (value: number) {
    const { onChange, setValue } = this.formsy;
    this.value = value;
    setValue(this.value);
    onChange(this.value);
  }

  private get formattedValue () {
    if (!this.value) { return 0; }
    return Number(this.value);
  }

  public render () {
    const {
      controlId,
      errorMessage,
      formGroupId,
      isDisabled,
      label,
      showErrorMessage,
      showRequiredMessage,
    } = this.formsy
    , className = cx(
      this.props.className,
      { disabled: isDisabled },
      'fg-rating',
      'has-value',
    );

    return (
      <FormGroup id={formGroupId} controlId={controlId} className={className}>
        {label &&
          <ControlLabel>
            {label}
          </ControlLabel>}
        <MightyReactRating
          initialRating={this.formattedValue}
          onChange={this.onChange}
          readonly={isDisabled}
        />

        {showRequiredMessage &&
          <HelpBlock>
            {`${label || 'This field'} is required.`}
          </HelpBlock>
        }

        {showErrorMessage &&
          <HelpBlock>{errorMessage}</HelpBlock>
        }
      </FormGroup>
    );
  }
}

export default Rating;
