import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button } from 'react-bootstrap';
import autoBindMethods from 'class-autobind-decorator';

import CaseStoreClass from '../../stores/CaseStoreClass';
import SmartBool from '../../utils/SmartBool';
import { ICase, IWindow } from '../../interfaces';

interface IProps {
  _case: ICase;
}

interface IInjected extends IProps {
  CaseStore: CaseStoreClass;
}

@inject('CaseStore')
@autoBindMethods
@observer
class RegistryButton extends Component <IProps> {
  @observable private isLoading = new SmartBool();

  private get injected () {
    return this.props as IInjected;
  }

  private get window () {
    return window as unknown as IWindow;
  }

  private get isCaseEligible () {
    return this.props._case.registry_sharing_eligibility === 'eligible_private';
  }

  private async addToRegistry () {
    const { _case } = this.props;
    this.isLoading.setTrue();
    await this.injected.CaseStore.update({
      caseId: _case.id,
      data: { migrate_to_registry: true },
    });
    this.isLoading.setFalse();
  }

  public render () {
    const {_case} = this.props
      , buttonProps = {
        bsSize: 'small' as 'small',
      }
      , orLoading = (nonLoading: string) => (this.isLoading.isTrue ? 'Loading...' : nonLoading)
      ;

    if (_case.registry_case) {
      const href = `${this.window.Mighty.REGISTRY_URL}/portfolio/?case=${_case.registry_case}`;
      return (
        <Button {...buttonProps} href={href} target='_blank'>
          {orLoading('Open in Law Firm Portal')}
        </Button>
      );
    }

    if (this.isCaseEligible) {
      return (
        <Button {...buttonProps} onClick={this.addToRegistry}>
          {orLoading('Add to Law Firm Portal')}
        </Button>
      );
    }

    if (_case.migrate_to_registry) {
      return <Button {...buttonProps} disabled={true}>{orLoading('Adding to Law Firm Portal')}</Button>;
    }

    return null;
  }
}

export default RegistryButton;
