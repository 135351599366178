import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import autoBindMethods from 'class-autobind-decorator';
import { format, isValid, parse } from 'date-fns';
import { stubTrue, noop, isString, get } from 'lodash';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import cx from 'classnames';
import Datetime from 'react-datetime';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';

import AppConstants from '../../constants/AppConstants';
import { createInputContainer } from '../../containers';
import { IFormInput, IInputContainer, Value } from '../../interfaces';

const { DATE_FORMATS } = AppConstants;

interface IProps extends IFormInput {
  isValidDate?: (date: string) => boolean;
  manual?: boolean;
  placeholder?: string;
  positionRight?: boolean;
  positionTop?: boolean;
  required?: boolean;
}

interface IPropDefaults extends IProps {
  isValidDate: (date: string) => boolean;
  manual: boolean;
  onChange: (value: Value) => void;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@autoBindMethods
@observer
class DateTime extends Component<IProps, {}> {
  @observable public value: Value = null;

  private refDateTime: any;
  private formGroup: any;

  public static defaultProps: Partial<IProps> = {
    className: '',
    disabled: false,
    isValidDate: stubTrue,
    label: '',
    manual: false,
    onChange: noop,
    placeholder: '',
    positionRight: false,
    positionTop: false,
    required: false,
    value: '',
  };

  get formsy () {
    return this.props as IPropsFormsy;
  }

  constructor (props: IPropsFormsy) {
    super(props);
    this.value = props.value;
  }

  private setRefDateTime (ref: any) {
    this.refDateTime = ref;
  }

  /*
  This method is called when both a date is selected from the picker, and when a user
  types in a raw value. In the case of a picked date the 'selectedDate' parameter will
  be a moment object. If the user typed in their own value then we parse it ('true' for strict parsing)
  against the inputFormat defined. Then with the moment object being valid (user raw
  text can be parsed, or it came from the picker) the 'value' will be the date in its
  output 'format' (that is also what will be validated against with 'isDateFormat').

  If it is not a valid moment object (user's raw text was not parsable) then we use
  the raw text as the value, which will then fail 'isDateFormat' validation
  */

  @action
  private onChange (selectedDate: any) {
    const { onChange, setValue } = this.formsy;

    const value = isString(selectedDate)
      ? selectedDate
      : format(selectedDate, DATE_FORMATS.date_value);

    // set value to null if user erases input
    this.value = !selectedDate ? '' : value;

    setValue(this.value);
    onChange(this.value);
  }

  // When we use an @observer and set the 'focused' className on the <FormGroup>, it triggers a re-render
  // and it closes the calendar. In order to avoid this, we directly modify the <FormGroup> className.
  //
  // Bootstrap <FormGroup> doesn't return the dom instance when using props.ref
  // We have to use findDomNode in order to retrieve it.
  private setFormGroup (ref: any) {
    this.formGroup = ref && ReactDOM.findDOMNode(ref);
  }

  private onFocus () {
    if (this.formGroup) { this.formGroup.classList.add('focused'); }
  }

  private onBlur () {
    if (this.formGroup) { this.formGroup.classList.remove('focused'); }
  }

  private getFormattedValue () {
    const { value } = this.props;
    if (!value) { return ''; }
    const dateParsed = parse(value)
      , formattedValue = isValid(dateParsed) ? format(dateParsed, DATE_FORMATS.date_edit) : value;
    return formattedValue;
  }

  public render () {
    const {
        className,
        controlId,
        errorMessage,
        formGroupId,
        isDisabled,
        isValidDate,
        label,
        manual,
        placeholder,
        positionRight,
        positionTop,
        required,
        showErrorMessage,
        showRequiredMessage,
        validationState,
      } = this.formsy
      , hasValue = !!this.getFormattedValue() || !!this.value
      , classNames = cx(className, {'has-value': hasValue}, { disabled: isDisabled })
      , rdtClassNames = cx({'position-right': positionRight, 'position-top': positionTop})
      , value = manual ? this.getFormattedValue() : get(this.refDateTime, 'props.value')
      ;

    return (
      <FormGroup id={formGroupId} controlId={controlId} className={classNames} ref={this.setFormGroup} validationState={validationState}>
        {label &&
          <ControlLabel>
            {label}
            {required && <span className='required'>*</span>}
          </ControlLabel>
        }

        <Datetime
          className={rdtClassNames}
          closeOnSelect
          dateFormat={DATE_FORMATS.date_edit}
          defaultValue={this.getFormattedValue()}
          value={value}
          ref={this.setRefDateTime}
          inputProps={{
            disabled: isDisabled,
            id: controlId,
            placeholder,
          }}
          isValidDate={isValidDate}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          strictParsing
          timeFormat={false}
        />

        {showRequiredMessage &&
          <HelpBlock>{label || 'This field'} is required.</HelpBlock>}

        {showErrorMessage &&
          <HelpBlock>{errorMessage}</HelpBlock>}

      </FormGroup>
    );
  }
}

export default DateTime;
