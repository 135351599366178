import React, { Component, Fragment } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { findIndex } from 'lodash';

import autoBindMethods from 'class-autobind-decorator';

import {
  Button,
} from 'react-bootstrap';

import {
  DropdownFilter,
  Icon,
  Loader,
} from '../../common';

import NewCaseNote from './NewCaseNote';

import { AppConstants } from '../../../constants/';
import {
  ICase,
} from '../../../interfaces';
import ActivityList from '../../common-activities/ActivityList';
import { CaseStoreClass, FunderStoreClass, LienStore as LienStoreClass, SessionStoreClass } from '../../../stores';

const {
  MODEL_TYPES,
} = AppConstants;

const FILTERABLE_ACTIVITIES = [
  'caseNote',
  'caseSms',
  'caseTrackingUpdate',
  'generatedEmail',
  'representativeAssignment',
].map(type => {
  return {
    id: MODEL_TYPES[type].key,
    name: MODEL_TYPES[type].display,
  };
});

interface IProps {
  _case: ICase;
}

interface IInjected extends IProps {
  CaseStore: CaseStoreClass;
  FunderStore: FunderStoreClass;
  LienStore: LienStoreClass;
  SessionStore: SessionStoreClass;
}

@inject('CaseStore', 'LienStore', 'SessionStore')
@autoBindMethods
@observer
class TabActivities extends Component<IProps> {
  @observable private showDeletedActivities = false;
  @observable private lienDocuments = [];
  @observable private isLienDocumentsLoading = true;

  private get injected () {
    return this.props as IInjected;
  }

  public async componentDidMount () {
    this.injected.CaseStore.caseActivities.loadInitial({ type: null, id: this.props._case.id });

    this.lienDocuments = await this.injected.LienStore.listCaseLienDocuments(this.props._case.id);
    this.isLienDocumentsLoading = false;
  }

  private toggleShowDeleted () {
    this.showDeletedActivities = !this.showDeletedActivities;
  }

  private handleFilterChange (change: { type?: string }) {
    const { CaseStore } = this.injected;
    CaseStore.caseActivities.loadInitial({ type: change.type || null });
  }

  private renderTypeFilter () {
    const { CaseStore } = this.injected
      , options = FILTERABLE_ACTIVITIES;

    return (
      <div className='filters dropdown-arrow'>
        <Icon type='filter' />
        <DropdownFilter
          bsStyle='link'
          defaultLabel='Filter'
          filterName='type'
          nullName='All'
          onFilterChange={this.handleFilterChange}
          options={options}
          pullRight={false}
          selectedId={CaseStore.caseActivities.activitiesFilter}
        />
      </div>
    );
  }

  private renderDeletedFilter () {
    const { CaseStore } = this.injected
      , activities = CaseStore.caseActivities.activities
      , hasDeletedActivities = findIndex(activities, a => a.deleted_at !== null) !== -1;

    if (!hasDeletedActivities) {
      return;
    }

    return (
      <div className='deleted'>
        <Button bsStyle='link' className='secondary' onClick={this.toggleShowDeleted}>
          <Icon type={this.showDeletedActivities ? 'check-square' : 'square-o'} />Show Deleted Notes
        </Button>
      </div>
    );
  }

  private renderLoadMore () {
    const { CaseStore } = this.injected;

    if (!CaseStore.caseActivities.hasMore) { return; }

    return (
      <Button
        className='load-more-activities'
        disabled={CaseStore.caseActivities.loadingMore}
        onClick={CaseStore.caseActivities.loadMore}
      >
        {CaseStore.caseActivities.loadingMore ? <Loader className='button-loader' /> : 'Load More'}
      </Button>
    );
  }

  private renderPaidFeatures () {
    const { _case } = this.props
      , { SessionStore } = this.injected
      ;

    if (SessionStore.isMightyFreeUser) { return null; }

    return (
      <Fragment>
        <NewCaseNote caseId={_case.id} />

        <div className='wrp-filters'>
          {this.renderTypeFilter()}
          {this.renderDeletedFilter()}
        </div>
      </Fragment>
    );
  }

  private renderEmptyFeedMessage () {
    const { CaseStore, SessionStore } = this.injected
    , isEmpty = !CaseStore.caseActivities.activities.length
    , showEmpty = isEmpty && SessionStore.isMightyFreeUser
    ;

    if (showEmpty) {
      return(
        <p className='empty-info'>
          This is your case feed. <br />
          When you request or share updates and documents, it will be recorded here.
        </p>
      );
    }

    return null;
  }

  public render () {
    const { _case, CaseStore } = this.injected;

    return (
      <div className='tab-activities'>
        {this.renderPaidFeatures()}
        {this.renderEmptyFeedMessage()}

        <ActivityList
          _case={_case}
          activities={CaseStore.caseActivities.activities}
          lienDocuments={this.lienDocuments}
          loading={CaseStore.caseActivities.loading || this.isLienDocumentsLoading}
          loadingNew={CaseStore.caseActivities.loadingNew}
          showDeletedActivities={this.showDeletedActivities}
        />

        {this.renderLoadMore()}
      </div>
    );
  }
}

export default TabActivities;
