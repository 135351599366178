import React, { Component } from 'react';
import { noop, get, toString } from 'lodash';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import cx from 'classnames';

import 'froala-editor/js/froala_editor.pkgd.min.js';

import FroalaEditor from 'react-froala-wysiwyg';

const FROALA_CONFIG = {
  fullPage: true,
  heightMin: 200,
  htmlRemoveTags: ['script', 'base'],
  htmlUntouched: true,
  iframe: true,
  key: get(window, 'Mighty.FROALA_KEY'),
  linkInsertButtons: [],
  pluginsEnabled: ['link', 'lists'],
  theme: 'mighty',
  toolbarButtons: 'undo redo | bold italic underline | formatUL formatOL | indent outdent | insertLink'.split(' '),
  zIndex: 10,
};

import {
  FormGroup,
  HelpBlock,
} from 'react-bootstrap';

import { IFormInput, IInputContainer } from '../../interfaces';
import { createInputContainer } from '../../containers';

interface IPropDefaults extends IFormInput {
  onChange: (value: string) => void;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@autoBindMethods
@observer
class HtmlInput extends Component<IFormInput> {
  @observable private value = '';

  public static defaultProps: Partial<IFormInput> = {
    onChange: noop,
  };

  constructor (props: IFormInput) {
    super(props);
    this.setValueFromProps({}, props);
  }

  // Jest needs to mock Froala so we can't test onChange
  // istanbul ignore next
  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  // Jest needs to mock Froala so we can't test onChange
  // istanbul ignore next
  get formsy () {
    return this.props as IPropsFormsy;
  }

  public componentWillReceiveProps (nextProps: IFormInput) {
    this.setValueFromProps(this.props, nextProps);
  }

  private setValueFromProps (props: Partial<IFormInput>, nextProps: IFormInput) {
    const valueDefined = typeof nextProps.value !== 'undefined'
      , valueChanged = props.value !== nextProps.value
      , valueDiffers = this.value !== nextProps.value;

    if (valueDefined && valueChanged && valueDiffers) {
      this.value = toString(nextProps.value);
    }
  }

  // Jest needs to mock Froala so we can't test onChange
  // istanbul ignore next
  private onChange (value: string) {
    this.value = value;
    this.formsy.setValue(this.value);
    this.propsWithDefaults.onChange(this.value);
  }

  public render () {
    const {
        className,
        label,
      } = this.props
      , {
      controlId,
      formGroupId,
      showErrorMessage,
      showRequiredMessage,
      errorMessage,
    } = this.formsy
    , classNames = cx(
      className,
      'html-input',
      'original-styling',
      {'has-error': (showErrorMessage || showRequiredMessage)},
    );

    return (
      <FormGroup id={formGroupId} controlId={controlId} className={classNames}>
        <FroalaEditor
          config={FROALA_CONFIG}
          model={toJS(this.value)}
          onModelChange={this.onChange}
          tag='textarea'
        />

        {showRequiredMessage && <HelpBlock>{label || 'This field'} is required.</HelpBlock>}
        {showErrorMessage && <HelpBlock>{errorMessage}</HelpBlock>}
      </FormGroup>
    );
  }
}

export default HtmlInput;
