import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';

import CaseModel from '../models/CaseModel';
import LeadSourceModel from '../models/LeadSourceModel';
import LienModel from '../models/LienModel';

const TYPES = ['case', 'leadSource', 'lien'];

function getModel (type: string): any {
  switch (type) {
    case 'case':
      return CaseModel;
    case 'leadSource':
      return LeadSourceModel;
    case 'lien':
      return LienModel;

    // istanbul ignore next
    default:
      throw new Error('Un-managed object type');
  }
}

@autoBindMethods
class FlatStore {
  /* tslint:disable:variable-name */
  @observable public case_map = observable.map({});
  @observable public leadSource_map = observable.map({});
  @observable public lien_map = observable.map({});
  [key: string]: any
  /* tslint:enable:variable-name */

  constructor () {
    // Examples:
    // FlatStore.case.store(id)
    // FlatStore.case.get(id)
    TYPES.forEach(type => {
      this[type] = {
        get: this.get.bind(this, type),
        store: this.store.bind(this, type),
      };
    });
  }

  public store (type: string, data: { id: string }) {
    if (this[`${type}_map`].has(data.id)) {
      this[`${type}_map`].get(data.id).extend(data);
    }
    else {
      const Model = getModel(type);
      this[`${type}_map`].set(data.id, new Model(data, this));
    }

    return this[`${type}_map`].get(data.id);
  }

  public get (type: string, id: string) {
    if (!this[`${type}_map`].has(id)) {
      const Model = getModel(type);
      this[`${type}_map`].set(id, new Model({}, this));
    }
    return this[`${type}_map`].get(id);
  }
}

export default FlatStore;
