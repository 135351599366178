import FormattingUtils from '../../../../utils/FormattingUtils';

const { formatPhoneNumber, getNameOrDefault, getOrDefault } = FormattingUtils;

export interface ISearchResultItemProps {
  index: number;
  item: any;
  itemsCount: number;
  searchValue: string;
}

export interface IDisplaySearchResultItemConfiguration extends ISearchResultItemProps {
  entity: string;
  path: string;
  [others: string]: any;
}

export function getBaseSearchResultDisplayItem (itemConfiguration: IDisplaySearchResultItemConfiguration) {
  const fullName: string = getNameOrDefault(itemConfiguration.item);
  return {
    _search_report: {
      entity: itemConfiguration.entity,
      fields: itemConfiguration.item.search_report,
      hits: itemConfiguration.itemsCount,
      rank: 1 + itemConfiguration.index,
      terms: itemConfiguration.searchValue,
    },
    email: getOrDefault(itemConfiguration.item.email),
    fullName,
    item: itemConfiguration.item,
    name: itemConfiguration.item.name || fullName,
    path: {pathname: `${itemConfiguration.path}${itemConfiguration.item.id}/`},
    phoneNumber: getOrDefault(formatPhoneNumber(itemConfiguration.item.phone_number)),
    vanityId: itemConfiguration.item.vanity_id || '',
  };
}
