/* tslint:disable no-magic-numbers */

import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { get } from 'lodash';

import { Table } from 'react-bootstrap';

import { FormattingUtils } from '../../../utils';
import { insertIf } from '../../../utils/util';

import LienTableRow from './LienTableRow';
import FunderStoreClass from '../../../stores/FunderStoreClass';
import { ILienState } from './CaseLogPaymentModal';

const { formatDate, formatMoney, getOrDefault } = FormattingUtils;

export type ITableType = 'plaintiff' | 'medical' | 'generic';

interface IProps {
  disableExpectedAmount: boolean;
  liens: ILienState[];
  onCheckboxChange: (lienId: string, checkAll: boolean) => void;
  onInputChange: (lienId: string, value: unknown, key?: string) => void;
  tableType: ITableType;
}

interface IInjected extends IProps {
  FunderStore: FunderStoreClass;
}

export interface ITableField {
  disabled?: boolean;
  formatter?: (value: any, ...args: any[]) => React.ReactNode;
  header: string;
  key: keyof ILienState;
  type?: 'input';
}

@inject('FunderStore')
@autoBindMethods
@observer
class LiensTable extends Component<IProps> {
  @observable private checkAll = false;

  private get injected () {
    return this.props as IInjected;
  }

  private onCheckAll () {
    const { liens, onCheckboxChange } = this.props;

    this.checkAll = !this.checkAll;
    liens.forEach(lien => onCheckboxChange(lien.id, this.checkAll));
  }

  private get tableFields (): ITableField[] {
    const { FunderStore: { isMedicalProvider, useExperimentalContracts }, tableType, disableExpectedAmount } = this.injected
      /* tslint:disable object-literal-sort-keys */
      , RETURNED_AND_EXPECTED_AMT: ITableField[] = [
        {header: 'Returned Amt', key: 'return_actual_amount', type: 'input'},
        {header: 'Expected Amt', key: 'return_expected_amount', type: 'input', disabled: disableExpectedAmount},
      ]
      , tableFields: { [key: string]: ITableField[] } = {
        plaintiff: [
          {header: 'Agreement Date', key: 'agreement_date', formatter: formatDate },
          {header: 'Total Principal', key: 'principal_amount', formatter: formatMoney },
          ...insertIf(!useExperimentalContracts, {header: 'Lien Balance on Date of Payment', key: 'current_lien_balance', formatter: formatMoney }),
          ...insertIf(useExperimentalContracts, {header: 'Lien Balance on Date of Payment', key: 'experimental_lien_balance', formatter: formatMoney }),
          ...RETURNED_AND_EXPECTED_AMT,
        ],
        medical: [
          {header: 'Label', key: 'label', formatter: (value: unknown) => <div className='ellipsis'>{getOrDefault(value)}</div> },
          ...insertIf(!isMedicalProvider, {header: 'Purchase Date', key: 'agreement_date', formatter: formatDate }),
          ...insertIf(!isMedicalProvider, {header: 'Purchase Amount', key: 'principal_amount', formatter: formatMoney }),
          {header: 'Billing Amount', key: 'amount_owed_today', formatter: formatMoney },
          ...RETURNED_AND_EXPECTED_AMT,
        ],
        generic: [
          {header: 'Acquired Date', key: 'acquired_date', formatter: formatDate },
          {header: 'Amount', key: 'amount', formatter: formatMoney },
          {header: 'Type', key: 'subtype', formatter: (value: unknown) => getOrDefault(get(value, 'label')) },
          ...RETURNED_AND_EXPECTED_AMT,
        ],
      };
      /* tslint:enable object-literal-sort-keys */

    return tableFields[tableType];
  }

  public render () {
    const {
        liens,
        onCheckboxChange,
        onInputChange,
        tableType,
      } = this.props
      , checkAllId = `liens-table-checkall-${tableType}`
      ;

    return (
      <Table condensed className='case-return-info no-side-padding fix-layout'>
        <thead>
          <tr>
            <th className='col-checkbox'>
              <input
                id={checkAllId}
                type='checkbox'
                onChange={this.onCheckAll}
                checked={this.checkAll}
              />
              <label htmlFor={checkAllId} />
            </th>
            {this.tableFields.map((field, idx) => {
              const className = cx({ 'col-return-amount': field.type === 'input', 'text-right': idx > 0 });

              return <th key={field.key} className={className}>{field.header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {liens.map(lien => (
            <LienTableRow
              key={lien.id}
              lien={lien}
              onCheckboxChange={onCheckboxChange}
              onInputChange={onInputChange}
              tableFields={this.tableFields}
            />)
          )}
        </tbody>
      </Table>
    );
  }
}

export default LiensTable;
