import React, { Component } from 'react';
import Helmet from 'react-helmet';
import autoBindMethods from 'class-autobind-decorator';
import { observer, inject } from 'mobx-react';
import { browserHistory } from 'react-router';

import {
  Col,
  Grid,
  Row,
} from 'react-bootstrap';

import { Page } from '../common';

import NewCaseForm from '../page-new-case/NewCaseForm';
import { SessionStoreClass } from '../../stores';

interface IInjected {
  SessionStore: SessionStoreClass;
}

@inject('SessionStore')
@autoBindMethods
@observer
class NewCasePage extends Component {
  public componentDidMount () {
    const { SessionStore } = this.injected;

    if (!SessionStore.isMightyFreeUser) {
      browserHistory.push('/');
    }
  }

  private get injected () {
    return this.props as IInjected;
  }

  public render () {
    return (
      <Page name='new-case' type='detail'>
        <Helmet title='New Case' />
        <Page.Content>
          <header className='condensed'>
            <Grid><h1>Add Cases</h1></Grid>
          </header>
          <div className='main'>
            <Row>
              <Col className='col-main' xs={12}>
                <NewCaseForm />
              </Col>
            </Row>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default NewCasePage;
