import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, computed } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import ReactTable from 'react-table';

import { Well } from 'react-bootstrap';

import { ActionButton, Icon } from '../../../common';

import { TableRenderingUtils } from '../../../../utils';

const { renderName } = TableRenderingUtils;

interface IProps {
  caseId: string;
  documentTemplates: any[];
  isFaxEnabled?: boolean;
  lienId?: string;
  onDownloadClick: (id: string, contextId: string | undefined) => void;
  onFaxClick?: (id: string) => void;
}

interface IPropDefaults extends IProps {
  isFaxEnabled: boolean;
}

@autoBindMethods
@observer
class DocumentTemplatesTable extends Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    isFaxEnabled: true,
  };

  private get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  @computed
  private get columns () {
    return [
      {
        accessor: 'id',
        Cell: () => <Icon type='file-code-o' />,
        className: 'col-file',
        Header: null,
        width: 25,
      },
      {
        accessor: 'name',
        Cell: renderName,
        Header: 'Name',
        minWidth: 200,
      },
      {
        accessor: 'id',
        Cell: (row: any) => (
          <div>
            <ActionButton bsSize='small' data={row} onClick={this.onDownloadClick}>
              <Icon type='download' />
            </ActionButton>
          </div>
        ),
        className: 'col-buttons',
        Header: 'actions',
        minWidth: 30,
      },
    ];
  }

  @action
  private onDownloadClick (row: any) {
    const { caseId, lienId, onDownloadClick } = this.propsWithDefaults
      , { id, context_type } = row.original
      , contextId = context_type === 'medical_lien' ? lienId : caseId;

    onDownloadClick(id, contextId);
  }

  public render () {
    const { documentTemplates } = this.propsWithDefaults;

    if (!documentTemplates.length) {
      return null;
    }

    return (
      <Well className='list-document-templates'>
        <h3>Document Templates</h3>
        <ReactTable
          className='-list -no-header -single-line'
          columns={this.columns}
          data={documentTemplates}
          keyField='id'
          noDataText='No contacts are assigned to this case.'
          pageSize={documentTemplates.length}
          minRows={0}
          resizable={false}
          sortable={false}
          showPagination={false}
        />
      </Well>
    );
  }
}

export default DocumentTemplatesTable;
