/* tslint:disable max-classes-per-file */
import React, { Component } from 'react';
import { Link as ReactRouterLink } from 'react-router';

export class Link extends ReactRouterLink {
  public static defaultProps = {
    ...ReactRouterLink.defaultProps,
    activeClassName: 'active',
  };
  public static propTypes = ReactRouterLink.propTypes;
}

export class ExternalLink extends Component<any, {}> {
  public render () {
    return (
      <a {...this.props} target='_blank'>
        {this.props.children}
      </a>
    );
  }
}
