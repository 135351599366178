import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';
import { isString } from 'lodash';

import { AppConstants } from '../../constants';
import DateTime from './DateTime';
import { IFormInput, IInputContainer } from '../../interfaces';

const { DATE_FORMATS } = AppConstants;

interface IValidations {
  validationErrors?: object | string;
  validations?: object | string;
}

interface IProps extends IFormInput, IValidations {
  label?: string;
  manual?: boolean;
  validationErrors?: object | string;
  validations?: object | string;
}

interface IPropsFormsy extends IProps, IInputContainer {}

function validationsToObject ({ validationErrors, validations }: IValidations) {
  if (isString(validationErrors) && isString(validations)) {
    return {
      validationErrors: { [validations]: validationErrors },
      validations: { [validations]: true },
    };
  }

  return {
    validationErrors,
    validations,
  } as { [key: string]: object };
}

@autoBindMethods
@observer
class DateInput extends Component<IProps, {}> {
  get formsy () {
    return this.props as IPropsFormsy;
  }

  private getValidationProps () {
    const {
        label,
      } = this.props
      , errorMsg = `${label || 'This field'} is not a valid date (${DATE_FORMATS.date_edit})`
      , validationProps = {
        validationErrors: { isDateFormat: errorMsg },
        validations: { isDateFormat: true },
      }
      , {
        validationErrors,
        validations,
      } = validationsToObject({
        validationErrors: this.props.validationErrors,
        validations: this.props.validations,
      })
      ;

    validationProps.validations = { ...validationProps.validations, ...validations };
    validationProps.validationErrors = { ...validationProps.validationErrors, ...validationErrors };

    return validationProps;
  }

  public render () {
    return <DateTime {...this.props} {...this.getValidationProps()} />;
  }
}

export default DateInput;
