import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { action, computed, observable, toJS } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { get, sortBy } from 'lodash';

import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import { Well } from 'react-bootstrap';

import { ContentToolTip, Link } from '../../common';
import { AppConstants } from '../../../constants';
import { ICase } from '../../../interfaces';
import { CaseStoreClass, ContactStoreClass, UiStoreClass } from '../../../stores';
import { FormattingUtils, TableRenderingUtils, SmartBool } from '../../../utils';
import ContactRowDropdown from './ContactRowDropdown';
import AddCaseContactDropdown from './AddCaseContactDropdown';

const { CONTACT_TYPES, ROUTING } = AppConstants
  , { renderPhoneNumber } = TableRenderingUtils
  , { getNameOrDefault, getOrDefault } = FormattingUtils
  ;

const TOOLTIP_CASE_CONTACTS = 'tooltip-case-contacts-table';

interface IProps {
  _case: ICase;
}

interface IInjected extends IProps {
  ContactStore: ContactStoreClass;
  CaseStore: CaseStoreClass;
  UiStore: UiStoreClass;
}

@inject(
  'CaseStore',
  'ContactStore',
  'UiStore',
)
@autoBindMethods
@observer
class CaseContactsTable extends Component<IProps> {
  @observable private isLoading = new SmartBool();

  private get injected () {
    return this.props as IInjected;
  }

  @computed
  private get contacts () {
    const attorney = this.props._case.attorney,
      contacts = toJS(get(this.props._case, 'contacts', []));

    if (!!attorney) {
      contacts.push({...attorney, contact_type: CONTACT_TYPES.ATTORNEY.type});
    }

    return sortBy(contacts, 'contact_type');
  }

  @computed
  private get columns () {
    const phoneProps = {
      Cell: renderPhoneNumber,
      className: 'col-style-gray',
      maxWidth: 120,
      minWidth: 50,
      tooltipId: TOOLTIP_CASE_CONTACTS,
    };

    return [
      {
        accessor: 'first_name',
        Cell: (item: {original: any}) => {
          const tooltipProps = {'data-for': TOOLTIP_CASE_CONTACTS, 'data-tip': getNameOrDefault(item.original)};
          return (
            <div>
              <Link to={`${ROUTING.peopleList}/${item.original.id}`}>
                <div className='contact-name ellipsis' {...tooltipProps}>{getNameOrDefault(item.original)}</div>
              </Link>
              <div className='label-tracking-contact'>
                {this.isTypeTrackingContact(item.original.id) ? 'Tracking Contact' : ' '}
              </div>
            </div>
          );
        },
        Header: 'Name',
        maxWidth: 200,
        minWidth: 100,
      },
      {
        accessor: 'contact_type',
        Cell: (item: {value?: string}) => {
          return (item.value && CONTACT_TYPES[item.value].shortDisplay) || null;
        },
        className: 'col-style-gray text-uppercase',
        Header: 'Type',
        maxWidth: 150,
        minWidth: 100,
      },
      {
        accessor: 'email',
        Cell: (item: {value: any, column: any}) => {
          const email = getOrDefault(item.value);
          return <ContentToolTip data-for={item.value ? TOOLTIP_CASE_CONTACTS : null} data-tip={email}>{email}</ContentToolTip>;
        },
        className: 'col-style-gray',
        Header: 'Email',
        minWidth: 100,
      },
      {
        accessor: 'phone_number',
        Header: 'Phone',
        ...phoneProps,
      },
      {
        accessor: 'additional_contact_info.cell_number',
        Header: 'Cell',
        ...phoneProps,
      },
      {
        accessor: 'additional_contact_info.fax_number',
        Header: 'Fax',
        ...phoneProps,
      },
      {
        accessor: 'id',
        Cell: (item: {original: any}) => {
          const { original: { id, contact_type } } = item;

          return (
            <ContactRowDropdown
              contactId={id}
              contactType={contact_type}
              isTrackingContact={this.isTypeTrackingContact(id)}
              onRemove={this.onContactRemove}
              onSetTrackingContact={this.onSetTrackingContact}
            />
          );
        },
        className: 'col-buttons',
        Header: null,
        width: 40,
      },
    ];
  }

  private isTypeTrackingContact (contactId: string) {
    const { _case } = this.props;
    return (_case.tracking_contact === contactId);
  }

  @action
  private async onContactRemove (contactId: string, contactType: string) {
    const { _case } = this.props
      , { CaseStore } = this.injected
      , requestData: any = {}
      ;

    if (contactType === CONTACT_TYPES.ATTORNEY.type) {
      requestData.attorney = null;
    } else {
      requestData.contacts = _case.contacts
        .filter((contact: any) => contact.id !== contactId )
        .map((contact: any) => contact.id);
    }

    if (contactId === this.props._case.tracking_contact) {
      requestData.tracking_contact = null;
    }

    await this.isLoading.until(CaseStore.update({
      caseId: _case.id,
      data: requestData,
    }));
  }

  @action
  private async onSetTrackingContact (contactId: string, contactType: string, removeAsTrackingContact: boolean = false) {
    const { _case } = this.props
      , { ContactStore } = this.injected
      ;

    this.isLoading.setTrue();
    await ContactStore.assignToCase({
      _case,
      contactId,
      contactType,
      removeTrackingContact: removeAsTrackingContact,
      setTrackingContact: !removeAsTrackingContact,
    });
    this.isLoading.setFalse();
  }

  public render () {
    const { _case } = this.props;

    return (
      <Well className='list-contacts'>
        <h3>
          Contacts
          <div className='title-actions'>
            <AddCaseContactDropdown caseId={_case.id} contacts={this.contacts} />
          </div>
        </h3>
        <ReactTable
          className='-list -no-scroll'
          columns={this.columns}
          data={this.contacts}
          keyField='id'
          loading={this.isLoading.isTrue}
          loadingText=''
          noDataText='No contacts are assigned to this case.'
          pageSize={this.contacts.length}
          minRows={0}
          resizable={false}
          sortable={false}
          showPagination={false}
        />
        <ReactTooltip
          effect='solid'
          html
          id={TOOLTIP_CASE_CONTACTS}
          place='bottom'
          type='info'
        />
      </Well>
    );
  }
}

export default CaseContactsTable;
