import React from 'react';
import { toJS } from 'mobx';
import parser from 'html-react-parser';
import { parse } from 'iso8601-duration';
import Pluralize from 'pluralize';
import { reject, map, has, result, sortBy, startCase, isNil, escape } from 'lodash';
import Decimal from 'decimal.js';
import numeral from 'numeral';

import {
  formatAddress,
  formatAddressMultiline,
  formatCommaSeparatedNumber,
  formatDate,
  formatDateTime,
  formatDelimitedList,
  formatDuration,
  formatFullName,
  formatMoneyInput,
  formatParagraphs,
  formatSocialSecurityNumber,
  getNameOrDefault,
  getOrDefault,
  getType,
  mapBooleanToText,
  parseAndPreserveNewlines,
  preserveNewLines,
  splitCommaList,
  splitName,
  stripNonAlpha,
  varToLabel,
} from '@mighty-justice/utils';

import {
  ILeadSource,
  IMedicalCode,
  IMention,
  IUnderwriterAnalysisSummary,
} from '../interfaces';

import AppConstants from '../constants/AppConstants';
import MightyReactRating from '../components/common/MightyReactRating';

const {
  CASE_LABELS,
  CENT_DECIMAL,
  DEFAULT_TRUNCATION_LENGTH,
  EMPTY_FIELD,
  MONTHS_PER_YEAR,
} = AppConstants;

function formatPhoneNumber (phoneNumber?: string, prefix: string = '') {
  // check phone number not already formatted
  if (phoneNumber) {
    const matches = phoneNumber.match(/\d/g) || []
      , unformattedNumber = matches.join('');

    // tslint:disable-next-line no-magic-numbers
    return `${prefix}(${unformattedNumber.slice(0, 3)}) ${unformattedNumber.slice(3, 6)}-${unformattedNumber.slice(6, 10)}`;
  }
  return EMPTY_FIELD;
}

// TODO: Remove after making changes to utils library
function toKey (dict: { [key: string]: any }, url?: string) {
  const dictSorted = sortBy(map(dict, (value: any, key: string) => [key, value]))
    , dictFiltered = reject(dictSorted, ([_key, value]: [string, any]) => {
    return (value === null || value === undefined);
  }) as Array<[string, any]>;

  if (dictFiltered.length < 1) {
    return '';
  }

  const dictString = dictFiltered.map(([key, value]: [string, any]) => {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }).join('&');

  if (url && url.indexOf('?') !== -1) {
    return `&${dictString}`;
  }

  return `?${dictString}`;
}

// TODO: Remove after making changes to utils library
function formatPercentage (value?: number|string|null, decimalPoints = 2) {
  if (typeof value === 'undefined' || value === null) {
    return EMPTY_FIELD;
  }

  const decimal = new Decimal(value).times(CENT_DECIMAL);

  if (decimalPoints === null) {
    return `${decimal.toString()}%`;
  }

  return `${decimal.toFixed(decimalPoints)}%`;
}

// TODO: Remove after making changes to utils library
function formatMoney (value?: number | string | Numeral) {
  return (value || value === 0)
    ? numeral(value).format('$0,0.00')
    : EMPTY_FIELD;
}

function truncate (text?: string, length = DEFAULT_TRUNCATION_LENGTH, endcap: string = '...') {
  if (!text) {
    return text;
  }

  if (text.length > length) {
    const truncateTo = Math.max(length - endcap.length, 0);
    return text.substring(0, truncateTo).trim() + endcap;
  }
  return text;
}

function formatLeadSource (obj?: ILeadSource) {
  const name = result(obj, 'name', '')
    , category = result(obj, 'category', '')
    , formattedCategory = startCase(category);
  if (name) {
    return (`${formattedCategory} - ${name}`);
  }
  return EMPTY_FIELD;
}

function formatDurationInMonthsOrDays (iso8601?: string) {
  if (!iso8601) {
    return iso8601;
  }

  let unitCounts = Object.entries(parse(iso8601)) as Array<[string, number]>;
  unitCounts = unitCounts.filter(([_unit, count]) => count > 0);

  if (unitCounts[0][0] === 'days') {return `${unitCounts[0][1]} Days`; }

  let months = 0;
  unitCounts.forEach(([unit, count]) => {
    months += unit === 'months' ? count : count * MONTHS_PER_YEAR;
  });

  if (months > 1) { return `${months} Months`; }
  return '1 Month';
}

function stripSpecialChars (str?: string) {
  return isNil(str) ? '' : str.replace(/[^a-zA-Z0-9_-]+/g, '');
}

function pluralize (word: string, count: number, inclusive: boolean): string {
  // Thin wrapper to make room for future addPluralRule and other logic
  return Pluralize(word, count, inclusive);
}

function formatCaseLabelList (caseLabelArg?: string[]) {
  const caseLabels = toJS(caseLabelArg) || [];
  return caseLabels.map(s => CASE_LABELS[s]).join(', ') || EMPTY_FIELD;
}

function boldMentions (body: string, mentions?: IMention[]) {
  if (!mentions || !mentions.length) { return body; }

  let boldBody = body;
  if (mentions) {
    mentions.forEach(mention => {
      const { person: { first_name, last_name } } = mention
        , name = escape(`@${first_name} ${last_name}`)
        , re = new RegExp(name, 'g');
      boldBody = boldBody.replace(re, `<strong>${name}</strong>`);
    });
  }
  return boldBody;
}

function parseCaseNote (body: string, mentions?: IMention[]) {
  const escapedBody = escape(body)
    , parsedBody = boldMentions(preserveNewLines(escapedBody), mentions);
  return parser(parsedBody);
}

function formatMedicalCode (medicalCode?: IMedicalCode) {
  return (medicalCode && has(medicalCode, 'code') && has(medicalCode, 'description'))
    ? `${medicalCode.code} - ${medicalCode.description}`
    : EMPTY_FIELD;
}

function formatRating (rating: string | null) {
  return (rating === null)
    ? EMPTY_FIELD
    : (
      <MightyReactRating initialRating={+rating} readonly/>
    );
}

function formatUnderwriterAnalysisSummary (summary?: IUnderwriterAnalysisSummary) {
  if (summary) {
    return (
      <div>
        {formatRating(summary.rating)}
        {!!summary.notes && <div className='rating-note'>{summary.notes}</div>}
      </div>
    );
  }
  return EMPTY_FIELD;
}

function formatContactNameAndEmail (contact: any): string {
  if (!contact) {
    return EMPTY_FIELD;
  }

  const fullName = getNameOrDefault(contact)
    , email = (contact.email && contact.email !== '')
      ? `<${contact.email}>`
      : 'No Email'
    ;

  return `${fullName} - ${email}`;
}

export default {
  formatAddress,
  formatAddressMultiline,
  formatCaseLabelList,
  formatCommaSeparatedNumber,
  formatContactNameAndEmail,
  formatDate,
  formatDateTime,
  formatDelimitedList,
  formatDuration,
  formatDurationInMonthsOrDays,
  formatFullName,
  formatLeadSource,
  formatMedicalCode,
  formatMoney,
  formatMoneyInput,
  formatParagraphs,
  formatPercentage,
  formatPhoneNumber,
  formatRating,
  formatSocialSecurityNumber,
  formatUnderwriterAnalysisSummary,
  getNameOrDefault,
  getOrDefault,
  getType,
  mapBooleanToText,
  parseAndPreserveNewlines,
  parseCaseNote,
  pluralize,
  preserveNewLines,
  splitCommaList,
  splitName,
  stripNonAlpha,
  stripSpecialChars,
  toKey,
  truncate,
  varToLabel,
};
