/* tslint:disable object-literal-sort-keys */
import React from 'react';

import FunderStoreClass from '../../stores/FunderStoreClass';
import { AppConstants } from '../../constants';
import { IReactTableColumn, IReactTableItem } from '../../interfaces';
import { Link } from '../common';
import { TableRenderingUtils } from '../../utils';

const { ROUTING, TOOLTIP_ID_TABLE, USER_FLOW } = AppConstants;
const { renderAddress, renderOption, renderPhoneNumber, renderUnformatted, renderWarningIcon } = TableRenderingUtils;

function renderLawFirmLink (item: IReactTableItem) {
  const pathname = `${ROUTING.lawfirmList}/${item.original.id}`
    , state = USER_FLOW.FROM_LAWFIRM_LIST_PAGE
    , tooltipHTML = item.value;

  return (
    <div className='ellipsis' data-tip={tooltipHTML} data-for={TOOLTIP_ID_TABLE}>
      <Link to={{ pathname, state }}>
        {item.value}
      </Link>
    </div>
  );
}

const ORGANIZATION_COLUMNS: IReactTableColumn[] = [
  {
    Header: renderWarningIcon,
    accessor: 'warning',
    isLegalOnly: true,
    maxWidth: 65,
    sortable: false,
    Cell: renderWarningIcon,
  },
  {
    Header: 'Name',
    accessor: 'name',
    maxWidth: 400,
    Cell: renderLawFirmLink,
  },
  {
    Header: 'Address',
    accessor: 'address',
    maxWidth: 400,
    sortable: false,
    Cell: renderAddress,
  },
  {
    Header: 'Phone Number',
    accessor: 'phone_number',
    maxWidth: 170,
    sortable: false,
    Cell: renderPhoneNumber,
  },
  {
    Header: 'Fax Number',
    accessor: 'fax_number',
    maxWidth: 170,
    sortable: false,
    Cell: renderPhoneNumber,
  },
  {
    Header: 'Contacts',
    accessor: 'contacts_count',
    maxWidth: 120,
    Cell: renderUnformatted,
  },
  {
    Header: 'Cases',
    accessor: 'cases_count',
    maxWidth: 120,
    Cell: renderUnformatted,
  },
  {
    Header: 'Eligibility',
    accessor: 'registry_eligibility',
    insertIf: (FunderStore: FunderStoreClass) => !!FunderStore.isRegistryEnabled,
    isLegalOnly: true,
    maxWidth: 240,
    Cell: renderOption,
  },
];

export {
  renderLawFirmLink,
  ORGANIZATION_COLUMNS,
};
