import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';

import { Icon } from '../common';
import { getExtension } from '../../utils/util';

/* tslint:disable object-literal-sort-keys */
const FILE_ICON_TYPES: { [key: string]: string } = {
  jpg: 'file-picture-o',
  jpeg: 'file-picture-o',
  png: 'file-picture-o',
  gif: 'file-picture-o',
  tif: 'file-picture-o',

  pdf: 'file-pdf-o',

  zip: 'file-zip-o',

  doc: 'file-word-o',
  docx: 'file-word-o',

  mov: 'file-movie-o',
  mp4: 'file-movie-o',
  avi: 'file-movie-o',
  mkv: 'file-movie-o',
}
, DEFAULT_ICON_TYPE = 'file';
/* tslint:enable object-literal-sort-keys */

interface IProps {
  border?: boolean;
  className?: string;
  fileName?: string;
  flip?: 'horizontal' | 'vertical';
  rotate?: '90' | '180' | '270';
  size?: 'lg' | '2x' | '3x' | '4x' | '5x';
  spin?: boolean;
}

@autoBindMethods
class FileIcon extends Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    border: false,
    spin: false,
  };

  public getFileType (extension: any): string {
    return FILE_ICON_TYPES[extension] || DEFAULT_ICON_TYPE;
  }

  public render () {
    const { fileName } = this.props
      , extension = getExtension(fileName)
      , type = this.getFileType(extension);

    return <Icon type={type} {...this.props} />;
  }
}
export default FileIcon;
