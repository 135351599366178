import React, { Component } from 'react';
import Helmet from 'react-helmet';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import URI from 'urijs';
import { LinkContainer } from 'react-router-bootstrap';
import { noop } from 'lodash';
import cx from 'classnames';

import {
  Button,
  Nav,
  NavItem,
} from 'react-bootstrap';

import EmptyListHelpText from './EmptyListHelpText';
import BaseList from '../common/BaseList';
import ClientsClass from '../../clients/ClientsClass';
import UiStoreClass from '../../stores/UiStoreClass';
import { FormattingUtils } from '../../utils';
import { AppConstants } from '../../constants';
import { Icon, Link, Page } from '../common';
import { setPagination, stubNull } from '../../utils/util';
import { SessionStoreClass } from '../../stores';

const { formatDateTime } = FormattingUtils;
const { TASKS_PAGE_SIZE, TASK_TYPES } = AppConstants;

const TABS = [['todo', 'To Do'], ['completed', 'Completed']]
  , PAGE_SLUG = 'tasks'
  , TITLE = 'Tasks'
  , ROW_PREFACE_TEXT_OPTIONS = {
    [TASK_TYPES.document_sharing]: {
      completed: 'Documents for',
      todo: 'Document Request for',
    },
    [TASK_TYPES.case_update_request]: {
      completed: 'Case Update for',
      todo: 'Update Request for',
    },
  }
  , getRowPrefaceText = (completed: boolean, type: string) => {
    return ROW_PREFACE_TEXT_OPTIONS[type][completed ? 'completed' : 'todo'];
  }
  , COLUMNS = [
    {
      accessor: 'id',
      Cell: (item: any) => (
        <div className='row-item clickable'>
          <Link to={`/${PAGE_SLUG}/${item.original.completed_at ? 'details' : 'response'}/${item.value}`}>
            <div className='flex-between'>
              <div className='flex-grow'>
                <div className={cx('title', 'fs-mask')}>{getRowPrefaceText(!!item.original.completed_at, item.original.type)} {item.original.plaintiff_name}</div>
                <div className='date'>{formatDateTime(item.original.created_at)}</div>
              </div>
              <div className='actions'>
                <Button bsStyle='link'>{item.original.completed_at ? 'View' : 'Respond'} <Icon type='angle-double-right' /></Button>
              </div>
            </div>
          </Link>
        </div>
      ),
    },
  ]
  ;

interface IProps {
  Clients: ClientsClass;
  UiStore: UiStoreClass;
  SessionStore: SessionStoreClass;
  params: {
    main: string,
  };
}

@inject('Clients', 'UiStore', 'SessionStore')
@autoBindMethods
@observer
class TasksPage extends Component<IProps> {
  @observable private params: {
    completed: boolean,
    ordering?: string,
    page: string,
  } = {
    completed: false,
    ordering: '-completed_at',
    page: '1',
  };

  private get client () {
    return this.props.Clients.tasksReceived;
  }

  private get pageStore () {
    return this.props.UiStore.tasksPage;
  }

  public componentDidMount () {
    this.componentWillReceiveProps(this.props as IProps);
  }

  public componentWillReceiveProps (nextProps: IProps) {
    this.setTab(nextProps);
    this.list();
  }

  private setTab (props: IProps) {
    this.params.completed = props.params.main === 'completed';
  }

  private async list () {
    const request = this.client.list({ params: this.params });
    await this.pageStore.fromRequest(request);
  }

  private handlePagination (page: string) {
    const query = URI.parse(page).query;
    this.params.page = setPagination(query);
    this.list();
  }

  private renderHeader () {
    const { params } = this.props
      , renderLinkContainer = ([path, label]: string[]) => (
        <LinkContainer key={path} to={{ pathname: `/${PAGE_SLUG}/${path}`}}>
          <NavItem className={`nav-${PAGE_SLUG}-main-${path}`}>{label}</NavItem>
        </LinkContainer>
      );

    return (
      <header>
        <h1>{TITLE}</h1>
        <Nav bsStyle='tabs' activeKey={params.main}>
          {TABS.map(renderLinkContainer)}
        </Nav>
      </header>
    );
  }

  private get emptyText () {
    const { SessionStore, params } = this.props;

    if (!SessionStore.isGuest) {
      return <EmptyListHelpText title='Tasks is your new home for Case Update Requests.' />;
    }

    if (params.main === 'completed') {
      return <div>No tasks have been completed yet!</div>;
    }
    return <div>You have completed all of your to do's!</div>;
  }

  public render () {
    return (
      <Page name={PAGE_SLUG} type='list'>
        <Helmet title={TITLE} />
        <Page.Content>
          <BaseList
            columns={COLUMNS}
            currentPageNumber={this.params.page}
            emptyText={this.emptyText}
            entityType={PAGE_SLUG}
            handlePagination={this.handlePagination}
            handleSort={noop}
            pageSize={TASKS_PAGE_SIZE}
            pageStore={this.pageStore}
            paginated
            reactTableProps={{ TheadComponent: stubNull }}
            renderHeader={this.renderHeader}
            tableClassName='-no-td-padding'
          />
        </Page.Content>
      </Page>
    );
  }
}

export default TasksPage;
