/* tslint:disable object-literal-sort-keys */

import { IFieldSetPartial } from '../../../lib/mighty-fields';

const FIELD_SETS_SETTLEMENT_AMOUNT: IFieldSetPartial[] = [
  [
    { field: 'settlement_amount', type: 'money' },
    { field: 'settlement_date', type: 'date' },
  ],
];

const FIELD_SETS_SETTLEMENT_OFFER: IFieldSetPartial[] = [
  [
    { field: 'offered_on', type: 'date' },
    { field: 'offer_amount', type: 'money' },
    { field: 'is_accepted', type: 'boolean', label: 'Is Accepted?' },
  ],
];

const FIELD_SETS_MEDICAL: IFieldSetPartial[] = [
  [
    { field: 'first_treatment_date', type: 'date' },
    { field: 'ambulance_on_scene', type: 'boolean' },
    { field: 'injury_diagnosis', type: 'text' },
    { label: 'Pre-existing Conditions', field: 'pre_existing_conditions', type: 'text' },
    { field: 'treatments_received', type: 'text' },
    { field: 'treatments_expected', type: 'text' },
  ],
];

const FIELD_SETS_PLAINTIFF_INSURANCE: IFieldSetPartial[] = [
  [
    { field: 'id', type: 'hidden', writeOnly: true },
    {
      endpoint: '/insurers/',
      field: 'insurer',
      label: 'Insurance Provider',
      type: 'objectSearchCreate',
      createFields: [
        {
          field: 'name',
          label: 'New Insurance Provider',
          populateFromSearch: true,
        },
      ],
    },
  ],
  [
    { field: 'policy_number' },
    { field: 'pip_medical_coverage', type: 'money' },
    { field: 'medpay_coverage', type: 'money' },
    { field: 'um_coverage_per_person', type: 'money' },
    { field: 'um_coverage_per_accident', type: 'money' },
    { field: 'uim_coverage_per_person', type: 'money' },
    { field: 'uim_coverage_per_accident', type: 'money' },
    { field: 'lost_wage_coverage', type: 'money' },
  ],
];

const FIELD_SETS_DEFENDANT: IFieldSetPartial[] = [
  [
    { field: 'name', required: true },
    { field: 'notes', type: 'text' },
  ],
];

const FIELD_SETS_DEFENDANT_INSURER: IFieldSetPartial[] = [
  [
    {
      endpoint: '/insurers/',
      field: 'insurer',
      label: 'Insurance Provider',
      type: 'objectSearchCreate',
      createFields: [
        {
          field: 'name',
          label: 'New Insurance Provider',
          populateFromSearch: true,
        },
      ],
      required: true,
    },
    { field: 'liability_limit_per_accident', type: 'money' },
    { field: 'liability_limit_per_person', type: 'money' },
  ],
];

const fieldSetsPrimaryInfo = (isMightyFreeUser: boolean): IFieldSetPartial[] => [
  [
    { field: 'summary', type: 'text' },
  ],
  [
    { label: 'Case Type', field: 'type', optionType: 'caseTypeOptions', type: 'optionSelect', required: !isMightyFreeUser },
    { field: 'state_of_incident', optionKey: 'code', optionType: 'stateOptions', type: 'optionSelect', required: true },
    { field: 'date_of_loss', type: 'date' },
    { field: 'court_index_number' },
  ],
];

export {
  FIELD_SETS_DEFENDANT,
  FIELD_SETS_DEFENDANT_INSURER,
  FIELD_SETS_MEDICAL,
  FIELD_SETS_PLAINTIFF_INSURANCE,
  fieldSetsPrimaryInfo,
  FIELD_SETS_SETTLEMENT_AMOUNT,
  FIELD_SETS_SETTLEMENT_OFFER,
};
