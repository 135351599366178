import React, { Component, Fragment } from 'react';
import { observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import Helmet from 'react-helmet';
import autoBindMethods from 'class-autobind-decorator';
import URI from 'urijs';
import { get } from 'lodash';
import { browserHistory } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import { Button, Col, HelpBlock, Row } from 'react-bootstrap';

import CaseSelectionModule from '../page-case-update/CaseSelectionModule';
import ClientsClass from '../../clients/ClientsClass';
import ICase from '../../interfaces/models/ICase';
import RequestFooter from '../page-case-update/RequestFooter';

import { CaseContactSelect, Form, MultiSelectTypeahead, Textarea } from '../common-formsy';
import { ContactStoreClass, OptionsStoreClass } from '../../stores';
import { FormattingUtils, SmartBool } from '../../utils';
import { Icon, Loader, Page, Spacer } from '../common';

const { splitName } = FormattingUtils;

interface IInjected {
  Clients: ClientsClass;
  ContactStore: ContactStoreClass;
  OptionsStore: OptionsStoreClass;
}

@inject('Clients', 'ContactStore', 'OptionsStore')
@autoBindMethods
@observer
class DocumentRequestPage extends Component {
  @observable private isLoading = new SmartBool();
  @observable private isSubmitting = new SmartBool();
  @observable private _case: ICase | undefined = undefined;
  private isComingFromCase = false;

  public componentDidMount () {
    const location = browserHistory.getCurrentLocation()
      , caseId = URI(location.pathname).segment(1)
      ;

    if (caseId) {
      this.fetchCaseInformation(caseId);
      this.isComingFromCase = !!caseId;
    }
  }

  private get injected () {
    return this.props as IInjected;
  }

  private async fetchCaseInformation (caseId: string) {
    const { Clients } = this.injected;

    this.isLoading.setTrue();
    this._case = await Clients.cases.retrieve(caseId);
    this.isLoading.setFalse();
  }

  private async getOrCreateRecipient (model: any) {
    if (model.recipient) { return model.recipient; }

    const { ContactStore } = this.injected
      , { newContactFullName, newContactEmail, contactType } = model
      , [ firstName, lastName ] = splitName(newContactFullName)
      , contactData = { first_name: firstName, last_name: lastName, email: newContactEmail, contact_type: contactType }
      ;

    const newContact = await ContactStore.create({ _case: this._case, contactData, contactType });
    return newContact.id;
  }

  private async handleCreateDocumentSharingTask (model: any, recipientId: string, caseId: string) {
    const { Clients } = this.injected;

    await Clients.documentSharingTask.create({
      case: caseId,
      document_types_requested: model.documentTypes.map((type: any) => (type.id)),
      recipient: recipientId,
      requester_note: model.requestNote,
    });
  }

  private async handleValidSubmit (model: any, _resetForm: () => void, _invalidateForm: () => void) {
    const caseId = get(this._case, 'id');

    // istanbul ignore next
    if (!caseId) { return; }

    this.isSubmitting.setTrue();
    const recipientId = await this.getOrCreateRecipient(model);
    await this.handleCreateDocumentSharingTask(model, recipientId, caseId);
    this.isSubmitting.setFalse();

    browserHistory.push(`/case/${caseId}`);
  }

  private async onCaseSelect (selectedCase: ICase | null) {
    this._case = selectedCase || undefined;
  }

  private renderRequestFormFields () {
    const { OptionsStore } = this.injected;
    return (
      <Fragment>
        <Spacer large />

        <CaseContactSelect _case={this._case} />

        <hr />

        <MultiSelectTypeahead
          field='documentTypes'
          name='documentTypes'
          label='What types of documents would you like to request?'
          options={toJS(OptionsStore.documentTypeOptions)}
          placeholder='Search or select document types'
          required
          value=''
        />

        <Spacer />

        <Textarea
          field='requestNote'
          name='requestNote'
          label='Add Note'
          value=''
        />
        <HelpBlock>Optional: add a note to explain what you need.</HelpBlock>
        <hr />

        <RequestFooter
          isSubmitting={this.isSubmitting}
          typeName='Documents'
        />
      </Fragment>
    );
  }

  public render () {
    const title = 'Request a Document';

    if (this.isLoading.isTrue) {
      return <Loader className='page-loader' logo />;
    }

    return (
      <Page name='document-request' type='detail'>
        <Helmet title={title} />
        <Page.Content>
          <header className='condensed'>
            <div>
              {(this.isComingFromCase && this._case) &&
                <LinkContainer to={{pathname: `/case/${this._case.id}` }}>
                  <Button bsStyle='link' className='go-back no-padding'><Icon type='angle-double-left' /> Back</Button>
                </LinkContainer>
              }
              <h1>{title}</h1>
            </div>
          </header>
          <div className='main'>
            <Col className='col-main' xs={12}>
              <Row>
                <Col xs={10} sm={8} lg={6}>
                  <Form className='form-page modal-form-style' onValidSubmit={this.handleValidSubmit}>
                    <CaseSelectionModule
                      disabled={this.isComingFromCase}
                      onCaseSelect={this.onCaseSelect}
                      preselectedCase={this._case}
                      updateAction='request'
                    />
                    {this._case && this.renderRequestFormFields()}
                  </Form>
                </Col>
              </Row>
            </Col>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default DocumentRequestPage;
