import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import Helmet from 'react-helmet';
import { find, has } from 'lodash';

import {
  Button,
  Col,
  Modal,
  Row,
  Well,
} from 'react-bootstrap';

import {
  AwaitButton,
  Icon,
  InfoWell,
  Link,
  Loader,
  Page,
} from '../../common';

import AppConstants from '../../../constants/AppConstants';
import SmartBool from '../../../utils/SmartBool';

import {
  IDedupeMatch,
} from '../../../interfaces/dedupeInterfaces';

import DataAdminHeader from '../DataAdminHeader';
import DedupeMatch from './DedupeMatch';
import DedupePageState from './dedupePageState';

const {
  BRAND_NAME,
  MODEL_TYPES,
} = AppConstants;

export const DEDUPE_MODELS = [
  MODEL_TYPES.attorney,
  MODEL_TYPES.capitalProvider,
  MODEL_TYPES.case,
  MODEL_TYPES.medicalFacility,
  MODEL_TYPES.medicalProvider,
  MODEL_TYPES.partyOwnedLawFirm,
];

@inject('Clients', 'SessionStore')
@autoBindMethods
@observer
class DeduplicationPage extends Component {
  public pageState: DedupePageState;
  private isModalVisible = new SmartBool(false);

  constructor (props: any) {
    super(props);
    this.pageState = new DedupePageState(props.Clients, this.getModel(props), props.SessionStore);
  }

  public componentDidUpdate () {
    this.pageState.updateModel(this.getModel(this.props));
  }

  private getModel (props: any) {
    const { params: { model } } = props;
    return model || MODEL_TYPES.attorney.key;
  }

  private get modelDisplay () {
    const model = this.getModel(this.props)
      , modelObj = find(DEDUPE_MODELS, { key: model })
      , display = modelObj && (modelObj.shortDisplay || modelObj.display)
      ;

    return display || '';
  }

  private get isFromImport () {
    return has(this.pageState.filters, 'from_import');
  }

  private renderHelpModal () {
    if (!this.isModalVisible.isTrue) { return null; }

    return (
      <Modal className='modal-deduplication-help' show onHide={this.isModalVisible.setFalse}>
        <Modal.Header closeButton>
          <Modal.Title>How Does Deduplication Work?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className='lead'>{BRAND_NAME} finds possible duplicate entries of cases, law firms, attorneys, etc. and allows you to merge the duplicates into one entity. This helps you greatly increase the data integrity within your {BRAND_NAME} system.</p>

          <p className='lead'>Please note: the values and information in the “merged” entries will not be carried over to the “master” entry (i.e. phone numbers, emails, address, etc)</p>

          <h5>How to use it:</h5>
          <ol>
            <li>Select the desired type of entry you’d like to merge in the left sidebar</li>
            <li>Find duplicates you’d like to merge</li>
            <li>
              Select the &ldquo;master&rdquo; entry with the radio button
              <ul>
                <li><i>Note: Your master entry should be the entry with the highest amount of accurate information.</i></li>
              </ul>
            </li>
            <li>Select the duplicates you’d like to merge into that entry</li>
            <li>Click MERGE</li>
          </ol>

          <h5>For example:</h5>
          <blockquote>
            <p>If you select <i>Attorney 1</i> as the master and <i>Attorney 2</i> as the duplicate, then <i>Attorney 2</i> will be removed, but every case and law firm it had a connection with will now be linked to <i>Attorney 1</i>, the selected master.</p>
          </blockquote>
        </Modal.Body>
      </Modal>
    );
  }

  private renderSidebar () {
    return (
      <div className='sidebar light'>
        <div className='wrapper'>
          {this.isFromImport &&
            <div>
              <Well className='filters-text'>
                <Icon type='exclamation-circle'/> These counts are for all duplicates. To see the duplicates from a
                specific import, click on a type below.
              </Well>
            </div>
          }
          <ul className='list-links'>
            {DEDUPE_MODELS.map((modelObj: any) => {
              const count = this.pageState.getModelCount(modelObj.key)
                , pathname = `/data-admin/deduplication/${modelObj.key}`;

              return (
                <li key={modelObj.key}>
                  <Link className='flex-between' to={{ pathname }}>
                    <span className='name'>{modelObj.shortDisplay || modelObj.display}</span>
                    <span className='count'>({count})</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  private renderMatches () {
    if (!this.pageState.count) {
      return (
        <div className='maxwidth-lg'>
          <h2>{this.modelDisplay} Duplicates (0)</h2>
          <div className='msg-empty'>
            <h4>We could not find {this.modelDisplay} duplicates</h4>
            <p>
              We process your data every 24 hours to find duplicate entries
            </p>
          </div>
        </div>
      );
    }

    if (this.isFromImport && !this.pageState.dedupeMatches.length) {
      return (
         <div className='maxwidth-lg'>
          <h2>{this.modelDisplay} Duplicates</h2>
          <div className='msg-empty'>
            <h4>No {this.modelDisplay} duplicates match the given import</h4>
            <p>
              To see all duplicates{' '}
              <a className='filters-link' onClick={this.pageState.clearFilters}><b>click here</b></a>.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className='maxwidth-lg'>
        <h2 className='flex-between'>
          <span>{this.modelDisplay} Duplicates ({this.pageState.count})</span>
          <Button bsStyle='link' className='btn-help' onClick={this.isModalVisible.setTrue}>
            Need Help <Icon type='question-circle' />
          </Button>
        </h2>

        {this.isFromImport &&
          <Well className='filters-text'>
            <InfoWell>
              <Icon type='exclamation-circle' /> You are only seeing the duplicates attached to a specific import. To see all duplicates,
              {' '}<a className='filters-link' onClick={this.pageState.clearFilters}><b>click here</b></a>.
            </InfoWell>
          </Well>
        }

        {this.pageState.dedupeMatches.map(this.renderDedupeMatch)}

        {this.pageState.showLoadMore && (
          <div className='pad-y-half text-center'>
            <AwaitButton
              bsStyle='primary'
              className='btn-load-more'
              onClick={this.pageState.loadMore}
              text='Load More'
            />
          </div>
        )}
      </div>
    );
  }

  private renderDedupeMatch (dedupeMatch: IDedupeMatch) {
    if (this.pageState.isMatchMerged(dedupeMatch)) {
      return null;
    }

    if (this.pageState.isMatchDismissed(dedupeMatch)) {
      return null;
    }

    return (
      <DedupeMatch
        dedupeMatch={dedupeMatch}
        key={dedupeMatch.id}
        pageState={this.pageState}
      />
    );
  }

  public render () {
    return (
      <Page name='deduplication' type='detail' hasSidebar>
        <Helmet title='Deduplication' />
        <Page.Content>
          <DataAdminHeader title='Deduplication' />
          <div className='main'>
            {this.renderSidebar()}
            <Row>
              <Col className='col-main' xs={12}>
                {this.pageState.isLoadingInitial
                  ? <Loader className='page-loader' logo />
                  : this.renderMatches()
                }
                {this.renderHelpModal()}
                </Col>
            </Row>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default DeduplicationPage;
