import React, { Fragment } from 'react';
import { toJS } from 'mobx';
import { pick, isArray, isObject } from 'lodash';

function isRowErrorsObj (data: object) {
  // Checks if object is of shape { row, errors }
  const rowKeys = ['row', 'errors']
    , hasRowKeys = Object.keys(pick(data, rowKeys)).length === 2
    , keysLength = Object.keys(data).length;

  return (hasRowKeys && keysLength === 2);
}

function dataToList (dataArg: any): any {
  const data = toJS(dataArg);

  // If the data is just an array with 1 strong, unroll the array
  if (isArray(data) && data.length < 2) {
      const firstItem = data[0];
      if (firstItem && !isObject(firstItem)) {
        return firstItem;
      }
  }

  // If the data is an array, return it as an unordered list
  if (isArray(data)) {
    return (
      <ul>
        {data.map((item, idx) => (
          <li key={idx}>{dataToList(item)}</li>
        ))}
      </ul>
    );
  }

  // If the data is an object, convert it to an unordered list with list-items "key: value"
  if (isObject(data)) {

    // Special object formatting case: { row, error }
    if (isRowErrorsObj(data)) {
      return (
        <Fragment>
          <strong>Row {data.row}</strong>
          {dataToList(data.errors)}
        </Fragment>
      );
    }

    // All other objects
    return (
      <ul>
        {Object.keys(data).map(key => (
          <li key={key}><u>{key}</u>: {dataToList(data[key])}</li>
        ))}
      </ul>
    );
  }

  // If the data is a simple string or number, return it
  return data;
}

export default dataToList;
