import React, { Component, Fragment } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { kebabCase, isString } from 'lodash';
import { Well } from 'react-bootstrap';

import InfoWell from '../../components/common/InfoWell';
import Loader from '../../components/common/Loader';

import CardRow from './CardRow';
import { fillInFieldSets, getFieldSetFields, filterFieldSets } from './common';
import { IFieldSetPartial } from './interfaces';

interface IProps {
  children?: any;
  className?: any;
  fieldSets: IFieldSetPartial[];
  id?: string;
  isLoading?: boolean;
  model: object;
  renderTopRight?: (...args: any[]) => any;
  title?: string | React.ReactNode;
  wellProps?: object;
}

@autoBindMethods
@observer
class Card extends Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    fieldSets: [],
    model: {},
  };

  @computed
  get fieldSets () {
    return filterFieldSets(fillInFieldSets(this.props.fieldSets), { writeOnly: false });
  }

  public render () {
    const {
        className,
        id,
        isLoading,
        model,
        renderTopRight,
        title,
        wellProps,
      } = this.props
      , wellId = id || (title && isString(title) && kebabCase(title));

    if (isLoading) {
      return (
        <Well id={`well-${wellId}`} {...wellProps}>
          <h3>{title}</h3>
          <Loader className='bloc-loader' />
        </Well>
      );
    }

    return (
      <Well id={`well-${wellId}`} {...wellProps}>
        {title && (
          <h3>
            {title}
            {renderTopRight && renderTopRight()}
          </h3>
        )}

        <InfoWell className={className}>
          {this.fieldSets.map((fieldSet, idx) => (
            <Fragment key={idx}>
              {(idx > 0) && <hr key={`divider-${idx}`} />}

              {getFieldSetFields(fieldSet).map(fieldConfig => (
                <CardRow
                  fieldConfig={fieldConfig}
                  key={fieldConfig.field}
                  model={model}
                />
              ))}
            </Fragment>
          ))}

        </InfoWell>

        {this.props.children}
      </Well>
    );
  }
}

export default Card;
