import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { pluralize } from '@mighty-justice/utils';

import ClientsClass from '../../clients/ClientsClass';
import { FormModal } from '../../lib/mighty-fields';
import { SmartBool, toast } from '../../utils';
import { getCurrentDate } from '../../utils/util';

interface IRowData {
  case_id: string;
}

interface IProps {
 isVisible: SmartBool;
 selectedRows: IRowData[];
}

interface IInjected extends IProps {
  Clients: ClientsClass;
}

@inject('Clients')
@autoBindMethods
@observer
class CloseAllLiensModal extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get caseText () {
    const { selectedRows } = this.props
      , caseCount = selectedRows.length
      , casePlural = pluralize('case', 's', caseCount)
    ;

    return `${caseCount} selected ${casePlural}`;
  }

  private get explanationText () {
    return (
      <p>
        You are about to close all liens for the {this.caseText} and set the case closed date to the value below.
      </p>
    );
  }

  private async onCloseAllLiens (formData: object) {
    const { selectedRows } = this.props
      , { Clients } = this.injected
      , caseIds = selectedRows.map(row => row.case_id)
      ;

    await Clients.liens.bulkPatchAction('bulk-close', { ...formData, case_ids: caseIds });
    toast.success(`Success! You have closed all liens on the ${this.caseText}!`);
  }

  public render () {
    const { isVisible } = this.props;

    if (!isVisible.isTrue) {
      return null;
    }

    return (
      <FormModal
        childrenBefore={this.explanationText}
        fieldSets={[[{ field: 'date_closed', type: 'date' }]]}
        model={{ date_closed: getCurrentDate() }}
        onClose={isVisible.setFalse}
        onSave={this.onCloseAllLiens}
        saveText='Close liens'
        title='Close All Liens'
      />
    );
  }
}

export default CloseAllLiensModal;
