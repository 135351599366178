import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import {
  IDedupableObject,
  IDedupeMatch,
} from '../../../interfaces/dedupeInterfaces';

import DedupePageState from './dedupePageState';
import { Card, IFieldConfigPartial } from '../../../lib/mighty-fields';

interface IProps {
  dedupableObject: IDedupableObject;
  dedupeMatch: IDedupeMatch;
  pageState: DedupePageState;
}

@inject()
@autoBindMethods
@observer
class DedupeMatchRow extends Component<IProps> {
  private async handleCanonicalChange () {
    const { dedupeMatch, dedupableObject, pageState } = this.props;
    await pageState.handleCanonicalChange(dedupeMatch, dedupableObject);
  }

  private async handleMergeChange () {
    const { dedupeMatch, dedupableObject, pageState } = this.props;
    await pageState.handleMergeChange(dedupeMatch, dedupableObject);
  }

  private get selections () {
    const { dedupeMatch, pageState } = this.props;
    return pageState.getSelections(dedupeMatch);
  }

  private renderColumn (column: IFieldConfigPartial[], index: number) {
    const { dedupableObject } = this.props;

    return (
      <div key={index}>
        <Card fieldSets={[column]} model={dedupableObject} />
      </div>
    );
  }

  public render () {
    const { dedupableObject, pageState } = this.props
      , active = (dedupableObject.id === this.selections.canonical_id)
      , checked = this.selections.merge_ids.includes(dedupableObject.id)
      ;

    return (
      <tr key={dedupableObject.id} className={cx({ active })}>
        <td className='col-radio'>
          <ul className='radio-buttons condensed'>
            <li>
              <input
                checked={active}
                disabled={pageState.isLoading}
                id={`chk-primary-${dedupableObject.id}`}
                onChange={this.handleCanonicalChange}
                type='radio'
                value={dedupableObject.id}
              />
              <label htmlFor={`chk-primary-${dedupableObject.id}`} />
            </li>
          </ul>
        </td>
        <td>
          <div className='title'>
            <input
              checked={checked || active}
              disabled={active || pageState.isLoading}
              id={`chk-select-${dedupableObject.id}`}
              onChange={this.handleMergeChange}
              type='checkbox'
              value={dedupableObject.id}
            />
            <label htmlFor={`chk-select-${dedupableObject.id}`} />
            {pageState.modelDisplay.title(dedupableObject)}
          </div>

          <div className='flex-two-cols'>
            {pageState.modelDisplay.data.map(this.renderColumn)}
          </div>
        </td>
      </tr>
    );
  }
}

export default DedupeMatchRow;
