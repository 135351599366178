import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Modal, ButtonToolbar, Button, HelpBlock, Row } from 'react-bootstrap';

import { pluralize } from '@mighty-justice/utils';
import { FormFieldSets, IFieldSetPartial } from '../../lib/mighty-fields';
import { FormManager, FormattingUtils, SmartBool, toast } from '../../utils';
import { Form } from '../common-formsy';
import { Icon, Spacer } from '../common';
import { ContactStoreClass } from '../../stores';

const { getNameOrDefault } = FormattingUtils;

interface IRowData {
  case_id: string;
}

interface IProps {
  isVisible: boolean;
  onHide: () => void;
  onSave: (model: any) => void;
  onToggleMode: () => void;
  selectedRows: IRowData[];
}

interface IInjected extends IProps {
  ContactStore: ContactStoreClass;
}

@inject('ContactStore')
@autoBindMethods
@observer
class BulkCreateTrackingContactForm extends Component<IProps> {
  @observable private isShowingMoreOptions = new SmartBool();
  private formManager: FormManager;

  constructor (props: IProps) {
    super(props);

    this.formManager = new FormManager({
      fieldSets: this.fieldSets,
      onSave: this.onValidSubmit,
    });
  }

  private get injected () {
    return this.props as IInjected;
  }

  private get idFieldSet (): IFieldSetPartial {
    return [];
  }

  private get contactTypeFieldSet (): IFieldSetPartial {
    return [
      {
        field: 'contact_type',
        label: 'Contact Type',
        optionKey: 'value',
        optionType: 'contactTypeOptions',
        required: true,
        type: 'optionSelect',
      },
    ];
  }

  private get fullNameFieldSet (): IFieldSetPartial {
    return [
      {
        field: 'first_name',
        required: true,
      },
      {
        field: 'last_name',
        required: true,
      },
    ];
  }

  private get emailFieldSet (): IFieldSetPartial {
    return [
      {
        field: 'email',
        label: 'Email Address',
        type: 'email',
      },
    ];
  }

  private get moreOptionsGroup (): IFieldSetPartial {
    return [
      {
        field: 'phone_number',
        label: 'Phone #',
        required: false,
        type: 'phone',
      },
      {
        field: 'additional_contact_info.cell_number',
        label: 'Cell #',
        required: false,
        type: 'phone',
      },
      {
        field: 'additional_contact_info.fax_number',
        label: 'Fax #',
        required: false,
        type: 'phone',
      },
    ];
  }

  private get fieldSets () {
    return [
      this.idFieldSet,
      this.contactTypeFieldSet,
      this.fullNameFieldSet,
      this.emailFieldSet,
      this.moreOptionsGroup,
    ];
  }

  private get caseText () {
    const { selectedRows } = this.props
      , caseCount = selectedRows.length
      , casePlural = pluralize('case', 's', caseCount)
    ;

    return `${caseCount} selected ${casePlural}`;
  }

  @action
  private async onValidSubmit (formData: any) {
    const { ContactStore } = this.injected
      , { onHide, onSave } = this.props
      , contactName = getNameOrDefault(formData);

    try {
      const newContact = await ContactStore.createWithoutCase({
        contactData: formData,
        contactType: formData['contact_type'],
      });

      const data = { tracking_contact: newContact.id };
      await onSave(data);

      toast.success(`Success! You have created new tracking contact ${contactName} on the ${this.caseText}!`);
      onHide();
    } catch (error) {
      toast.error('There was an error creating the case tracking contact.');
      throw error;
    }
  }

  private toggleShowingMoreOptions () {
    this.isShowingMoreOptions.toggle();
  }

  private renderFieldSet (fieldSet: IFieldSetPartial) {
    const formManagerProps = this.formManager && this.formManager.formGroupsProps;

    return (
      <FormFieldSets fieldSets={[fieldSet]} {...formManagerProps}/>
    );
  }

  private renderMoreOptionsGroup () {
    const className = !this.isShowingMoreOptions.isTrue ? 'hidden' : '';

    return (
      <div className={className}>
        {this.renderFieldSet(this.moreOptionsGroup)}
        <HelpBlock>Format: (000) 000-0000</HelpBlock>
      </div>
    );
  }

  private renderToggleButton () {
    const optionDescriber = this.isShowingMoreOptions.isTrue ? 'Fewer' : 'More'
      , iconType = this.isShowingMoreOptions.isTrue ? 'angle-up' : 'angle-down'
      ;

    return (
      <Button bsStyle='link' onClick={this.toggleShowingMoreOptions}>
        {optionDescriber} options
        {' '}<Icon type={iconType} />
      </Button>
    );
  }

  public render () {
    const { isVisible, onHide, onToggleMode } = this.props;

    if (!isVisible) {
      return null;
    }

    return (
      <Modal show onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Create Case Tracking Contact</Modal.Title>
        </Modal.Header>
        <Form className='form-page modal-form-style bulk-create-tracking-contact' {...this.formManager.formProps}>
          <Modal.Body>
            {this.renderFieldSet(this.idFieldSet)}
            {this.renderFieldSet(this.contactTypeFieldSet)}
            {this.renderFieldSet(this.fullNameFieldSet)}

            <hr />
            <h5>Contact Information</h5>
            <Spacer />

            {this.renderFieldSet(this.emailFieldSet)}

            <Spacer />
            {this.renderToggleButton()}
            <Spacer />

            {this.renderMoreOptionsGroup()}

            <hr />
            {this.formManager.errorMessages.map(error => (
              <Row className='text-danger' key={error.field}>
                <strong>{error.field}</strong> - {error.message}
              </Row>
            ))}
            {!!this.formManager.errorMessages.length && <br/>}
            <span className='pull-right text-red'>
              *Creating an attorney will also assign that attorney to all selected cases
            </span>
            <div className='new-contact-button'>
              <Button bsStyle='link pull-right no-padding' bsSize='sm' onClick={onToggleMode}>
                Search for an existing contact instead
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar className='pull-right'>
              <Button onClick={onHide}>Cancel</Button>
              <Button bsSize='large' bsStyle='primary' className='btn-submit-new-tracking-contact' disabled={this.formManager.isSubmitting} type='submit'>
                {this.formManager.isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default BulkCreateTrackingContactForm;
