import React, { Component } from 'react';
import { UiStoreClass } from '../../stores';
import { MenuItem } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { createGuardedContainer, createDisabledContainer } from '../../containers';

import AppConstants from '../../constants/AppConstants';

const { PERMISSIONS } = AppConstants;

interface IProps {
  isDisabled: boolean;
}

interface IInjected extends IProps {
  UiStore: UiStoreClass;
}

@inject('UiStore')
@observer
class GuardedLogPaymentMenuItem extends Component <IProps> {

  private get injected () {
    return this.props as IInjected;
  }

  public render () {
    const { isDisabled } = this.props
      , { UiStore } = this.injected
      ;

    return (<MenuItem disabled={isDisabled} onSelect={UiStore.modals.CaseLogPayment.open}>Log Payment</MenuItem>);
  }

}

export default createGuardedContainer({
  disabledComponent: createDisabledContainer(GuardedLogPaymentMenuItem),
  enabledComponent: GuardedLogPaymentMenuItem,
  permissionName: PERMISSIONS.LOG_PAYMENT,
});
