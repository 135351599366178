import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import ClientsClass from '../../../../clients/ClientsClass';
import { FormModal } from '../../../../lib/mighty-fields';
import { SmartBool, toast } from '../../../../utils';

interface IProps {
 isVisible: SmartBool;
 selectedLiens: string[];
}

interface IInjected extends IProps {
  Clients: ClientsClass;
}

@inject('Clients')
@autoBindMethods
@observer
class MakeAvailableForCoopModal extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get explanationText () {
    const { selectedLiens } = this.props;

    return (
      <p>
        Would you like to make {selectedLiens.length} lien(s) available for CoOp Capital?
      </p>
    );
  }

  private async onMakesAvailableForCoop () {
    const { selectedLiens } = this.props
      , { Clients } = this.injected
      ;

    await Clients.liens.bulkPatchAction('bulk-set-is-available-to-coop', { lien_ids: selectedLiens });
    toast.success('Success!');
  }

  public render () {
    const { isVisible } = this.props;

    if (!isVisible.isTrue) {
      return null;
    }

    return (
      <FormModal
        childrenBefore={this.explanationText}
        fieldSets={[]}
        onClose={isVisible.setFalse}
        onSave={this.onMakesAvailableForCoop}
        saveText='Confirm'
        title='Make liens available for CoOp Capital'
      />
    );
  }
}

export default MakeAvailableForCoopModal;
