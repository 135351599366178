import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable, toJS } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { get } from 'lodash';
import { browserHistory } from 'react-router';

import {
  Alert,
  Col,
  Row,
} from 'react-bootstrap';

import { Loader } from '../common';
import { AppConstants } from '../../constants';
import { FormattingUtils } from '../../utils';
import { FormModal } from '../../lib/mighty-fields';
import { ICase, ILien } from '../../interfaces';
import { CaseStoreClass, FunderStoreClass, UiStoreClass } from '../../stores';
import ClientsClass from '../../clients/ClientsClass';

const { DATE_FORMATS, DOCUMENT_CONVERT_CHECK_INTERVAL, PAYOFF_ACTIONS } = AppConstants;
const {
  formatDate,
  pluralize,
} = FormattingUtils;

interface IProps {
  _case: ICase;
  action: 'download' | 'email';
  CaseStore: CaseStoreClass;
  FunderStore: FunderStoreClass;
  UiStore: UiStoreClass;
}

interface IInjected extends IProps {
  Clients: ClientsClass;
}

@inject(
  'CaseStore',
  'Clients',
  'FunderStore',
  'UiStore',
)
@autoBindMethods
@observer
class PayoffModal extends Component<IProps> {
  @observable private isSubmitting = false;
  private pollInterval?: any;

  // istanbul ignore next
  public componentWillUnmount () {
    if (!!this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }

  private get injected () {
    return this.props as IInjected;
  }

  private async pollCaseDocumentExists (documentId: string, resolve: () => any) {
    const { _case } = this.props
      , { Clients } = this.injected;

    try {
      await Clients.caseDocuments.retrieve(documentId);

      resolve();
      clearInterval(this.pollInterval);
      this.pollInterval = null;

      browserHistory.push({
        pathname: `/case/${_case.id}/share-documents`,
        state: {checkedDocumentIds: [documentId]},
      });
    // tslint:disable-next-line no-empty
    } catch (err) {}
  }

  private async onEmailClick (model: any, templateId: string) {
    const { _case, CaseStore } = this.props;

    this.isSubmitting = true;
    const documentId = await CaseStore.onCasePayoffEmailClick(_case, model, templateId);

    return new Promise(resolve => {
      this.pollInterval = setInterval(
        () => this.pollCaseDocumentExists(documentId, resolve)
        , DOCUMENT_CONVERT_CHECK_INTERVAL);
    });
  }

  public async onValidSubmit (model: any) {
    const {
        _case,
        action,
        CaseStore,
        FunderStore,
        UiStore,
      } = this.props
      , payoffTemplates = FunderStore.payoffTemplates;

    if (payoffTemplates.length === 1) {
      model.payoff_template = payoffTemplates[0].id;
    }
    const templateId = get(model, 'payoff_template', '');

    switch (action) {
      case PAYOFF_ACTIONS.download:
        CaseStore.onCasePayoffDownloadClick(_case, model.payoff_date, templateId);
        break;
      case PAYOFF_ACTIONS.email:
        await this.onEmailClick(model, templateId);
        break;
      default:
        throw new Error('Invalid action prop.');
    }

    UiStore.modals.Payoff.close();
  }

  get childrenBefore () {
    const { _case } = this.props
      , importedLienCount = _case.getLiens({ is_imported: true, is_open: true }).length
      , openLiensCount = _case.getLiens({ is_open: true }).length
      , lienReturnArrays = _case.getLiens({ is_open: true }).map((lien: ILien) => get(lien, 'lien_returns', []))
      , lienReturnsCount = lienReturnArrays.reduce((sum: number, lienReturnArray: any[]) => sum + lienReturnArray.length, 0)
      , langLiens = 'this lien'.split(' ').map(s => pluralize(s, openLiensCount, false)).join(' ')
      , langImportedLiens = pluralize('lien', importedLienCount, false)
      ;

    return (
      <div>
        {lienReturnsCount > 0 &&
          <Row>
            <Col>
              <Alert bsStyle='warning'>
                PLEASE NOTE: This payoff contains calculations from a partial payment toward the balance of {langLiens}. Please confirm accuracy.
              </Alert>
            </Col>
          </Row>
        }

        {importedLienCount > 0 &&
          <Row>
            <Col>
              <Alert bsStyle='warning'>
                PLEASE NOTE: This payoff contains calculations from {importedLienCount} imported {langImportedLiens}. Please confirm accuracy.
              </Alert>
            </Col>
          </Row>
        }
      </div>
    );
  }

  get footer () {
    if (!this.isSubmitting) {
      return null;
    }

    return (
      <div className='loader-footer'>
        Generating Payoff
        <Loader className='button-footer' />
      </div>
    );
  }

  get formDefaults () {
    const object: any = {
      payoff_date: formatDate((new Date()).toISOString(), DATE_FORMATS.date_value),
    };

    const payoffTemplates = this.props.FunderStore.payoffTemplates;
    if (payoffTemplates.length > 1) {
      object.payoff_template = payoffTemplates[0].id;
    }

    return object;
  }

  get fieldSets () {
    const { FunderStore } = this.props
      , fieldSets = []
      ;

    if (FunderStore.payoffTemplates.length > 1) {
      fieldSets.push([
        {
          field: 'payoff_template',
          options: toJS(FunderStore.payoffTemplates),
          type: 'dropdown',
        },
      ]);
    }

    fieldSets.push([
      { field: 'payoff_date', type: 'date', required: true },
    ]);

    return fieldSets;
  }

  public render () {
    const {
      action,
      UiStore,
    } = this.props;

    return (
      <FormModal
        childrenBefore={this.childrenBefore}
        fieldSets={this.fieldSets}
        footer={this.footer}
        model={this.formDefaults}
        onClose={UiStore.modals.Payoff.close}
        onSave={this.onValidSubmit}
        saveText={`${action} Payoff`}
        title={`${action} Payoff`}
      />
    );
  }
}

export default PayoffModal;
