import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';

import autoBindMethods from 'class-autobind-decorator';

import { Nav } from 'react-bootstrap';
import { ICase } from '../../../interfaces';
import AppConstants from '../../../constants/AppConstants';

import SidebarTabNav from './SidebarTabNav';
import TabActivities from './TabActivities';
import TabEmail from './TabEmail';
import TabSms from './TabSms';
import { FunderStoreClass, SessionStoreClass } from '../../../stores';

const {
  CASE_ASIDE_TABS,
} = AppConstants;

interface IProps {
  _case: ICase;
  aside: string;
  pathname: string;
}

interface IInjected extends IProps {
  FunderStore: FunderStoreClass;
  SessionStore: SessionStoreClass;
}

@inject('FunderStore', 'SessionStore')
@autoBindMethods
@observer
class SidebarFeed extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private renderNav () {
    const { FunderStore } = this.injected
    , {
        aside,
        pathname,
      } = this.props
      , navProps = {
      fromTab: aside,
      pathname,
      }
    , canUseSms = get(FunderStore.funder, 'can_use_sms', false);

    return (
      <Nav bsStyle='tabs' activeKey={aside}>
        <SidebarTabNav {...navProps} toTab={CASE_ASIDE_TABS.FEED} label='Feed' />
        <SidebarTabNav {...navProps} toTab={CASE_ASIDE_TABS.EMAIL} icon='envelope' label='Email' />
        {canUseSms && <SidebarTabNav {...navProps} toTab={CASE_ASIDE_TABS.SMS} icon='mobile' label='Send SMS' />}
      </Nav>
    );
  }

  private renderTab () {
    const {
      _case,
      aside,
    } = this.props;

    switch (aside) {
      case CASE_ASIDE_TABS.FEED:
        return <TabActivities _case={_case} key={ _case.id } />;
      case CASE_ASIDE_TABS.EMAIL:
        return <TabEmail _case={_case} />;
      case CASE_ASIDE_TABS.SMS:
        return <TabSms _case={_case} key={ _case.id } />;

      // istanbul ignore next
      default:
        throw new Error(`Unrecognized navigation key: '${aside}'`);
    }
  }

  public render () {
    const { _case } = this.props
      , { SessionStore } = this.injected;

    if (SessionStore.isMightyFreeUser) {
      return (
        <div className='sidebar-feed'>
          <TabActivities _case={_case} key={ _case.id } />
        </div>
      );
    }

    return (
      <aside>
        <div className='sidebar-feed'>
          {this.renderNav()}
          {this.renderTab()}
        </div>
      </aside>
    );
  }
}

export default SidebarFeed;
