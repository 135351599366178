import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import {
  IFormInput,
} from '../../interfaces';

import ObjectSelect from './ObjectSelect';

interface IProps extends IFormInput {
  objectRender: (value: any) => string;
  optionKey: string;
  optionType: string;
}

interface IInjected extends IProps {
  OptionsStore: { [key: string]: any };
}

@inject('OptionsStore')
@autoBindMethods
@observer
class OptionSelect extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public render () {
    const {
        optionKey,
        optionType,
      } = this.props
      , { OptionsStore } = this.injected;

    return (
      <ObjectSelect
        {...this.props}
        objects={OptionsStore[optionType]}
        objectKey={optionKey}
      />
    );
  }
}

export default OptionSelect;
