import { action, observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import { IDefendantInsurer, ITransport } from '../interfaces';

import CaseStoreClass from './CaseStoreClass';

const BASE_ENDPOINT = '/defendant-insurers/';

@autoBindMethods
class DefendantInsurerStoreClass {
  @observable public defendantInsurers: IDefendantInsurer[] | null = null;

  public caseStore: CaseStoreClass;
  public transport: ITransport;

  constructor (caseStore: CaseStoreClass, transport: ITransport) {
    this.caseStore = caseStore;
    this.transport = transport;
  }

  @action
  public async fetch (caseId: string) {
    const params = { defendant__case: caseId }
      , data = await this.transport.get(BASE_ENDPOINT, { params });

    this.defendantInsurers = data.results;

    return this.defendantInsurers;
  }

  @action
  public delete (defendantInsurerId: string) {
    if (this.defendantInsurers) {
      this.defendantInsurers = this.defendantInsurers.filter(({ id }) => id !== defendantInsurerId);
    }
    this.transport.delete(`${BASE_ENDPOINT}:defendantInsurerId/`, { urlParams: { defendantInsurerId } });
  }

  @action
  public async upsert (data: {[key: string]: any}) {
    if (!data.id) {
      const insurer = await this.transport.post(BASE_ENDPOINT, { data });
      this.defendantInsurers = this.defendantInsurers || [];
      this.defendantInsurers.push(insurer);
    }
    else {
      const updatedInsurer = await this.transport.put(
        `${BASE_ENDPOINT}:defendantInsurerId/`,
        { urlParams: { defendantInsurerId: data.id }, data },
      );

      if (this.defendantInsurers) {
        this.defendantInsurers = this.defendantInsurers.map((insurer) => {
          return insurer.id === updatedInsurer.id ? updatedInsurer : insurer;
        });
      }
    }
  }
}

export default DefendantInsurerStoreClass;
