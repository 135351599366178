import autoBindMethods from 'class-autobind-decorator';

import Client from './Client';

@autoBindMethods
class ClientDedupe extends Client {
  public async dismiss (id: string) {
    const response = await this.patchAction(id, 'dismiss', {});
    return response;
  }
}

export default ClientDedupe;
