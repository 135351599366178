import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import Helmet from 'react-helmet';
import autoBindMethods from 'class-autobind-decorator';
import { find, get } from 'lodash';

import { Page, Loader } from '../common';

import ClientsClass from '../../clients/ClientsClass';
import FunderStoreClass from '../../stores/FunderStoreClass';

interface IProps {
  params: {
    type: 'medical' | 'plaintiff' | 'provider';
  };
}

interface IInjected extends IProps {
  Clients: ClientsClass;
  FunderStore: FunderStoreClass;
}

interface IAnalytic {
  url: string;
}

@inject(
'Clients',
'FunderStore',
)
@autoBindMethods
@observer
class AnalyticsPage extends Component<IProps> {
  @observable private isLoading = true;
  private analyticData?: IAnalytic;
  private errorMessage: string = '';

  public componentDidMount () {
    window.addEventListener('message', this.handlePeriscopeEvents);
  }

  public componentWillUnmount () {
    // istanbul ignore next
    window.removeEventListener('message', this.handlePeriscopeEvents);
  }

  public componentWillMount () {
    this.setAnalytics(this.parameters);
  }

  public componentDidUpdate (prevProps: any) {
    if (prevProps.params.type !== this.props.params.type) {
      this.analyticData = undefined;
      this.setAnalytics(this.parameters);
    }
  }

  private handlePeriscopeEvents (e: any) {
    // istanbul ignore next
    if (e.origin === 'https://app.periscopedata.com' && e.data.event_type === 'drilldown') {
      const { destination_dashboard_id, filter_values } = e.data
        , drilldownFilters = filter_values.reduce(
          (accumulator: any, filter: any) => {
            const { filter_name, column_value } = filter;
            accumulator[filter_name] = column_value;
            return accumulator;
          },
          {}
        );

      this.setAnalytics({
        custom_dashboard_id: destination_dashboard_id,
        dashboard_type: 'custom',
        drilldown_filters: encodeURIComponent(JSON.stringify(drilldownFilters)),
      });
    }
  }

  private get injected () {
    return this.props as IInjected;
  }

  private get parameters () {
    const { params: { type } } = this.props
    , { FunderStore } = this.injected
    , customDashboardConfig = find(FunderStore.customAnalyticsConfigurations, (item) => item.name === type);

    if (!!customDashboardConfig) {
        return { dashboard_type: 'custom', custom_dashboard_id: customDashboardConfig.dashboard_id };
    }

    return { dashboard_type: type };
  }

  @action
  private async setAnalytics (params: any) {
    const { Clients: { periscopeEmbeddedDashboard } } = this.injected;

    this.errorMessage = '';
    this.isLoading = true;

    try {
      this.analyticData = await periscopeEmbeddedDashboard.retrieve({ params });
    }
    catch (err) {
      this.errorMessage = err.statusText || 'An error occurred.';
    }
    finally {
      if (!this.analyticData) {
        this.errorMessage = 'An error occurred.';
      }

      this.isLoading = false;
    }
  }

  public render () {
    const dashboardUrl = get(this.analyticData, 'url');

    return (
      <Page name='analytics' type='list'>
        <Helmet title='Analytics' />
        <Page.Content>
          {this.isLoading && <Loader className='page-loader' logo />}
          {(this.errorMessage || !dashboardUrl) && <div className='page-error'>{this.errorMessage}</div>}

          {dashboardUrl && <iframe className='fullscreen' frameBorder='0' src={dashboardUrl} />}
        </Page.Content>
      </Page>
    );
  }
}

export default AnalyticsPage;
