import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { browserHistory } from 'react-router';
import autoBindMethods from 'class-autobind-decorator';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import { Page } from '../common';

import { SmartBool } from '../../utils';
import Client from '../../clients/Client';
import ClientsClass from '../../clients/ClientsClass';
import CaseReferenceSidebar from '../common-collaboration/CaseReferenceSidebar';
import CaseUpdateTaskResponse from './CaseUpdateTaskResponse';
import DocumentSharingTaskResponse from './DocumentSharingTaskResponse';

import { AppConstants } from '../../constants';
import Loader from '../common/Loader';

const {
  ROUTING,
  USER_FLOW,
} = AppConstants;

interface IProps {
  params: {
    id: string;
  };
}

interface IInjected extends IProps {
  Clients: ClientsClass;
}

@inject('Clients')
@autoBindMethods
@observer
class TaskResponsePage extends Component<IProps> {
  @observable private isLoading = new SmartBool(true);
  @observable private task: any = null;

  private get injected () {
    return this.props as IInjected;
  }

  public async componentDidMount () {
    await this.fetch();

    if (this.task.completed_at) {
      browserHistory.push({pathname: `${ROUTING.tasks}/details/${this.task.id}`, state: USER_FLOW.FROM_COMPLETED_TASK_RESPONSE});
    }
  }

  @action
  private async fetch () {
    const { params: { id } } = this.props
      , { Clients } = this.injected;

    this.isLoading.set(true);
    this.task = await Clients.tasksReceived.retrieve(id);
    this.isLoading.set(false);
  }

  private get pageConfiguration () {
    const { Clients } = this.injected;

    return {
      'tasks.casetrackingupdatetask': {
        client: Clients.caseTrackingUpdateTasks,
        DetailComponent: CaseUpdateTaskResponse,
        titleString: 'Case Update',
      },
      'tasks.documentsharingtask': {
        client: Clients.documentSharingTask,
        DetailComponent: DocumentSharingTaskResponse,
        titleString: 'Documents',
      },
    } as {[key: string]: {client: Client, DetailComponent: any, titleString: string}};
  }

  public render () {
    if (this.isLoading.isTrue) {
      return <Loader className='page-loader' logo />;
    }

    const { client, DetailComponent, titleString } = this.pageConfiguration[this.task.type];

    return (
      <Page name='task-detail' type='detail' hasSidebar>
        <Helmet className='fs-mask' title={`Tasks - ${titleString} Request for ${this.task.plaintiff_name}`} />
        <CaseReferenceSidebar page='tasks' data={this.task} />
        <Page.Content>
          <header className='condensed'>
            <h1 className='fs-mask'>{titleString} Request for {this.task.plaintiff_name}</h1>
          </header>
          <div className='main'>
            <div className='col-main'>
              <div className='form-page'>
                <DetailComponent client={client} task={this.task} />
              </div>
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default TaskResponsePage;
