import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { pick, omit, get } from 'lodash';

import { Button } from 'react-bootstrap';

import { Form } from '../common-formsy';

import { AppConstants } from '../../constants';

import NEW_CASE_FIELD_SETS from './newCaseFieldSets';
import { FormFieldSets } from '../../lib/mighty-fields';
import FormManager from '../../lib/mighty-fields/FormManager';
import { CaseStoreClass } from '../../stores';
import { SmartBool, toast } from '../../utils';
import { browserHistory } from 'react-router';

const { MODEL_TYPES } = AppConstants;

interface IInjected {
  CaseStore: CaseStoreClass;
}

@inject('CaseStore')
@autoBindMethods
@observer
class SingleCaseForm extends Component <{}> {
  private isLoading = new SmartBool();
  private formManager: any;

  public constructor (props: any) {
    super(props);
    this.formManager = new FormManager({
      fieldSets: NEW_CASE_FIELD_SETS,
      onSave: this.onSubmit,
    });
  }

  private get injected () {
    return this.props as IInjected;
  }

  private async onSubmit (formData: any) {
    const { CaseStore } = this.injected
      , plaintiffFields = ['first_name', 'last_name']
      , plaintiff = pick(formData, plaintiffFields)
      , data = {...omit(formData, plaintiffFields), plaintiff}
      ;

    if (get(data, 'lawfirm.type') === MODEL_TYPES.verifiedLawFirm.key) {
      data.lawfirm.verified_lawfirm = data.lawfirm.id;
      delete data.lawfirm.id;
    }

    this.isLoading.setTrue();
    const newCase = await CaseStore.create({ data });
    this.isLoading.setFalse();
    toast.success('Success! The case has been added to your portfolio');
    browserHistory.push(`/case/${newCase.id}`);
  }

  public render () {
    return (
      <Form {...this.formManager.formProps} className='modal-form-style'>
        <FormFieldSets
          fieldSets={NEW_CASE_FIELD_SETS}
          {...this.formManager.formGroupsProps}
        />
        <hr />
        <Button disabled={this.isLoading.isTrue} type='submit' bsStyle='primary'>
          {this.isLoading.isTrue ? 'Loading...' : 'Add Case'}
        </Button>
      </Form>
    );
  }
}

export default SingleCaseForm;
