import autoBindMethods from 'class-autobind-decorator';

import Client from './Client';

@autoBindMethods
class ClientImports extends Client {
  public async validate (id: string) {
    const response = await this.patchAction(id, 'validate', {});
    return response;
  }

  public async testCommit (id: string) {
    const response = await this.patchAction(id, 'test-commit', {});
    return response;
  }

  public async previewApprove (id: string) {
    const response = await this.patchAction(id, 'review-preview', { approved: true });
    return response;
  }

  public async previewReject (id: string) {
    const response = await this.patchAction(id, 'review-preview', { approved: false });
    return response;
  }

  public async commit (id: string) {
    const response = await this.patchAction(id, 'commit', { approved: false });
    return response;
  }

  public async split (id: string) {
    const response = await this.patchAction(id, 'split', { approved: false });
    return response;
  }
}

export default ClientImports;
