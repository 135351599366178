import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { LinkContainer } from 'react-router-bootstrap';
import { some } from 'lodash';

import {
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';

import { Icon } from '../../common';

import { AppConstants } from '../../../constants';

const { CONTACT_TYPES } = AppConstants;

const TOOLTIP_CASE_CONTACTS = 'tooltip-case-contacts-table';

interface IProps {
  caseId: string;
  contacts: any[];
}

@autoBindMethods
@observer
class AddCaseContactDropdown extends Component<IProps> {

  private isContactTypeDisabled (contactType: string): boolean {
    if (contactType !== CONTACT_TYPES.ATTORNEY.type) {
      return false;
    }

    return some(this.props.contacts, ['contact_type', contactType]);
  }

  private renderMenuItem (typeKey: string) {
    const { caseId } = this.props
      , contactType = CONTACT_TYPES[typeKey]
      , disabled = this.isContactTypeDisabled(contactType.type)
      , tooltipProps = {
        'data-for': TOOLTIP_CASE_CONTACTS,
        'data-place': 'left',
        'data-tip': `Only one <b>${contactType.display}</b> can be set on a case.`,
        'data-tip-disable': !disabled,
      }
      , menuItem = (
        <MenuItem
          disabled={disabled}
          eventKey={`add-contact-${contactType.type}`}
          key={`add-contact-${contactType.type}`}
          {...tooltipProps}
        >
          {contactType.display}
        </MenuItem>
      );

    if (disabled) { return menuItem; }

    return (
      <LinkContainer
        key={`link-add-contact-${contactType.type}`}
        to={`/case/${caseId}/add-contact/${contactType.route}`}
      >
        {menuItem}
      </LinkContainer>
    );
  }

  public render () {
    const titleComponent = [<Icon key='icon' type='plus-square-o' />, 'Add New Contact'];

    return (
      <div className='dropdown-arrow right' >
        <DropdownButton
          bsStyle='link'
          id='dropdown-new-contact'
          noCaret
          pullRight
          title={titleComponent}
        >
          {Object.keys(CONTACT_TYPES).map(this.renderMenuItem)}
        </DropdownButton>
      </div>
    );
  }
}

export default AddCaseContactDropdown;
