import React, { Component, Fragment } from 'react';
import { computed, observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import { browserHistory } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import autoBindMethods from 'class-autobind-decorator';
import { debounce, get } from 'lodash';

import { Button, Col, Row } from 'react-bootstrap';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';

import ICase from '../../interfaces/models/ICase';
import { AppConstants } from '../../constants';
import { Icon, Loader, Page, Spacer } from '../common';
import { Form, Input } from '../common-formsy';
import { SmartBool, FormattingUtils, TableRenderingUtils, toast } from '../../utils';
import { SelectInputComponent } from '../../utils/TableRenderingUtils';
import { ContactStoreClass } from '../../stores';
import ClientsClass from '../../clients/ClientsClass';
import ContactExistsModal from './ContactExistsModal';
const { renderContactType, renderName, renderUnformatted } = TableRenderingUtils;
const { getNameOrDefault } = FormattingUtils;

const {
  CONTACT_TYPES,
  CONTACT_URL_TYPE_MAP,
  DEBOUNCE_DELAY,
  MAX_CONTACT_RESULT_DISPLAY,
  ROUTING,
} = AppConstants;

const CheckboxTable = checkboxHOC(ReactTable);

interface IProps {
  params: {
    id: string;
    type: string;
  };
}

interface IInjected extends IProps {
  Clients: ClientsClass;
  ContactStore: ContactStoreClass;
}

@inject('Clients', 'ContactStore')
@autoBindMethods
@observer
class AddContactPage extends Component<IProps> {
  @observable private _case: ICase | undefined = undefined;
  @observable private isLoadingSearch = new SmartBool();
  @observable private isSubmitting = new SmartBool();
  @observable private searchResults: object[] = [];
  @observable private searchTerm = '';
  @observable private selectedRow: null | string = null;
  @observable private showContactExistsModal = new SmartBool();
  private readonly createNewId: string = 'create-new';
  private readonly debouncedOnContactSearch: (event: any) => void;

  constructor (props: IProps) {
    super(props);

    if (!Object.keys(CONTACT_URL_TYPE_MAP).includes(this.props.params.type)) {
      browserHistory.push(`/case/${this.props.params.id}/contacts`);
    }
    this.debouncedOnContactSearch = debounce(this.onContactSearch, DEBOUNCE_DELAY);
  }

  public componentDidMount () {
    this.fetchCase(this.props.params.id);
  }

  private get injected () {
    return this.props as IInjected;
  }

  private get columns () {
    return [
      {
        Cell: renderName,
        className: 'col-name',
        Header: 'Name',
        id: 'fullName',
        minWidth: 160,
      },
      {
        accessor: 'contact_type',
        Cell: renderContactType,
        Header: 'Type',
        minWidth: 120,
      },
      {
        accessor: 'email',
        Cell: renderUnformatted,
        Header: 'Email',
        minWidth: 140,
      },
      {
        accessor: 'phone_number',
        Cell: renderUnformatted,
        Header: 'Phone #',
        minWidth: 80,
      },
            {
        accessor: 'additional_contact_info.cell_number',
        Cell: renderUnformatted,
        Header: 'Cell #',
        minWidth: 80,
      },
            {
        accessor: 'additional_contact_info.fax_number',
        Cell: renderUnformatted,
        Header: 'Fax #',
        minWidth: 80,
      },
    ];
  }

  private get contactType () {
    return CONTACT_URL_TYPE_MAP[this.props.params.type];
  }

  private get hasSearchQuery () {
    return this.searchTerm.trim() !== '';
  }

  private get hasNoSearchResults () {
    return (this.hasSearchQuery && !this.searchResults.length);
  }

  private get readableContactType () {
    return get(CONTACT_TYPES, `${this.contactType}.display`, '') as string;
  }

  @computed
  private get searchResultsWithOption () {
    return ([
      ...this.searchResults,
      {
        additional_contact_info: {
          cell_number: <br />,
          fax_number: <br />,
        },
        contact_type: <br />,
        email: <br />,
        first_name: `Create new ${this.readableContactType.toLowerCase()}`,
        id: this.createNewId,
        phone_number: <br />,
      },
    ]);
  }

  private async fetchCase (caseId: string) {
    const { Clients } = this.injected;

    const _case = await Clients.cases.retrieve(caseId)
      , contactExists = this.atContactTypeMax(_case)
      ;

    if (contactExists) {
      this.showContactExistsModal.setTrue();
    }
    this._case = _case;
  }

  private atContactTypeMax (_case: ICase) {
    if (this.contactType === CONTACT_TYPES.ATTORNEY.type) {
      return !!_case.attorney;
    }

    return false;
  }

  private onSearchChange (contactName: string) {
    this.searchTerm = contactName;
    this.debouncedOnContactSearch(contactName);
  }

  private async onContactSearch (contactName: string) {
    const { ContactStore } = this.injected;

    if (!!contactName.trim()) {
      const response = await this.isLoadingSearch.until(
        ContactStore.list({search: contactName}, this.contactType)
      );
      if (response.results.length > MAX_CONTACT_RESULT_DISPLAY) {
        response.results.length = MAX_CONTACT_RESULT_DISPLAY;
      }

      this.searchResults = response.results;
    } else {
      this.searchResults = [];
    }
  }

  private async onSubmit () {
    const { ContactStore } = this.injected
      , caseId = this.props.params.id
      ;

    if (this.hasNoSearchResults || this.selectedRow === this.createNewId) {
      browserHistory.push({
        pathname: `/case/${caseId}${ROUTING.caseCreateContact}/${this.props.params.type}`,
        state: this.searchTerm,
      });
      return;
    }

    await this.isSubmitting.until(
      ContactStore.assignToCase({
        _case: this._case,
        contactId: this.selectedRow,
        contactType: this.contactType,
      }),
    );

    toast.success('Success! A contact has been added to your case.');
    browserHistory.push(`/case/${caseId}/contacts`);
  }

  private isRowSelected (key: string) {
    return this.selectedRow === key;
  }

  private toggleRowSelection (key: string, _shift: any, _row: any) {
    this.selectedRow = key;
  }

  private renderSearchResults () {
    const tableProps = {
        isSelected: this.isRowSelected,
        selectedRows: this.selectedRow,
        selectType: 'radio',
        selectWidth: 35,
        toggleSelection: this.toggleRowSelection,
      };

    if (!this.hasSearchQuery) {
      return null;
    }

    if (this.isLoadingSearch.isTrue) {
      return (
        <div>
          Loading...
          <Spacer />
        </div>
      );
    }

    if (this.hasNoSearchResults) {
      return (
        <div>
          No existing contacts found. Click "Create New Contact" to continue.
        </div>
      );
    }

    return (
      <Fragment>
        <p className='info'>
          It looks like this contact already exists in your contacts.<br />You can select the correct contact below or add as a new contact.
        </p>
        <CheckboxTable
          className='-selectable -list -single-line -spaced-rows'
          columns={this.columns}
          data={toJS(this.searchResultsWithOption)}
          keyField='id'
          pageSize={this.searchResultsWithOption.length}
          minRows={0}
          resizable={false}
          selectType='radio'
          sortable={false}
          SelectInputComponent={SelectInputComponent}
          showPagination={false}
          {...tableProps}
        />
      </Fragment>
    );
  }

  public render () {
    const caseId = this.props.params.id
      , buttonText = this.hasNoSearchResults ? `Create new ${this.readableContactType}` : 'Next'
      , hasResultsAndUnselected = !this.hasNoSearchResults && !this.selectedRow
      , isSubmitButtonDisabled = hasResultsAndUnselected || this.isSubmitting.isTrue
      , plaintiffName = this._case ? getNameOrDefault(this._case.plaintiff) : ''
      ;

    if (this.showContactExistsModal.isTrue) {
      return (
        <ContactExistsModal
          contactType={this.readableContactType}
          linkPath={`case/${caseId}/contacts`}
          plaintiffName={plaintiffName}
        />
      );
    }

    if (!this._case) {
      return <Loader className='page-loader' logo />;
    }

    return (
      <Page name='add-contact' type='detail'>
        <Page.Content>
          <header className='condensed'>
            <div>
              <LinkContainer to={{pathname: `/case/${caseId}` }}>
                <Button bsStyle='link' className='go-back no-padding'><Icon type='angle-double-left' /> Back</Button>
              </LinkContainer>
              <h1 className='fs-mask'>Add New {this.readableContactType} for {plaintiffName}</h1>
            </div>
          </header>
          <div className='main'>
            <Col className='col-main' xs={12}>
              <Row>
                <Col xs={10} sm={10} lg={10}>
                  <Form className='form-page'>
                    <h5>Contact's Full Name</h5>
                    <div className='modal-form-style'>
                      <Input
                        field='contactName'
                        name='contactName'
                        onChange={this.onSearchChange}
                        placeholder='Full Name'
                        value={this.searchTerm}
                      />
                    </div>

                    <Spacer large />

                    <div className='section-search'>
                      {this.renderSearchResults()}
                    </div>

                    <Spacer large />

                    <hr />
                    <div className='footer'>
                      <Button
                        bsSize='large'
                        bsStyle='primary'
                        disabled={isSubmitButtonDisabled}
                        onClick={this.onSubmit}
                        type='submit'
                      >
                        {buttonText}
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Col>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default AddContactPage;
