/* eslint-disable sort-keys */

import { IFieldSetPartial } from '../../../lib/mighty-fields';

const FIELD_SETS_UNDERWRITER: IFieldSetPartial[] = [
  [
    { field: 'underwriter_summary', type: 'text' },
    { label: 'Projected Gross Settlement Value', field: 'value', type: 'money' },
    { field: 'expected_settlement_date', type: 'date' },
  ],
];

const FIELD_SETS_UNDERWRITER_ANALYSIS_SUMMARY: IFieldSetPartial[] = [
  [{ label: 'Coverage/Limits', field: 'coverage_limits', type: 'underwriterAnalysisField' }],
  [{ field: 'liability', type: 'underwriterAnalysisField' }],
  [{ label: 'Damages/Injuries', field: 'damages_injuries', type: 'underwriterAnalysisField' }],
  [{ label: 'Causation/Records', field: 'causation_records', type: 'underwriterAnalysisField' }],
  [{ label: 'Additional Liens/Prior Funding', field: 'additional_liens_prior_funding', type: 'underwriterAnalysisField' }],
  [{ label: 'Overall Score', field: 'ratingTotal', type: 'text', readOnly: true}],
];

export {
  FIELD_SETS_UNDERWRITER,
  FIELD_SETS_UNDERWRITER_ANALYSIS_SUMMARY,
};
