import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

interface IProps {
  checked: boolean;
  count?: number;
  countIsLoading: boolean;
  disabled?: boolean;
  label: string | React.ReactNode;
  onChange: (value: string, checked: boolean) => void;
  type?: 'checkbox' | 'radio';
  uniqueKey: string;
  value: string;
}

@autoBindMethods
@observer
class FacetListItem extends Component<IProps> {
  @observable private isChecked = false;

  public constructor (props: IProps) {
    super(props);
    this.isChecked = props.checked;
  }

  public componentWillReceiveProps (nextProps: IProps) {
    this.isChecked = nextProps.checked;
  }

  private onChange () {
    const { value, onChange } = this.props;
    this.isChecked = !this.isChecked;
    onChange(value, this.isChecked);
  }

  public render () {
    const { count, countIsLoading, disabled, label, type, uniqueKey, value } = this.props
      // disabled IF disabled prop OR not checked AND count is undefined or 0
      , isDisabled = !!disabled || (!this.isChecked && !count)
      , classNames = cx({ disabled: isDisabled});

    return (
      <li className={classNames} key={uniqueKey}>
        <input
          checked={this.isChecked}
          disabled={isDisabled}
          id={uniqueKey}
          onChange={this.onChange}
          type={type || 'checkbox'}
          value={value}
        />
        <label htmlFor={uniqueKey}>{label}</label>
        <span className='count'>({countIsLoading ? '...' : count || 0})</span>
      </li>
    );
  }
}

export default FacetListItem;
