import axios from 'axios';
import qs from 'qs';
import UrlPattern from 'url-pattern';
import autoBindMethods from 'class-autobind-decorator';
import { get, isArray } from 'lodash';
import fileSaver from 'browser-filesaver';
import mime from 'mime-types';
import contentDisposition from 'content-disposition';

import AppState from '../lib/AppState';
import { IAuthenticator, ITransport, IWindow } from '../interfaces';

import toast from './toast';
import selectAuthenticator from './selectAuthenticator';
import Storage from './Storage';
import { isTransportErrorUnauthorized, transportErrorToastMessage } from './transportErrorUtils';

const DEFAULT_HEADERS = {
    'Accept': 'application/json; charset=utf-8;',
    'Content-Type': 'application/json',
  }
  , LEADING_SLASH = /^\//
  , TRAILING_SLASH = /\/$/
  , FINALIZED_URL = /^https?.*$/
  , BACKEND_URL = /^\/backend\/.*$/
  ;

function joinUrl (base: string, path: string) {
  // Makes sure that base and path are joined by one, and only one, slash.
  return `${base.replace(TRAILING_SLASH, '')}/${path.replace(LEADING_SLASH, '')}`;
}

interface IHeaders {
  'Content-Type'?: string;
  'X-CSRFToken'?: string;
  'X-Client-Version'?: string;
  Authorization?: string;
  'X-Impersonated-User-Id'?: string;
}

interface IConstructorOptions {
  authenticator?: any;
  baseUrl: string;
  headers: IHeaders;
}

interface IInvokeOptions {
  data?: object;
  headers?: IHeaders;
  method: string;
  params?: object;
  protected?: boolean;
  responseType?: string;
  signal?: object;
  urlParams?: object;
  withCredentials?: boolean;
}

interface IAxiosOptions extends IInvokeOptions {
  paramsSerializer: (...args: any[]) => any;
  url: string;
}

@autoBindMethods
class Transport implements ITransport {
  public delete: (url: string, options?: object) => Promise<any>;
  public get: (url: string, options?: object) => Promise<any>;
  public patch: (url: string, options?: object) => Promise<any>;
  public post: (url: string, options?: object) => Promise<any>;
  public put: (url: string, options?: object) => Promise<any>;

  public authenticator?: IAuthenticator;
  public baseUrl: string;
  public headers: object;

  private get window () {
    return window as unknown as IWindow;
  }

  constructor ({ baseUrl, headers, authenticator }: IConstructorOptions) {
    this.baseUrl = baseUrl;
    this.headers = {...DEFAULT_HEADERS, ...headers};

    const AuthenticatorClass = authenticator || selectAuthenticator();
    if (AuthenticatorClass) {
      this.authenticator = new AuthenticatorClass(this);
    }

    // generate convenience methods, .get(..), .post(..), .put(..), .patch(..), .delete(..)
    const invokeForMethod = (method: string) => {
      return async (endpoint: string, options: any = {}) => {
        options.method = method;
        const response = await this._invoke(endpoint, options);
        return get(response, 'data');
      };
    };

    this.delete = invokeForMethod('DELETE');
    this.get = invokeForMethod('GET');
    this.patch = invokeForMethod('PATCH');
    this.post = invokeForMethod('POST');
    this.put = invokeForMethod('PUT');
  }

  get isAuthenticated () {
    if (this.authenticator) {
      return this.authenticator.isAuthenticated;
    }

    // istanbul ignore next
    return false;
  }

  public async getAuthentication (options: { data: { username: string, password: string }}) {
    if (this.authenticator && this.authenticator.getAuthentication) {
      return this.authenticator.getAuthentication(options);
    }
  }

  public async refreshAuthentication () {
    if (this.authenticator && this.authenticator.refreshAuthentication) {
      return (await this.authenticator.refreshAuthentication());
    }
  }

  public setAuthentication ({ token }: { token: string; }) {
    if (this.authenticator && this.authenticator.setAuthentication) {
      return this.authenticator.setAuthentication({ token });
    }
  }

  public clearAuthentication () {
    if (this.authenticator && this.authenticator.clearAuthentication) {
      return this.authenticator.clearAuthentication();
    }
  }

  public addAuthenticationToUrl (url: string): string | never {
    if (this.authenticator && this.authenticator.addAuthenticationToUrl) {
      return this.authenticator.addAuthenticationToUrl(url);
    }

    // istanbul ignore next
    return url; // Stub
  }

  public async getAll (endpoint: string, options: object = {}) {
    let url = endpoint
      , opt = options;
    const data: any[] = [];

    while (url) {
      const response = await this.get(url, opt)
        , results = response.results;

      results.forEach((item: any) => { data.push(item); });

      url = response.next;
      opt = {};
    }

    return data;
  }

  public async downloadFromS3URL (url: string, { options, errorMessage }: { options: object, errorMessage: string } = {errorMessage: 'Failed to Download...', options: {}}) {
    try {
      const staticOptions = { method: 'GET', params: { generate_url: true }, responseType: 'text' }
        , response = await this._invoke(url, { ...options, ...staticOptions })
        , data = get(response, 'data')
        ;

      this.window.location.href = data;
    }
    catch (e) {
      toast.error(errorMessage);
    }
  }

  public async getDocument (url: string, filename?: string, { options, errorMessage }: { options: object, errorMessage: string } = {errorMessage: 'Failed to Download...', options: {}}) {
    try {
      const response = await this._invoke(url, { ...options, method: 'GET', responseType: 'blob' });

      // In the case of no response, an error should already have been thrown
      // istanbul ignore next
      if (!response) { throw new Error(); }

      const { data, headers } = response
        , parsedContentDisposition = contentDisposition.parse(headers['content-disposition'])
        , filenameFromHeaders = get(parsedContentDisposition, 'parameters.filename')
        , realFilename = filename || filenameFromHeaders
        , fdocx = new window.Blob([data], {type: mime.lookup(realFilename)});

      fileSaver.saveAs(fdocx, realFilename);
    }
    catch (e) {
      toast.error(errorMessage);
    }
  }

  public paramsSerializer (params: { [key: string]: any }) {
    Object.keys(params).forEach(key => {
      if (isArray(params[key])) {
        params[key] = params[key].join(',');
      }
    });

    return qs.stringify(params);
  }

  private handleInvokeError (options: any, error: any) {
    error.isTransportError = true;

    const toastMessage = transportErrorToastMessage(error, options);
    if (toastMessage) {
      toast.error(toastMessage, { autoClose: false });
    }

    if (this.authenticator && isTransportErrorUnauthorized(error)) {
      this.authenticator.handleUnauthorized();
    }

    throw error;
  }

  private buildUrl (endpoint: string, urlParams?: object): string {
    if (FINALIZED_URL.test(endpoint)) {
      return endpoint;
    }

    const pattern = new UrlPattern(endpoint)
      , url = pattern.stringify(urlParams || {});

    if (BACKEND_URL.test(url)) {
      // Finalize URL and add authentication, so we don't hit the nonsensical /api/v1/backend/
      return this.addAuthenticationToUrl(`${this.window.Mighty.API_HOST}${url}`);
    }

    return joinUrl(this.baseUrl, url);
  }

  private invokeBuildOptions (endpoint: string, options: IInvokeOptions): IAxiosOptions {
    const headers: IHeaders = {};

    if (this.authenticator) {
      headers.Authorization = this.authenticator.getAuthorizationHeader();
    }

    if (get(AppState.SessionStore, 'isSessionHijacked', false)) {
      headers['X-Impersonated-User-Id'] = AppState.SessionStore.impersonatedUserId;
    }

    // if data is FormData type we can assume multipart/form-data (i.e. file upload)
    if ((options.data) instanceof this.window.FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    }

    return {
      data: options.data,
      headers: {
        ...this.headers,
        ...headers,
        ...options.headers,
        'X-Client-Version': this.window.Mighty.APP_HASH,
        'X-CSRFToken': Storage.getCookie(this.window.Mighty.CSRF_COOKIE_NAME),
      },
      method: options.method as string,
      params: options.params,
      paramsSerializer: this.paramsSerializer,
      protected: options.protected,
      responseType: options.responseType,
      signal: options.signal,
      url: this.buildUrl(endpoint, options.urlParams),
      withCredentials: true,
    };
  }

  // tslint:disable-next-line cyclomatic-complexity
  public async _invoke (endpoint: string, optionsArg: any) {
    const protectedCall = optionsArg.protected !== false;

    if (this.authenticator && protectedCall) {
      const prepared = await this.authenticator.prepareInvoke();
      if (!prepared) { return; }
    }

    const options = this.invokeBuildOptions(endpoint, optionsArg);

    // checking explicitly for false allows us to assume true if not set
    // i.e. protected is assumed to default to true
    if (!protectedCall) {
      return (await axios(options));
    }

    try {
      return (await axios(options));
    }
    catch (err) {
      this.handleInvokeError(options, err);
    }
  }
}

export default Transport;
