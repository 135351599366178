import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { LinkButton, PrimaryButton } from '../common';

interface IProps {
  confirmButtonText?: string;
  element?: string;
  fixed?: boolean;
  loading?: boolean;
  message?: string | React.ReactNode;
  onClickCancel?: () => void;
  onClickDelete?: () => void;
  showConfirmDelete?: boolean;
  small?: boolean;
}

@autoBindMethods
@observer
class ConfirmDelete extends Component<IProps, {}> {
  public static defaultProps = {
    confirmButtonText: 'Delete',
    element: 'div',
    showConfirmDelete: false,
  };

  public render () {
    const {
        confirmButtonText,
        fixed,
        loading,
        message,
        showConfirmDelete,
        small,
        onClickDelete,
        onClickCancel,
      } = this.props
      , ElementType = this.props.element as any
      , deleteButtonSize = small ? 'xsmall' : 'small'
      , classNames = cx(
        'confirm-delete',
        { fixed },
        { small },
      );

    return (
      showConfirmDelete && (
        <ElementType className={classNames}>
          <div>
            <div className='message'>{message}</div>
            <PrimaryButton
              className='btn-delete'
              disabled={loading}
              bsSize={deleteButtonSize}
              onClick={onClickDelete}
            >
              {confirmButtonText}
            </PrimaryButton>
            <LinkButton className='btn-cancel' onClick={onClickCancel}>
              Cancel
            </LinkButton>
          </div>
        </ElementType>
    ));
  }
}

export default ConfirmDelete;
