import React, { Component } from 'react';
import Formsy, { addValidationRule } from 'formsy-react';
import { noop, omit, mapKeys } from 'lodash';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import validationRules from './validationRules';

// Adds all validators from validationRules.js
mapKeys(validationRules, (value: any, key: string) => {
  addValidationRule(key, value);
  return null;
});

interface IFormsyFormProps {
  className?: string;
  id?: string;
  mapping?: any;
  onValidSubmit?: (model: object, resetForm: () => void, invalidateForm: () => void) => void;
}

interface IProps extends IFormsyFormProps {
  onInvalid?: () => void;
  onInvalidSubmit?: () => void;
  onSubmitErrorsChange?: (submitErrors: boolean) => void;
  onValid?: () => void;
  onValidChange?: (isValid: boolean) => void;
}

interface IPropDefaults extends IProps {
  onInvalid: () => void;
  onInvalidSubmit: () => void;
  onSubmitErrorsChange: (submitErrors: boolean) => void;
  onValid: () => void;
  onValidChange: (isValid: boolean) => void;
}

@autoBindMethods
@observer
class Form extends Component<IProps> {
  @observable private submitErrors = false;
  public refForm?: any;

  public static defaultProps: Partial<IProps> = {
    onInvalid: noop,
    onInvalidSubmit: noop,
    onSubmitErrorsChange: noop,
    onValid: noop,
    onValidChange: noop,
  };

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  public getModel () {
    return this.refForm.getModel();
  }

  public reset () {
    this.refForm.reset();
  }

  public submit () {
    this.refForm.submit();
  }

  public updateInputsWithError (errors: object) {
    this.refForm.updateInputsWithError(errors);
  }

  // Custom prop for this wrapper
  // Called whenever isValid or isInvalid would be called, with boolean of valid state
  private onValidChange (isValid: boolean) {
    this.propsWithDefaults.onValidChange(isValid);
  }

  // Custom prop for this wrapper
  // Called when valid state changes for form with submit attempt
  // Good for disabling Save until validation warnings are addressed
  private onSubmitErrorsChange (submitErrors: boolean) {
    // Only emits on change
    if (this.submitErrors !== submitErrors) {
      this.submitErrors = submitErrors;
      this.propsWithDefaults.onSubmitErrorsChange(submitErrors);
    }
  }

  // Middleman function to intercept change
  private handleInvalid () {
    this.propsWithDefaults.onInvalid();
    this.onValidChange(false);
  }

  // Middleman function to intercept change
  private handleValid () {
    this.propsWithDefaults.onValid();
    this.onValidChange(true);
    this.onSubmitErrorsChange(false);
  }

  // Middleman function to intercept change
  private handleInvalidSubmit () {
    this.propsWithDefaults.onInvalidSubmit();
    this.onSubmitErrorsChange(true);
  }

  private setRefForm (comp: any) {
    this.refForm = comp;
  }

  public render () {
    const { children } = this.props
      , wrapperProps = ['onValidChange', 'onSubmitErrorsChange']
      , passThroughProps = omit(this.props, wrapperProps);

    return (
      <Formsy
        {...passThroughProps}
        autoComplete='off'
        onInvalid={this.handleInvalid}
        onInvalidSubmit={this.handleInvalidSubmit}
        onValid={this.handleValid}
        ref={this.setRefForm}
      >
        {children}
      </Formsy>
    );
  }
}

export default Form;
