import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import Activity from './Activity';
import CaseActivity from './CaseActivity';

import {
  Loader,
} from '../common';

import { IActivity, ICase, IDocument } from '../../interfaces';

const UnTypedActivity: any = Activity;
const UnTypedCaseActivity: any = CaseActivity;

interface IProps {
  activities: IActivity[];
  _case: ICase;
  lienDocuments?: IDocument[];
  loading?: boolean;
  loadingNew?: boolean;
  showDeletedActivities?: boolean;
}

@inject()
@autoBindMethods
@observer
class ActivityList extends Component<IProps> {
  private filterActivity (activity: IActivity) {
    if (activity.deleted_at && !this.props.showDeletedActivities) {
      return false;
    }
    return true;
  }

  public render () {
    const { _case, activities, lienDocuments, loading, loadingNew } = this.props
      , validActivities = activities.filter(this.filterActivity).reverse()
    ;

    if (loading) {
      return <Loader className='bloc-loader loading-activities' />;
    }

    return (
      <div className='list-activities'>
        {loadingNew && (
          <div key='loading' className='activity-wrapper'>
            <UnTypedCaseActivity
              dot='small'
              activity={{
                id: 'loading',
                type: 'loading',
              }}
              activity_body={<span className='activity-loading'>Loading...</span>}
            />
          </div>
        )}

        {validActivities.map(activity => (
          <div key={activity.id} className='activity-wrapper'>
            <UnTypedActivity
              _case={_case}
              activity={activity}
              enableDelete
              lienDocuments={lienDocuments}
              viewDetails
            />
          </div>
        ))}
      </div>

    );
  }
}

export default ActivityList;
