import React from 'react';
import ReactToolTip from 'react-tooltip';

import AppConstants from '../../constants/AppConstants';

const { NO_PERMISSION_TEXT } = AppConstants;

interface IProps {
  children?: JSX.Element;
  class?: string;
  effect?: 'float' | 'solid';
  id?: string;
  offset?: object;
  place?: 'top' | 'bottom' | 'left' | 'right';
  type?: 'info';
}

const PermissionRequiredToolTip: React.SFC<IProps> = props => (
  <ReactToolTip {...props}>
    <span>{NO_PERMISSION_TEXT}</span>
  </ReactToolTip>
);

PermissionRequiredToolTip.defaultProps = {
  effect: 'solid',
  id: 'permission-required',
  offset: { bottom: 1, right: 2 },
  place: 'right',
  type: 'info',
};

export default PermissionRequiredToolTip;
